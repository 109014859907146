import {React, useContext } from 'react';import Default from './pages/error/Default.jsx';
import InternFeed from './pages/internships/InternFeed.jsx'

import LoginTest from './pages/authentication/LoginTest.jsx';

import StudentInternshipApplications from './pages/awetecksteam/internship-applications/StudentInternshipApplications.jsx'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ProfilePage from './pages/profile/ProfilePage.jsx';
import { Redirect } from 'react-router';
import { AuthContext } from "./context/AuthContext.js";

import TimelinePage from './pages/timeline/TimeLine.jsx';

import NotificationsPage from './pages/account/NotificationsPage.jsx';
import RegistrationsPage from './pages/account/RegistrationsPage.jsx';

import ENDPOINT from './context/EndPoint.js'
import OfferLetterPage from './pages/internships/OfferLetterPage.jsx';
import MyInternshipsPage from './pages/account/MyInternshipsPae.jsx';
import CertificatePage from './pages/internships/Certificate.jsx';
import { useState } from 'react';
import { useEffect } from 'react';

import StudentPayments from './pages/awetecksteam/payments/StudentPayments.jsx';
import StudentWorkshopRegistrations from './pages/awetecksteam/workshops/StudentWorkshopRegistrations.jsx';
import UsersList from './pages/awetecksteam/users/UsersList.jsx';

import ConfirmRegistrationPage from './pages/authentication/ConfirmRegistrationPage.jsx';
import InternDashboard from './pages/internships/InternDashboard.jsx';
import InternMeetings from './pages/internships/InternMeetings.jsx';
import InternTasks from './pages/internships/InternTasks.jsx';
import InternReport from './pages/internships/InternReport.jsx';
import InternLeaves from './pages/internships/InternLeaves.jsx';
import InternVerification from './pages/internships/InternVerification.jsx';
import InternAchievements from './pages/internships/InternAchievements.jsx';
import InternHelp from './pages/internships/InternHelp.jsx';
import InternWallet from './pages/internships/InternWallet.jsx';
import InternLeaderboard from './pages/internships/InternsLeaderboard.jsx';

import InternalSalesSummary from './pages/awetecksteam/InternalSalesSummary.jsx';
import InternalGrowthSummary from './pages/awetecksteam/InternalGrowthSummary.jsx';
import InternalStudentManagement from './pages/awetecksteam/InternalStudentManagement.jsx';
import InternalStudentsList from './pages/awetecksteam/InternalStudentsList.jsx';
import InternalInternshipApplications from './pages/awetecksteam/InternalInternshipApplications.jsx';
import InternalWorkshopRegistrations from './pages/awetecksteam/InternalWorkshopRegistrations.jsx';
import InternalAmbassadorRegistrations from './pages/awetecksteam/InternalAmbassadorRegistrations.jsx';
import InternalLeadsManagement from './pages/awetecksteam/InternalLeadsManagement.jsx';
import InternalUsersManagement from './pages/awetecksteam/InternalUsersManagement.jsx';
import InternalDoubtRequests from './pages/awetecksteam/supportrequests/InternalDoubtRequests.jsx';
import InternalSupportLogs from './pages/awetecksteam/supportrequests/SupportLogs.jsx';
import InternalCoursesManagement from './pages/awetecksteam/courses/InternalCoursesManagement.jsx';
import InternalChaptersManagement from './pages/awetecksteam/courses/ChaptersManagement.jsx';
import InternalTopicsManagement from './pages/awetecksteam/courses/InternalTopicsManagement.jsx';
import InternalClassSchedules from './pages/awetecksteam/courses/InternalClassSchedules.jsx';
import InternalAssignmentsList from './pages/awetecksteam/courses/InternalAssignmentsList.jsx';

import InternSinglePost from './pages/internships/InternSinglePost.jsx';

import SupportPage from './pages/contact/ChatPage.jsx';
import ChatPage from './pages/chat/ChatPage.jsx';
import InternalPlacementRequests from './pages/awetecksteam/supportrequests/InternalPlacementRequests.jsx';
import InternAttendance from './pages/internships/InternAttendance.jsx';
import InternActivity from './pages/internships/InternActivity.jsx';
import InternReports from './pages/internships/InternReports.jsx';

import AdminInternshipsList from './pages/awetecksteam/internship-applications/AdminInternshipsList.jsx';
import InternalInternshipManagement from './pages/awetecksteam/internships/InternalInternshipManagement.jsx';
import DesignBlogs from './pages/design/DesignBlogs.jsx';
import RewardEarnedPage from './pages/rewards/RewardEarnedPage.jsx';
import PersonalRewardInfo from './pages/rewards/PersonalRewardDetails.jsx';
import SecuritySettings from './pages/settings/SecuritySettings.jsx';
import InternalAllClassSchedules from './pages/awetecksteam/schedules/InternalAllClassSchedules.jsx';
import TeamDashboard from './pages/team/TeamDashboard.jsx';
import EditCourseOverview from './pages/awetecksteam/courses/EditCourseOverview.jsx';
import AdminAddCourse from './pages/awetecksteam/courses/AdminAddCourse.jsx';
import AdminCourseBatches from './pages/awetecksteam/courses/AdminCourseBatches.jsx';
import AdminCourseAddBatch from './pages/awetecksteam/courses/AdminCourseAddBatch.jsx';
import InternalVideoManagement from './pages/awetecksteam/videos/InternalVideoManagement.jsx';
import InternalEmployeeManagement from './pages/awetecksteam/employees/InternalEmployeeManagement.jsx';
import UploadVideo from './pages/awetecksteam/videos/UploadVideo.jsx';
import InternalAddInternship from './pages/awetecksteam/internships/InternalAddInternship.jsx';
import InternalWorkshopsanagement from './pages/awetecksteam/workshops/InternalWorkshopsManagement.jsx';
import InternalAddWorkshop from './pages/awetecksteam/workshops/InternalAddWorkshop.jsx';
import Localbase from 'localbase';
import HomeTemplate from './themes/HomeTemplate.jsx';
import InternshipTemplate from './themes/InternshipTemplate.jsx';
import DailyClassFeedbacks from './pages/awetecksteam/feedbacks/DailyClassFeedbacks.jsx';
import InternalCourseReviews from './pages/awetecksteam/feedbacks/CourseReviews.jsx';
import InternalWebsiteLog from './pages/awetecksteam/activities/InternalWebsiteLog.jsx';
import InternalTemplate from './themes/InternalTemplate.jsx';

import InternResources from './pages/internships/InternshipResources.jsx';
import AdminInternsTasks from './pages/awetecksteam/tasks/AdminInternsTasks.jsx';
import StudentsTaskSubmissions from './pages/awetecksteam/tasks/StudentsTaskSubmissions.jsx';
import AdminPostsManagement from './pages/awetecksteam/posts/AdminPostsManagement.jsx';

import AdminInternalLeaves from './pages/awetecksteam/leaves/InternalLeaves.jsx';
import InternalScholarshipApplications from './pages/awetecksteam/scholarship/InternalScholarshipApplications.jsx';

import WhatsAppChat from './pages/awetecksteam/supportrequests/WhatsAppChat.jsx';
import StudentCourseProfile from './pages/awetecksteam/student/StudentCourseProfile.jsx';
import InternalCommunityManagement from './pages/awetecksteam/InternalCommunityManagement.jsx';
import InternalQuizManagement from './pages/awetecksteam/InternalQuizManagement.jsx';
import WhatsAppSupport from './pages/awetecksteam/whatsapp/WhatsAppSupport.jsx';
import InternalAlumniManagement from './pages/awetecksteam/alumni/InternalAlumniManagement.jsx';
import axios from 'axios';
import SecurityHeaders from './context/SecurityHeaders.js';
import NoPermissionsTemplate from './themes/NoPermissionTemplate.jsx';
import IDCardManagement from './pages/awetecksteam/idcards/IDCardManagement.jsx';
import CertificateManagement from './pages/awetecksteam/certifications/CertificateManagement.jsx';
import Configurations from './pages/awetecksteam/config/Configurations.jsx';
import CompanyActivityLog from './pages/activity/CompanyActivityLog.jsx';
import WhatsAppNotifications from './pages/awetecksteam/whatsapp/WhatsAppNotifications.jsx';
import AddCourseAssignment from './pages/awetecksteam/courses/AddCourseAssignment.jsx';
import Planner from './pages/awetecksteam/planner/Planner.jsx';
import ResourceManagement from './pages/awetecksteam/courses/ResourceManagement.jsx';
import InternalMockTestsManagement from './pages/awetecksteam/InternalMockTestsManagement.jsx';
import InternalTeamManagement from './pages/awetecksteam/InternalTeamManagement.jsx';
import InternAddTask from './pages/awetecksteam/internships/InternAddTask.jsx';
import CheatSheetsManagement from './pages/awetecksteam/courses/CheatSheetsManagement.jsx';
import TechnicalQuizManagement from './pages/awetecksteam/TechnicalQuizManagement.jsx';
import MockTestQuestions from './pages/awetecksteam/MockTestQuestions.jsx';
import AddRemainder from './pages/awetecksteam/actions/AddRemainder.jsx';
import AddTeamTask from './pages/awetecksteam/actions/AddTeamTask.jsx';
import AddTeamMilestone from './pages/awetecksteam/actions/AddTeamMilestone.jsx';
import CouponCodes from './pages/awetecksteam/couponcodes/CouponCodes.jsx';
import AdminWebsiteSettings from './pages/settings/AdminWebsiteSettings.jsx';
import TeamPermissions from './pages/team/TeamPermissions.jsx';
import Remainders from './pages/awetecksteam/actions/Remainders.jsx';
import StudentAttendance from './pages/awetecksteam/student/StudentAttendance.jsx';
import InternWorkHistory from './pages/awetecksteam/internships/InternWorkHistory.jsx';
import CurrentDayClasses from './pages/awetecksteam/schedules/CurrentDayClasses.jsx';
import DailyReports from './pages/awetecksteam/reports/DailyReports.jsx';
import AddDailyReport from './pages/awetecksteam/reports/AddDailyReport.jsx';
import PermissionsManagement from './pages/awetecksteam/security/PermissionsManagement.jsx';
import HackingInternshipApplications from './pages/awetecksteam/internships/HackingInternshipApplications.jsx';
import InternalHackingScholarshipRegistration from './pages/awetecksteam/InternalHackingScholarshipRegistrations.jsx';
import InternBasedApplication from './pages/awetecksteam/internships/InternBasedApplication.jsx';
import BatchwiseAssignmentSubmission from './pages/awetecksteam/courses/BatchwiseAssignmentSubmission.jsx';
import UploadCertificateUrl from './pages/awetecksteam/certifications/UploadCertificateUrl.jsx';
import TrainerDashboard from './pages/trainer/TrainerDashboard.jsx';
import TrainerCoursesList from './pages/trainer/courses/TrainerCoursesList.jsx';
import TrainerStudentsList from './pages/trainer/courses/TrainerStudentsList.jsx';
import TrainerCourseBatches from './pages/trainer/TrainerCourseBatches.jsx';
import TrainerAssignmentsList from './pages/trainer/courses/TrainerAssignmentsList.jsx';
import TrainerClassSchedules from './pages/trainer/courses/TrainerClassSchedules.jsx';
import TrainerChaptersManagement from './pages/trainer/courses/TrainerChaptersManagement.jsx';
import TrainerAddCourse from './pages/trainer/courses/TrainerAddCourse.jsx'

import firebaseConfig from './firebase-Config.js';
import UpdateVideoDetails from './pages/awetecksteam/videos/UpdateVideoDetails.jsx';
import Expenses from './pages/awetecksteam/expenses/Expenses.jsx';
import TeamMilestones from './pages/awetecksteam/team-milestones/TeamMilestones.jsx';
import EditTeamMilestone from './pages/awetecksteam/team-milestones/EditTeamMilestone.jsx';
import InternalSummaryPage from './pages/awetecksteam/InternalSummaryPage.jsx';
import WorkHistory from './pages/awetecksteam/WorkHistory.jsx';
import CreateCertificate from './pages/awetecksteam/certifications/CreateCertificate.jsx';
function App() {



  const { user } = useContext(AuthContext);


  const db = new Localbase('db');



  const [permission,setpermission] = useState(JSON.parse(sessionStorage.getItem("permissions"))||null)
  const [account,setaccount]=  useState(null)
  useEffect(()=>{
      

    
    db.collection('paths').doc('user').get().then(user=>{
      setaccount(user.user)
    }).catch(error=>{

    })

if(user!==null||user!=="null"){
  getaccount();


}

       
}

,[user,window.location.pathname])


useEffect(()=>{
  if(permission===null){
  
  }

},[permission])

const  getaccount = async ()=>{
    
      
  try{

   fetch(`${ENDPOINT}get-account.php?session=${user.SessionId}`)
   .then(r=>r.json())
   .then(result=>{
   
  if(result){

 if((result.status==="success")&&(result.data!=null)){


   
   setaccount(result.data);
   db.collection('paths').add({
     user:result.data
   },'user')
   GetPermissions() 
 }else {
  db.collection('paths').doc('user').delete()

db.collection("paths").doc("notifications").delete()

  db.collection('paths').doc('profile').delete()
   //localStorage.setItem("user",null);
  // window.location.replace("/")
 }

  }
   else{
 
     localStorage.setItem("user",null);
   window.location.replace("/")
   }
   
 



   
       
   })
  }
  catch(err){

  }
         
 
    
         }    ;




const GetPermissions = async ()=>{
const data = new FormData()
if(user.SessionId){
  data.append("session",user.SessionId)
  const res = await axios.post(ENDPOINT+"admin-permissions.php",data,SecurityHeaders)

if(res){

if(res.data){
  if(res.data.status==="success"&res.data.permissions!==null){
    setpermission(res.data.permissions)
    sessionStorage.setItem("permissions",JSON.stringify(res.data.permissions))
  }else{
    
  }
}
}
}


}

  return (
    <div>





      
      <Router userdata={user} >
      <Switch>
        


      <Route exact path="/login">


<InternalTemplate permission={permission}>
{user&&permission?
<>
<Redirect to="/"></Redirect>

</>:
<>

<LoginTest account={account} permission={permission}/>
</>}

</InternalTemplate>

</Route>



<Route exact path="/my-internship/:internshipid/dashboard">
{user&&permission?
<>


<InternalTemplate permission={permission}>


<InternDashboard  userdata={user} permission={permission} timeline={"false"} account={account}/>
</InternalTemplate>




</>:
<Redirect to="/login"></Redirect>}
</Route>





<Route exact path="/internal/add-assignment/:course_url/:batch_id">
{user&&permission?
<>


<InternalTemplate permission={permission}>


<AddCourseAssignment userdata={user} permission={permission} timeline={"false"} account={account}/>
</InternalTemplate>




</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/hacking-scholarship-registrations">
{user&&permission?
<>


<InternalTemplate permission={permission}>
  <InternalHackingScholarshipRegistration  userdata={user} />



</InternalTemplate>




</>:
<Redirect to="/login"></Redirect>}
</Route>


























































<Route exact path="/trainer-dashboard/:trainer_id" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<TrainerDashboard account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/trainer/courses/add">
{user&&permission&&permission.transactions?
<>
<InternalTemplate>

<TrainerAddCourse  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>





<Route exact path="/trainer/:trainer_id/:course_url/:course_id/students">
{user&&permission&&permission.transactions?
<>
<InternalTemplate>

<TrainerStudentsList  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/trainer/:trainer_id/:course_url/:course_id/chapters">
{user&&permission&&permission.transactions?
<>
<InternalTemplate>

<TrainerChaptersManagement  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/trainer/:trainer_id/:course_url/:course_id/classes">
{user&&permission&&permission.transactions?
<>
<InternalTemplate>

<TrainerClassSchedules  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/trainer/:trainer_id/:course_url/:course_id/assignments">
{user&&permission&&permission.transactions?
<>
<InternalTemplate>

<TrainerAssignmentsList  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>





<Route exact path="/trainer/:trainer_id/:course_url/:course_id/batches">
{user&&permission&&permission.transactions?
<>
<InternalTemplate>

<TrainerCourseBatches  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/trainer/:trainer_id/course-list">
{user&&permission&&permission.transactions?
<>
<InternalTemplate>

<TrainerCoursesList  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/trainer/:trainer_id/student-list">
{user&&permission&&permission.transactions?
<>
<InternalTemplate>

<TrainerStudentsList  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/my-internship/:internshipid/attendance">
{user&&permission&&permission.transactions?
<>
<InternshipTemplate>

<InternAttendance  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternshipTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/student-attendance/:student_id">
{user&&permission&&permission.transactions?
<>
<InternshipTemplate>

<StudentAttendance  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternshipTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/my-internship/:internshipid/activity">
{user&&permission?
<>
<InternActivity  userdata={user} permission={permission} timeline={"true"}  account={account}/>
</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/my-internship/:internshipid/leaves">
{user&&permission?
<>
<InternshipTemplate>


<InternLeaves  userdata={user} permission={permission} timeline={"true"}  account={account}/>

</InternshipTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/my-internship/:internshipid/my-report">
{user&&permission?
<>
<InternReport  userdata={user} permission={permission} timeline={"true"}  account={account}/>
</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/my-internship/:internshipid/issues">
{user&&permission?
<>
<InternReports  userdata={user} permission={permission} timeline={"true"}  account={account}/>
</>
:<Redirect to="/"></Redirect>}
</Route>




<Route exact path="/my-internship/:id/help">
{user&&permission?
<>
<InternHelp  userdata={user} permission={permission}   account={account}/>
</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/my-internship/:internshipid/achievements">

{user&&permission?
<>
<InternshipTemplate>

<InternAchievements userdata={user} permission={permission}  timeline={"true"} account={account}/>


</InternshipTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/my-internship/:internshipid/wallet">
{user&&permission?
<>
<InternWallet userdata={user} permission={permission}  account={account}/>
</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/my-internship/:internshipid/report">
{user&&permission?
<>
<InternReport userdata={user} permission={permission}  account={account}/>
</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/my-internship/:internshipid/verification">
{user&&permission?
<>
<InternVerification userdata={user} permission={permission}  account={account}/>
</>
:<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/my-internship/:internshipid/leaderboard">
{user&&permission?
<>
<InternLeaderboard userdata={user} permission={permission} timeline={"true"}  account={account}/>
</>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/my-internship/:id/leaves">
{user&&permission?

<>
<InternLeaves userdata={user} permission={permission}  account={account}/>
</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/my-internship/:id/certificate">

{user&&permission?

<>
<InternLeaves userdata={user} permission={permission}  account={account}/>
</>:
<Redirect to="/login"></Redirect>
}

</Route>


<Route exact path="/my-internship/:internshipid/meetings">
{user&&permission?
<>
<InternshipTemplate>

<InternMeetings  userdata={user} permission={permission} timeline={"true"} account={account}/>


</InternshipTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/my-internship/:internshipid/feed">
{user&&permission?<>
  <InternshipTemplate>

  <InternFeed  userdata={user} permission={permission} timeline={"true"} account={account}/>

  </InternshipTemplate>

  </>:
  <Redirect to="/login"></Redirect>
  }</Route>



<Route exact path="/support/ticket:ticket_id">
<SupportPage  userdata={user} permission={permission} timeline={"true"} account={account}/>
</Route>



<Route  path="/chat/:conversationid">
  {user&&permission?<>
<HomeTemplate bottomMenu={"false"}>
<ChatPage  userdata={user} permission={permission} timeline={"true"} account={account}/>

</HomeTemplate>
  </>:
  <Redirect to="/login"></Redirect>
  }</Route>


<Route  path="/chat">
  {user&&permission?<>
<HomeTemplate bottomMenu={"false"}>
<ChatPage  userdata={user} permission={permission} timeline={"true"} account={account}/>

</HomeTemplate>
  </>:
  <Redirect to="/login"></Redirect>
  }</Route>



<Route exact path="/my-internship/:internshipid/feed/:post_id">
{user&&permission?<>
<InternSinglePost  userdata={user} permission={permission} timeline={"true"} account={account}/>
  </>:
  <Redirect to="/login"></Redirect>
  }</Route>



<Route exact path="/my-internship/:internshipid/feed/:postid">
{user&&permission?<>
  
<InternSinglePost userdata={user} permission={permission} timeline={"true"} account={account}/>

  </>:
  <Redirect to="/login"></Redirect>
  }</Route>



<Route exact path="/my-internship/:internshipid/tasks">
{user&&permission?<>
  <InternshipTemplate>
  <InternTasks  userdata={user} permission={permission} timeline={"true"} account={account}/>


  </InternshipTemplate>

  </>:
  <Redirect to="/login"></Redirect>
  }</Route>





<Route exact path="/my-internship/:id/resources">
{user&&permission?<>
  
<InternResources  userdata={user} permission={permission}  account={account}/>

  </>:
  <Redirect to="/login"></Redirect>
  }</Route>






















<Route exact path="/offerletter/:applicationid/:internshipid"  >
{user&&permission?<>
  <OfferLetterPage  account={account}/>
  </>:
  <Redirect to="/login"></Redirect>
  }

</Route>







<Route exact path="/whatsapp/:phone_number"  >
{user&&permission?<>
<InternalTemplate permission={permission}>

<WhatsAppSupport  account={account}/>
</InternalTemplate>
  </>:
  <Redirect to="/login"></Redirect>
  }

</Route>




<Route exact path="/id-cards"  >
{user&&permission?<>
<InternalTemplate permission={permission}>

<IDCardManagement  account={account}/>
</InternalTemplate>
  </>:
  <Redirect to="/login"></Redirect>
  }

</Route>






<Route exact path="/certificate"  >
{user&&permission?
<>
<HomeTemplate bottomMenu={"false"}>
<CertificatePage account={account}/>
</HomeTemplate>
</>:
<Redirect to="/login"></Redirect>
}
</Route>




<Route exact path="/rewards/:rewardid" >
<HomeTemplate bottomMenu={"false"}>
<RewardEarnedPage  account={account}/>
</HomeTemplate>
</Route>



<Route exact path="/reward-earned/:badgeid" >

  <HomeTemplate bottomMenu={"false"}>
    
<RewardEarnedPage userdata={user} permission={permission}  page={"rewards"}  account={account}/>

  </HomeTemplate>
</Route>




<Route exact path="/reward-info/:badgeid" >
<HomeTemplate bottomMenu={"false"}>
<PersonalRewardInfo userdata={user} permission={permission}  page={"rewards"}  account={account}/>

</HomeTemplate>
</Route>

























<Route exact path="/profile-settings" >
{user&&permission?

<HomeTemplate bottomMenu={"false"}>

<SecuritySettings account={account}/>
</HomeTemplate>
 : <Redirect to="/login"/>
}
</Route>






<Route exact path="/website-settings" >
{user&&permission?

<HomeTemplate bottomMenu={"false"}>

<AdminWebsiteSettings account={account}/>
</HomeTemplate>
 : <Redirect to="/login"/>
}
</Route>








<Route exact path="/website-settings" >
{user&&permission?

<HomeTemplate bottomMenu={"false"}>

<AdminWebsiteSettings account={account}/>
</HomeTemplate>
 : <Redirect to="/login"/>
}
</Route>






<Route exact path="/team-permissions" >
{user&&permission?

<HomeTemplate bottomMenu={"false"}>

<TeamPermissions account={account}/>
</HomeTemplate>
 : <Redirect to="/login"/>
}
</Route>












<Route exact path="/notifications" >
{user&&permission?
<InternalTemplate bottomMenu={"false"}>

<NotificationsPage bottomMenu={"false"}  account={account}/>

</InternalTemplate>:<Redirect to="/login"/>
}
</Route>




<Route exact path="/reminders" >
{user&&permission?
<HomeTemplate bottomMenu={"false"}>

<Remainders bottomMenu={"false"}  account={account}/>

</HomeTemplate>:<Redirect to="/login"/>
}
</Route>





<Route exact path="/configurations" >
{user&&permission?
<InternalTemplate bottomMenu={"false"}>

<Configurations bottomMenu={"false"}  account={account}/>

</InternalTemplate>:<Redirect to="/login"/>
}
</Route>






<Route exact path="/activities" >
{user&&permission?
<InternalTemplate bottomMenu={"false"}>

<CompanyActivityLog bottomMenu={"false"}  account={account}/>

</InternalTemplate>:<Redirect to="/login"/>
}
</Route>





<Route exact path="/whatsapp-notifications" >
{user&&permission?
<InternalTemplate bottomMenu={"false"}>

<WhatsAppNotifications bottomMenu={"false"}  account={account}/>

</InternalTemplate>:<Redirect to="/login"/>
}
</Route>
















<Route exact path="/user">
{user&&permission?
<HomeTemplate bottomMenu={"false"}>
<ProfilePage  userdata={user} permission={permission} page="Profile"  account={account}/>
  
</HomeTemplate>
  :
<Redirect to="/login"/>
}
</Route>

<Route exact path="/timeline">
{user&&permission?

<HomeTemplate bottomMenu={"false"}>
<TimelinePage  account={account}/>

</HomeTemplate>
:
<Redirect to="/login"/>
}
</Route>






















<Route exact path="/registrations" userdata={user} permission={permission}>
{user&&permission?
<>
<RegistrationsPage  account={account} />
</>:
<Redirect to="/login"></Redirect>}

</Route>



<Route exact path="/work" >

{user&&permission?
<>
<HomeTemplate bottomMenu={"false"}>

<MyInternshipsPage  />
</HomeTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/work-history/:intern_id/:internship_id" >

{user&&permission?
<>
<HomeTemplate bottomMenu={"false"}>

<InternWorkHistory  />
</HomeTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/sales-summary/:status" userdata={user} permission={permission}>
{account?account.role==="admin"?
<>
<InternalTemplate permission={permission}>
<StudentPayments account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>


:"no permissions"
:"no permissions"}

</Route>





<Route exact path="/dashboard" userdata={user} permission={permission}>
{account?account.role==="admin"?
<>
<InternalTemplate permission={permission}>
<InternalSummaryPage account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>


:"no permissions"
:"no permissions"}

</Route>


<Route exact path="/internal/whatsapp" userdata={user} permission={permission}>
{account?account.role==="admin"?
<>
<InternalTemplate permission={permission}>
<WhatsAppChat account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>


:"no permissions"
:"no permissions"}





</Route>




<Route exact path="/internal/tasks" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<AdminInternsTasks account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/student-posts" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<AdminPostsManagement account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/students-tasks" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<StudentsTaskSubmissions account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/scholarship-applications" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalScholarshipApplications account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/hacking-internship-registrations/:category" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<HackingInternshipApplications account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/expenses" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<Expenses account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/team-milestones" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<TeamMilestones account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/edit-team-milestones/:id" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<EditTeamMilestone account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>












<Route exact path="/internal/users" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<UsersList account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>

  {permission?
<>
<InternalStudentManagement account={account} userdata={user} permission={permission}/>
</>:
<>
<NoPermissionsTemplate/>
</>}

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalStudentManagement account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/sales-summary" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalSalesSummary counters={true} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/student-course-profile/:purchase_id" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<StudentCourseProfile counters={true} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/community-management" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalCommunityManagement counters={true} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/leaves" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<AdminInternalLeaves  account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/growth-summary" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>




<InternalGrowthSummary account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/finance-summary" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalSalesSummary account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/student-management" userdata={user} permission={permission}>

{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalStudentManagement account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>





<Route exact path="/internal/employee-management" userdata={user} permission={permission}>

{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalEmployeeManagement account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/alumni-management" userdata={user} permission={permission}>

{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalAlumniManagement account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/internship-applications/:category" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternalInternshipApplications account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>





<Route exact path="/team-management" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternalTeamManagement account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/work-history/:user_id/internship/:internship_id" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<WorkHistory account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/add-internship-task/:user_id/:internship_id" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternAddTask account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/certificate-management" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<CertificateManagement account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/create-certificate" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<CreateCertificate account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>
<Route exact path="/certificate-upload" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<UploadCertificateUrl account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/workshop-registrations/:category" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalWorkshopRegistrations account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/cai-registrations/:category" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalAmbassadorRegistrations account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/internships/:category" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>

<AdminInternshipsList account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/internships" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>

<InternalInternshipManagement account={account} userdata={user} permission={permission}/>

  </InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/leads/:category/:status" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalLeadsManagement account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/student-management/:category" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>

<InternalStudentsList account={account} userdata={user} permission={permission}/>

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/trainings-management/courses/:courseurl/students/:category" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>

<InternalStudentsList account={account} userdata={user} permission={permission}/>
</InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/workshop-registrations" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<StudentWorkshopRegistrations account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/users-management" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalUsersManagement account={account} userdata={user} permission={permission}/>
</InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/design-blogs" userdata={user} permission={permission}>
<DesignBlogs account={account} userdata={user} permission={permission}/>

</Route>


<Route exact path="/internal/doubts/:status" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternalDoubtRequests account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/quiz-management" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternalQuizManagement account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/add-team-task" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<AddTeamTask account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/add-team-milestone" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<AddTeamMilestone account={account} userdata={user} permission={permission}/>
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/technical-quizzes/:course_url" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternalMockTestsManagement test_type={"technical-quiz"} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/coupon-codes" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<CouponCodes test_type={"technical-quiz"} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/mock-tests/:course_url" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternalMockTestsManagement test_type={"mock-test"} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/course-certification/:course_url" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternalMockTestsManagement test_type={"course-certification"} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/mock-test/:course_url/test-code/:test_code" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<MockTestQuestions test_type={"mock-test"} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/course-certification/:course_url/test-code/:test_code" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<MockTestQuestions test_type={"course-certification"} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/add-reminder" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<AddRemainder test_type={"mock-test"} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>





<Route exact path="/technical-quiz/:course_url/test-code/:test_code" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<MockTestQuestions test_type={"technical-quiz"} account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/mock-test-management" userdata={user} permission={permission}>
{user&&permission?
<>

<InternalTemplate permission={permission}>
<InternalMockTestsManagement account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/placement-requests/:status" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalPlacementRequests account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/support/:category" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalSupportLogs account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/vcp/:code" userdata={user} permission={permission}>
<ConfirmRegistrationPage/>

</Route>


<Route exact path="/internal/internship-applications" userdata={user} permission={permission}>
{user&&permission?
<>
<InternalTemplate permission={permission}>
<StudentInternshipApplications account={account} userdata={user} permission={permission}/>

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>






<Route exact path="/internal/internships-management">
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalInternshipManagement page="Internships"  />

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/internships-management/add">
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalAddInternship page="Internships"  />

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/workshops-management">
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalWorkshopsanagement page="Internships"  />

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/workshops-management/add">
{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalAddWorkshop page="Internships"  />

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/trainings-management/courses/edit/:courseid">
{user&&permission?
<>
<InternalTemplate permission={permission}>
<EditCourseOverview page="Internships"  />
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/trainings-management/courses/:mode">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalCoursesManagement page="Internships"  />

</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/trainings-management/course/add">
{user&&permission?
<>
<InternalTemplate permission={permission}>
<AdminAddCourse  />
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/video-management">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalVideoManagement  />
  </InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>
<Route exact path="/internal/video-management/edit/:video_id">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<UpdateVideoDetails/>
  </InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/video-management/upload">
{user&&permission?
<>
<InternalTemplate permission={permission}>
<UploadVideo  />
</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/trainings-management/course/:courseurl/:courseid/batches">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<AdminCourseBatches  />
</InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/trainings-management/course/:courseurl/:courseid/addbatch">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<AdminCourseAddBatch  />
</InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/trainings-management/courses/assignment-submission/:courseurl/:batchid">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<BatchwiseAssignmentSubmission/>
</InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/trainings-management/feedbacks">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<DailyClassFeedbacks  />
</InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/trainings-management/reviews">
{user&&permission?
<>
<InternalTemplate permission={permission}>
  
<InternalCourseReviews />
  </InternalTemplate>
</>:
<Redirect to="/login"></Redirect>}
</Route>

<Route exact path="/internal/activity-log">
{user&&permission?
<>


<InternalTemplate>

{parseInt(permission.website_log)===1?
<>
<InternalWebsiteLog />
</>:<>
<NoPermissionsTemplate/>
</>}

</InternalTemplate>



</>:
<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/internal/trainings-management/courses/:courseurl/chapters">

{user&&permission?
<>
<InternalTemplate permission={permission}>
<InternalChaptersManagement page="Internships"  />

</InternalTemplate>

</>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/trainings-management/:courseurl/:batchid/schedules">
{user&&permission?
<>
<InternalTemplate permission={permission}>
{permission.classes_management?
<>
<InternalClassSchedules page="Internships"  />
</>:
<>
<NoPermissionsTemplate/>
</>}



</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>





<Route exact path="/internal/daily-reports">
{user&&permission?
<>
<InternalTemplate permission={permission}>
{permission.classes_management?
<>
<DailyReports page="Daily Reports"  />
</>:
<>
<NoPermissionsTemplate/>
</>}



</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/internal/permissions/:user_id">
{user&&permission?
<>
<InternalTemplate permission={permission}>
{permission.classes_management?
<>
<PermissionsManagement page="Daily Reports"  />
</>:
<>
<NoPermissionsTemplate/>
</>}



</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>











<Route exact path="/internal/daily-reports/add">
{user&&permission?
<>
<InternalTemplate permission={permission}>
{permission.classes_management?
<>
<AddDailyReport page="Daily Reports"  />
</>:
<>
<NoPermissionsTemplate/>
</>}



</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>












<Route exact path="/internal/classes-today">
{user&&permission?
<>
<InternalTemplate permission={permission}>
{permission.classes_management?
<>
<CurrentDayClasses page="Internships"  />
</>:
<>
<NoPermissionsTemplate/>
</>}



</InternalTemplate></>:
<Redirect to="/login"></Redirect>}
</Route>



<Route exact path="/internal/classes">
{user&&permission?
<>
<InternalTemplate permission={permission}>
{permission.classes_management?
<>
<InternalAllClassSchedules account={account} />

</>:
<>
<NoPermissionsTemplate/>
</>}




</InternalTemplate></>
:<Redirect to="login"></Redirect>}
</Route>





<Route exact path="/internal/planner">
{user&&permission?
<>
<InternalTemplate permission={permission}>
{permission.classes_management?
<>
<Planner account={account} />

</>:
<>
<NoPermissionsTemplate/>
</>}




</InternalTemplate></>
:<Redirect to="login"></Redirect>}
</Route>





<Route exact path="/internal/resource-management/:course_url">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<ResourceManagement page="Internships"  />

</InternalTemplate></>:<Redirect to="/login"></Redirect>}
</Route>


<Route exact path="/cheat-sheets/:course_url">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<CheatSheetsManagement page="Internships"  />

</InternalTemplate></>:<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/internal/trainings-management/courses/:courseurl/:batch_id/assignments">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalAssignmentsList page="Internships"  />

</InternalTemplate></>:<Redirect to="/login"></Redirect>}
</Route>






<Route exact path="/internal/trainings-management/courses/:courseurl/chapters/:chapterid">
{user&&permission?
<>
<InternalTemplate permission={permission}>

<InternalTopicsManagement page="Internships"  />

</InternalTemplate></>:<Redirect to="/login"></Redirect>}
</Route>




<Route exact path="/intern-application/:intern_title/:internship_id">
  <InternalTemplate permission={permission}>
  <InternBasedApplication/>
  </InternalTemplate>
  
</Route>





























<Route exact path="/team/:internal" userdata={user} permission={permission}>
{user&&permission?
<>
<TeamDashboard account={account} userdata={user} permission={permission}/>

</>:
<Redirect to="/login"></Redirect>}
</Route>











































{/*404 PAGE*/}
<Route  path="/" >
<Default  userdata={user} permission={permission}  account={account}/>
</Route>





</Switch>
      </Router>

    </div>
  );
}

export default App;

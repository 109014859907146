import React, { createContext } from "react";
import { Navbar } from "react-bootstrap";
import userdp from "../../assets/bg/bhuvanasri.jpg";
import { Link } from "react-router-dom";
import searchicon from "../../assets/svg/SearchIcon.svg";
import NavBarTitles from "./NavBarTitles";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { TextField } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import AccountOptions from "../../pages/account/AccountOptions";
import ReactGA from "react-ga";
import { Class, NotificationsActiveRounded, Search } from "@material-ui/icons";
import { Badge } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { FaMicrophone } from "react-icons/fa";
import SpeechActions from "./SpeechActions";
import RoutesList from "../RoutesList";
import { Redirect } from "react-router/cjs/react-router.min";
import salesicon from "../../assets/iconsv2/transactions.svg";
import summary from "../../assets/iconsv2/summary.svg";
import users from "../../assets/iconsv2/users.svg";
import budget from "../../assets/iconsv2/budget.svg";
import employee from "../../assets/iconsv2/employee.svg";
import interns from "../../assets/iconsv2/interns.svg";
import students from "../../assets/iconsv2/students.svg";
import courses from "../../assets/iconsv2/courses.svg";
import workshops from "../../assets/iconsv2/workshop.svg";
import internships from "../../assets/iconsv2/internships.svg";
import customer from "../../assets/iconsv2/customer-support.svg";
import placement from "../../assets/iconsv2/placement-support.svg";
import community from "../../assets/iconsv2/summary.svg";

import videos from "../../assets/iconsv2/video.svg";
import reviews from "../../assets/iconsv2/review.svg";

import configurations from "../../assets/iconsv2/settingsicon.svg";

import quizicon from "../../assets/iconsv2/quiz.svg";

import assignmentsubmissionsimage from "../../assets/iconsv2/assignmentsubmissionsimage.svg";

import feedback from "../../assets/iconsv2/class-feedback.svg";

import tasksicon from "../../assets/iconsv2/tasksicon.svg";

import certificateimage from "../../assets/iconsv2/certificateimage.svg";

import idcard from "../../assets/iconsv2/idcardicon.svg";

import error from "../../assets/iconsv2/summary.svg";
import leads from "../../assets/iconsv2/leads.svg";
import classes from "../../assets/iconsv2/class-schedules.svg";

import logs from "../../assets/iconsv2/logs.svg";
const InternalNavbar = ({ props, account, unread,permissions }) => {
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const [showSearch, setshowSearch] = useState(false);

  const closeSearch = () => setshowSearch(false);

  useEffect(() => {
    const getGA = async () => {
      ReactGA.initialize("UA-166108797-3");
      ReactGA.pageview(window.location.pathname);
    };
    getGA();
  }, []);

  const [menustatus, setmenustatus] = useState(false);

  const togglemenu = () => {
    var sidebar = document.getElementById("sidebar");
    if (sidebar.classList.contains("sidebardisplay")) {
      setmenustatus(false);
      sidebar.classList.remove("sidebardisplay");
    } else {
      setmenustatus(true);
      sidebar.classList.add("sidebardisplay");
    }
  };

  const ToggleFixedSearch = () => {
    var components = document.getElementsByClassName("fixed-search");
  };

  const [meeting, setmeeting] = useState(false);
  const [query, setquery] = useState(null);
  const [category, setcategory] = useState("all");

  const [initialData, setInitialData] = useState(RoutesList);

  const [response, setResponse] = useState(initialData);

  const [searchTerm, setSearchTerm] = useState("");
  const [path, setpath] = useState(null);
  const ChangeData = (data) => {
    setpath(data);
  };
  // State for search input and filtered courses
  const [searchInput, setSearchInput] = useState("");
  const [filteredCourses, setFilteredCourses] = useState(initialData);
  const [cursorstate, setcursorstate] = useState(false);

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    // Filter courses based on course title or course ID
    const filteredData = response.filter(
      (course) =>
        course.name.toLowerCase().includes(searchTerm) ||
        course.path.toString().includes(searchTerm)
    );

    // Update filtered courses
    setFilteredCourses(filteredData);
    setSearchInput(searchTerm);
  };

  return (
    <Navbar class="primary-nav SlideInFromLeft" bg="light" expand="lg">
      <div className=" container">
        <NavBarTitles />

  

        <Modal
          show={showSearch}
          className="search-modal d-none"
          onHide={closeSearch}
          animation={false}
        >
          <div className="flex search-container align-items-center">
            <input
              className="w-100 p-10"
              type="text"
              placeholder="Search courses, internships, workshops, profiles..."
            ></input>

            <FaMicrophone />

            <SpeechActions ChangeData={ChangeData} initialData={initialData} />

            <img className="search-icon" src={searchicon} alt=""></img>
          </div>
          <div className="text-center mt-20 mb-20">
            <p>No Recent Searches</p>
          </div>
        </Modal>

        <div class="navbar-options align-center">
          <div class="nav-items">
            <p id="version">1.02</p>

          

            <Paper
              className="position-relative d-none"
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                type="text"
                placeholder="Search Information"
                value={searchInput}
                onChange={handleSearchInputChange}
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              {searchInput !== "" ? (
                <>
                  <div
                    onClick={() => setSearchInput("")}
                    className="bg-white search-suggestions p-3 border-bottom border-right border border-top-0 position-absolute left-0 "
                  >
                    {filteredCourses.map((course) => (
                      <div key={course.id}>
                        <Link className="nav-link" to={"" + course.path}>
                          {course.name}
                        </Link>
                        <hr></hr>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </Paper>
{permissions?
<>
<Dropdown className="position-relative">
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Options
              </Dropdown.Toggle>

              <Dropdown.Menu className="p-3 position-absolute nav-applications mt-2 shadow border-0 rounded-bottom ">
                <div className="applications ">
                  <div className="d-flex">
                    <div className="col-4">
       
       <h4>Sales</h4>
       <hr></hr>
       <Link to="/internal"className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={summary} />
                        </div>
                        <p className="ms-2">Sales Summary</p>
                      </Link>
                     

                      <Link
                        to="/internal/sales-summary/all"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={budget} />
                        </div>
                        <p className="ms-2">Course Registrations</p>
                      </Link>
                      <Link
                        to="/internal/leads/all/all"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={leads} />
                        </div>
                        <p className="ms-2">Leads</p>
                      </Link>
                      <Link
                        to="/internal/users-management"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={users} />
                        </div>
                        <p className="ms-2">Users Management</p>
                      </Link>


                      <Link to="/internal/support/all" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={students} />
                        </div>
                        <p className="ms-2">Customer Support</p>
                      </Link>
                      <Link to="/community-management" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={students} />
                        </div>
                        <p className="ms-2">Community Management</p>
                      </Link>

                  

                  


                  
                    </div>

                    <div className="col-4">
<h4>Course Management</h4>
<hr></hr>                  
                 
<Link
                        to="/internal/trainings-management/courses/all"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={courses} />
                        </div>
                        <p className="ms-2">Courses Management</p>
                      </Link>
                 
                      <Link
                        to="/internal/student-management/all"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={students} />
                        </div>
                        <p className="ms-2">Students Management</p>
                      </Link>
                      <Link to="/internal/doubts/all" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={students} />
                        </div>
                        <p className="ms-2">Student Support</p>
                      </Link>
                      <Link
                        to="/internal/placement-requests/all"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={placement} />
                        </div>
                        <p className="ms-2">Placement Support</p>
                      </Link>

                 
                     
                      <Link
                        to="/internal/students-tasks"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={assignmentsubmissionsimage} />
                        </div>
                        <p className="ms-2">Assignmet Submissions</p>
                      </Link>
                  
                      <Link to="/internal/classes" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={classes} />
                        </div>
                        <p className="ms-2">Class Schedules</p>
                      </Link>


                      <Link
                        to="/internal/trainings-management/feedbacks"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={feedback} />
                        </div>
                        <p className="ms-2">Daily Class Feedbacks</p>
                      </Link>

                      
                  
                    </div>

                    <div className="col-4">

                  <h4>Team Management</h4>
                  <hr></hr>

                      <Link to="/team-management" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={employee} />
                        </div>
                        <p className="ms-2">Team Management</p>
                      </Link>
                      <Link to="/internal/leaves" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={placement} />
                        </div>
                        <p className="ms-2">Leave Requests</p>
                      </Link>

                      <Link
                        to="/internal/internship-applications/all"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={interns} />
                        </div>
                        <p className="ms-2">Interns Management</p>
                      </Link>

                  

                      <Link to="/internal/activity-log" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={logs} />
                        </div>
                        <p className="ms-2">Website Activity Log</p>
                      </Link>

                 
                   

                  
                    </div>
                  </div>

                  <div>
<hr></hr>
<div className="d-flex flex-wrap">


<div className="col-lg-4">
<Link
                        to="/certificate-management"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={certificateimage} />
                        </div>
                        <p className="ms-2">Certificate Management</p>
                      </Link>
                    
</div>
<div className="col-lg-4">
<Link
                        to="/internal/scholarship-applications"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={placement} />
                        </div>
                        <p className="ms-2">Scholarship applications</p>
                      </Link>

                    
</div>
<div className="col-lg-4">
<Link to="/alumni-management" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={logs} />
                        </div>
                        <p className="ms-2">Team Permissions</p>
                      </Link>
</div>


<div className="col-lg-4">
<Link
                        to="/internal/internships-management"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={internships} />
                        </div>
                        <p className="ms-2">Manage Internships</p>
                      </Link>
</div>

<div className="col-lg-4">
<Link
                        to="/internal/video-management"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={videos} />
                        </div>
                        <p className="ms-2">Videos Management</p>
                      </Link>

</div>
<div className="col-lg-4">
  
<Link
                        to="/internal/workshops-management"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={workshops} />
                        </div>
                        <p className="ms-2">Manage Workshops</p>
                      </Link>
</div>

<div className="col-lg-4">
<Link to="/quiz-management" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={quizicon} />
                        </div>
                        <p className="ms-2">Quiz Management</p>
                      </Link>
                  

               

</div>
<div className="col-lg-4">
<Link to="/id-cards" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={idcard} />
                        </div>
                        <p className="ms-2">ID Cards Manageent</p>
                      </Link>
</div>

<div className="col-lg-4">
  
<Link to="/configurations" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={configurations} />
                        </div>
                        <p className="ms-2">Configurations</p>
                      </Link>
                    
</div>

<div className="col-lg-4">
<Link to="/alumni-management" className="d-flex my-1">
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={logs} />
                        </div>
                        <p className="ms-2">Alumni Management</p>
                      </Link>
</div>


<div className="col-lg-4">
<Link
                        to="/internal/trainings-management/reviews"
                        className="d-flex my-1"
                      >
                        <div class="nav-icons">
                          <img alt="" className="w-100" src={reviews} />
                        </div>
                        <p className="ms-2">Reviews Management</p>
                      </Link>

</div>
</div>

                  </div>
                </div>
              </Dropdown.Menu>
       </Dropdown>
</>:
<>

</>}

     


       <div className="d-flex">
  
            <div className=" flex space-evenly align-items-center">
              <Link className="me-4" to="/">
                Home
              </Link>

              <Link className="me-4" to="/work">
                Work
              </Link>
         
            </div>

            {user !== null ? <></> : ""}

            {account ? (
              <>
                <SpeechActions initialData={initialData} />
                {unread ? (
                  <Link className="mx-3" to="/notifications">
                    <Badge
                      type="button"
                      badgeContent={unread}
                      color="secondary"
                    >
                      <NotificationsActiveRounded />
                    </Badge>
                  </Link>
                ) : (
                  <Link className="mx-3" to="/notifications">
                    <NotificationsActiveRounded />
                  </Link>
                )}
              </>
            ) : null}

       </div>
          </div>
          {/*  <img className="search-icon" type="button" onClick={openSearch} src={searchicon} alt="search"></img>*/}
          {user ? (
            ""
          ) : (
            <div className="nav-signup btn ml-5 ">
              <Link to="/login" class="animated-button1">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <svg
                  className="mr-2"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 496 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path>
                </svg>
                Login / SignUp
              </Link>
            </div>
          )}

      
          <div className="d-block d-sm-block d-md-none">
            {unread ? (
              <Link to="/notifications">
                <Badge type="button" badgeContent={unread} color="secondary">
                  <NotificationsActiveRounded />
                </Badge>
              </Link>
            ) : (
              <Link to="/notifications">
                <NotificationsActiveRounded />
              </Link>
            )}
          </div>


          {account ? (
            <AccountOptions userdp={userdp} account={account} type="button" />
          ) : (
            <Link to="/login" className="btn d-sm-none btn-danger">
              Login/SignUp
            </Link>
          )}


          <div class="toggle-menu mb-2" type="button" onClick={togglemenu}>
            {menustatus ? (
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                data-testid="CloseIcon"
                tabindex="-1"
                title="Close"
              >
                <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            ) : (
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                data-testid="MenuIcon"
                tabindex="-1"
                title="Menu"
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
            )}
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default InternalNavbar;

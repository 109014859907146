import React,{memo} from 'react'
import { Navbar } from 'react-bootstrap'
import logo from '../../assets/bg/logo.png'
import logo2 from '../../assets/logo.png'
import { Link } from 'react-router-dom'




const NavBarTitles = ({page}) => {








    return (
        <div className="flex align-items-center">
           <Link to="/" className="flex  align-items-center">
          <Navbar.Brand href="/"><img src={logo2} className="toplogo d-sm-none" alt="Skills Uprise Logo"></img>
          <img src={logo} className="toplogo" alt="Skills Uprise Logo"></img>
          </Navbar.Brand>
          </Link> 







        </div>
    )
}

export default memo(NavBarTitles)

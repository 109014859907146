import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext } from 'react';
import {useState} from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import ENDPOINT from '../../../context/EndPoint'
import InternalTemplate from '../../../themes/InternalTemplate'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
const TrainerAssignmentsList = () => {
const params = useParams();
const course_url = params.courseurl;
const [chapters,setchapters] = useState(null)
const {user} = useContext(AuthContext)




const getChapters = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);

  formData.append('course_url',course_url);


const res = await axios.post(ENDPOINT+"admin-get-course-assignments.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
 if(res.data.status==="success"){
  setchapters(res.data.data)
 }
 else{

 }
}
}






const addChapter = async (status)=>{
        

     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     formData.append('session',user.SessionId);
     
       formData.append('course_url',course_url);
       formData.append('chapter_title',ctitle);
       formData.append('chapter_id',currentchapter);
     
     const res = await axios.post(ENDPOINT+"admin-add-chapter.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
      if(res.data[0].status==="success"){
      // setchapters(res.data[0].data)
      setctitle(null)
      setcurrentchapter(null)
      getChapters()
      }
      else{
     
      }
     }
     }
     
const [edit,setedit] = useState(false)
const [ctitle,setctitle] = useState(null);
const [currentchapter,setcurrentchapter] = useState(null)

useEffect(()=>{

getChapters();
},[])









const deleteChapter = async ({url,chapter})=>{
        

     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     formData.append('session',user.SessionId);
     
       formData.append('course_url',url);
       formData.append('chapter_id',chapter);
     
     const res = await axios.post(ENDPOINT+"admin-delete-chapter.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
      if(res.data.status==="success"){
      // setchapters(res.data[0].data)
      getChapters();
      }
      else{
     
      }
     }
     }
     

useEffect(()=>{

getChapters();
},[])








const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     pt: 2,
     px: 4,
     pb: 3,
   };
   







  return (
<>




<div className="container-fluid mt-5">





<Modal
  open={edit}
  onClose={()=>setedit(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...style, width: 500 }}>
  <p>Title  :</p>
    <input className="form-control"  value={ctitle} onChange={(e)=>{setctitle(e.target.value)}}></input>
    <br/>
    <p>Chapter No:</p>
    <input className="form-control" value={currentchapter} onChange={(e)=>{setcurrentchapter(e.target.value)}}></input>
    
   
 
<button className="btn btn-primary w-100 mt-3" onClick={addChapter}>Update Topic</button>
  </Box>
</Modal>











<h1>Assignment management</h1>

<div className="flex-wrap">


     <div className="col-lg-3 p-2" onClick={()=>{setedit(true)}}>
<p>Add Assignment</p>
     </div>
</div>
{chapters?



<>

{chapters.map((chapter)=>(

<>
<div className=" container-fluid p-2 mt-2">
<div className="container-fluid flex shadow p-2">
<div className="container-fluid">
{chapter.title}
</div>

<div className="flex">
<DeleteIcon type="button" onClick={()=>deleteChapter({url:course_url,chapter:chapter.chapter_id})}   className="mr-2"/>
     <EditIcon type="button" className="mr-2" onClick={()=>{setctitle(chapter.chapter_title);setcurrentchapter(chapter.chapter_id);setedit(true)}}/>
     <Link   to={"/internal/trainings-management/courses/"+course_url+"/chapters/"+chapter.chapter_id}>
          
     <ForwardIcon type="button"  />
          </Link>
  
</div>


</div>



</div>


</>


))}

</>
:"no chapters"}
</div>

</>

  )
};

export default TrainerAssignmentsList;

import React from 'react'

const AssignmentEvaluated = ({payload}) => {
  return (
    <div className="shadow p-3 border br-5">



      {payload.image?"":"Image Needed"}
        <p>Hey  <b>{payload.one?payload.one:"{{1}}"}</b> , Your assignment has been evaluated for your course  <b> {payload.two?payload.two:"{{2}}"} </b>. 
        You have scored  <b>{payload.three?payload.three:"{{3}}"} </b> marks.
        Visit Skills Uprise course dashboard to check your corrections and remarks.
</p>
      </div>
  )
}

export default AssignmentEvaluated
import React, { useContext } from 'react'
import styled from 'styled-components';
import StudentsList from './TrainerStudentsList';
import { useState } from 'react';
import { useEffect } from 'react';
import ENDPOINT from '../../../context/EndPoint';
import ShimmerCourseItem from '../../../skeleton/ShimmerCourseItem';
import { Container, Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import AddCourseAssignment from './TrainerAddCourseAssignment';
import { MoreVert } from '@material-ui/icons';
import { Modal } from 'react-bootstrap';
import { Chip, TextField } from '@material-ui/core';
import EditCourseOverview from './TrainerEditCourseOverview';
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
import { AuthContext } from '../../../context/AuthContext';
import axios from 'axios';
import SecurityHeaders from '../../../context/SecurityHeaders';
const TrainerCoursesList = ({props}) => {

const params = useParams();
const trainer_id = params.trainer_id;
console.log(trainer_id)
const { user } = useContext(AuthContext);
const [mode,setmode] = useState("all")


    const [show, setShow] = useState(false);
    const [currentbatch,setcurrentbatch]= useState(null)
const [courseurl,setcourseurl]= useState(null)
    const handleClose = () => setShow(false);
    const addAssignment = (course_url,batch_id) => {
        setcourseurl(course_url)
        setcurrentbatch(batch_id)
        setShow(true);
    
    }
    const [studentslist,setstudentslist] = useState(false)
    

    const [courses, setcourses] = useState(null);

    const  getcourses = async ()=>{

        const data = new FormData();
        data.append('session',user.SessionId);
        data.append('course_trainer',trainer_id);
        const res = await axios.post(`${ENDPOINT}trainer-based-course.php`,data,SecurityHeaders);
        if(res){
          
          if(res.data.status==="success"){ 
            setInitialData(res.data.data);
            setResponse(res.data.data);
           
             
          }
        }
  
  
          
          }    ;


useEffect(()=>{


getcourses();
       
}

,[])







const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
const [fullscreen, setFullscreen] = useState(true);
const [currentcourse,setcurrentcourse]= useState(null);
const displayStudents = (course)=>{
    setcurrentcourse(course)
    setstudentslist(true)
}


const [editoverview,seteditoverview] = useState(false)

const [courseid,setcourseid] = useState(null)

const editCourseOverview = ({courseurl,course_batch,course_id})=>{
setcurrentbatch(course_batch);
setcourseurl(courseurl)
setcourseid(course_id)
seteditoverview(true)
}
const role = "trainer";
const [initialData, setInitialData] = useState("");

const [response, setResponse] = useState(initialData);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();

  const filteredData = initialData.filter((item) => {
    return (
      item.course_id.toLowerCase().includes(term) ||
      item.course_title.toLowerCase().includes(term) ||
      item.mode.toLowerCase().includes(term)
    );
  });

  setResponse(filteredData);
  setSearchTerm(term);
};
    return (
        <div>








<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{courseurl} {currentbatch}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <AddCourseAssignment courseurl={courseurl}/>

        </Modal.Body>
    
      </Modal>



      <Modal show={editoverview} onHide={()=>seteditoverview(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{courseurl} {currentbatch}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <EditCourseOverview currentbatch={currentbatch} course_id={courseid} courseurl={courseurl}/>

        </Modal.Body>
    
      </Modal>

    
      <Modal
      className="min-vh-90"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
     show={studentslist} fullscreen onHide={() => setstudentslist(false)}>
        <Modal.Header closeButton>
          <Modal.Title><b className="text-primary">{currentcourse?currentcourse.toUpperCase():null} </b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <StudentsList course={currentcourse}/>    




        </Modal.Body>
      </Modal>
 








<div className="flex align-items-center container-fluid space-between">
    

<h2 className="sm-16 mb-4 mt-3 text-primary">Courses Management </h2>


<div className="mt-3">
<Link to="/trainer/courses/add" className="btn btn-primary">Add Course</Link>
</div>
{/* <div>

    <select>
    <option>All Courses</option>
    <option>Online Courses</option>
    <option>Offline Courses</option>
    <option>Selfpaced Courses</option>
    <option>Specialization Courses</option>
    </select>
</div> */}
</div>

<Container fluid className="my-3">
        <div>
          <TextField
            label="Search courseId, mode, course name "
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </Container>

<div className="flex-wrap space-evenly">





















{ response?response.map((course, index)=>(


<div key={index} className="shadow p-2 container-fluid mb-2 mt-2">
<div className="flex">
    <div className="col">
    <h4>{course.course_title}</h4>
    <div className="flex">
  
   {course.class_starts?
   
   <Chip size="small" className="bg-success text-light" label="Active"/>
:<Chip size="small" className="bg-danger  text-light" label="InActive"/>}
        
    </div>
    </div>
    <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
  <Dropdown.Item  key={index} href={"/trainer/"+trainer_id+"/edit-course/"+course.course_id} >Edit Overview</Dropdown.Item>
  <Dropdown.Item  key={index} href={"/trainer/"+trainer_id+"/"+course.course_url+"/"+course.course_id+"/batches"} >Batches</Dropdown.Item>
  <Dropdown.Item  key={index} onClick={()=>addAssignment(course.course_url,course.batch_id)}>Add Assignment</Dropdown.Item>
  <Dropdown.Item key={index} href={"/trainer/"+trainer_id+"/"+course.course_url+"/"+course.course_id+"/assignments"} >View Assignmentss</Dropdown.Item>
  <Dropdown.Item key={index} href={"/trainer/"+trainer_id+"/"+course.course_url+"/"+course.course_id+"/classes"} >Class Schedules</Dropdown.Item>
  <Dropdown.Item key={index} href={"/trainer/"+trainer_id+"/"+course.course_url+"/"+course.course_id+"/chapters"} >View Chapters</Dropdown.Item>
  <Dropdown.Item key={index} href={"/trainer/"+trainer_id+"/"+course.course_url+"/"+course.course_id+"/students"} >View Students</Dropdown.Item>
  <Dropdown.Item key={index} >Send Notification</Dropdown.Item>

  </Dropdown.Menu>
</Dropdown>
</div>

<hr/>

<div className="container-fluid text-secondary">
<div className="flex space-between">

    <div className="col">
    <p><b>Training Mode : </b>{course.mode}</p>
    </div>

    <div className="col">
    <p><b>Batch ID : </b>{course.batch_id}</p>
    </div>
    <div className="col">
    <p><b>Timings : </b>{course.class_starts}</p>
    </div>
    
</div>


<div className="flex space-between">

    <div className="col">
    <p><b>Batch Starts : </b>{dateFormat(course.registration_ends,"dS ddd mmm yy")}</p>
    </div>

    <div className="col">
    <p><b>Seats Left : </b>{course.seats_left}</p>
    </div>
    <div className="col">
    <p><b>Timings : </b>{course.class_starts}</p>
    </div>
    
</div>
</div>


</div>


)):


<>
<div  className=" d-none shimmer-courses-row">

<ShimmerCourseItem/>

<ShimmerCourseItem/>

<ShimmerCourseItem/>

<ShimmerCourseItem/>

<ShimmerCourseItem/>

<ShimmerCourseItem/>

</div>

</>



}

</div>
   






        </div>
    )
}

export default TrainerCoursesList


export const SliderStyled = styled.div`
margin-bottom: 50px;
   #container {
       display: flex;
             overflow-x: scroll; width: 100%;
        }
        #content {
             width: 100%;
           
            display: flex;
            padding: 10px 5px;
        }
        .slider-buttons{
            display: flex;
        }
        .courses-title{
            margin-bottom: 10px;
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
        .courses-title h1{
            font-size:32px;
        }
        .slider-buttons img{
            height: 30px;
            width: 30px;
            margin-right: 7px;
            padding: 5px;
            box-shadow:0px 0px 5px grey;
            border-radius: 15px;

        }
        .slider-buttons img:hover{
background-color: #00cbfd;
color: white;
fill: white;
transition: 0.5s;

        }
        #container::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width:576px){
    width: 100%;
    .courses-title h1{
        font-size: 20px;
        padding-left: 5px;
    }
    .slider-buttons img{
            
            padding: 5px;
            margin-right: 7px;
           

        }
}

`
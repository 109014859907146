import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";

import axios from "axios";

import { AuthContext } from "../../../context/AuthContext";

import ENDPOINT from "../../../context/EndPoint";

import { useParams } from "react-router-dom";

import InternshipApplicant from "./InternshipApplicant";
import SecurityHeaders from "../../../context/SecurityHeaders";

const InternBasedApplication = () => {
  const { user } = useContext(AuthContext);
  const params = useParams();
  const internship_id = params.internship_id;

  const [internship, setInternship] = useState(null);

  const getApplication = async () => {
    const data = new FormData();
    data.append("internship_id", internship_id);
    data.append("session", user.SessionId);

    const res = await axios.post(
      ENDPOINT + "intern-based-application.php?",
      data,
      SecurityHeaders
    );
    if (res) {
      if (res.data.data) {
        setInternship(res.data.data);
      }
    }
  };

  useEffect(() => {
    getApplication();
  }, []);

  return (
    <>
      {internship ? (
        internship.map((internship, index) => (
          <>
            <InternshipApplicant internship={internship} index={index} />
          </>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default InternBasedApplication;

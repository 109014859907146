import React from 'react'
import { Link } from 'react-router-dom'
import HomeTemplate from '../../themes/HomeTemplate'
import { useEffect } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import styled from 'styled-components'
import nopayments from '../../assets/empty-cart.svg'
import applicationsearch from '../../assets/application-search.svg'
import ENDPOINT from '../../context/EndPoint'
import { Chip } from '@material-ui/core'
import  dateFormat from 'dateformat'
import axios from 'axios'



import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';



import Alert from '@mui/material/Alert';
const MyInternshipsPage = () => {

const {user} = useContext(AuthContext)

const userdata = user;


 const [category,setcategory] = useState("all")
    const [registrations,setregistrations] = useState(null);
    
    useEffect(()=>{
       
     getInterviews()
    getApplications({cat:"all"});
           
    }
    
    ,[])
    
    



    let URL
    const  getApplications = async ({cat})=>{
        
       
   if(category){
    URL  = `${ENDPOINT}get-my-internships.php?session=${user.SessionId}&status=${cat}`
   }else{
     URL = `${ENDPOINT}get-my-internships.php?session=${user.SessionId}`
   }
  
        fetch(URL)
        .then(r=>r.json())
        .then(result=>{
         
          
       if(result[0].status==="success"){
           

        if((result[0].data)==="no internships"){
         
          
            setregistrations("no internships")
        

        }
        else{
          setregistrations(result[0].data);
          
        }
            
       }
               
        
            
        })
        
        }    ;





const changeCategory = (i)=>{

const cats = document.getElementsByClassName("cat");


cats[i].classList.add("cat-active");


}

    const [interviews,setinterviews]= useState(null);
const getInterviews = async (status)=>{
        

    const formData = new FormData();
    
    
   
    formData.append('session',user.SessionId);
    formData.append('status','pending');
    formData.append('limit',3);
    


    const res = await axios.post(ENDPOINT+"my-interviews.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
   
     if(res.data.status==="success"){
      setinterviews(res.data.data)
     }
     else{
    
     }
    }
    }
    
    
    
    
    
    
    
    
    
    
    




    return (



    
<PaymentsStyled className="pt-3 pb-5 sm-p-15 p-2  min-vh-90 container-fluid">


<div className=" mt-3 container-xl d-none">
<div className="flex align-items-center space-between">





<div>
<FormControl className="d-none">
        <InputLabel >Category</InputLabel>
      
<Select
   className="select"
  value={category}
  label="Category"
  onChange={(e)=>{setcategory(e.target.value);getApplications({cat:e.target.value})}}
>
  <MenuItem value={"all"}>All </MenuItem>

  <MenuItem value={"shortlisted"}>Shortlisted</MenuItem>
  <MenuItem value={"selected"}>Selected</MenuItem>
  
  <MenuItem value={"joined"}>Joined</MenuItem>
  
  <MenuItem value={"rejected"}>Rejected</MenuItem>
  
</Select>
      </FormControl>
</div>




</div>





</div>



{ registrations==="no internships"?

<>
<div className="container-xl justify-center">


<div className="col-lg-5">

<div className="justify-center">
<img className="w-50" src={applicationsearch} alt=""></img>

</div>
<div className="text-center text-light">
<h5 className="mt-5 sm-18">No {category==="all"?null:category} applications found !</h5>

<Link to="/internships" className="btn mt-3 btn-warning">Browse Internships</Link>
</div>
</div>


</div>





</>


:registrations!==null?registrations[0].status!=="error"?registrations[0].data!==null?





<div className="container-xl tab-reverse space-evenly p-0 flex-wrap">


<div className="col-lg-7 p-0 col-sm-12">
{registrations.map(internship=>(





<div className="internship-item bg-white p-3 border br-5 col-lg-11 col-md-11 col-sm-12">

<div className="flex space-between">
<h5 className="internship-title sm-18">{internship.title}</h5>

</div>



<div className="internship-info mb-2">

<p> {internship.status==="applied"?
  <Chip label="Applied" size="small"  className="bg-primary text-white" />
  :internship.status==="selected"?
  <Chip label="selected" size="small" className="bg-success text-light"  />
:  <Chip label={internship.status} size="small" className="bg-success text-light"   />
}</p>
</div>
<hr className="mt-0 mb-2"/>


<div className="flex space-between">




<div className="internship-info sm-d-none">
<h6>Company</h6>
<p>{internship.company?internship.company:"awetecks"}</p>
</div>



<div className="internship-info">
<h6>Applied On</h6>
<p> {dateFormat(internship.applied_date, "dS  mmm, yy")}</p>
</div>





{internship.status==="joined"?

<>
<div className="flex align-items-center">
<Link to={"/my-internship/"+internship.internship_id+"/feed"}>
<Chip type="button" label={<> Go to Dashboard </>}  className="pt-1 bg-primary text-white" />
</Link>
</div>
</>
:<>
{internship.status==="selected"|| internship.status==="accepted"?
<>
<div className="flex align-items-center">
<Link to={"/offerletter/"+internship.id+"/"+internship.internship_id}>
<Chip type="button" label={<> View Offer Letter </>}  className="pt-1 bg-primary text-white" />
</Link>
</div>
</>
:<>
{internship.status==="rejected"?
<>
<div className="internship-info">
<h6>Status</h6>
<Chip  label={<> Rejected </>}  className=" bg-primary text-white" />

</div>
</>
:<>
<div className="internship-info">
<h6>Status</h6>
<Chip  label={<> Applied </>}  className=" bg-primary text-white" />

</div>
</>
}
</>
}
</>
}



</div>




</div>





))

}






</div>



{/*interview schedules*/}
<div className="col-lg-4 d-none d-md-block h-fit br-4 bg-white p-3">
    {interviews?

<>
<h4 className="text-orangered sm-22">Upcoming Interview's</h4>
<p>Have any questions ? <a target="_blank" rel="noreferrer" href="mailto:hr@skillsuprise.com">Contact Recruitment Team</a></p>


{interviews?
<>
{interviews.map((interview)=>(


<>
<div className="border-1 p-3 br-4 mb-3">
<h5 className="sm-18">{interview.title}</h5>
<p>{interview.internship_title}</p>
<p><b>Schedule : </b>{dateFormat(interview.date, "dS mmm yy, hh:mm tt") }</p>
{interview.category==="telephonic"?
<>
</>:
<>

<a href={interview.link} target="_blank" rel="noreferrer" className="btn btn-primary w-100">Join Interview</a>

</>}

</div>

</>
))}

<div className="justify-center mt-3 text-center">
    
<Link to="/interviews" className="text-center sm-14  ">View All Interviews</Link>

</div>
</>
:
<>

</>}

</>:
<>
{/*no interviews*/}

<div>
<h4>Note !</h4>
<Alert severity="info">
Skills Uprise will never ask for any registration fee/security deposits to apply for internship & job opportunities from students/applicants.
 We also request our partnered companies & recruiters to follow the same. 
Please report to us at <a href="mailto:hr@skillsuprise.com"> report@skillsuprise.com</a> incase of any such incidents. 


</Alert>



</div>


</>}




</div>



</div>














:


<div className="flex-wrap container-xl bg no-courses p-20">

<div className="col-lg-4 col-md-5 col-sm-12">

<div className="flex">
<img className="w-100" src={nopayments} alt=""></img>

    </div>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 text-dark">
<h2 className="mt-5">No Registrations !</h2>
<h6>It seems like you have not registered for any internships, workshops, events or hackathons...</h6>


</div>


</div>






:"batch unset"
:

<>

{/*internship item shimmer*/}
<div className="internship-item bg-white p-3 col-lg-11 col-md-11 col-sm-12">

<div className="flex w-100 space-between">
<h3 className="internship-title h-30 shimmer animate w-75"> </h3>

</div>

<div className="flex">

    <div className="short-info  p-0 col-6 col-lg-3 col-md-4 col-xl-4">
        <div className="flex w-100">

<p  className="h-30 w-25 shimmer animate "></p>
        </div>
    
    </div>



</div>
<hr className="mt-2 mb-2 "/>
<div className="short-info w-100 space-between flex">


<p  className="h-30 w-25 shimmer animate"></p>




<p  className="h-30 w-25 shimmer animate"></p>





<p className="h-30 w-25 shimmer animate"></p>




</div>
<div className="bottom-flex w-100 flex space-between">

<span className="h-30 w-25 shimmer animate"></span>
<span className="h-30 w-25 shimmer animate"></span>
</div>

</div>

{/*internship item shimmer end*/}





{/*internship item shimmer*/}
<div className="internship-item bg-white p-3 col-lg-11 col-md-11 col-sm-12">

<div className="flex w-100 space-between">
<h3 className="internship-title h-30 shimmer animate w-75"> </h3>

</div>

<div className="flex">

    <div className="short-info  p-0 col-6 col-lg-3 col-md-4 col-xl-4">
        <div className="flex w-100">

<p  className="h-30 w-25 shimmer animate "></p>
        </div>
    
    </div>



</div>
<hr className="mt-2 mb-2 "/>
<div className="short-info w-100 space-between flex">


<p  className="h-30 w-25 shimmer animate"></p>




<p  className="h-30 w-25 shimmer animate"></p>





<p className="h-30 w-25 shimmer animate"></p>




</div>
<div className="bottom-flex w-100 flex space-between">

<span className="h-30 w-25 shimmer animate"></span>
<span className="h-30 w-25 shimmer animate"></span>
</div>

</div>

{/*internship item shimmer end*/}






{/*internship item shimmer*/}
<div className="internship-item bg-white p-3 col-lg-11 col-md-11 col-sm-12">

<div className="flex w-100 space-between">
<h3 className="internship-title h-30 shimmer animate w-75"> </h3>

</div>

<div className="flex">

    <div className="short-info  p-0 col-6 col-lg-3 col-md-4 col-xl-4">
        <div className="flex w-100">

<p  className="h-30 w-25 shimmer animate "></p>
        </div>
    
    </div>



</div>
<hr className="mt-2 mb-2 "/>
<div className="short-info w-100 space-between flex">


<p  className="h-30 w-25 shimmer animate"></p>




<p  className="h-30 w-25 shimmer animate"></p>





<p className="h-30 w-25 shimmer animate"></p>




</div>
<div className="bottom-flex w-100 flex space-between">

<span className="h-30 w-25 shimmer animate"></span>
<span className="h-30 w-25 shimmer animate"></span>
</div>

</div>

{/*internship item shimmer end*/}







</>



}











</PaymentsStyled>


    )
}

export default MyInternshipsPage


export const PaymentsStyled = styled.div`


.plans-section{
    background-image: none;
}
li{
    color: #202020;
}


`





import React from 'react'
import FeedIcon from '@mui/icons-material/Feed';
import ChatIcon from '@mui/icons-material/Chat';
import { Link } from 'react-router-dom';
import workend from '../../assets/workend.svg'
const WorkHoursEnd = ({account,internshipid}) => {
  return (
    <div className="work-section w-100 vh-90">
         
         <div className="text-center pt-5 mt-5">
          <img src={workend} className="w-50"/>
<h1 className="text-danger sm-25">Your Shift Time is Over !</h1>

<div className="col-lg-10 col-md-11 col-sm-12 mx-auto">
<p><b>Hey {account.first_name}</b>,  we do not allow our beloved team to work beyond work hours. Have a great time with your friends & family. Incase you have an emergency task to accomplish, send a request to extend your work hours today at <a href="mailto:work@skillsuprise.com">work@skillsuprise.com</a></p>
  



  <div className="mt-5">
       <h6>Wanna stay with Skills Uprise ?</h6>
       <p>Check your team posts & updates, start a chat or discuss ideas..   </p>

       <div className="flex space-evenly mt-3">
<Link to={"/my-internship/"+internshipid+"/feed"} className="btn btn-warning"><FeedIcon/> Browse Newsfeed</Link>
<Link to={"/chat/a"} className="btn btn-primary"> <ChatIcon/> Chat with team</Link>
       </div>
  </div>
</div>
  </div>





    </div>
  )
}

export default WorkHoursEnd
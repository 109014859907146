import React, { useContext, useEffect, useState } from "react";
import CircularProgress from "../../../components/progress/CircularProgress";
import axios from "axios";
import ENDPOINT from "../../../context/EndPoint";
import SecurityHeaders from "../../../context/SecurityHeaders";
import { AuthContext } from "../../../context/AuthContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditTeamMilestone = () => {
  const params = useParams();
  const id = params.id;

  const [milestone, setMilestone] = useState([]);

  const { user } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [corrections, setCorrections] = useState('');
  const [reference, setReference] = useState('');
  const [deadline, SetDeadline] = useState('');

  const [submission, setSubmission] = useState('');
  const [updateStatus, setUpdateStatus] = useState(false);
  const [status, setStatus] = useState('');

  //toast
  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toast, setToast] = useState(false);

  const getMilestones = async () => {
    try {
      const data = new FormData();
      data.append("session", user.SessionId);
      data.append("id", id);
  
      const res = await axios.post(
        ENDPOINT + "admin-get-single-milestone.php",
        data,
        SecurityHeaders
      );
  
      if (res.data && res.data.status === "success") {
        const data = res.data.data;
        setTitle(data.title);
        setDescription(data.description);
        setCorrections(data.corrections);
        setReference(data.reference);
        SetDeadline(data.deadline);  
  
        setSubmission(data.submission_time);
        setStatus(data.status);
      }
    } catch (error) {
      console.log("An error occurred while fetching team milestones");
    }
  };
  
  useEffect(() => {
    getMilestones();
  }, []);
  
 
  const updateMilestone = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("title", title);
    data.append("description", description);
    data.append("status", status);
    data.append("corrections", corrections);
    data.append("reference", reference);
    data.append("deadline", deadline);

    data.append("submission_time", submission);
    data.append("id", id);
    const res = axios.post(
      ENDPOINT + "admin-update-team-milestone.php",
      data,
      SecurityHeaders
    );
    if (res.data && res.data.status === "success") {
    }
  };

  return (
    <>
      <div>
        <form onSubmit={updateMilestone}>
          <div className="form-group">
            <label>title</label>
            <input
              className="form-control"
              placeholder="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>description</label>
            <input
              className="form-control"
              placeholder="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>corrections</label>
            <input
              className="form-control"
              placeholder="category"
              value={corrections}
              onChange={(e) => setCorrections(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>reference</label>
            <input
              className="form-control "
              placeholder="type"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>dead line</label>
            <input
              className="form-control "
              placeholder="amount"
              type="datetime-local"
              value={deadline}
              onChange={(e) => SetDeadline(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>submission time</label>
            <input
              className="form-control "
              type="datetime-local"
              placeholder="amount"
              value={submission}
              onChange={(e) => setSubmission(e.target.value)}
            />
          </div>

          <div className="form-group mt-3">
            <label>status</label>
            <select
              className="form-control"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="pending">pending</option>
              <option value="granted">granted</option>
            </select>
          </div>

          <button type="submit" className="btn btn-primary">
            {updateStatus ? (
              <CircularProgress className="text-white" size={20} />
            ) : (
              ""
            )}
            Update Milestone
          </button>
        </form>
      </div>
    </>
  );
};

export default EditTeamMilestone;

import React from 'react'
import InternalTemplate from './InternalTemplate'
const NoPermissionsTemplate = () => {
  return (

    <InternalTemplate>

<div className="container pt-t mt-5">
<div className="text-center">
  <h2>No Permissions</h2>
</div>
    </div>
    </InternalTemplate>
 
  )
}

export default NoPermissionsTemplate
import {
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
  import MailIcon from "@mui/icons-material/Mail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import React, { useContext, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import dateFormat from "dateformat";
import ResolutionStatus from "../../../components/status/ResolutionStatus";
import { AttachMoney, CheckCircleOutline, Edit, GroupAddOutlined, MoreVert } from "@material-ui/icons";
import { Dangerous } from "@mui/icons-material";
import { Dropdown } from "react-bootstrap";
import ENDPOINT from "../../../context/EndPoint";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import SecurityHeaders from "../../../context/SecurityHeaders";
import EditLeadModal from "../../../components/modals/EditLeadModal";
const LeadItem = ({item}) => {
    const {user} = useContext(AuthContext)

    const updateRequest = async ({ ticketstatus, ticketid }) => {

        if (user !== null) {
    
          const data = new FormData();
          data.append('session',user.SessionId);
          data.append('status',ticketstatus);
          data.append('id',ticketid);
          const res = await axios.post(`${ENDPOINT}admin-update-lead.php`,data,SecurityHeaders);
          if(res){
            if (res.data.status === "success") {
          
            } else {
          
            }
          }
    
        } else {
        }
      };

      const [edit,setedit] = useState(false)
      const CloseEdit = ()=>{
    
    setedit(false)
    }
  return (
   <>
   
   <EditLeadModal item={item} visibility={edit} handleClose={CloseEdit}/>
   <TableRow
        key={item.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          
          <TableCell component="th" scope="row">
          <div className="flex w-100">
              <Avatar src={item.profile_pic} className="me-2" />
              <div>
                <p className="mb-0">
               
                  {item.name }
                </p>
                <div className="flex">
                  <PhoneInTalkIcon className="mr-2 text-primary" />
                  <WhatsAppIcon className="text-success mr-2" />
                  <p className="mb-0 ">{item.phone}</p>
                </div>
              </div>
            </div>
          </TableCell>

     

         <TableCell align="left">
         <div className="d-flex w-100">   

<div className="d">
{item.note}
</div>
</div>
         </TableCell>

           <TableCell align="left">
           <div className="  w-100">
              <p className="sm-10 mx-auto">
                
                {dateFormat(
                  item.create_datetime,
                  "dd mmm yy hh:mm:ss TT"
                )}
              </p>
            </div>
           </TableCell>
           <TableCell align="center">
         <div className="w-100 text-align-left">
              <div className="d-none">{item.create_datetime}</div>
              <ResolutionStatus status={item.status} />
            </div>
         </TableCell>
         <TableCell align="right">
         <Dropdown className="options-dropdown">
                            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                              <MoreVert className="text-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                type="button"
                                onClick={() =>
                               setedit(true)
                                }
                              >
                                <Edit className="mr-2 text-secondary" />
                                Edit Lead
                              </Dropdown.Item>
                              <Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateRequest({
                                    ticketstatus: "interested",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <CheckCircleOutline className="mr-2 text-success" />{" "}
                                Mark Interested
                              </Dropdown.Item>
                              <Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateRequest({
                                    ticketstatus: "notinterested",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <Dangerous className="mr-2 text-danger" />
                                Not Interested
                              </Dropdown.Item>
                              <Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateRequest({
                                    ticketstatus: "pending",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <AttachMoney className="mr-2 text-info" />
                                Pending Payment
                              </Dropdown.Item>
                              <Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateRequest({
                                    ticketstatus: "joined",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <GroupAddOutlined className="mr-2 text-success" />
                                Joined
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
         </TableCell>
          
        </TableRow>
   </>
  )
}

export default LeadItem
import React from 'react'

import HomeTemplate from '../../themes/HomeTemplate'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import noid from '../../assets/noid.svg'

import { AuthContext } from '../../context/AuthContext'
import logo from '../../assets/awetecks-logo.svg'
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react'
import ENDPOINT from '../../context/EndPoint'
const CertificatePage = () => {

const {user} = useContext(AuthContext)
const userdata= user;

 const content = useRef();
    const [certificates,setcertificates] = useState(null);
    
    useEffect(()=>{
       
    
        const  getcertificates = async ()=>{

            fetch(`${ENDPOINT}get-idcard.php?session=${user.SessionId}  `)
            .then(r=>r.json())
            .then(result=>{
           
                    setcertificates(result);
                  
                  
          
                
            })
            
            }    ;
    getcertificates();
           
    }
    
    ,[])
    
    
    
    
    
    
    
    
    
    
    
    
    
  const downloadOfferLetter = ()=>{
    
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('hello');
  
        a.document.close();
        a.print();
    

  
  }
    
  
  const handlePrint = useReactToPrint({
      
     content: () => content.current,
  });
    
    




    return (

<HomeTemplate>


    
<div className="pt-3 pb-5">


{/*assignment item start*/}

{ certificates?certificates[0].status!=="error"?certificates[0].status!=="no certificates"?certificates.map(certificate=>(

<div className="flex-wrap assignment-item">
<div className="col-lg-6 col-md-6 col-sm-12">
<h5>{certificate.title}</h5>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 space-between">
<p>#{certificate.certificate_id}</p>
<p>Issued On : {certificate.issued_date}</p>
<a className="btn btn-primary" href={certificate.certificate_url} to="/">Download</a>
</div>
</div>


)):

<div>



<div className="container-fluid  justify-center">

   
<div className="col-xl-7 col-lg-9 col-md-11 col-sm-12 col-12 noselect" onClick="" onSelect="" onAuxClick="" >

<ReactToPrint copyStyles="true" ref={content} id="print" documentTitle="Offer Letter" nonce>

<div className="p-1 w-100">
<div    className="  p-1 col-lg-12 mx-auto">

<div className="certificate ratio ratio-16x9 w-100 border-1 p-3">

<div className="border-3 h-100 p-2 bg-theme w-100 h-auto">

<div className="flex space-between">

<div className="flex align-items-center">
<h6 className="mb-0">Issued On : </h6>
    <p className="mb-0">25th Aug 2021</p>
    </div>


    <div className="flex align-items-center">
<h6 className="mb-0">ID : </h6>
    <p className="mb-0">1585676858</p>
    </div>
    
    
    </div>



{/*certificate body*/}


<div className="certificate-body p-2">

<div className="text-center mt-2 mb-3">
<h6>This is to certify that</h6>
<h4>Manoj Kumar Koavangi</h4>


</div>

<p className="pl-3 pr-3">has successfully completed her internship on <b>
"Front-End Website Development"
    </b> with great dedication and hard work between Mar 30, 2021 to Apr 14, 2021.She has been successful in working with our team and has shown a consistent progress with a satisfactory performance.</p>



<div className="bg-dark flex space-around">
<img src={logo} className="userdp" alt=""></img>

<div>



    </div>
</div>

</div>
</div>


</div>

   </div>
</div>

   </ReactToPrint>

</div>

    </div>
 <div className="justify-center">

 <button onClick={handlePrint} className="btn-danger mr-3 col-lg-2 btn mt-4">Reject Offer</button>

    <button onClick={handlePrint} className="btn-primary col-lg-2 col-4 btn mt-4">Download </button>
 </div>
</div>
:<div className="flex-wrap container-fluid  no-courses p-20">


<div className="col-lg-4 col-md-4 col-sm-12">

<div className="flex justify-center">
<img className="w-50" src={noid} alt=""></img>

    </div>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 mt-5 text-dark sm-text-center">
<h2>No ID Cards Found !</h2>
<h6>It seems like you donot have any ID Cards issued</h6>

error

</div>


</div>:


<div className="flex-wrap container-fluid  no-courses p-20">

<div className="col-lg-4 col-md-4 col-sm-12">

<div className="flex">
<img className="w-100" src={noid} alt=""></img>

    </div>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 mt-5 text-dark">
<h2>No ID Cards Found !</h2>
<h6>It seems like you donot have any ID Cards issued</h6>




</div>


</div>




}









</div>
</HomeTemplate>
    )
}

export default CertificatePage








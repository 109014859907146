import React from 'react'

import HomeTemplate from '../../themes/HomeTemplate'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'

import { styled } from '@mui/material/styles';
import {Container,Col} from 'react-bootstrap';
import reward from './assets/reward.svg';
import user from './assets/dp1.jpg';
import icon1 from './assets/icon-1.png';
import icon2 from './assets/icon2.png';
import { HiLocationMarker } from "react-icons/hi";
import { FaHandshake } from "react-icons/fa";
import { BsFillPlayFill } from "react-icons/bs";

import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import defaultboy from '../../assets/user.png'

import ENDPOINT from '../../context/EndPoint'
import Reward from './Reward'

import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const PersonalRewardInfo = ({userdata,props}) => {
const params = useParams();
const badge_id = params.badgeid;


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    
    
    
    
    
    
    
    
   
    
    //get course badges


    
    
    const [badge,setbadge] = useState(null);
    
    useEffect(()=>{
       
    
        const  getbadge = async ()=>{
          //// console.log(badge_id)
      const result2 =  await 
      axios.post(`${ENDPOINT}personal-badge-info.php?badge_id=${badge_id}`);
  
  if(result2!==null){
      const list = result2.data;
      setbadge(list.data);
    // // console.log("bdage "+JSON.stringify(result2))
                    
  }          
            }
            
            
    getbadge();
           
    }
    
    ,[])
    
    
    
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
  
  




    return (

<>


<Modal show={show} onHide={handleClose} 

{...props}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered
>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <video height="auto" autoPlay width="auto" src="https://skillsuprise.com/videos/rewards-demo.mp4" title="Offline Trainings Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen controls controlsList="nodownload"></video>
     
    </Modal>

    
<>












{/*badge section start*/}


{badge?


<>


<Container>
            <div className="d-flex p-5 flex-wrap">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5">
               
<div className="justify-center mb-5 w-100">
<div className=" p-2 text-center  blue-bg br-4 shadow col-10">
<img className="w-50 rounded-circle" src={badge.image?badge.image:defaultboy}/>
<h3 className="mt-3 sm-22 text-white">{badge.title}</h3>





  </div>



</div>

                    
<div className="text-center">
<h3 className="sm-22">Description</h3>
  <p>{badge.description}</p>

</div>
                    
             
                
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 position-relative">
                    <img src={reward} alt="" className=" w-100"></img>
                    <div className="d-none start-25 top-0 shadow rounded mt-5 p-3 bg-light">
                        <p className="text-secondary"><small>Donator registered</small></p>
                        <h1>2.413</h1>
                        <div className="d-flex flex-wrap mt-3 ms-2">
                            <img src={user} alt="" className="user rounded rounded-circle"></img>
                            <img src={user} alt="" className="user rounded rounded-circle"></img>
                            <img src={user} alt="" className="user rounded rounded-circle"></img>
                            <img src={user} alt="" className="user rounded rounded-circle"></img>
                            <div className="user bg-green rounded rounded-circle text-light"><p><small>2.3k+</small></p></div>
                        </div>
                    </div>
                    <div className="d-none end-0 bottom-0 shadow rounded mb-5 p-3 bg-light">
                    <div className="hand-icon bg-green rounded rounded-circle text-light text-center start-75"><FaHandshake className="mt-1"/></div>
                    <p className="mb-0"><small>Social</small></p>
                    <h6><small>loreum epsum loreum epsum loreum <br/>epsum loreum</small></h6>
                    <p><small><HiLocationMarker className="text-warning me-2 mt-0"/>Hongkong</small></p>
                    <div className="d-flex flex-wrap mb-3">
                        <Col>50%</Col>
                        <Col>IDR:5.000.000/-</Col>

                    </div>
                    <Box sx={{ flexGrow: 1 }} >
    
                    <BorderLinearProgress variant="determinate" value={50} />
                    </Box>

                    </div>
                </div>
            </div>
        </Container>



</>
:

<>

</>}







</>
</>
    )
}

export default PersonalRewardInfo







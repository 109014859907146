import React from 'react'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import RocketIcon from '@mui/icons-material/Rocket';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FavoriteIcon from '@mui/icons-material/Favorite';
const LeadsStatistics = () => {
    return (
        <>

            <div className='d-flex flex-wrap  '>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                    <div className='bg-white border border-end p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h6 className='m-0 p-0 text-secondary font-size'>CAMPAIGN SENT</h6>
                            </div>
                            <div>
                                <ArrowCircleUpIcon className='text-secondary fs-5' />
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <RocketIcon className='m-0 p-0 fs-1 text-secondary' />
                            <h2 className='ps-3 m-0 p-0'>197</h2>
                        </div>
                    </div>

                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                    <div className=' bg-white border border-end p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h6 className='m-0 p-0 text-secondary font-size'>ANNUAL PROFIT</h6>
                            </div>
                            <div>
                                <ArrowCircleUpIcon className='text-secondary fs-5' />
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <CurrencyExchangeIcon className='fs-1 text-secondary' />
                            <h2 className='ps-3 m-0 p-0'>$489.4k</h2>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                    <div className='bg-white border border-end p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h6 className='m-0 p-0 text-secondary font-size'>LEAD CONVERSATION</h6>
                            </div>
                            <div>
                                <ArrowCircleUpIcon className='text-secondary fs-5' />
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <i class="bi bi-activity fs-1 text-secondary"></i>
                            <h2 className='ps-3 m-0 p-0'>32.89%</h2>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                    <div className='bg-white border border-end p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h6 className='m-0 p-0 text-secondary font-size'>DAILY AVERAGE INCOME</h6>
                            </div>
                            <div>
                                <ArrowCircleUpIcon className='text-secondary fs-5' />
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <EmojiEventsIcon className='fs-1 text-secondary' />
                            <h2 className='ps-3 m-0 p-0'>$1,596.5</h2>
                        </div>
                    </div>
                </div>
               
            </div>



        </>
    )
}

export default LeadsStatistics
import React, { useContext } from 'react'
import dateFormat from 'dateformat';
import { useState } from 'react'
import { useEffect } from 'react'
import { Dropdown } from "react-bootstrap";
import { Class, MoreVert } from "@material-ui/icons";
import {
  Calendar as CalendarIcon,
  Clock,
  ClockFill,
} from "react-bootstrap-icons";
import { Chip } from '@mui/material';
import { CopyAll, Edit } from '@mui/icons-material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ForwardIcon from "@mui/icons-material/ArrowForwardIos";
import UploadClassRecordingModal from '../../../components/modals/UploadClassRecordingModal';
import ClassRescheduleModal from '../../../components/modals/ClassRescheduleModal';
import EditClassModal from '../../../components/modals/EditClassModal';
import SecurityHeaders from '../../../context/SecurityHeaders';
import { AuthContext } from '../../../context/AuthContext';
import ENDPOINT from '../../../context/EndPoint';
import axios from 'axios';
const ScheduleItem = ({item,index}) => {

  const {user} = useContext(AuthContext)
    const [reschedule,setreschedule] = useState(false)

    const [upload,setupload] = useState(false)
    const CloseUpload = ()=>{
    
    setupload(false)
    }


  
    const CloseReschedule = ()=>{
    setreschedule(false)
    }

const [edit,setedit] = useState(false)
  const CloseEditModal = ()=>{
  
  setedit(false)
  }

    const StartClass = async ()=>{
      const data = new FormData()
      data.append("session",user.SessionId)
      data.append("batch_id",item.batch_id)
      data.append("course_url",item.course_url)
      const res = await axios.post(ENDPOINT+"start-class.php",data,SecurityHeaders)
      if(res){
        console.log(" res "+JSON.stringify(res))
      }
    }
    
  return (
    <>

<UploadClassRecordingModal visibility={upload} item={item} handleClose={CloseUpload}/>
<ClassRescheduleModal visibility={reschedule} item={item} handleClose={CloseReschedule}/>
<EditClassModal visibility={edit} handleClose={CloseEditModal} item={item}/>
 
<div
                    key={index}
                    className=" container-fluid p-3 my-2 col-lg-6"
                  >
                    <div className="w-100 flex rounded border p-3">
                      <div className="container-fluid">
                        <div class="d-flex justify-content-between">
                          <h5 className="">
                            #{item.id}:{item.class_title}
                          </h5>
                          <div class="d-flex">
                            <Chip
                              className={
                                item.class_status === "completed"
                                  ? "bg-success text-light"
                                  : "bg-danger text-light"
                              }
                              size="small"
                              label={item.class_status}
                            />
                            <Dropdown className="options-dropdown">
                              <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                                <MoreVert className="text-dark" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                              
                              {item.meeting_link?
                              <>
                                <Dropdown.Item
                                  type="link"
                                  target="_blank"
                                  href={item.meeting_link}
                                >
                                  <CopyAll className="mr-1" />
                                  Copy Meeting Link
                                </Dropdown.Item>
                              </>:
                              <>
                              </>}
                              
{item.recording?
<>

<Dropdown.Item
                                  type="link"
                                  target="_blank"
                                  href={item.recording}
                                >
                                  <CopyAll className="mr-1" />
                                  Copy Recording link
                                </Dropdown.Item>
</>:
<>

</>}
                               {item.class_status!="ongoing"
                               ?
                              <>
                            {item.class_status==="completed"?
                            <>
                            
                            
                            </>:
                            <>
                               <Dropdown.Item onClick={()=>
                                    setreschedule(true)}>
                                  <Edit className="mr-1" />
                                  Reschedule
                                </Dropdown.Item>
                            </>}
                             


{item.class_status==="completed"?
<>


</>:
<>

<Dropdown.Item
                                                               
                                  
                                                               >
                                                                 <DeleteIcon className="mr-1" />
                                                                 Delete 
                                                               </Dropdown.Item>
</>}


                              </>:
                              <>
                              </>}


                             
                              <Dropdown.Item
                                  onClick={() => {
                               setedit(true)
                                  }}
                                >
                                  <Class className="mr-1" />
                                 Edit 
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between mb-2">
                          <div class="d-flex align-items-end mb-1">
                            <CalendarIcon className="mb-1 text-danger" />
                            <p class="ml-1 mb-0">
                              <b>Date : </b>
                              {dateFormat(
                                item.class_starts,
                                "dS mmm , dddd"
                              )}
                            </p>
                          </div>
                          <div>
                            <p className="mb-0">
                              <b>Batch Id:</b>
                              {item.batch_id}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between mt-1">
                          <div class="d-flex align-items-end">
                            <ClockFill className="mb-1 text-danger" />
                            <p class="ml-1 mb-0">
                              {dateFormat(item.class_starts, "hh:mm tt")} -{" "}
                              {dateFormat(item.class_ends, " hh:mm tt")}
                            </p>
                          </div>
                          <div class="d-flex justify-content-between ">
                           

                          {item.class_status==="completed"||item.class_status==="ongoing"?
                          <>
                           <button
                              class="btn-outline-info btn me-2 btn-sm"
                           onClick={()=>setupload(true)}
                            >
                              Upload Recording
                            </button>
                          
                          </>:
                          <>
                          
                          <a
                            onClick={()=>StartClass()}
                             
                              target="_blank"
                              className="btn-outline-primary btn btn-sm"
                            >
                              Start class
                            </a>
                          </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

    </>
  )
}

export default ScheduleItem
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import GetRemainders from '../../../actions/team/GetRemainders'
import { AuthContext } from '../../../context/AuthContext'
import AddRemainder from './AddRemainder'
import * as timeago from 'timeago.js'
import { Dropdown, Modal } from 'react-bootstrap'
import { MoreVert } from '@material-ui/icons'
import DeleteTeamReminder from '../../../actions/team/DeleteTeamReminder'
const Remainders = () => {
const {user} = useContext(AuthContext)
const [reminders,setreminders] = useState(null)

    const NewRemainders = async ()=>{


        const res = await GetRemainders({payload:{
            "SessionId":user.SessionId
        }})
if(res){
    console.log("Resp is "+JSON.stringify(res))
    if(res.status==="success"){
        setreminders(res.data)
        setstats(res.stats)
        
                }
}
      
    }

const [stats,setstats] = useState(null)
    useEffect(()=>{
NewRemainders()
    },[])

    const Delete = async({payload})=>{
    
    const res = await DeleteTeamReminder({payload:payload})
    



    if(res){
    
    if(res.status==="success"){
    
    NewRemainders()
    }
    
    }
    }
  return (
    <div>
<div className="container">


<div className="d-flex flex-wrap justify-content-between mt-4 pt-3">
 
<div className="col-lg-7 overflow-y">

{stats?
<><div className="d-flex mb-3 p-mb-0">

<div className="col-lg-2 p-1">

<div className="border p-3">
    <h6>{stats.pending}</h6>
    <p >Pending</p>
</div>
</div>


<div className="col-lg-2 p-1">

<div className="border p-3">
    <h6>{stats.completed}</h6>
    <p>Completed</p>
</div>
</div>


<div className="col-lg-2 p-1">

<div className="border p-3">
    <h6>{stats.completed}</h6>
    <p>Rejected</p>
</div>
</div>


</div>

</>:
<>


</>}

{reminders?
<>
{reminders.map((item)=>(
    <>
<div className="border hover-active mb-3 p-3 br-5">

<div className="d-flex justify-content-between">
<h6>{item.title}</h6>
<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    <Dropdown.Item onClick={()=>Delete({payload:item})}>Delete Task</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
</div>
<hr/>
<div className="d-flex align-items-center justify-content-between">
    <p><b>Added : </b> {timeago.format(item.create_datetime)} </p>
    <p><b>Deadline : </b> 23rd </p>
    <p><b>Time Left : </b> {timeago.format(item.deadline)}</p>
</div>
<p>{item.description}</p>
    </div>


    </>
)
)}

</>:null}


</div>
<div className="col-lg-4 p-3 rounded">
    <AddRemainder/>
</div>
</div>


</div>




    </div>
  )
}

export default Remainders
import React from 'react'
import {useHistory} from "react-router-dom";

import { Link } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'

import salesicon from '../../assets/iconsv2/transactions.svg'
import summary from '../../assets/iconsv2/summary.svg'
import users from '../../assets/iconsv2/users.svg'
import budget from '../../assets/iconsv2/budget.svg'
import employee from '../../assets/iconsv2/employee.svg'
import interns from '../../assets/iconsv2/interns.svg'
import students from '../../assets/iconsv2/students.svg'
import courses from '../../assets/iconsv2/courses.svg'
import workshops from '../../assets/iconsv2/workshop.svg'
import internships from '../../assets/iconsv2/internships.svg'
import customer from '../../assets/iconsv2/customer-support.svg'
import placement from '../../assets/iconsv2/placement-support.svg'
import community from '../../assets/iconsv2/summary.svg'

import videos from '../../assets/iconsv2/video.svg'
import reviews from '../../assets/iconsv2/review.svg'

import feedback from '../../assets/iconsv2/class-feedback.svg'

import error from '../../assets/iconsv2/summary.svg'
import leads from '../../assets/iconsv2/leads.svg'
import classes from '../../assets/iconsv2/class-schedules.svg'

import logs from '../../assets/iconsv2/logs.svg'

const Sidebar = ({userdata,page,account,permission}) => {

    



  const history = useHistory();
    return (
        
<div  id="sidebar" className="sidebar">


<div class="menu-w color-scheme-dark color-style-bright menu-position-side menu-side-left menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link">
<div  class="logo-w">

</div>


<ul class="main-menu">


<li class="selected ">
<Link onClick={() => history.goBack()}>
          
          
            <Navbar.Brand href="/">
              <div className="flex back align-center">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 11L6.414 11 11.707 5.707 10.293 4.293 2.586 12 10.293 19.707 11.707 18.293 6.414 13 21 13z"></path></svg>
              
              <h4 className="go-back" >Go Back</h4>
              
  
              </div>
   
              </Navbar.Brand>
            
            </Link> 
</li>


<li class="selected">






    {
userdata?


""

:

<Link to="/login">
<div  class="icon-w">
<div  class="os-icon ">
    

<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path></svg>



    </div></div><span>Login/SignUp</span>
</Link>




}

</li>


{permission?
<>


{parseInt(permission.whatsapp_chat)===1?
<>



<li class="selected ">
<Link to="/whatsapp/919381683945">
<div  class="icon-w">
<div  class="os-icon ">
    
<img alt="" src={salesicon}/>



    </div></div><span>WhatsApp Chats</span>
</Link>
</li>


</>:
<>

</>}






{parseInt(permission.whatsapp_chat)?
<>
<li class="selected "><Link to="/whatsapp-notifications">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>WA Notifications</span></Link>

    </li>



</>:
<>

</>}



{userdata&&permission?

<>

<li class="selected ">
<Link to="/internal">
<div  class="icon-w">
<div  class="os-icon ">
    
<img alt="" src={summary}/>

    </div></div><span>Summary</span>
</Link>
</li>

{parseInt(permission.sales_statistics)===1?
<>
<li class="selected ">
<Link to="/internal/finance-summary">
<div  class="icon-w">
<div  class="os-icon ">
    

<img alt="" src={budget}/>



    </div></div><span>Sales Overview</span>
</Link>
</li>



</>:
<>

</>}


{parseInt(permission.transactions)===1?
<>



<li class="selected ">
<Link to="/internal/sales-summary/all">
<div  class="icon-w">
<div  class="os-icon ">
    
<img alt="" src={salesicon}/>



    </div></div><span>Course Enrollments</span>
</Link>
</li>


</>:
<>

</>}


{parseInt(permission.users_management)?
<>
<li class="selected ">
<Link to="/internal/users-management">
<div  class="icon-w">
<div  class="os-icon ">
    




<img alt="" src={users}/>


    </div></div><span>Users Management</span>
</Link>
</li>

</>:
<>

</>}


{parseInt(permission.students_management)?
<>
<li class="selected ">
<Link to="/internal/student-management/all">
<div  class="icon-w">
<div  class="os-icon ">
    

<img alt="" src={students}/>



    </div></div><span>Students Management</span>
</Link>
</li>

</>:
<>

</>}



{parseInt(permission.courses_management)?
<>
<li class="selected ">
<Link to="/internal/trainings-management/courses/all">
<div  class="icon-w">
<div  class="os-icon ">
    
<img alt="" src={courses}/>

    </div></div><span>Courses Management</span>
</Link>
</li>

</>:
<>

</>}


{parseInt(permission.internships_management)?
<>


<li class="selected ">
<Link to="/internal/internship-applications/all">
<div  class="icon-w">
<div  class="os-icon ">
    

<img alt="" src={interns}/>



    </div></div><span>Interns Management</span>
</Link>
</li>


</>:
<>

</>}



{parseInt(permission.team_management)?
<>
<li class="selected ">
<Link to="/team-management">
<div  class="icon-w">
<div  class="os-icon ">
    

<img alt="" src={employee}/>


    </div></div><span>Team Management</span>
</Link>
</li>


</>:
<>

</>}






{parseInt(permission.doubt_support)?
<>
<li class="selected ">
<Link to="/internal/doubts/all">
<div  class="icon-w">
<div  class="os-icon">
  
<img alt="" src={students}/>

    </div></div><span>Student Support</span>
</Link>
</li>


</>:
<>

</>}



{parseInt(permission.student_tasks)?
<>

<li class="selected "><Link to="/internal/students-tasks">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>Assignmet Submissions</span></Link>

    </li>




</>:
<>

</>}


{parseInt(permission.community_management)?
<>


<li class="selected ">
<Link to="/community-management">
<div  class="icon-w">
<div  class="os-icon ">
    

<img alt="" src={students}/>



    </div></div><span>Community Management</span>
</Link>
</li>

</>:
<>

</>}


{parseInt(permission.quiz_management)?
<>
<li class="selected ">
<Link to="/quiz-management">
<div  class="icon-w">
<div  class="os-icon ">
    

<img alt="" src={students}/>



    </div></div><span>Quiz Management</span>
</Link>
</li>

</>:
<>

</>}




{parseInt(permission.team_tasks)?
<>
<li class="selected "><Link to="/internal/tasks">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>Team Tasks</span></Link>

    </li>



</>:
<>

</>}




{parseInt(permission.leads)?
<>
<li class="selected ">
<Link to="/internal/leads/all/all">
<div  class="icon-w">
<div  class="os-icon">
     
<img alt="" src={leads}/>

    </div></div><span>Leads</span>
</Link>
</li>
</>:
<>

</>}




{parseInt(permission.placement_support)?
<>

<li class="selected ">
<Link to="/internal/placement-requests/all">
<div  class="icon-w">
<div  class="os-icon ">
<img alt="" src={placement}/>

    
    </div></div><span>Placement Support</span>
</Link>
</li>

</>:
<>

</>}



{parseInt(permission.videos_management)?
<>

<li class="selected"><Link to="/internal/video-management">
<div  class="icon-w">
<div  class="os-icon">
  
<img alt="" src={videos}/>

    </div></div><span>Videos Management</span></Link>

    </li>


</>:
<>

</>}











{parseInt(permission.website_log)?
<>
<li class="selected "><Link to="/internal/activity-log">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>Website Activity Log</span></Link>

    </li>


</>:
<>

</>}




{parseInt(permission.reviews_management)?
<>
  
<li class="selected"><Link to="/internal/trainings-management/reviews">
<div  class="icon-w">
<div  class="os-icon">
  
<img alt="" src={reviews}/>


  </div></div><span>Reviews Management</span></Link>

    </li>
 

</>:
<>

</>}




{parseInt(permission.class_feedbacks)?
<>

<li class="selected"><Link to="/internal/trainings-management/feedbacks">
<div  class="icon-w">
<div  class="os-icon">
  
<img alt="" src={feedback}/>

 </div></div><span>Daily Class Feedbacks</span></Link>

    </li>
    



</>:
<>

</>}




{parseInt(permission.classes_management)?
<>
<li class="selected ">
<Link to="/internal/classes">
<div  class="icon-w">
<div  class="os-icon ">
<img alt="" src={classes}/>
 
    
    
    </div></div><span>Class Schedules</span>
</Link>
</li>




</>:
<>

</>}






  














{parseInt(permission.quiz_attempts)?
<>

<li class="selected ">
<Link to="/quiz-attempts">
<div  class="icon-w">
<div  class="os-icon ">
    
<img alt="" src={courses}/>

    </div></div><span>Quiz Attempts</span>
</Link>
</li>

</>:
<>

</>}



{parseInt(permission.internships_management)?
<>

<li class="selected ">
<Link to="/internal/internships-management">
<div  class="icon-w">
<div  class="os-icon ">
    
<img alt="" src={internships}/>

    </div></div><span>Manage Internships</span>
</Link>
</li>



</>:
<>

</>}




{parseInt(permission.workshops_management)?
<>

<li class="selected ">
<Link to="/internal/workshops-management">
<div  class="icon-w">
<div  class="os-icon ">
    
<img alt="" src={workshops}/>

    </div></div><span>Manage Workshops</span>
</Link>
</li>




</>:
<>

</>}






{parseInt(permission.leaves_management)?
<>

<li class="selected ">
<Link to="/internal/leaves">
<div  class="icon-w">
<div  class="os-icon ">
<img alt="" src={placement}/>

    
    </div></div><span>Leave Requests</span>
</Link>
</li>




</>:
<>

</>}




{parseInt(permission.scholarship_management)?
<>


<li class="selected ">
<Link to="/internal/scholarship-applications">
<div  class="icon-w">
<div  class="os-icon ">
<img alt="" src={placement}/>

    
    </div></div><span>Scholarship applications</span>
</Link>
</li>



</>:
<>

</>}






{parseInt(permission.idcard_management)?
<>
<li class="selected "><Link to="/id-cards">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>ID Cards Manageent</span></Link>

    </li>



</>:
<>

</>}




{parseInt(permission.certificate_management)?
<>
<li class="selected "><Link to="/certificate-management">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>Certificate Management</span></Link>

    </li>



</>:
<>

</>}

{parseInt(permission.website_settings)?
<>
<li class="selected "><Link to="/configurations">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>Configurations</span></Link>

    </li>



</>:
<>

</>}





{parseInt(permission.alumni_management)?
<>


    

<li class="selected mb-50"><Link to="/alumni-management">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>Alumni Management</span></Link>

    </li>



</>:
<>

</>}







{parseInt(permission.team_permissions)?
<>


    

<li class="selected mb-50"><Link to="/alumni-management">
<div  class="icon-w">
<div  class="os-icon">
<img alt="" src={logs}/>

      </div></div><span>Team Permissions</span></Link>

    </li>



</>:
<>

</>}







































    











       
    
  
    
 

 
    
















 






  








</>










  
:""
}




</>:
<>




</>}












<hr/>
























  


  


 
































</ul>




</div>


</div>










    )
}

export default Sidebar



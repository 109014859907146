import React, { useContext, useState } from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import EditIcon from "@mui/icons-material/Edit";
import { Toast, Modal } from "react-bootstrap";
import axios from "axios";
import ENDPOINT from "../../../context/EndPoint";
import SecurityHeaders from "../../../context/SecurityHeaders";
import { AuthContext } from "../../../context/AuthContext";
import CircularProgress from "../../../components/progress/CircularProgress";
const ExpenseItem = ({ expense,fetchData }) => {
  const { user } = useContext(AuthContext);
  const [display, setDisplay] = useState(false);
  const [modal, setModal] = useState(false);
 

  const [updateStatus, setUpdateStatus] = useState(false);
  //add and updateExpenses
  const [status, setStatus] = useState("pending");
  const [title, setTitle] = useState(expense.title);
  const [description, setDescription] = useState(expense.description);
  const [category, setCategory] = useState(expense.category);
  const [type, setType] = useState(expense.type);
  const [amount, setAmount] = useState(expense.amount);
  const [expenseId, setExpenseId] = useState("");
  //toast
  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toast, setToast] = useState(false);

  //send input data

  // update Expenses

  const updateExpenses = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData();
      data.append("session", user.SessionId);
      data.append("id", expenseId);
      data.append("title", title);
      data.append("description", description);
      data.append("type", type);
      data.append("amount", amount);
      data.append("category", category);
      data.append("status", status);

      const res = await axios.post(
        ENDPOINT + "admin-update-expense.php",
        data,
        SecurityHeaders
      );

      if (res) {
        if (res.data.status === "success") {
          setToastMessage("Update successfully");
          setToast(true);
          fetchData()
          
        } else {
          setToastTitle("Something went wrong!");
          setToastMessage("Please try again...");
          setToast(true);
        }
      }
    } catch (error) {
      setToastTitle("Error");
      setToastMessage("An error occurred. Please try again.");
      setToast(true);
    }
  };

  // const sendIdToParent = (id) => {
  //   modalOpen(true)
  //   onDataFromChild(id);
  // };

  return (
    <>
      <div className="position-fixed bottom-60 left-0 w-100 justify-center">
        <Toast
          className="bg-warning"
          onClose={() => setToast(false)}
          show={toast}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastTitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </div>
      <Modal
        show={modal}
        size="md"
        animation={false}
        onHide={() => setModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={updateExpenses}>
              <div className="form-group">
                <label>title</label>
                <input
                  className="form-control"
                  placeholder="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>description</label>
                <input
                  className="form-control"
                  placeholder="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>category</label>
                <input
                  className="form-control"
                  placeholder="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>type</label>
                <input
                  className="form-control "
                  placeholder="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>amount</label>
                <input
                  className="form-control "
                  placeholder="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="form-group mt-3">
                <label>status</label>
                <select
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="pending">pending</option>
                  <option value="granted">granted</option>
                </select>
              </div>

              <button type="submit" className="btn btn-primary">
                {updateStatus ? (
                  <CircularProgress className="text-white" size={20} />
                ) : (
                  ""
                )}
                Add Expenses
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <div>
        <div className="shadow p-3 mt-2 mb-2">
          <div
            onClick={() => setDisplay(!display)}
            className="d-flex click justify-content-between"
          >
            <h5>{expense.date}</h5>
            {display ? (
              <>
                <ChevronUp className="click" />
              </>
            ) : (
              <>
                <ChevronDown className="click" />
              </>
            )}
          </div>
          <div className="d-flex justify-content-between col-11">
            <p className="mr-4">
              <b> {expense.title}</b>
            </p>
            <p className="mr-4">
              <b> {expense.category}</b>
            </p>
            <p>
              <b>status : </b>
              {expense.status}{" "}
            </p>
            <div >
              <EditIcon color='primary'
                onClick={(e) => (setModal(true), setExpenseId(expense.id))}
              />
            </div>
          </div>
          {display ? (
            <>
              <hr />
              <p>
                {" "}
                <b>Amount :</b> {expense.amount}
              </p>
              <p>
                {" "}
                <b>type :</b> {expense.type}
              </p>
              <p>
                <b>Description : </b> {expense.description}
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ExpenseItem;

import React from 'react';
import styled from 'styled-components';


const ShimmerCourseItem = ()=>{

return(

<ShimmerCourseItemStyled>

<div className="course-item col-lg-6 ">
    
    <div className="course-image shimmer animate"  alt=""></div>
   <h2 class="course-title shimmer shimmer-title"> </h2>

<p class="tutor-name animate shimmer-tutor-name"><b></b> </p>



</div>



</ShimmerCourseItemStyled>


)


};
export default ShimmerCourseItem;

export const ShimmerCourseItemStyled = styled.div`


margin-right: 10px;
.course-item{
    height: 350px;

background-color: white;
position: relative;
box-shadow:0px 0px 4px grey;
}
.course-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.course-image{
    width: 100%;
    height: 150px;
}
.course-top{
    position: absolute;
    top: 0px;
    font-size:14px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.course-title{
    font-size: 20px;
    padding: 10px;
}
.course-buttons{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 16px;
    text-decoration:none !important;
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.course-buttons a{
    text-decoration: none;
}
.ratings-container{
    display: flex;
    font-size: 14px;
    padding-left: 10px;
    margin-top: -7px;
    margin-bottom: 10px;
    color: #565656;

}
.students-count{
    margin-left: 10px;
    
}
.ratings{
    display: flex;
    align-items: center;
    align-content: center;
    
}
.ratings-container p{
    margin: 0px 7px 0px 0px;
    padding: 0px;
}
.ratings object, .ratings svg{
    height: 20px;
    width: 20px;
    fill: orange;
    stroke: orange;
    margin-right: 3px;
}
.tutor-name{
    font-size: 14px;
    padding-left: 10px;
    margin-top: -7px;
}
.course-prices{
    display: flex;
    font-size: 16px;
    align-content: center;
    padding: 0px 10px;
}
.current-price{
    color: red;
    
    font-size: 18px;
    margin-right: 15px;
}
.cutoff-price{
    color: grey;
    margin-right: 15px;
}
.discount{
    color: grey;
}
@media only screen and (max-width:576px){

    .course-item{
    width: 230px;
    height: 320px;
 
}
.course-title{
   font-size: 16px;
}

.course-image{
    height: 120px;
}
.current-price{
    font-size: 16px;
}
.course-buttons a{
    font-size:14px;
}
.ratings object, .ratings svg{
    height: 15px;
    width: 15px;
    fill: orange;
    stroke: orange;
    margin-right: 3px;
}
}

`;


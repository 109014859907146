import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import InternshipItemSkeleton from "../../../skeleton/InternshipItemSkeleton";
import dateFormat from "dateformat";
import { Dropdown, Modal,Container } from "react-bootstrap";
import {
  AttachFile,
  Check,
  CropSharp,
  Delete,
  MoreVert,
  Schedule,
  ThumbDown,
} from "@material-ui/icons";
import TaskEditForm from "./EditTask";
import ENDPOINT from "../../../context/EndPoint";
import AdminAddTask from "./AddTask";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import SubmitTask from "./SubmitTask";
import CountDown from "../components/CountDown";
import Alert from "@mui/material/Alert";
import { Avatar } from "@material-ui/core";
import StudentAssignmentEvaluate from "./StudentAssignmentEvaluate";
import { ChevronDown, Upload } from "react-bootstrap-icons";
import { Download, FileUpload } from "@mui/icons-material";
import { TextField } from '@material-ui/core'
const StudentsTaskSubmissions = ({ page, role }) => {
  const { user } = useContext(AuthContext);

  const [edit, setedit] = useState(false);

  const tasktype = useRef();

  const changeTaskStatus = (e) => {
    settaskstatus(tasktype.current.value);
    getTasks(tasktype.current.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [tasks, settasks] = useState("start");
  const [category, setcategory] = useState("all");

  const [taskstatus, settaskstatus] = useState("all");

  const [taskid, settaskid] = useState(null);
  const [remove, setremove] = useState(false);
  const [removeid, setremoveid] = useState(null);
  const [submit, setsubmit] = useState(false);

  const showSubmit = (e) => {
    const id = e.target.id;
    settaskid(id.replace("submit", ""));
    setsubmit(true);
  };

  const closeSubmit = () => {
    setsubmit(false);
  };
  const editTask = (e) => {
    const id = e.target.id;
    settaskid(id.replace("edit", ""));

    setedit(true);
  };

  const closeEdit = () => {
    setedit(false);
  };
  const [add, setadd] = useState(false);

  const closeAddition = () => {
    setadd(false);
    getTasks(taskstatus);
  };

  const showRemove = (e) => {
    const id = e.target.id;

    settaskid(id.replace("delete", ""));
    setremove(true);
  };
  const closeRemove = () => {
    setremove(false);
  };

  useEffect(() => {
    getTasks(taskstatus);
  }, []);

  const getTasks = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    formData.append("status", status);

    const res = await axios.post(
      ENDPOINT + "admin-get-students-tasks.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      // console.log(" s"+JSON.stringify(res))
      if (res.data.status === "success") {
        
        setInitialData(res.data.data);
        setResponse(res.data.data)
      } else {
      }
    }
  };

  //delete task code

  const deleteTask = async () => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("id", taskid);

    const res = await axios.post(ENDPOINT + "admin-delete-task.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res.data.status === "success") {
      getTasks();
      closeRemove();
     
    } else {
    }
  };

  const [query, setquery] = useState(null);

  const ToggleMore = ({ id }) => {
    const more = document.getElementById("more" + id);
    if (more) {
      if (more.classList.contains("d-none")) {
        more.classList.replace("d-none", "d-block");
      } else {
        more.classList.replace("d-block", "d-none");
      }
    }
  };

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.email.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term) ||
        item.title.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };
  return (
    <>
      <div className="tasks-page">
        

      
        <Container fluid className="mb-3">
      
      <div>
   
      <input
        placeholder="name email title status"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => handleSearch(e)}
        className="fixed-search"
        margin="normal"
      />
    
    </div>
            </Container>
        <div className="internships-list d-flex flex-wrap  sm-p-0  container-fluid">
          {response === "start" ? (
            <div className="container-fluid">
              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />
            </div>
          ) : response ? (
            response.map((internship) => (
              <>
                <div class="p-2 col-lg-6 col-md-12  col-sm-12">
                  <div class="hover-active  rounded shadow p-4">
                    <div class="d-flex flex-row justify-content-between">
                      <h5 className="mb-0  text-primary">{internship.title}</h5>
                      <Dropdown className="options-dropdown">
                        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                          <MoreVert className="text-dark" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#"
                            id={"edit" + internship.id}
                            onClick={editTask}
                          >
                            <Check className="me-2" />
                            Evaluate
                          </Dropdown.Item>
                          <Dropdown.Item href="#" id={"delete" + internship.id}>
                            <ThumbDown className="me-2" />
                            Reject
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            id={"delete" + internship.id}
                            onClick={showRemove}
                          >
                            <Delete className="mr-3" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <p class="ts-6">
                      <b>Course:</b>
                      {internship.course_url} | Assignment ID :{" "}
                      {internship.assignment_id}
                    </p>
                    <p class="text-secondary truncate truncate-2">
                      {internship.description}
                    </p>
                    <div class="d-flex flex-row align-items-end justify-content-between">
                      <div class=" d-flex align-items-end">
                        <Avatar src={internship.profile_pic}/>             
                        
                        <div class="ms-2">
                          <h6 class="mb-0">{internship.first_name+" "+internship.last_name}</h6>
                          <h6 class="mb-0 text-secondary">Efficiency:{internship.effeciency} %</h6>
                        </div>
                      </div>

                      <div class="d-flex flex-row align-items-end">
                        <Schedule className="me-1" />
                        Deadline : { dateFormat(
                            internship.deadline,
                            "dS mmm hh:mm tt"
                          )}
                        <a href={internship.solution_link} target="_blank">
                          {" "}
                          <AttachFile className="ms-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hover-active d-none p-2 mb-1 mt-1">
                  <div className="col-12 p-0 mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-0  text-primary">
                        {internship.id}. {internship.title}
                      </h5>
                      <div className="d-flex align-items-center">
                        {internship.solution_link ? (
                          <>
                            <a
                              href={internship.solution_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Download />
                            </a>
                          </>
                        ) : null}

                        <ChevronDown
                          className="click ml-3 mr-3"
                          onClick={() => ToggleMore({ id: internship.id })}
                        />
                        {/* Rohith added icons to dropdown  */}

                        <Dropdown className="options-dropdown">
                          <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                            <MoreVert className="text-dark" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#"
                              id={"edit" + internship.id}
                              onClick={editTask}
                            >
                              <Check className="me-2" />
                              Evaluate
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              id={"delete" + internship.id}
                              onClick={showRemove}
                            >
                              <ThumbDown className="me-2" />
                              Reject
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              id={"delete" + internship.id}
                              onClick={showRemove}
                            >
                              <Delete className="mr-3" />
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        {/* Rohith added icons to dropdown  */}
                      </div>
                    </div>
                  </div>

                  <div className="flex space-between">
                    <div className="flex">
                      <div className="flex mr-15">
                        <Avatar src={internship.profile_pic} />
                        <div className="ml-2">
                          <h6 className="mb-0">
                            {internship.first_name + " " + internship.last_name}
                          </h6>
                          <p className="extra-small mb-0">
                            <b>Course: </b>
                            {internship.course_url} | Assignment ID :{" "}
                            {internship.assignment_id}
                          </p>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div>
                      {internship.corrections !== "null" ? (
                        <>
                          <p className="extra-small text-danger">
                            {internship.corrections ? (
                              <>
                                <>Corrections</>
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div id={"more" + internship.id} className="d-none">
                    <hr />
                    <div>
                      <p>{internship.description}</p>
                      <div className="flex justify-content-between">
                        <p className="mb-0  p-0">
                          Assigned On :{" "}
                          {dateFormat(
                            internship.create_datetime,
                            "dS mmm hh:mm tt"
                          )}
                        </p>

                        <p className="mb-0  p-0">
                          Deadline :{" "}
                          {dateFormat(internship.deadline, "dS mmm hh:mm tt")}
                        </p>
                        {internship.solutio_url ? (
                          <>
                            <p className="mb-0  p-0">
                              Submitted On :{" "}
                              {dateFormat(
                                internship.submission_time,
                                "dS mmm hh:mm tt"
                              )}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0  p-0">
                          Assigned/Evaluated :{" "}
                          {dateFormat(internship.deadline, "dS mmm hh:mm tt")}
                        </p>
                        {internship.solution_link ? (
                          <>
                            <p className="mb-0  p-0">
                              Effeciency : {internship.marks_received} %
                            </p>

                            <p className="mb-0  p-0">
                              Delay : {internship.delay}
                            </p>
                          </>
                        ) : null}
                      </div>

                      <hr />

                      {internship.corrections !== "null" ? (
                        <>
                          <p>
                            {internship.corrections ? (
                              <>
                                <>Corrections : {internship.corrections}</>
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className="container-fluid">No tasks</div>
          )}
        </div>
      </div>

      <Modal show={edit} animation={false} onHide={closeEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Evaluate Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudentAssignmentEvaluate id={taskid} />
        </Modal.Body>
      </Modal>

      <Modal show={submit} animation={false} onHide={closeSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubmitTask id={taskid} />
        </Modal.Body>
      </Modal>

      <Modal show={add} animation={false} onHide={closeAddition}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdminAddTask />
        </Modal.Body>
      </Modal>

      <Modal show={remove} animation={false} onHide={closeRemove}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TaskEditForm id={removeid} />
          <button className="btn btn-danger" onClick={deleteTask}>
            Delete
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StudentsTaskSubmissions;

import React, { useState } from 'react'

const DesignBlogs = () => {


const design = document.getElementById("design");


const changeDesign = ({content})=>{
localStorage.setItem("content",content)
design.innerHTML = localStorage.getItem("content");
}
  return (
    <div className="container-fluid">


<div className="flex-wrap">

<div className="col-6 border-1">
<textarea className="w-100 min-vh-70" id="html"
 onChange={(e)=>{changeDesign({content:e.target.value})}}></textarea>
</div>
<div className="col-6">
<div className="design">

<div id="design">
<p>design goes here</p>
</div>

</div>

</div>

</div>



    </div>
  )
}

export default DesignBlogs
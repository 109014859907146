import React from 'react'
import { useState } from 'react'
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../../context/EndPoint'
import { useParams } from 'react-router-dom';

const AddCourseAssignment = ({id}) => {

const params = useParams()
const courseurl = params.course_url
const batchid = params.batch_id
const {user} =  useContext(AuthContext)







const [title,settitle] = useState(null)
const [description,setdescription] = useState(null)
const [assignedto,setassignedto] = useState(null)
const [reference,setreference] = useState(null)
const [deadline,setdeadline] = useState(null)
const [marks,setmarks] = useState(null)
const [earnedmarks,setearnedmarks] = useState(null)

const [corrections,setcorrections] = useState(null)
const [assignedby,setassignedby]=  useState(null)
const [notify,setnotify] = useState(true)

const [account,setaccount]=  useState(null)














    const changeTitle = (e)=>{
        settitle(e.target.value)
        
      }
      const changeDescription = (e)=>{
          setdescription(e.target.value)
      }
      const changeAssignto = (e)=>{
          setassignedto(e.target.value)
      }
    const changeMaxMarks = (e)=>{
        setmarks(e.target.value)
    }
    const changeMarksGiven = (e)=>{
        setearnedmarks(e.target.value)
    }
    const changeCorrections = (e)=>{
        setcorrections(e.target.value)
    }
    const changeReference = (e)=>{
        setreference(e.target.value);

    }
    const changeDeadline = (e)=>{
        setdeadline(e.target.value)
    }
    const changeStatus = (e)=>{
        setstatus(e.target.value)
    }
      const [status,setstatus]= useState("pending")
const [addstatus,setaddstatus] = useState(false)
      const [snackbar,setsnackbar] = useState(false);
      const [snackbarMessage,setsnackbarMessage] = useState(null)
      
      

      const onSubmit = async (e)=> {
        e.preventDefault();
          setaddstatus(true)




         
          
                fetch(`${ENDPOINT}admin-add-course-assignment.php?title=${title}&description=${description}&marks=${marks}&deadline=${deadline}&reference=${reference}&session=${user.SessionId}&course=${courseurl}&batch_id=${batchid}`)
                .then(r=>r.json())
                .then(result=>{
                     if(result){
                     console.log("addedd assignment"+batchid)

                      if(result[0].status==="success"){
setaddstatus(false);
setsnackbarMessage("Changes Saved Successfully")
setsnackbar(true)    
           
}
else{
    setaddstatus(false);

setsnackbarMessage("Something went wrong")
setsnackbar(true)  
}
                     }
                       
                    
                
             
                    
                })
                
               
        
               
          }
          
         


     
          const options = ['Option 1', 'Option 2'];
          
        
            const [value, setValue] = React.useState(options[0]);
            const [inputValue, setInputValue] = React.useState('');
          
   



      



    return (
        <div>
            
            {/*account form*/}
          
            
        <>
<div className="flex-wrap container">
            
            <div className="">
            
            
            
            <form>
            
         
            <div class="form-row">
                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Assignment Title</label>
                  <input type="text" class="form-control" id="inputEmail4" value={title} onChange={changeTitle} maxLength={200}/>
                </div>

                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Description</label>
                  <input type="text" class="form-control" id="inputEmail4" value={description} onChange={changeDescription} maxLength={500}/>
                </div>

                <div class="form-group col-lg-6">
                  <label for="inputEmail4">Reference</label>
                  <input type="text" class="form-control" id="inputEmail4" value={reference} onChange={changeReference} maxLength={50}/>
                </div>
                <div class="form-group col-lg-6">
                  <label for="inputEmail4">Deadline</label>
                  <input type="datetime-local" class="form-control" id="inputEmail4" value={deadline} onChange={changeDeadline} maxLength={30}/>
                </div>
               
                <div className="col-lg-12">
                  <p>Notify Students</p>
                  <input type="checkbox" checked></input>
                </div>

   

<Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={()=>setsnackbar(false)}
        message={snackbarMessage}
     
      />





<div className="form-row">
<div class="form-group col-lg-6">
                  <label for="inputPassword4">Max Marks</label>
                  <input type="text" class="form-control" id="inputPassword4" value={marks} onChange={changeMaxMarks} maxLength={30}/>
                </div>
             
               
             
             
              </div>
            
            
             
</div>

           
                
            
            
           
            
            
            
           
            
             
            
            
            
              <div class="form-group">
                
              </div>
              <button type="submit" class="btn btn-primary w-100" onClick={onSubmit}  >{addstatus?<CircularProgress className="text-white" size={20}/> :""}Save Changes</button>
            </form>
            
            </div>
  
            
            
            
                        </div>

        </>

       
                           {/*account form*/}
        </div>
    )
}

export default AddCourseAssignment

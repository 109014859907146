import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Container, Dropdown } from "react-bootstrap";

import { Snackbar, TextField } from "@material-ui/core";
import Chip from "@mui/material/Chip";

import { Link, useParams } from "react-router-dom";
import axios from "axios";

import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import dateFormat from "dateformat";
import { Check, MoreVert, PlayArrow } from "@material-ui/icons";
import SecurityHeaders from "../../context/SecurityHeaders";
import {
  Clock,
  DistributeVertical,
  EyeFill,
  Pause,
  PauseBtn,
} from "react-bootstrap-icons";
import {
  Add,
  AddBox,
  Chair,
  Delete,
  RampRight,
  Schedule,
} from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../context/EndPoint";
const TrainerCourseBatches = () => {
  const { user } = useContext(AuthContext);

  const params = useParams();

  const courseid = params.courseid;

  const courseurl = params.courseurl;

  const [category, setcategory] = useState("all");

  const [query, setquery] = useState(null);

  useEffect(() => {
    getBatches({ bid: "all" });
  }, []);

  const [coursedata, setcoursedata] = useState(null);

  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const getBatches = async ({ bid }) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    formData.append("session", user.SessionId);
    formData.append("course_id", courseid);
    formData.append('course_trainer','Manoj Kumar')
    if (query !== null) {
      formData.append("query", query);
    }
    if (bid !== null) {
      formData.append("status", bid);
    }

    const res = await axios.post(ENDPOINT + "trainer-based-batches.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      if (res.data.status === "success") {
        // setcoursedata(res.data.data);
        setInitialData(res.data.data)
        setResponse(res.data.data)

      } else {
      }
    }
  };

  const updateBatchStatus = async ({ cid, bid, bstatus }) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);
    formData.append("course_id", cid);
    formData.append("id", bid);
    formData.append("status", bstatus);

    const res = await axios.post(
      ENDPOINT + "admin-update-batch-status.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      if (res.data.status === "success") {
        getBatches({ bid });
      } else {
      }
    }
  };

  const options = ["Option 1", "Option 2"];

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  const [delete_id, setdelete_id] = useState(null);

  const DeleteBatch = async ({ id }) => {
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("id", id);
    const res = await axios.post(
      ENDPOINT + "admin-delete-batch.php",
      data,
      SecurityHeaders
    );

    if (res) {
      if (res.data.status === "success") {
        getBatches({ bid: "all" });
        console.log(JSON.stringify(res));
      }
    }
  };

  const [initialData, setInitialData] = useState("");

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");
  
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
  
    const filteredData = initialData.filter((item) => {
      return (
        item.batch_id.toLowerCase().includes(term) ||
        item.course_status.toLowerCase().includes(term) ||
        item.mode.toLowerCase().includes(term)
      );
    });
  
    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <>
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setsnackbar(false)}
        message={snackbarMessage}
      />

      <div className="container-fluid p-2  min-vh-100 sm-p-15 ">
        <div className="flex align-items-center space-between">
          <div className="flex align-items-center col-lg-8 space-between">
            <div className="flex fixed-search">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>

                <Select
                  value={category}
                  label="Category"
                  onChange={(e) => {
                    setcategory(e.target.value);
                    getBatches({ bid: e.target.value });
                  }}
                >
                  <MenuItem value={"all"}>All </MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"completed"}>Completed</MenuItem>
                </Select>
              </FormControl>

              <Paper
                onSubmit={(e) => {
                  e.preventDefault();
                  getBatches({ bid: "all" });
                }}
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  onChange={(e) => setquery(e.target.value)}
                  placeholder="Search Batch ID"
                  inputProps={{ "aria-label": "Search Batch ID " }}
                />
                <IconButton
                  onClick={() => getBatches({ bid: "all" })}
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </div>
          <Link
            className="btn btn-primary"
            to={
              "/internal/trainings-management/course/" +
              courseurl +
              "/" +
              courseid +
              "/addbatch"
            }
          >
            Add Batch
          </Link>
        </div>

        <Container fluid className="my-3">
        <div>
          <TextField
            label="Search courseId, mode, course name "
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </Container>

        <div className="d-flex flex-wrap">
          {response ? (
            <>
              {response.map((batch, index) => (
                <>
                  <div class="col-lg-4 p-2">
                    <div class="shadow rounded p-3 hover-active">
                      <div class="d-flex justify-content-between">
                        <p>
                          <b class="text-primary"> Batch ID : </b>{" "}
                          {batch.batch_id}
                        </p>
                        <div class="d-flex  ">
                          <Chip

size="small" className="mr-2 bg-success text-white"
                            label={batch.status}
                          />

                          <Dropdown className="options-dropdown">
                            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                              <MoreVert className="text-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateBatchStatus({
                                    cid: batch.course_id,
                                    bid: batch.batch_id,
                                    bstatus: "active",
                                  })
                                }
                              >
                                <PlayArrow className="mr-1" />
                                Activate Batch
                              </Dropdown.Item>
                              <Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateBatchStatus({
                                    cid: batch.course_id,
                                    bid: batch.batch_id,
                                    bstatus: "pause",
                                  })
                                }
                              >
                                {" "}
                                <PauseBtn className="mr-2" />
                                Pause Batch
                              </Dropdown.Item>
                              <Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateBatchStatus({
                                    cid: batch.course_id,
                                    bid: batch.batch_id,
                                    bstatus: "completed",
                                  })
                                }
                              >
                                <Check className="mr-1" />
                                Mark Completed
                              </Dropdown.Item>

                              <div className="pl-4 mb-2">
                                <Link
                                  className="text-dark"
                                  to={
                                    "/internal/add-assignment/" +
                                    courseurl +
                                    "/" +
                                    batch.batch_id
                                  }
                                >
                                  <p>
                                    {" "}
                                    <AddBox className="mr-1" />
                                    Add Assignment
                                  </p>
                                </Link>
                              </div>

                              <div className="pl-4 mb-2">
                                <Link
                                  className="text-dark"
                                  to={
                                    "/internal/trainings-management/courses/" +
                                    courseurl +
                                    "/" +
                                    batch.batch_id
                                  }
                                >
                                  <p>
                                    {" "}
                                    <EyeFill className="mr-2" />
                                    View Assignments
                                  </p>
                                </Link>
                              </div>

                              <div className="pl-4 mb-2">
                                <Link
                                  className="text-dark"
                                  to={
                                    "/internal/trainings-management/" +
                                    courseurl +
                                    "/" +
                                    batch.batch_id +
                                    "/schedules"
                                  }
                                >
                                  <Schedule className="mr-1" /> Class Schedules
                                </Link>
                              </div>

                              <div className="pl-4 mb-2">
                                <Link
                                  className="text-dark w-100 "
                                  to={
                                    "/internal/trainings-management/courses/assignment-submission/" +
                                    courseurl +
                                    "/" +
                                    batch.batch_id
                                  }
                                >
                                  <p>
                                    {" "}
                                    <FileUploadIcon className="mr-2" />
                                    Task submission
                                  </p>
                                </Link>
                              </div>

                              <Dropdown.Item
                                type="button"
                                onClick={() => DeleteBatch({ id: batch.id })}
                              >
                                {" "}
                                <Delete className="mr-1" />
                                Delete Batch
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <p>
                        <b class="text-secondary">Course starts :</b> 24th june 
                      </p>
                      <div class="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <Clock className="mr-1 mb-1" />
                          <p class="m-0">
                            <b class="text-secondary">Timings :</b>18:00 - 19:00
                          </p>
                        </div>
                        <div>
                          <p className="">
                            <b>mode:</b>
                            {batch.mode}
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-end">
                          <AvatarGroup className="justify-content-end mt-3 ">
                            <Avatar
                              className="avatar-mini"
                              alt="Remy Sharp"
                              src="https://tse1.mm.bing.net/th?id=OIP.wV7mU_l096IoevHV71vevgHaJh&pid=Api&P=0&h=180"
                            />
                            <Avatar
                              className="avatar-mini"
                              alt="Travis Howard"
                              src="https://tse1.mm.bing.net/th?id=OIP.wV7mU_l096IoevHV71vevgHaJh&pid=Api&P=0&h=180"
                            />
                            <Avatar
                              className="avatar-mini"
                              alt="Cindy Baker"
                              src="https://tse1.mm.bing.net/th?id=OIP.wV7mU_l096IoevHV71vevgHaJh&pid=Api&P=0&h=180"
                            />
                            <Avatar
                              className="avatar-mini"
                              alt="Agnes Walker"
                              src="https://tse1.mm.bing.net/th?id=OIP.wV7mU_l096IoevHV71vevgHaJh&pid=Api&P=0&h=180"
                            />
                            <Avatar
                              className="avatar-mini"
                              alt="Trevor Henderson"
                              src="https://tse1.mm.bing.net/th?id=OIP.wV7mU_l096IoevHV71vevgHaJh&pid=Api&P=0&h=180"
                            />
                          </AvatarGroup>
                          <p class="mb-0">+452</p>
                        </div>
                        <div class="d-flex align-items-end">
                          <Chair />
                          <p class="m-0 pl-1">
                            <b class="text-secondary">Seats left : </b>
                            {batch.seats_left}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    key={index}
                    className="container-fluid d-none border p-2 hover-active sm-p-15  mt-2 mb-2"
                  >
                    <div className="flex space-between">
                      <div className="col-11 text-primary p-0 flex">
                        <p className="mr-3">
                          <b>Batch ID : </b>
                          {batch.batch_id}
                        </p>

                        <p>
                          <b>Course Starts : </b>
                          {dateFormat(batch.registration_ends, "dS mmm yy ")}
                        </p>
                      </div>
                      <div>
                        <Dropdown className="options-dropdown">
                          <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                            <MoreVert className="text-dark" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                updateBatchStatus({
                                  cid: batch.course_id,
                                  bid: batch.batch_id,
                                  bstatus: "active",
                                })
                              }
                            >
                              Activate Batch
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                updateBatchStatus({
                                  cid: batch.course_id,
                                  bid: batch.batch_id,
                                  bstatus: "pause",
                                })
                              }
                            >
                              Pause Batch
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                updateBatchStatus({
                                  cid: batch.course_id,
                                  bid: batch.batch_id,
                                  bstatus: "completed",
                                })
                              }
                            >
                              Mark Completed
                            </Dropdown.Item>

                            <div className="pl-4 mb-2">
                              <Link
                                to={
                                  "/internal/add-assignment/" +
                                  courseurl +
                                  "/" +
                                  batch.batch_id
                                }
                              >
                                <p>Add Assignment</p>
                              </Link>
                            </div>

                            <div className="pl-4 mb-2">
                              <Link
                                to={
                                  "/internal/trainings-management/courses/" +
                                  courseurl +
                                  "/" +
                                  batch.batch_id +
                                  "/assignments"
                                }
                              >
                                <p>View Assignments</p>
                              </Link>
                            </div>

                            <div className="pl-4 mb-2">
                              <Link
                                to={
                                  "/internal/trainings-management/" +
                                  courseurl +
                                  "/" +
                                  batch.batch_id +
                                  "/schedules"
                                }
                              >
                                Class Schedules
                              </Link>
                            </div>

                            <Dropdown.Item
                              onClick={() => DeleteBatch({ id: batch.id })}
                            >
                              Delete Batch
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="flex space-between">
                      <div>
                        <p>
                          <b>Total Students : </b>
                          {batch.course_students}
                        </p>
                        <p>
                          <b>Seats Left : </b>
                          {batch.seats_left}
                        </p>
                      </div>

                      <div className="">
                        <p>
                          <b>Class Timings : </b>
                          {batch.class_starts} - {batch.class_ends}
                        </p>

                        <Chip
                          type="button"
                          size="small"
                          className="bg-warning"
                          label={batch.course_status}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>No Batches</>
          )}
        </div>
      </div>
    </>
  );
};

export default TrainerCourseBatches;

import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import DashboardLoadingTeplate from '../../themes/DashboardLoadingTemplate'
import { useParams } from 'react-router'
import ENDPOINT from '../../context/EndPoint'
import DeleteIcon from '@mui/icons-material/Delete';

import PostItem from '../../components/post/PostItem'
import AdminDeleteComment from '../../actions/post/AdminDeleteComment'
const InternalCommunityManagement = () => {
const params = useParams();

const {user} =  useContext(AuthContext)

    const [info,setinfo] = useState(null);

    


    useEffect(()=>{
       
    
        const  getinfo = async ()=>{

            fetch(`${ENDPOINT}admin-get-posts.php?session=${user.SessionId}`)
            .then(r=>r.json())
            .then(result=>{
           
                  if(result){
              
                    if(result.status==="success"){
                         setposts(result.posts)
                    }
                 
                   
                  }
            
                        
            
                
            })
            
            }    ;
    getinfo();
        GetComments()   
    }
    
    ,[])
    
    
    
    const  GetComments = async ()=>{

     fetch(`${ENDPOINT}admin-get-comments.php?session=${user.SessionId}`)
     .then(r=>r.json())
     .then(result=>{
    
           if(result){
       
             if(result.status==="success"){
                  setallcomments(result.comments)
             }
          
            
           }
     
                 
     
         
     })
     
     }  
    
    const [account,setaccount] = useState(null);
  const [role,setrole] = useState(null);


    
    
    const DeleteComment = async ({comment_id})=>{
     const res = await AdminDeleteComment({
          payload:{
               user:user.SessionId,
               id:comment_id
          }
     })
     if(res.status==="success"){
          GetComments()
     }
    }
    
    
    
    
    const [posts,setposts] = useState(null)
    
    

const [allcomments,setallcomments] = useState(null)


    return (

<>


{posts?
<>


<div className="flex justify-content-between">
<div className="col-lg-6">
<h4>Latest Posts</h4>

<div className="overflow-y vh-90 p-3">

{posts?
<>
{posts.map((post)=>(
     <>
<PostItem post={post}/>
     </>
))}
</>:null}
</div>

</div>

<div className="col-lg-6">
<h5>Latest Comments</h5>


<div className="overflow-y p-3 vh-90">
{allcomments?
<>
{allcomments.map((comment)=>(
     <>
     <div className="comment-item flex justify-content-between p-2">
     <p className="mb-2">{comment.comment}</p>

<DeleteIcon onClick={()=>{DeleteComment({comment_id:comment.id})}} className="click"/>

     </div>

     </>
))}
</>:null}
</div>



</div>


</div>




</>:
<>
<DashboardLoadingTeplate/>
</>}

</>
    )
}

export default InternalCommunityManagement








import React from 'react'
import { Link } from 'react-router-dom'
import InternalTemplate from '../../themes/InternalTemplate'
import { useEffect } from 'react'
import { useState } from 'react'

import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import DashboardLoadingTeplate from '../../themes/DashboardLoadingTemplate'
import { Redirect, useParams } from 'react-router'
import LoadingTemplate from '../../themes/LoadingTemplate'
import { Badge} from '@material-ui/core'
import CallbackIcon from '@mui/icons-material/PhoneCallback';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ENDPOINT from '../../context/EndPoint'

import VideoSettingsIcon from '@mui/icons-material/VideoSettings';

import InternalSalesSummary from './InternalSalesSummary'

import NoPermissionsTemplate from '../../themes/NoPermissionTemplate'
import InternalDashboard from './InternalDashboard'
import usersIcons from '../../assets/icons/man.png'
import team from '../../assets/icons/united.png'
import students from '../../assets/iconsv2/students.svg'
import interns from '../../assets/iconsv2/interns.svg'
import trainer from '../../assets/iconsv2/trainer.svg'
import company from '../../assets/iconsv2/company-icon.svg'
const InternalStudentManagement = ({permission}) => {
const params = useParams();
const batchid = params.batchid; 
const courseurl = params.courseurl; 
const {user} =  useContext(AuthContext)
 const userdata = user;
    const [info,setinfo] = useState(null);

    
const [page,setpage]=useState("tasks");


    useEffect(()=>{
       
    
        const  getinfo = async ()=>{

            fetch(`${ENDPOINT}admin-student-management.php?session=${user.SessionId}`)
            .then(r=>r.json())
            .then(result=>{
           
                  if(result){
                   // // console.log("stu "+JSON.stringify(result))
                    setinfo(result.data);
                   
                  }
            
                        
            
                
            })
            
            }    ;
    getinfo();
           
    }
    
    ,[])
    
    
    
    
    
    const [account,setaccount] = useState(null);
  const [role,setrole] = useState(null);


    
    
    
    
    
    
    
    
    
    




    return (

<>
{permission?
<>
<div className="w-100 sm-p-15  internal-dashboard">




{info?
<>





{parseInt(permission.super_admin)===1?
<>

<div className="container-fluid mb-2 pt-2">

<div className="d-flex justify-content-between">
<h5>Super Admin Controls</h5>
<Link></Link>
</div>
<div className="d-flex smgmt mt-3 mb-2 justify-content-between text-center flex-wrap">

<div className="col-lg-2 wa-cover dash-cover col-sm-6 col-6 border p-2">

</div>


<div className="col-lg-2 ai-cover dash-cover border  col-sm-6 col-6 p-2">
 
</div>


<Link to="/internal/daily-reports" className="col-lg-2 dash-cover   col-sm-6 col-6 activity-report border p-2">

</Link>

<Link to="/dashboard" className="col-lg-2 dash-cover   col-sm-6 col-6 growth-tracker border p-2">
 
</Link>

<Link to="/internal/activity-log" className="col-lg-2 dash-cover   col-sm-6 col-6 security-control border p-2">

</Link>


<Link to="/internal/expenses" className="col-lg-2 dash-cover   col-sm-6 col-6 security-control border p-2">

</Link>



<Link to="/internal/placement-requests/all" className="col-lg-2 dash-cover   col-sm-6 col-6 security-control border p-2">

</Link>




</div>
</div>

<hr/>
</>:
<>


</>}
<div className="container-fluid mt-2">
 
{parseInt(permission.quick_actions)===1?
<>
<div className="container-fluid white p-0">
<h5 className="text-dark">Frequently Used</h5>
</div>
</>:
<>


</>}
  <div className="d-flex smgmt white flex-wrap mt-3">

 



  {parseInt(permission.classes_management)===1?
<>
<Link to="/internal/classes" className="col-lg-2 col-sm-6 col-6 col-md-2 p-1">

<div className="border class-mgt dash-cover  br-5 p-2">

</div>

</Link>

</>:
<>

</>}



{parseInt(permission.team_management)===1?
<>
<Link to="/team-management" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

  <div className="border dash-cover team-cover br-5 p-2">

  </div>

</Link>

</>:
<>

</>}

{parseInt(permission.students_management)===1?
<>
<Link to="/internal/student-management/all" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

  <div className="border dash-cover students-cover br-5 p-2">
 
  </div>

</Link>

</>:
<>

</>}


{parseInt(permission.courses_management)===1?
<>
<Link to="/internal/trainings-management/courses/all"  className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

<div className="border dash-cover trainings-cover  br-5 p-2">

</div>

</Link>


</>:
<>

</>}

    
{parseInt(permission.doubt_support)===1?
<>


<Link to="/internal/doubts/all" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

<div className="border dash-cover doubts-cover  br-5 p-2">

</div>

</Link>

</>:
<>

</>}




{parseInt(permission.customer_support)===1?
<>
<Link to="/internal/support/all" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

<div className="border dash-cover customer-cover br-5 p-2">

</div>

</Link>

</>
:<>

</>}


</div>



<hr></hr>
<div className='d-flex smgmt  flex-wrap'>


<div className="container-fluid smgmt">
  <h4>Work Management</h4>
</div>
{parseInt(permission.add_team_task)===1?
<>
<Link to="/internal/tasks" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

<div className="border team-work-mgmt dash-cover  br-5 p-2">

</div>

</Link>
</>:
<>

</>}




{parseInt(permission.view_remainders)===1?
<>
<Link to="/reminders" className=" col-lg-2 col-md-2 col-sm-6 col-6 p-1">
<div  className="reminder-cover dash-cover border br-5 p-2">

</div>
</Link>
</>:
<>



</>}
    
 

{parseInt(permission.add_lead)===1?
<>

<Link to="/internal/leads/all/all" className="col-lg-2 col-md-2 col-sm-6 col-6  p-1">

<div className="border leads-cover dash-cover br-5 p-2">

</div>

</Link>

</>:
<>

</>}


    

    
{parseInt(permission.users_management)===1?
<>
<Link to="/internal/users-management" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

  <div className="border users-cover dash-cover br-5 p-2">
  
  </div>

</Link>

</>:
<>

</>}
    
{parseInt(permission.interns_management)===1?
<>


<Link to="/internal/internship-applications/all" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

  <div className="border interns-cover dash-cover br-5  p-2">
 
  </div>

</Link>

</>:
<>

</>}
    



{parseInt(permission.add_team_milestone)===1?
<>

<Link to="/whatsapp-notifications" className="col-lg-2 col-md-2 col-sm-6 col-6  p-1">

<div className="border wa-sms dash-cover  br-5 p-2">

</div>

</Link>

</>:
<>

</>}









{parseInt(permission.transactions)===1?
<>
<Link to="/internal/sales-summary/all" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

  <div className="border  dash-cover payments-cover br-5 p-2">

  </div>

</Link>

</>:
<>

</>}
    
{parseInt(permission.coupon_codes_management)===1?
<>
<Link to="/coupon-codes" className="col-lg-2 col-md-2 col-sm-6 p-1 col-6">
<div className="border coupons-cover dash-cover  br-5 p-2">

</div>
</Link>


</>:
<>

</>}




{parseInt(permission.view_remainders)===1?
<>

</>:
<>

</>}
    

{parseInt(permission.videos_management)===1?
<>
<Link to="/internal/video-management" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

  <div className="border  dash-cover videos-cover br-5 p-2">

  </div>

</Link>

</>:
<>

</>}
    






{parseInt(permission.quiz_management)===1?
<>

<Link to="/quiz-management" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

<div className="border quiz-cover dash-cover br-5 p-2">

</div>

</Link>



</>:
<>

</>}






{parseInt(permission.community_management)===1?
<>
<Link to="/community-management" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

<div className="border dash-cover community-cover br-5 p-2">

</div>

</Link>

</>:
<>

</>}




{parseInt(permission.trainers_management)===1?
<>
<div className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

  <div className="border dash-cover trainers-cover br-5  p-2">
 
  </div>

</div>
</>:
<>

</>}

















{parseInt(permission.student_tasks)===1?
<>
<Link to="/internal/students-tasks" className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

<div className="border student-assignments-cover dash-cover  br-5 p-2">

</div>

</Link>

</>:
<>

</>}
































{parseInt(permission.videos_management)===1?
<>

<Link className="col-lg-2 col-md-2 col-sm-6 p-1 col-6">

<div className="border dash-cover toppers-cover br-5 p-2">

</div>

</Link>

</>:
<>

</>}






{parseInt(permission.video_management)===1?
<>
<Link className="col-lg-2 col-md-2 col-sm-6 col-6 p-1">

<div className="border dash-cover event-cover br-5 p-2">

</div>

</Link>


</>:
<>

</>}




{parseInt(permission.video_management)===1?
<>
<Link className="col-lg-2 col-md-2 col-sm-4 col-4">

<div className="border  br-5 p-2">
<h6>Placements</h6>
</div>

</Link>


</>:
<>

</>}






{parseInt(permission.team_targets)===1?
<>
<Link className="col-lg-2 col-md-2 col-sm-4 col-4">

<div className="border br-5  p-2">
<h6>Team Targets</h6>
</div>

</Link>


</>:
<>

</>}




{parseInt(permission.video_management)===1?
<>
<Link className="col-lg-2 col-md-2 col-sm-4 col-4">

<div className="border br-5  p-2">
<h6>Reminders</h6>
</div>

</Link>


</>:
<>

</>}



{parseInt(permission.video_management)===1?
<>
<Link className="col-lg-2 col-md-2 col-sm-4 col-4">

<div className="border br-5  p-2">
<h6>Team Tasks</h6>
</div>

</Link>


</>:
<>

</>}



{parseInt(permission.video_management)===1?
<>
<Link className="col-lg-2 col-md-2 col-sm-4 col-4">

<div className="border br-5  p-2">
<h6>Attendance</h6>
</div>

</Link>



</>:
<>

</>}




{parseInt(permission.video_management)===1?
<>


<Link className="col-lg-2 col-md-2 col-sm-4 col-4">

<div className="border br-5  p-2">
<h6>Leaves</h6>
</div>

    </Link>
</>:
<>

</>}





{parseInt(permission.video_management)===1?
<>
<Link className="col-lg-2 col-md-2 col-sm-4 col-4">

<div className="border br-5  p-2">
<h6>Meetings</h6>
</div>

</Link>

</>:
<>

</>}

















  </div>
</div>

















</>:
<>

</>}






{info?
<>

 










</>:
<>

{permission?
<>


{parseInt(permission.intern)===1?
<Redirect to="/work"></Redirect>
:
<>


<NoPermissionsTemplate/>

</>
}
</>:
<>
<DashboardLoadingTeplate/>


</>}
</>}

</div>
</>
:<>


</>}

</>
    )
}

export default InternalStudentManagement








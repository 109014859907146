import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

import { Avatar } from "@material-ui/core";
import {  VideoCall } from "@material-ui/icons";
import { useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import InternshipItemSkeleton from "../../../skeleton/InternshipItemSkeleton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MailIcon from "@mui/icons-material/Mail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Dropdown } from "react-bootstrap";
import {
  AttachMoney,
  CheckCircleOutline,
  Edit,
  EmailOutlined,
  MoreVert,
} from "@material-ui/icons";

import ENDPOINT from "../../../context/EndPoint";
import dateFormat from "dateformat";
import { Toast } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddLead from "./AddLead";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import {
  Dangerous,
  GroupAddOutlined,
  PeopleAlt,
  Phone,
  WhatsApp,
} from "@mui/icons-material";
import { CheckCircle } from "react-bootstrap-icons";
import { TextField } from "@material-ui/core";
import { Container } from "react-bootstrap";
import SecurityHeaders from "../../../context/SecurityHeaders";
import LeadsStatistics from "./LeadsStatistics";
import ResolutionStatus from "../../../components/status/ResolutionStatus";
import EditLeadModal from "../../../components/modals/EditLeadModal";
import LeadItem from "./LeadItem";

const Leads = ({ page, role }) => {
  const params = useParams();

  const [category, setcategory] = useState("all");

  const { user } = useContext(AuthContext);

  const [edit, setedit] = useState(false);

  const tasktype = useRef();

  const changeTaskStatus = (e) => {
    settaskstatus(tasktype.current.value);
    getTasks(tasktype.current.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTasks();
  }, [category]);

  const [tasks, settasks] = useState("start");

  const [taskstatus, settaskstatus] = useState("all");

  const [taskid, settaskid] = useState(null);
  const [remove, setremove] = useState(false);
  const [removeid, setremoveid] = useState(null);
  const [submit, setsubmit] = useState(false);

  const showSubmit = (e) => {
    const id = e.target.id;
    settaskid(id.replace("submit", ""));
    setsubmit(true);
  };

  const closeSubmit = () => {
    setsubmit(false);
  };
  const editTask = (e) => {
    const id = e.target.id;
    settaskid(id.replace("edit", ""));

    setedit(true);
  };

  const closeEdit = () => {
    setedit(false);
  };
  const [add, setadd] = useState(false);

  const closeAddition = () => {
    setadd(false);
    getTasks(taskstatus);
  };

  const showRemove = (e) => {
    const id = e.target.id;

    settaskid(id.replace("delete", ""));
    setremove(true);
  };
  const closeRemove = () => {
    setremove(false);
  };

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const getTasks = async () => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    // console.log("getting")
    formData.append("session", user.SessionId);

    formData.append("status", category);
    if (query) {
      formData.append("query", query);
    }
    //// console.log("getting leads")
    const res = await axios.post(ENDPOINT + "admin-get-leads.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      if (res.data.status === "success") {
        if (res.data.data) {
          setInitialData(res.data.data);
          setResponse(res.data.data);
        }
      } else {
      }
    }
  };

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);

  const [toastmessage, settoastmessage] = useState(null);

  const [query, setquery] = useState(null);

  const updateRequest = async ({ ticketstatus, ticketid }) => {
    if (user !== null) {

      const data = new FormData();
      data.append('session',user.SessionId);
      data.append('status',ticketstatus);
      data.append('id',ticketid);
      const res = await axios.post(`${ENDPOINT}admin-update-lead.php`,data,SecurityHeaders);
      if(res){
        if (res.data.status === "success") {
          settoasttitle(res.data.title);
          settoastmessage(res.data.message +""+ ticketstatus);
          settoast(true);
          getTasks();
        } else {
          settoasttitle(res.data.title);
          settoastmessage(res.data.message);
          settoast(true);
        }
      }

    } else {
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const loadLeads = () => {
    getTasks();
    setaddlead(false);
  };

  const [addlead, setaddlead] = useState(false);

  const [lead, setlead] = useState(null);

  const EditLead = async ({ index, ticketstatus, ticketid }) => {
    setlead(tasks[index]);
    if (user !== null) {
      const data = new FormData();
      data.append("name", leadname);
      data.append("email", lemail);
      data.append("phone", lphone);
      data.append("whatsapp", lwhatsapp);
      data.append("status", lstatus);
      data.append("category", lcategory);
      data.append("note", lnote);
      data.append("id", currentid);
      data.append("session", user.SessionId);

      const res = await axios.post(ENDPOINT + "admin-edit-lead.php", data, {
        headers: { "content-type": "multipart/form-data" },
      });
      if (res) {
        // console.log("lead "+JSON.stringify(res))
        if (res.data.status === "success") {
          settoasttitle("Lead updated successfully");
          setedit(false);
          settoast(true);
          getTasks();
        } else {
          settoasttitle("Something went wrong !");
          settoastmessage("Please try again");
          settoast(true);
        }
      }
    }
  };

  const StartEdit = ({ item }) => {
    setlead(item);
    setitem(item)
   
    setleadname(item.name);
    setlemail(item.email);
    setlphone(item.phone);
    setlwhatsapp(item.whatsapp);
    setlstatus(item.status);
    setlcategory(item.category);
    setlnote(item.note);
    setedit(true);
  };
  const [currentid, setcurrentid] = useState(null);
  const [leadname, setleadname] = useState(null);
  const [lemail, setlemail] = useState(null);
  const [lphone, setlphone] = useState(null);
  const [lwhatsapp, setlwhatsapp] = useState(null);
  const [lnote, setlnote] = useState(null);
  const [lstatus, setlstatus] = useState(null);
  const [lcategory, setlcategory] = useState(null);

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.name.toLowerCase().includes(term) ||
        item.phone.toLowerCase().includes(term) ||
        item.email.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term) ||
        item.note.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  const CloseEdit = ()=>{
  
  setedit(false)
}
  const [item,setitem] = useState(null)
  return (
    <>
  
      <Modal
        open={addlead}
        onClose={() => loadLeads()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <div className="flex space-between">
              <h4>Add New Lead</h4>
              <CloseIcon type="button" onClick={() => loadLeads()} />
            </div>
            <AddLead />
          </>
        </Box>
      </Modal>


      <div className="position-fixed container-fluid left-0 bottom-60 justify-center ">
        <Toast
          className="bg-warning"
          show={toast}
          onClose={() => {
            settoast(false);
          }}
          autohide={true}
          delay={5000}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>

      <div className="container-fluid ">
        <div className="flex  flex-wrap align-items-center space-between mb-4">
   
          <div className="container-fluid  align-items-center sm-p-15 justify-content-sm-between col-lg-12 col-12 col-sm-12 d-flex flex-wrap  ">
     

     
            
            <div className=" col-10">
              <div>
                <input
                  placeholder="Search Status, Name, Phone, Email"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e)}
                  className="fixed-search"
                  margin="normal"
                
                />
              </div>
            </div>
            
          </div>
        </div>

       
<div className="w-100 d-flex">

  <div className="col-10">
  <LeadsStatistics/>
  </div>
  <button
              onClick={() => setaddlead(true)}
              className="btn h-fit  btn-primary"
            >
              Add New Lead
            </button>
</div>



<>
<div className="internships-list  sm-p-0  container-fluid">
          {response === "start" ? (
            <div className="container-fluid">
              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />
            </div>
          ) : response ? (
            <Table>
                           <TableHead>
  <TableRow>
    <TableCell >User</TableCell>
    
   
    <TableCell align="left">Category</TableCell>
    <TableCell align="left">Date</TableCell>
    <TableCell align='center'>Status</TableCell>
    <TableCell align="right">Actions</TableCell>
  </TableRow>
</TableHead>
<TableBody>
  {
       response.map((internship,index) => (
   <LeadItem item={internship}/>
      ))
  }

</TableBody>
            </Table>

         
          ) : (
            <div className="container-fluid">No Internships</div>
          )}
        </div>

</>


       
      </div>
    </>
  );
};

export default Leads;

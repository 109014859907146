import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../../context/EndPoint'
import axios from 'axios';
import { Toast } from 'react-bootstrap';
const UpdateStudentStatistics = ({studentid}) => {

const {user} =  useContext(AuthContext)


const [activeness,setactiveness] = useState(null)

const arr = [];
const [corrections,setcorrections] = useState(arr)
const [assignedby,setassignedby]=  useState(null)
const [taskstatus,settaskstatus]= useState(null);
const [deadline2,setdeadline2] = useState(null)
const [account,setaccount]=  useState(null)


const [braveness,setbraveness]=  useState(null)
const [confidence,setconfidence] = useState(null)
const [courage,setcourage] = useState(null)
const [communication,setcommunication] = useState(null)
const [determination,setdetermination] = useState(null)
const [focus,setfocus] = useState(null)
const [vision,setvision] = useState(null)
const [responsibility,setresponsibility] = useState(null)
const [leadership,setleadership] = useState(null)
const [cactiveness,setcactiveness] = useState(null)
const [cbraveness,setcbraveness] = useState(null);
const [ccourage,setccourage] = useState(null)
const [cconfidence,setcconfidence] = useState(null)
const [ccommunication,setccommunication] = useState(null)
const [cdetermination,setcdetermination] = useState(null)
const [cfocus,setcfocus] = useState(null)
const [cvision,setcvision] = useState(null)
const [cresponsibility,setcresponsibility] = useState(null)
const [cleadership,setcleadership] = useState(null)
const [tactiveness,settactiveness] = useState(null);
const [tbraveness,settbraveness] = useState(null)
const [tconfidence,settconfidence] = useState(null)
const [tcourage,settcourage] = useState(null);
const [tcommunication,settcommunication] = useState(null)
const [tdetermination,settdetermination] = useState(null)
const [tfocus,settfocus] = useState(null)
const [tvision,settvision] = useState(null)
const [tresponsibility,settresponsibility] = useState(null)
const [tleadership,settleadership] = useState(null)



const  getaccount = async ()=>{
  
    
  fetch(`${ENDPOINT}admin-get-user-statistics.php?session=${user.SessionId}&studentid=${studentid}`)
    .then(r=>r.json())
    .then(result=>{
   
   if(result){
//// console.log("statistics"+JSON.stringify(result.data))
  if((result.status==="success")&&(result.data!=null)){
      const data = result.data;
   setactiveness(data.activeness)
   setbraveness(data.braveness)
setconfidence(data.confidence)
setcourage(data.courage)
setdetermination(data.determination)
setfocus(data.focus)
setvision(data.vision)
setleadership(data.leadership)
setcommunication(data.communication)
setresponsibility(data.responsibility)
setcactiveness(data.current_activeness)
setcbraveness(data.current_braveness)
setcconfidence(data.current_confidence)
setccourage(data.current_courage)
setcdetermination(data.current_determination)
setcfocus(data.current_focus)
setcvision(data.current_vision)
setcleadership(data.current_leadership)
setccommunication(data.current_communication)
setcresponsibility(data.current_responsibility)
settactiveness(data.target_activeness)
settbraveness(data.target_braveness)
settconfidence(data.target_confidence)
settcourage(data.target_courage)
settdetermination(data.target_determination)
settfocus(data.target_focus)
settvision(data.target_vision)
settleadership(data.target_leadership)
settcommunication(data.target_communication)
settresponsibility(data.target_responsibility)


  }else {
    setactiveness(0)
   setbraveness(0)
setconfidence(0)
setcourage(0)
setdetermination(0)
setfocus(0)
setvision(0)
setcommunication(0)
setleadership(0)
setresponsibility(0)
setcactiveness(0)
setcbraveness(0)
setcconfidence(0)
setccourage(0)
setcdetermination(0)
setcfocus(0)
setcvision(0)
setcleadership(0)
setccommunication(0)
setcresponsibility(0)
settactiveness(0)
settbraveness(0)
settconfidence(0)
settcourage(0)
settdetermination(0)
settfocus(0)
settvision(0)
settleadership(0)
settcommunication(0)
settresponsibility(0)

  }

   }
    else{
     
    }
    
                
    
        
    })
  


  }    ;





useEffect(()=>{
    
  
  
  getaccount()

     
}

,[])













    const   UPLOAD_ENDPOINT = `${ENDPOINT}edit-profile.php`;



    const changeActiveness = (e)=>{
setactiveness(e.target.value);
    }
      const [status,setstatus]= useState(false)

      const [snackbar,setsnackbar] = useState(false);
      const [snackbarMessage,setsnackbarMessage] = useState(null)
      
      


      const onSubmit = async (e)=>{
e.preventDefault();
        const formData = new FormData();
        formData.append('session',user.SessionId);
        formData.append('studentid',studentid);
        formData.append('activeness',activeness);
        formData.append('braveness',braveness);
        formData.append('confidence',confidence);
        formData.append('communication',communication);
        formData.append('determination',determination);
        formData.append('focus',focus);
        formData.append('responsibility',responsibility);
        formData.append('leadership',leadership);
        formData.append('vision',vision);
        formData.append('cactiveness',cactiveness);
        formData.append('cbraveness',cbraveness);
        formData.append('cconfidence',cconfidence);
        formData.append('ccommunication',ccommunication);
        formData.append('cdetermination',cdetermination);
        formData.append('cfocus',cfocus);
        formData.append('cresponsibility',cresponsibility);
        formData.append('cleadership',cleadership);
        formData.append('cvision',cvision);
        formData.append('tactiveness',tactiveness);
        formData.append('tbraveness',tbraveness);
        formData.append('tconfidence',tconfidence);
        formData.append('tcommunication',tcommunication);
        formData.append('tdetermination',tdetermination);
        formData.append('tfocus',tfocus);
        formData.append('tresponsibility',tresponsibility);
        formData.append('tleadership',tleadership);
        formData.append('tvision',tvision);
        
        const res = await axios.post(ENDPOINT+"admin-update-statistics.php",formData,{
          headers:{
            'content-type': 'multipart/form-data'
          }
        });
        //// console.log("STAT UPDATE"+JSON.stringify(res))
         
        if(res.data.status==="success"){
         
         
          settoasttitle(res.data.title)
          settoastmessage(res.data.message);
         setsnackbar(true);
        
         
          
        }
        else{
         
          settoasttitle(res.data.title)
          settoastmessage(res.data.message);
          setsnackbar(true);
        }
        }
        
         

        const [toast,settoast] = useState(false)
        const [toasttitle,settoasttitle] = useState(null)
        const [toastmessage,settoastmessage] = useState(null)




      



    return (

      <form onSubmit={onSubmit}>    
        <div className="xxl">
            
            {/*account form*/}


            <div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>
            {/* <Snackbar
 className="bg-success text-light"
        open={snackbar}
        autoHideDuration={6000}
        onClose={()=>setsnackbar(false)}
        message={snackbarMessage}
    
    
      /> */}

{/*initial stats starts*/}


<>

<div className="flex-wrap  col-xxl">
            
            <div className="">
            
            
            
           
            <div class="form-row">
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Activeness</label>
                  <input type="number" class="form-control" id="inputEmail4" value={activeness} onChange={(e)=>{setactiveness(e.target.value)}} maxLength={5}/>
                </div>

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Braveness</label>
                  <input type="number" class="form-control" id="inputEmail4" value={braveness} onChange={(e)=>{setbraveness(e.target.value)}} maxLength={500}/>
                </div>

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Confidence</label>
                  <input type="text" class="form-control" id="inputEmail4" value={confidence} onChange={(e)=>{setconfidence(e.target.value)}} maxLength={300}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Communication</label>
                  <input type="number" class="form-control" id="inputEmail4" value={communication} onChange={(e)=>{setcommunication(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Determination</label>
                  <input type="text" class="form-control" id="inputEmail4" value={determination} onChange={(e)=>{setdetermination(e.target.value)}} maxLength={30}/>
                </div>
                

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Focus</label>
                  <input type="text" class="form-control" id="inputEmail4" value={focus} onChange={(e)=>{setfocus(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Responsibility</label>
                  <input type="text" class="form-control" id="inputEmail4" value={responsibility} onChange={(e)=>{setresponsibility(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Leadership</label>
                  <input type="text" class="form-control" id="inputEmail4" value={leadership} onChange={(e)=>{setleadership(e.target.value)}} maxLength={30}/>
                </div>
                



   




  
             
</div>

         

            
            
            
           
            
             
            
            
            
              
             
            
            </div>
  
            
            
            
                        </div>

        </>   
{/*initial stats ends*/}









{/*target stats starts*/}


<>
        
<div className="flex-wrap  col-xxl">
            
            <div className="">
            
            
           
            <div class="form-row">
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Activeness</label>
                  <input type="number" class="form-control" id="inputEmail4" value={cactiveness} onChange={(e)=>{setcactiveness(e.target.value)}} maxLength={5}/>
                </div>

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Braveness</label>
                  <input type="number" class="form-control" id="inputEmail4" value={cbraveness} onChange={(e)=>{setcbraveness(e.target.value)}} maxLength={500}/>
                </div>

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Confidence</label>
                  <input type="text" class="form-control" id="inputEmail4" value={cconfidence} onChange={(e)=>{setcconfidence(e.target.value)}} maxLength={300}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Communication</label>
                  <input type="number" class="form-control" id="inputEmail4" value={ccommunication} onChange={(e)=>{setccommunication(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Determination</label>
                  <input type="text" class="form-control" id="inputEmail4" value={cdetermination} onChange={(e)=>{setcdetermination(e.target.value)}} maxLength={30}/>
                </div>
                

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Focus</label>
                  <input type="text" class="form-control" id="inputEmail4" value={cfocus} onChange={(e)=>{setcfocus(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Responsibility</label>
                  <input type="text" class="form-control" id="inputEmail4" value={cresponsibility} onChange={(e)=>{setcresponsibility(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Leadership</label>
                  <input type="text" class="form-control" id="inputEmail4" value={cleadership} onChange={(e)=>{setcleadership(e.target.value)}} maxLength={30}/>
                </div>
                



   




  
             
</div>

         

            
            
            
           
            
             
            
            
            
         
            </div>
  
            
            
            
                        </div>

        </>  


{/*target stats ends*/}



{/*current stats starts*/}

<>
<div className="flex-wrap  col-xxl">
            
            <div className="">
            
            
            
            <div class="form-row">
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Activeness</label>
                  <input type="number" class="form-control" id="inputEmail4" value={tactiveness} onChange={(e)=>{settactiveness(e.target.value)}} maxLength={5}/>
                </div>

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Braveness</label>
                  <input type="number" class="form-control" id="inputEmail4" value={tbraveness} onChange={(e)=>{settbraveness(e.target.value)}} maxLength={500}/>
                </div>

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Confidence</label>
                  <input type="text" class="form-control" id="inputEmail4" value={tconfidence} onChange={(e)=>{settconfidence(e.target.value)}} maxLength={300}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Communication</label>
                  <input type="number" class="form-control" id="inputEmail4" value={tcommunication} onChange={(e)=>{settcommunication(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Determination</label>
                  <input type="text" class="form-control" id="inputEmail4" value={tdetermination} onChange={(e)=>{settdetermination(e.target.value)}} maxLength={30}/>
                </div>
                

                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Focus</label>
                  <input type="text" class="form-control" id="inputEmail4" value={tfocus} onChange={(e)=>{settfocus(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Responsibility</label>
                  <input type="text" class="form-control" id="inputEmail4" value={tresponsibility} onChange={(e)=>{settresponsibility(e.target.value)}} maxLength={30}/>
                </div>
                <div class="form-group col-lg-3">
                  <label for="inputEmail4">Leadership</label>
                  <input type="text" class="form-control" id="inputEmail4" value={tleadership} onChange={(e)=>{settleadership(e.target.value)}} maxLength={30}/>
                </div>
                



   




  
             
</div>

         

            
            
            
           
            
             
            
            
            
<button type="submit" class="mt-5 mb-5 btn btn-primary w-100" onClick={onSubmit}  >{status?<CircularProgress className="text-white" size={20}/> :""}Update Statistics</button>
          
              
            
            </div>
  
            
            
            
                        </div>
                       
        </>

{/*current stats ends*/}
                           {/*account form*/}
        </div>
        </form>
    )
}

export default UpdateStudentStatistics

import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

import { useEffect } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { useContext } from 'react'
import { Avatar, Chip } from '@material-ui/core'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import UploadClassRecording from './UploadClassRecording'
import IconButton from '@mui/material/IconButton';
import ENDPOINT from '../../../context/EndPoint'
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios'
import { CheckCircle, MoreVert} from '@material-ui/icons'
import { Dropdown, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import dateFormat from 'dateformat'




import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { useParams } from 'react-router-dom'
import SecurityHeaders from '../../../context/SecurityHeaders';
import RescheduleClass from '../../../actions/course/RescheduleClass';
const CurrentDayClasses = () => {

const {user} = useContext(AuthContext)

const params = useParams();
const course_url = params.courseurl
const batch_id = params.batchid
const [status,setstatus] = useState("incomplete")

    const [payments,setpayments] = useState(null);
    useEffect(()=>{
   //  // console.log("getting payments")
      getpayments();
             
      },[status])
      


      const [category,setcategory] = useState("incomplete")

      const [query,setquery] = useState(null)
      
const [order,setorder] = useState("ASC")


      const getpayments = async ()=>{
        

        const formData = new FormData();
        
        
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',user.SessionId);
        
          formData.append('status',status);
          formData.append('order',order);
          if(query){
            formData.append('query',query);
          }
             
        
        const res = await axios.post(ENDPOINT+"admin-get-classes.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
         // console.log("class "+JSON.stringify(res))
         if(res.data.status==="success"){
          setpayments(res.data.data);
          
        
         }
         else{
        
         }
        }
        }






  
    
    
    
    
    const [toast,settoast] = useState(false)
    const [toasttitle,settoasttitle] = useState(null)
    const [toastmessage,settoastmessage] = useState(null)
    

    
    

    
    const [videoupload,setvideoupload] = useState(false)

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    
const [currentclass,setcurrentclass] = useState(null)
const StartUpload = ({class_id,class_starts}) =>{
  setclassstarts(class_starts)
setcurrentclass(class_id)
setvideoupload(true)

}

const [classstarts,setclassstarts] = useState(null)
const StartClass = async ({payload})=>{
  const data = new FormData()
  data.append("session",user.SessionId)
  data.append("batch_id",payload.batch_id)
  data.append("course_url",payload.course_url)
  const res = await axios.post(ENDPOINT+"start-class.php",data,SecurityHeaders)
  if(res){
    console.log(" res "+JSON.stringify(res))
  }
}


const DeleteClass = async ({payload})=>{
  const data = new FormData()
  data.append("session",user.SessionId)
  data.append("class_id",payload.id)
 
  const res = await axios.post(ENDPOINT+"start-class.php",data,SecurityHeaders)
  if(res){
    getpayments()
  }
}
const [currentdate,setcurrentdate] = useState(null)
useEffect(()=>{
  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var newdate = dd+mm;
setcurrentdate(dd+mm)
console.log("Cu"+(dd+mm))
},[])
const [classid,setclassid] = useState(null)
const [reschedule,setreschedule] = useState(false)

const [startdate,setstartdate] = useState(null)
const [enddate,setenddate] = useState(null)

const Reschedule = async ()=>{

  const res= await RescheduleClass({payload:{
    user:user.SessionId,
    class_starts:startdate,
    class_ends:enddate,
    class_id:classid
  }})

  if(res){
    if(res.status==="success"){
      setreschedule(false)
    }
  }
}
//
    return (
<>

<div className="sm-p-15 container-fluid">



<div className="fixed-bottom justify-center container-fluid w-100 bottom-60 left-0">
<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header> 
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>


</div>






<Modal
  open={videoupload}
  onClose={()=>setvideoupload(false)}

>
  <Box className="col-lg-4 col-md-5 col-sm-6 col-12" sx={style}>
    <div className="container-fluid mb-3">
    <div className="flex space-between">
  <h5>Upload Recording</h5>
  <CloseIcon type="button" className="text-danger" onClick={()=>setvideoupload(false)}/>
</div>
</div>
  
    <hr/>
   <UploadClassRecording course_url={course_url} batch_id={batch_id} classid={currentclass} class_starts={classstarts}/>
  </Box>
</Modal>




<Modal
  open={reschedule}
  onClose={()=>setreschedule(false)}

>
  <Box className="col-lg-4 col-md-5 col-sm-6 col-12" sx={style}>
    <div className="container-fluid mb-3">
    <div className="flex space-between">
  <h5>Reschedule class</h5>
  <CloseIcon type="button" className="text-danger" onClick={()=>setreschedule(false)}/>
</div>
<p>Selected Class ID : {classid}</p>
</div>
  
    <hr/>
  
  

 <form onSubmit={(e)=>{e.preventDefault();Reschedule()}} >

 <div className="container">
    <h6>Class starts</h6>
    <div className="d-flex">
  <input required className="form-control" onChange={(e)=>setstartdate(e.target.value)} type="datetime-local"></input>
  

  </div>
  </div>

  <div className="container mt-4">
    <h6>Class ends</h6>
    <div className="d-flex">
  <input required className="form-control"  onChange={(e)=>setenddate(e.target.value)} type="datetime-local"></input>
  
 
  </div>
  </div>
 
<button className="btn btn-primary mt-3 w-100" type="submit">Save Changes</button>
  
 </form>
    </Box>
</Modal>












<div className="container-fluid ">


<div className=" mb-2">

  <h4>Today's Classes</h4>
<div className="d-flex flex-wrap p-0 mt-3">
  {payments?
<>
{payments.map((item)=>(
<>

{parseInt(dateFormat(item.class_starts,"ddmm"))===(parseInt(currentdate))?<>
<div className="col-lg-4 col-md-4 col-sm-6 col-12 p-0">

<div className="border br-5 p-0">
<div className="p-3">
<h6 className="truncate truncate-1">{item.class_title}</h6>
<div className="d-flex  text-secondary justify-content-between">
<p className="truncate truncate-1 text-secondary">{item.course_url}</p>
<p >Batch :
  {item.batch_id}</p>
</div>

</div>

<div className="d-flex justify-content-between">
  <button className="btn" onClick={()=>{setclassid(item.id); setreschedule(true)}}>Reschedule</button>
 {item.class_status==="incomplete"?
 <>
  <button onClick={()=>StartClass({payload:{batch_id:item.batch_id,course_url:item.course_url}})} className="btn btn-class-start">Start Class</button>

 </>:
 <>
 
 </>}
 
 </div>
 
</div>

</div>
</>:
<>

</>}


</>

))}
</>:
<>

</>}
  </div>


</div>

</div>


</div>
</>
    )
}

export default CurrentDayClasses


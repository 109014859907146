import React, { useContext, useState } from 'react'
import CustomModal from './CustomModal'
import RescheduleClass from '../../actions/course/RescheduleClass';
import { AuthContext } from '../../context/AuthContext';

const ClassRescheduleModal = ({visibility,handleClose,item}) => {

    const [startdate, setstartdate] = useState(null);
    const [enddate, setenddate] = useState(null);
    const [reschedule, setreschedule] = useState(false);

    const {user} = useContext(AuthContext)
    const Reschedule = async () => {
      const res = await RescheduleClass({
        payload: {
          user: user.SessionId,
          class_starts: startdate,
          class_ends: enddate,
          class_id: item.id,
        },
      });
  
      if (res) {
        if (res.status === "success") {
          setreschedule(false);
        }
      }
    };
  
  return (
    <>
    
    <CustomModal visibility={visibility} handleClose={handleClose}>

    <form
              onSubmit={(e) => {
                e.preventDefault();
                Reschedule();
              }}
            >
              <div className="container">
                <h6>Class starts</h6>
                <div className="d-flex">
                  <input
                    required
                    className="form-control"
                    onChange={(e) => setstartdate(e.target.value)}
                    type="datetime-local"
                  ></input>
                </div>
              </div>

              <div className="container mt-4">
                <h6>Class ends</h6>
                <div className="d-flex">
                  <input
                    required
                    className="form-control"
                    onChange={(e) => setenddate(e.target.value)}
                    type="datetime-local"
                  ></input>
                </div>
              </div>

              <button className="btn btn-primary mt-3 w-100" type="submit">
                Save Changes
              </button>
            </form>

    </CustomModal>
    </>
  )
}

export default ClassRescheduleModal
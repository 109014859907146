import React from 'react'
import {  Navbar} from 'react-bootstrap'

import { Link } from 'react-router-dom'

import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'

import { AuthContext } from '../../context/AuthContext'

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { FaMicrophone } from 'react-icons/fa'

import { useHistory } from 'react-router-dom';
const SpeechActions = ({props,account,unread,initialData,ChangeData}) => {


 const {user} = useContext(AuthContext);
 const history = useHistory();
      
      
 const [searchTerm, setSearchTerm] = useState("");

// State for search input and filtered courses
const [searchInput, setSearchInput] = useState('');
const [filteredCourses, setFilteredCourses] = useState(null);


   
const [menustatus,setmenustatus] = useState(false);

const togglemenu=()=>{
  var sidebar = document.getElementById("sidebar");
  if(sidebar.classList.contains("sidebardisplay")){
setmenustatus(false)
    sidebar.classList.remove("sidebardisplay");
  }
  else{
    setmenustatus(true)
    sidebar.classList.add("sidebardisplay");
  
  }

  



}









const [script,setscript] = useState(null)

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(()=>{
if(transcript!==null){

  setscript(transcript)


}


  },[transcript])

  useEffect(()=>{
    setTimeout(()=>{
        resetTranscript()
        setscript(transcript)
    },1000)
  
},[window.location,history])


  useEffect(()=>{

    if(!listening&&transcript!==null){

     handleSearchInputChange()
    
    
    
    }
  },[listening])


  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
 





 
  const handleSearchInputChange = () => {
    const searchTerm = transcript.toLowerCase();
  var term = searchTerm
    if(searchTerm.includes("display")){
         term = searchTerm.replace("display","")
    setSearchTerm(term)
    }
    if(searchTerm.includes("open")){
    term = searchTerm.replace("open",'')
    setSearchTerm(term)}
    // Filter courses based on course title or course ID
    const filteredData = initialData.filter(
      (course) =>
        course.command.toLowerCase().includes(searchTerm)
    );
  
    // Update filtered courses
    setFilteredCourses(filteredData);
    if(filteredCourses!=null&&filteredCourses.length>0&&!listening){
        setTimeout(()=>{
    
          if(filteredData[0]){
            let path = filteredData[0].path
            setFilteredCourses(null)
          //  window.location.replace("/"+filteredData[0].path)
          ChangeData(filteredData[0].path)
          history.push("/"+filteredData[0].path);
 
        }else{
        resetTranscript();
    setscript(transcript)
    }
        
        }, 1000);
       

    }else{resetTranscript()
    
    
    setscript(transcript)
    }
    setSearchInput(searchTerm);
  };


    return (
        




<>


<div className="speech-actions d-flex align-items-center">

{searchInput!==""&&filteredCourses?
    <>

    <div onClick={()=>setSearchInput("")} className="bg-white search-suggestions p-3 border-bottom border-right border border-top-0 position-absolute left-0 ">
   
        {filteredCourses.map((course) => (
          <div key={course.id}>
            <Link className="nav-link" to={"/"+course.path}>{course.name}</Link>
            <hr></hr>
          </div>
        ))}
     
    </div>
   
    </>:null}
    
<>



     <div className="d-flex align-items-center">
     <FaMicrophone onDoubleClick={resetTranscript} onClick={()=>{listening?SpeechRecognition.stopListening():SpeechRecognition.startListening({ language: 'en-IN' })}} className={listening?"text-success click":"text-danger click"}/>
     
 
    
        </div>   

</>
   
       

</div>


</>

       

   

    

      
    )
}

export default SpeechActions

import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import InternalTemplate from '../../themes/InternalTemplate'
import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'
import ENDPOINT from '../../context/EndPoint'
import { Link, useParams } from 'react-router-dom'
import StudentInternshipApplications from './internship-applications/StudentInternshipApplications'
const InternalInternshipApplications = ({ page,course,props}) => {

const {user} = useContext(AuthContext)
const userdata = user;


const params = useParams();
const category = params.category;

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

 



















  
    
    

    return (
       <>
  
<>

<>





<StudentInternshipApplications/>



        </>
</>

       </>
    )
}

export default InternalInternshipApplications


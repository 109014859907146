import React from 'react'
import { useState } from 'react'
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../../context/EndPoint'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SecurityHeaders from '../../../context/SecurityHeaders';
import { MoreVert } from '@material-ui/icons';

import { Dropdown } from 'react-bootstrap'
import { data } from 'jquery';
const CouponCodes = ({id}) => {

const params = useParams()

const {user} =  useContext(AuthContext)





















      const [status,setstatus]= useState("pending")
const [addstatus,setaddstatus] = useState(false)
      const [snackbar,setsnackbar] = useState(false);
      const [snackbarMessage,setsnackbarMessage] = useState(null)
      
     
         


  const [code,setcode] = useState(null)
  const [percentage,setpercentage] = useState(null)
const [coupons,setcoupons] = useState(null)

      

const GetCoupons = async()=>{
const data = new FormData()
data.append("session",user.SessionId)
    const res= await axios.post(ENDPOINT+"admin-get-coupons.php",data,SecurityHeaders)
if(res){

    if(res.data){

        if(res.data.status==="success"){

            setcoupons(res.data.data)
        }
    }
}


}
const [expiry,setexpiry] = useState(null)

const AddCoupon = async()=>{
    const data = new FormData()
    data.append("session",user.SessionId)
    data.append("code",code)
    data.append("percentage",percentage)
    data.append("expiry",expiry)
        const res= await axios.post(ENDPOINT+"add-coupon-code.php",data,SecurityHeaders)
    if(res){
    
        if(res.data){
    
            if(res.data.status==="success"){
    
                GetCoupons()
            }
        }
    }
    
    
    }


    const DeleteCoupon = async({id})=>{
const data = new FormData()
data.append("session",user.SessionId)
data.append("id",id)
        const res = await axios.post(ENDPOINT+"delete-coupon.php",data,SecurityHeaders)

        if(res){

            if(res.data){
                if(res.data.status==="success"){
GetCoupons()
                }
            }
        }
    }

useState(()=>{
GetCoupons()

},[])
    return (
        <div>
            
            {/*account form*/}
          
            
        <>
<div className="flex-wrap container">
            
          
            
          <div className="col-lg-6">
            
            {coupons?
            <>
            {coupons.map((coupon)=>(
                <>
                <div className="border d-flex justify-content-between p-3 br-4">
              
                <div className="col">
                <h4 >{coupon.coupon_code}</h4>
<p>{coupon.expired?"Expired":coupon.validity}</p>

                </div>
<h3 className="col">%{coupon.coupon_price}</h3>



<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options d-flex align-items-center justify-content-between p-0 w-fit h-fit w-100 border-0" >


  
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>

  <Dropdown.Menu>

  <Dropdown.Item onClick={()=>DeleteCoupon({id:coupon.coupon_id})}>
    
Delete Coupon
    
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>



                </div>

                </>
            ))}
            </>:
            <>
            
            </>}
            
            </div>  
            


<div className="col-lg-6">
<input  required onChange={(e)=>setcode(e.target.value)}  placeholder="COUPON CODE" className="form-control mb-3"></input>

<input required onChange={(e)=>setpercentage(e.target.value)}  placeholder="%OFF" className="form-control mb-3"></input>

<input required type="date" onChange={(e)=>setexpiry(e.target.value)} placeholder="Expiry date" className="form-control mb-3"></input>

<button className="btn btn-primary w-100" onClick={()=>AddCoupon()}>Add Coupon Code</button>
</div>
            
                        </div>

        </>

       
                           {/*account form*/}
        </div>
    )
}

export default CouponCodes

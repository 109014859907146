
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';

import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import Avatar from '@mui/material/Avatar';
import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'
import ENDPOINT from '../../context/EndPoint'

import WorkIcon from '@mui/icons-material/Work';
import GalleryIcon from '@mui/icons-material/Collections';
import { useParams } from 'react-router-dom'
import { Box,  Modal } from '@material-ui/core'
import MoodIcon from '@mui/icons-material/Mood';
import internsteam from '../../assets/intern-community.svg'

import Localbase from 'localbase';


import TimelinePost from './TimelinePost';
const TimelinePage = ({account,timeline,internship}) => {


    const {user} = useContext(AuthContext);

let db = new Localbase('db')
const params = useParams();
const internship_id = params.internshipid;

const [append,setappend] = useState(false)
const [uploadprogress,setuploadprogress] = useState(0);



const [posts,setposts]= useState(null)

const getPosts = async (status)=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  

  formData.append('internship_id',internship_id)
  
  const res = await axios.post(ENDPOINT+"interns-posts.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   if(res.data.status==="success"){
    db.collection('team').add({
      id:1,
   posts:res.data.posts

  },'iposts')
    if(append){
      setposts(res.data.posts)
    }
    else{
      setposts(res.data.posts)
    }
   }
   else{
  
   }
  }
  }









  //delete post
  const deletePost = async ({post})=>{
        

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    
  
    formData.append('internship_id',internship_id)
    formData.append('post_id',post.post_id)
    const res = await axios.post(ENDPOINT+"intern-post-delete.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
    //  // console.log("delete"+JSON.stringify(res))
     if(res.data.status==="success"){
    getPosts()
     }
     else{
    
     }
    }
    }
  
  



var [file, setfile] = useState(null);


const imageChange = (e)=>{
    setfile(e.target.files[0])
  

}




  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setfile(null)


  };
  const handleShow = () => setShow(true);












  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };




const selectImage =()=>{
  const imageSelector = document.getElementById("image-selector");

  imageSelector.click()
  
}





const UPLOAD_ENDPOINT = ENDPOINT+"intern-post.php"


const [status,setstatus]= useState(false)
const [filetype,setfiletype] = useState(null)
   const [toast,settoast] = useState(false)

const [filesize,setfilesize] = useState(0)
const onSubmit = async (e)=> {
  setstatus(true)
  setappend(true)


   e.preventDefault() 
   let res = await uploadFile(file);
  
if(res){
//// console.log("uploaded post"+JSON.stringify(res))

if(res.data.status==="success"){
  settoast(true)
   setShow(false) 
   setuploadprogress(null)
   setfile(null)   
       getPosts()
     }else{
       setuploadprogress(null)
     }
}
 
}


const [postdesc,setpostdesc] = useState(null)

const uploadFile = async (file)=>{
   
  setfilesize(file.size)
    setfiletype(file.filetype)
 
 //// console.log("changed"+file.filetype)
  const config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
   //// console.log("total "+progressEvent.total)
      setuploadprogress(percentCompleted)
  
      setfilesize((progressEvent.total/1000000));

    }
  }




   // // console.log("apply internship")
    const formData = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));
 
    formData.append('resume',file);
   // // console.log("Type: " + file.type +" hello");
   // // console.log("Size: " + file.size +  file.type);
    //check for image
if((file.type==="image/jpeg")||(file.type==="image/png")){


  if(postdesc){
    formData.append('description',postdesc);
  
  }
     formData.append('session',user.SessionId);
     formData.append('internship_id',internship_id);
     formData.append('category',category);
    
     return  await axios.post(UPLOAD_ENDPOINT, formData,config,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });

}else{
 // // console.log("invalid file"+ file.type)
}


    



 

  }



useEffect(() => {
getPosts();
}, [])



const [category,setcategory] = useState("post")

//single post actions

const [addcomment,setaddcomment] = useState(false)
const [comments,setcomments] = useState(null)
const [liked,setliked]= useState(false)
const [comment,setcomment] = useState(null)
const [totallikes,settotallikes]= useState(0)
    const toggleLike = async ({post})=>{
        

        const formData = new FormData();
        
        
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',user.SessionId);
        
          formData.append('post_id',post.post_id);
        
        
        const res = await axios.post(ENDPOINT+"like-post.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
       //  // console.log("liked response "+JSON.stringify(res))
         if(res.data.status==="success" && res.data.message==="liked"){
    setliked(true)
    settotallikes((totallikes+1))
    getPosts()
         }
         else if(res.data.status==="success" && res.data.message==="disliked"){
            setliked(false)
            getPosts()
            settotallikes((totallikes-1))
         }}}
        const toggleComments = ({post})=>{
          setcomments(null)
          const container = document.getElementById("comment"+post.post_id)
          const containers = document.getElementsByClassName("comments-wrap");
          if(container.classList.contains("is-hidden")){
          getComments({post});
          for(var i =0;i<containers.length;i++){
          containers[i].classList.add("is-hidden")
          
          }
          
          container.classList.remove("is-hidden")
          }else{
          container.classList.add("is-hidden")
          }
          }
          


        const getComments = async ({post})=>{
        

            const formData = new FormData();
            
            
            //formData.append('whyus',document.getElementById('whyus').value)
            
            formData.append('session',user.SessionId);
            
              formData.append('post_id',post.post_id);
            
            
            const res = await axios.post(ENDPOINT+"get-comments.php", formData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            if(res){
             // console.log("comments "+JSON.stringify(res.data))
             
             if(res.data.status==="success"){
       setcomments(res.data.comments)
    
             }
             else{
              
             }
            }
            }
    











            const postComment = async ({post})=>{
      

                const formData = new FormData();
                
                
                //formData.append('whyus',document.getElementById('whyus').value)
                
                formData.append('session',user.SessionId);
                
                  formData.append('post_id',post.post_id);
                formData.append('comment',comment)
                formData.append('internship_id',internship_id)
                
                const res = await axios.post(ENDPOINT+"add-comment.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res){
              //   // console.log("comments "+JSON.stringify(res.data))
                 
                 if(res.data.status==="success"){
           getComments({post})
                 }
                 else{
                  
                 }
                }
                }
        
//save post

const savePost = async ({post})=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  
    formData.append('post_id',post.post_id);
  
    formData.append('internship_id',internship_id);

  
  
  const res = await axios.post(ENDPOINT+"save-post.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   //// console.log("saved "+JSON.stringify(res))
   if(res.data[0].status==="success"){
getPosts({post})
   }
   else if(res.data[0].status==="error"){
   
   }
  }
  }



    return (
        <>






            <div className="timeline mx-auto min-vh-80 pt-0 pb-5">







           

<div className="flex-wrap pt-2 ">

<div className="container-fluid ">


<div className="">
  {/*create post card*/}
<div className="  mb-20 p-1 ">


<div className="post-status bg-white">
<div className="flex align-items-center">
<Avatar className="mr-2" src={account?account.profile_pic:null} />
<div className="w-100">
<input onClick={handleShow} className="form-control w-100 mt-10  mt-3 mb-3 rounded-pill" type="text" placeholder={account?account.first_name+", what's in your mind ?":"Hello"+", What's in your mind ?"}></input>

</div>
</div>




<div className="flex sm-12 ">
<button className="p-1 pl-3 pr-3 mr-2 br-4  btn-border-dashed  sm-14" onClick={()=>{setcategory("post");handleShow()}}>
  <GalleryIcon className="text-danger mr-2 truncate truncate-1"/>Image / Video
      </button>



      <button className="p-1 mr-2 br-4 d-none d-sm-block btn-border-dashed  sm-14" onClick={()=>{setcategory("feeling");handleShow()}}>
   <MoodIcon className="text-warning mr-2 text-truncate"/>Feeling/Activity
      </button>




     
      <button className="p-1  br-4  btn-border-dashed  sm-14" onClick={()=>{setcategory("work");handleShow()}}>
   <WorkIcon className="text-primary mr-2 "/>Work Status
      </button>
 
</div>

</div>


<div className="flex">





<form onSubmit={onSubmit} method="post" className="position-relative">      
             










<Modal
  open={show}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  
  <Box sx={style}>

    <div className="flex space-between">
      <h6>Upload Image / Video</h6>
      <CancelIcon type="button" onClick={()=>setShow(false)}/>
    </div>
  <div className="flex align-items-center"><div>

    </div>
       </div>
    <div>
{file?

<>
<textarea onChange={(e)=>setpostdesc(e.target.value)} className="input-holder border-1 position-relative w-100 p-10 mb-2 mt-2" type="text" placeholder={account?"Hey "+account.first_name+" what do you want to say ?":""}>   
</textarea>
</>

:null}


           </div>
         <div className="justify-center">
        {uploadprogress>0?
        
      <>
      
      </>
    :
    <>
     <button className="btn-border-dashed" onClick={selectImage}>{file?
           
           "CHANGE IMAGE":
           "SELECT IMAGE"
           }</button>
           </>  
    }
         </div>
       
         <input id="image-selector"  type="file" className="Select-Image form-control col-1" placeholder="Photo/Video" onChange={imageChange}/>
    
       <div className="justify-center w-100">
       <img className="publish-image w-100" src={file?URL.createObjectURL(file):""} alt=""></img>
        
       </div>
        {file?
      <>
{uploadprogress>0?

<>
<button className="btn btn-primary w-100 mt-3 mb-1 disabled" type="submit" disabled >{uploadprogress>0?<>{"Uploading "+uploadprogress+"%..."}</>:"Share Post"}</button>
    
</>
:<>
   <button className="btn btn-primary w-100 mt-3 mb-1" type="submit"  onClick={onSubmit}>Share Post</button>
    
</>
}   
      
      </>
      :null  
      }

  </Box>
</Modal>  

  






      </form>












</div>




</div>

</div>








{posts?posts.map((post)=>(
<>

<>


<TimelinePost post={post}/>
      
        </>
</>

)):
<>

<div className="container-fluid p-0">



<div className="bg-primary text-center sm-p-15 pt-5 pb-5 text-white">
<h3>Welcome {account?account.first_name:null} !</h3>
<p>Get Ready to make your hands dirty working with your team</p>
<div className="justify-center mt-3">

<img src={internsteam} className="w-75"/>
</div>


</div>




</div>



</>





}

</div>  






</div>

            </div>
        </>
    )
}

export default TimelinePage

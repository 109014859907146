import Form from 'react-bootstrap/Form'
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Localbase from 'localbase'
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useEffect } from 'react'
import { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'
import ENDPOINT from '../../../context/EndPoint'
import Alert from '@mui/material/Alert';
import MmsIcon from '@mui/icons-material/Mms';
import Toast from 'react-bootstrap/Toast'
import WorkIcon from '@mui/icons-material/Work';
import GalleryIcon from '@mui/icons-material/Collections';
import { useParams } from 'react-router-dom'
import { Box,  Modal } from '@material-ui/core'
import MoodIcon from '@mui/icons-material/Mood';
import internsteam from '../../../assets/intern-community.svg'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import FavouriteIcon from '@mui/icons-material/FavoriteBorder';


import MessageIcon from '@mui/icons-material/Message';

import * as timeago from 'timeago.js'

import MoreVert from '@mui/icons-material/MoreVert';

import { AttachFile } from '@material-ui/icons';

import RecommendIcon from '@mui/icons-material/Recommend';
import { Link} from 'react-router-dom';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import TimelinePost from '../../timeline/TimelinePost';
const AdminPostsManagement = ({account,timeline,internship}) => {


    const {user} = useContext(AuthContext);
    let db = new Localbase('db')
    const session = user.SessionId;

const params = useParams();
const internship_id = params.internshipid;
const course_url = params.courseurl;
const batch_id = params.batchid;
const [append,setappend] = useState(false)
const [uploadprogress,setuploadprogress] = useState(0);

const [category,setcategory] = useState("post")

const [posts,setposts]= useState(null)
const [limit,setlimit] = useState(10)
const [totalposts,settotalposts] = useState(0)
const [newpoststotal,setnewpoststotal] = useState(0)
const [loadmore,setloadmore] = useState(false)

const loadMore = ()=>{
  setlimit(limit+10);
  getPosts()
  
}


const getPosts = async (status)=>{
        setloadmore(false)

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  

  formData.append('internship_id',internship_id)
    formData.append('course_url',course_url)
    formData.append('batch_id',batch_id)
    formData.append('limit',limit)
  const res = await axios.post(ENDPOINT+"admin-get-posts.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   // console.log("student posts"+JSON.stringify(res.data))
   if(res.data.status==="success"){



    if(localposts!==null&&(res.data.posts.length!==localposts.length)){
      setposts(res.data.posts)
      db.collection('paths').add({
      
     sposts:res.data.posts
  
    },'sposts')

    }
    

    if(append){
      setposts(res.data.posts)
      
     
    

 
  

      if((res.data.posts).length>=10){
setloadmore(true)



      }
    }
    else{
      setposts(res.data.posts)
    }
   }
   else{
  
   }
  }
  }




const [toasttitle,settoasttitle] = useState(false)
const [toastmessage,settoastmessage] = useState(false)
var [file, setfile] = useState(null);
var [image,setimage] = useState(null);

const reader = new FileReader();
 
const imageChange = (e)=>{
    setfile(e.target.files[0])
  

}




  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setfile(null)


  };
  const handleShow = () => setShow(true);












  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };




const selectImage =()=>{
  const imageSelector = document.getElementById("image-selector");

  imageSelector.click()
  
}





const UPLOAD_ENDPOINT = ENDPOINT+"student-post.php"


const [status,setstatus]= useState(false)
const [filetype,setfiletype] = useState(null)
   const [toast,settoast] = useState(false)

const [filesize,setfilesize] = useState(0)
const onSubmit = async (e)=> {
  setstatus(true)
  setappend(true)


   e.preventDefault() 
   let res = await uploadFile(file);
  
if(res){
//// console.log("uploaded post"+JSON.stringify(res.data))

if(res.data.status==="success"){
 
   setShow(false) 
   setuploadprogress(null)
   setfile(null)   
       getPosts()
     }else{
       setuploadprogress(null)
     }
}
 
}


const [postdesc,setpostdesc] = useState(null)

const uploadFile = async (file)=>{
   
  setfilesize(file.size)
    setfiletype(file.filetype)
 
 //// console.log("changed"+file.filetype)
  const config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  // // console.log("total "+progressEvent.total)
      setuploadprogress(percentCompleted)
  
      setfilesize((progressEvent.total/1000000));

    }
  }




  
    const formData = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));
 
    formData.append('resume',file);
    //// console.log("Type: " + file.type);
    //// console.log("Size: " + file.size + " bytes");
    //check for image
if(file.type==="image/jpeg" || file.type==="image/png" || file.type==="image/jpg"){


  if(postdesc){
    formData.append('description',postdesc);
  
  }
     formData.append('session',user.SessionId);
     formData.append('internship_id',internship_id);
    
     formData.append('course_url',course_url);
     formData.append('category',category);
     formData.append('batch_id',batch_id);
    
     return  await axios.post(UPLOAD_ENDPOINT, formData,config,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });

}else{
  settoasttitle("Invalid file")
  settoastmessage("Please upload images")
  settoast(true)
}


    



 

  }



useEffect(() => {

// console.log("posts ")
  db.collection('paths').doc('sposts').get().then(team => {
   
    setlocalposts(team.sposts);
    setposts(localposts)
    
  }).catch(error=>{

  })
  
  //  [
  //    { id: 1, name: 'Bill', age: 47 },
  //    { id: 2, name: 'Paul', age: 34 }
  //  ]

getPosts();
}, [])


//single post data and actions




const [addcomment,setaddcomment] = useState(false)
const [comments,setcomments] = useState(null)
const [comment,setcomment] = useState(null)
const [totallikes,settotallikes]= useState(0)
const [localposts,setlocalposts] = useState(null)







    const toggleLike = async ({post})=>{
        
const like = document.getElementById("l"+post.post_id);

if(like.classList.contains("text-primary")){

  like.classList.remove("text-primary")
}else{
  like.classList.add("text-primary")
}



        const formData = new FormData();
        
        
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',user.SessionId);
        
          formData.append('post_id',post.post_id);
        
        
        const res = await axios.post(ENDPOINT+"like-student-post.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
        // // console.log("post "+JSON.stringify(res))
         if(res.data.status==="success" && res.data.message==="liked"){

    getPosts({post})
    settotallikes((totallikes+1))
         }
         else if(res.data.status==="success" && res.data.message==="disliked"){
         
            getPosts({post})
            settotallikes((totallikes-1))
         }
        }
        }



//DELETE POST






    //SAVE POST
    


        const getComments = async ({post})=>{
        

            const formData = new FormData();
            
            
            //formData.append('whyus',document.getElementById('whyus').value)
            
            formData.append('session',user.SessionId);
            
              formData.append('post_id',post.post_id);
            
            
            const res = await axios.post(ENDPOINT+"get-student-comments.php", formData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            if(res){
            // // console.log("comments "+JSON.stringify(res.data))
             
             if(res.data.status==="success"){
       setcomments(res.data.comments)
       
      
             }
             else{
              setcomments(null)
             }
            }
            }
    











            const postComment = async ({post})=>{
     
              

              

if(comment===null){
  settoast(true)
  settoasttitle("Enter your comment")
}
else{

  const formData = new FormData();
                
                
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  
    formData.append('post_id',post.post_id);
  formData.append('comment',comment)

  
  const res = await axios.post(ENDPOINT+"add-student-comment.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
   //// console.log("comments "+JSON.stringify(res.data))
   
   if(res.data.status==="success"){
getComments({post})
setcomment(null)
getPosts()
setcomment(null)
   }
   else{
    
   }
  }
}
            
                }
        

const [report,setreport] = useState(false)

const [report_postid,setreport_postid] = useState(null)




const startReport = ({post})=>{

  setreport_postid(post.post_id)
  setreport(true)
}


  const [reportmessage,setreportmessage] = useState(null)
    
const reportPost = async()=>{


      
  const formData = new FormData();
       
  
   //// console.log("reporting "+report_postid)
   formData.append('session',user.SessionId);
    formData.append('post_id',report_postid);    
    formData.append('subject','student_post');    
    formData.append('message',reportmessage); 
      
      const res = await axios.post(`https://skillsuprise.com/report-post.php`,formData);
      
      
      if(res.data[0].status==="success"){
        settoasttitle("Successful")
        settoastmessage("Post reported successfully")
        settoast(true)
          setreport(false)
          setreportmessage(null)
         
      }
      else{
         
        settoasttitle("Error !")
        settoastmessage("Please try again")
        settoast(true)
      }
      
      
      
         
  }
      

  const [changed,setchanged] = useState(false)
const commentChanged =()=>{

  if((comment!==null)&&(comment.length>=1)){
    setchanged(true)
  }else{
    setchanged(false)
  }

}

const [edit,setedit] = useState(false)


const startEdit = ({post})=>{

setedit(true)
}

    return (
        <>



<Modal
  open={report}
  onClose={()=>setreport(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     <div className="flex space-between">
<h5>Report this post</h5>
<CancelIcon type="button" className="text-danger" onClick={()=>setreport(false)}/>
     </div>
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    
    <Form>
 
  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    
    <Form.Control as="textarea" required value={reportmessage} minLength={5} maxLength={500} onChange={(e)=>setreportmessage(e.target.value)} placeholder="Enter your report" rows={3} />
  </Form.Group>


</Form> <button className="btn btn-primary w-100 mt-3" onClick={()=>reportPost({report_postid})}>Submit Report</button>
    
     </Typography>
  </Box>
</Modal>












<Modal
  open={edit}
  onClose={()=>setedit(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     <div className="flex space-between">
<h5>Report this post</h5>
<CancelIcon type="button" onClick={()=>setedit(false)}/>
     </div>
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    
    <Form>
 
  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    
    <Form.Control as="textarea" value={reportmessage} onChange={(e)=>setreportmessage(e.target.value)} placeholder="Enter your report" rows={3} />
  </Form.Group>


</Form> <button className="btn btn-primary w-100 mt-3" onClick={()=>reportPost({report_postid})}>Submit Report</button>
    
     </Typography>
  </Box>
</Modal>




















            <div className="timeline mx-auto min-vh-90 pt-0 pb-5">




<div className="position-fixed container-fluid justify-center bottom-60 left-0">

<Toast className="bg-warning" show={toast} onClose={()=>settoast(false)} autohide delay={4000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>


           

<div className="flex-wrap pt-2 ">

<div className="container-fluid ">





<div className="post-status bg-white">
<div className="flex align-items-center">
<Avatar className="mr-2" src={account?account.profile_pic:null} />
<div className="w-100">
<input onClick={handleShow} className="form-control w-100 mt-10  mt-3 mb-3 rounded-pill" type="text" placeholder={account?account.first_name+", what's in your mind ?":"Hello"+", What's in your mind ?"}></input>

</div>
</div>




<div className="flex sm-12 ">


      <button className="p-1 mr-2 br-4  d-sm-block btn-border-dashed  sm-14" onClick={()=>{setcategory("feeling");handleShow()}}>
   <QuestionAnswerIcon className="text-danger mr-2 text-truncate"/>Start a discussion
      </button>




     
      <button className="p-1  br-4  btn-border-dashed mr-2 sm-14" onClick={()=>{setcategory("knowledge");handleShow()}}>
   <GalleryIcon className="text-primary mr-2 "/>Share Knowledge
      </button>

      <button className="p-1 mr-2 br-4 d-none d-sm-block btn-border-dashed  sm-14" onClick={()=>{setcategory("post");handleShow()}}>
   <MmsIcon className="text-danger mr-2 text-truncate"/>Share a post
      </button>

 
</div>

</div>





<div className="">
  {/*create post card*/}
<div className="  mb-20 p-1 ">





<div className="flex">





<form onSubmit={onSubmit} method="post" className="position-relative">      
             










<Modal
  open={show}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  
  <Box sx={style}>

    <div className="flex space-between">
      <h6>Upload Image / Video</h6>
     
      <CancelIcon type="button" onClick={()=>setShow(false)}/>
    </div>
    <p className="extra-small">

{category==="knowledge"?
"A notification will be sent to all users about your post"  
:null
}
</p>


  <div className="flex align-items-center"><div>

    </div>
       </div>
    <div>
{file?

<>
<textarea onChange={(e)=>setpostdesc(e.target.value)} className="input-holder border-1 position-relative w-100 p-10 mb-2 mt-2" type="text" placeholder={account?"Hey "+account.first_name+" what do you want to say ?":""}>   
</textarea>
</>

:null}


           </div>
         <div className="justify-center">
        {uploadprogress>0?
        
      <>
      
      </>
    :
    <>
     <button className="btn-border-dashed" onClick={selectImage}>{file?
           
           "CHANGE IMAGE":
           "SELECT IMAGE"
           }</button>
           </>  
    }
         </div>
       
         <input id="image-selector"  type="file" className="Select-Image form-control col-1" placeholder="Photo/Video" onChange={imageChange}/>
    
       <div className="justify-center w-100">
       <img className="publish-image w-100" src={file?URL.createObjectURL(file):""} alt=""></img>
        
       </div>
        {file?
      <>
{uploadprogress>0?

<>
<button className="btn btn-primary w-100 mt-3 mb-1 disabled" type="submit" disabled >{uploadprogress>0?<>{"Uploading "+uploadprogress+"%..."}</>:"Share Post"}</button>
    
</>
:<>
   <button className="btn btn-primary w-100 mt-3 mb-1" type="submit"  onClick={onSubmit}>Share Post</button>
    
</>
}   
      
      </>
      :null  
      }

  </Box>
</Modal>  

  






      </form>












</div>




</div>

</div>


{posts?posts.map((post,index)=>(
<>


<>

<TimelinePost key={index} post={post} course_url={course_url} batch_id={batch_id} internship_id={internship_id}/>
        </>









</>

)):
<>

<div className="container-fluid p-0">

<div className="container-fluid p-0 mb-3">
<Alert severity="info">Share an informative post about the course content to enlighten others and get 10 points for each post shared through knowledge base. <Link to="">Click Here</Link> to know more about what type of content is allowed and how it has to be shared.</Alert>
</div>


<div className="bg-primary text-center sm-p-15 pt-5 pb-5 text-white">
<h3>Welcome {account?account.first_name:null} !</h3>
<p>Get Ready to make your hands dirty working with your team</p>
<div className="justify-center mt-3">

<img src={internsteam} className="w-75"/>
</div>


</div>




</div>



</>





}







{loadmore?
<>
<div className="justify-center">
<p className="text-center text-secondary sm-14 " type="button" onClick={()=>loadMore()}>Load More</p>

</div>


</>
:null

}

</div>  






</div>

            </div>
        </>
    )
}

export default AdminPostsManagement

import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Avatar } from '@material-ui/core'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'
import ENDPOINT from '../../../context/EndPoint'
import InternAddMeeting from '../internships/InternAddMeeting'
import { Dropdown, Toast } from 'react-bootstrap'
import { MoreVert } from '@material-ui/icons'
import { useParams } from 'react-router-dom'
const AdminInternshipsList = ({ page}) => {
const params = useParams()
const category = params.category;
const {user} = useContext(AuthContext)


useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [internships,setinternships] = useState("start");


    const  getinternships = async ()=>{
    

            fetch(`${ENDPOINT}admin-internship-applications.php?session=${user.SessionId}&category=${category}`)
            .then(r=>r.json())
            .then(result=>{
           
                    setinternships(result[0].data);
                   
                   //("user", JSON.stringify(result))
            
               
                
            })
      
      
          
            
            }    ;




    useEffect(()=>{
     
    
       
    getinternships();
           
    }
    
    ,[category])
    
    
    
 
    const  updateInternship = async ({iid,istatus})=>{

        fetch(`${ENDPOINT}update-intern-status.php?session=${user.SessionId}&application_id=${iid}&internship_status=${istatus}`)
        .then(r=>r.json())
        .then(result=>{
            //// console.log("updated internship status"+JSON.stringify(result)+" internship id"+iid+" status"+istatus)
       
             if(result.status==="success"){
                getinternships()
settoasttitle("Application "+istatus+iid);
settoastmessage("Successful")
settoast(true)

             }
            
            
        })
        
        }    ;
    
    
    
    

const [schedule,setschedule] = useState(false)
    


const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)
const [currentintern,setcurrentintern] = useState(null)
    return (
        <>

<Modal
  open={schedule}
  onClose={()=>{setschedule(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box >
   <>
<InternAddMeeting intern_id={currentintern}/>
   </>
  </Box>
</Modal>


<div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>
           
<div className="internships-page flex-wrap mobile-reverse ">
<div className="container-fluid mt-3">
    
<h4>{internships?internships.length:0} Internship Applications</h4>
<hr/>
</div>
<div className="internships-list  sm-p-0  container-fluid">

{ internships==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>

:internships?internships.map(internship=>(

<>
<div className="flex container-fluid space-between align-items-center shadow p-3 mb-2">
<div className="flex col-lg-6 col-sm-6">
<Avatar src={internship.profile_pic} className="mr-2"/>
<div >
<p className="mb-0">{internship.first_name+" "+internship.last_name}</p>
<p className="mb-0">{internship.email}</p>
</div>

</div>

<div className="col-lg-1">
    <a href={internship.resume_link} target="_blank" rel="noreferer" className="btn btn-link">
        Resume</a>
</div>
<div className="col-lg-1">
    <a href={internship.resume_link} target="_blank" rel="noreferer" className="btn btn-link">
        {internship.status}</a>
</div>
<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu> 

  <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"rejected"})}} >Reject</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"selected"})}} >Select</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"joined"})}}  >Mark Joined</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{setcurrentintern(internship.id);setschedule(true)}} >Schedule Meeting</Dropdown.Item>
  
    <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"completed"})}} >Mark Completed</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"terminated"})}} >Terminate</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>

</div>
</>
)):
<div className="container-fluid">

No Internships

    </div>

}





</div>




</div>

   



        </>
    )
}

export default AdminInternshipsList


import React from 'react'
import { Link } from 'react-router-dom'
import InternalTemplate from '../../themes/InternalTemplate'
import { useEffect } from 'react'
import { useState } from 'react'

import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import DashboardLoadingTeplate from '../../themes/DashboardLoadingTemplate'
import { Redirect, useParams } from 'react-router'
import LoadingTemplate from '../../themes/LoadingTemplate'
import { Badge} from '@material-ui/core'
import CallbackIcon from '@mui/icons-material/PhoneCallback';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ENDPOINT from '../../context/EndPoint'

import VideoSettingsIcon from '@mui/icons-material/VideoSettings';

import InternalSalesSummary from './InternalSalesSummary'

import NoPermissionsTemplate from '../../themes/NoPermissionTemplate'
import InternalDashboard from './InternalDashboard'
import usersIcons from '../../assets/icons/man.png'
import team from '../../assets/icons/united.png'
import students from '../../assets/iconsv2/students.svg'
import interns from '../../assets/iconsv2/interns.svg'
import trainer from '../../assets/iconsv2/trainer.svg'
import company from '../../assets/iconsv2/company-icon.svg'
const InternalSummaryPage = ({permission}) => {
const params = useParams();
const batchid = params.batchid; 
const courseurl = params.courseurl; 
const {user} =  useContext(AuthContext)
 const userdata = user;
    const [info,setinfo] = useState(null);

    
const [page,setpage]=useState("tasks");


    useEffect(()=>{
       
    
        const  getinfo = async ()=>{

            fetch(`${ENDPOINT}admin-student-management.php?session=${user.SessionId}`)
            .then(r=>r.json())
            .then(result=>{
           
                  if(result){
                   // // console.log("stu "+JSON.stringify(result))
                    setinfo(result.data);
                   
                  }
            
                        
            
                
            })
            
            }    ;
    getinfo();
           
    }
    
    ,[])
    
    
    
    
    
    const [account,setaccount] = useState(null);
  const [role,setrole] = useState(null);


    
    
    
    
    
    
    
    
    
    




    return (

<>
{permission?
<>
<div className="w-100 sm-p-15  internal-dashboard">




{info?
<>


















<div className="container-fluid pt-3">
<h5>Overview</h5>
</div>


{permission.transactions?
<>
<div className="flex-wrap  smgmt">


<div className="col-lg-12">

<div className="flex-wrap mb-3">


{parseInt(permission.users_counts)===1?
<>
<div className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">
   <Link to="/internal/users-management">
   <div className="border p-2 br-4  position-relative">
   <div>
   <h4>{info.total_users}</h4>
    <h6 className="mb-0 ">Users</h6>

   </div>
    <div className="d-icon">
      <img src={usersIcons} className='w-100'/>
   
   

      </div>
    </div></Link>
  </div>

  <div className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">
  <Link to="team-management">
    <div className="border p-2 br-4 position-relative">
 <div>
 <h4>{info.total_team?info.total_Team:0}</h4>
    <h6  className="mb-0 ">Team</h6>
   
 </div>
 <div className="d-icon">
      <img src={team} className='w-100'/>
   
   

      </div>
    </div>
    </Link>
  </div>


  <div className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">
   <Link to="/internal/student-management/all">
   <div className="border p-2 br-4 position-relative">
   <div>
   <h4>{info.total_students?info.total_students:0}</h4>
    <p  className="mb-0 ">Students</p>
   </div>
   <div className="d-icon">
      <img src={students} className='w-100'/>
   
   

      </div>
  
    </div></Link>
  </div>



  <div className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">
    <Link to="/internal/internship-applications/all">
    <div className="border p-2 rounded position-relative">
   <div>
   <h4> {info.total_interns}</h4>
    <p className="mb-0">Total Interns</p>
   </div>
   <div className="d-icon">
      <img src={interns} className='w-100'/>   

      </div>
 
    
    </div>
    </Link>
  </div>



</>:null}

 

  <div className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">
  <Link to="">
  <div className="border p-2 rounded position-relative">
    <div>
    <h4>{info.total_trainers}</h4>
    <p className="mb-0">Total Trainers</p>
    </div>
    <div className="d-icon">
      <img src={trainer} className='w-100'/>   

      </div>
 
    </div>
    </Link>
  </div>




  <div className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">
    <Link to="">
    <div className="border p-2 rounded position-relative">
 <div>
 <h4>{info.total_companies}</h4>
    <p className="mb-0"> Companies</p>
 </div>

 <div className="d-icon">
      <img src={company} className='w-100'/>   

      </div>
 
    </div>
    </Link>
  </div>







</div>
<hr/>
<div className=" flex-wrap mt-3">


{parseInt(permission.doubt_support_statistics)===1?
<>
<div className="col-lg-6 flex-wrap">
<div className="container-fluid h-fit">
    <h5>Doubt Support</h5>
</div>






<div className="col-lg-3 p-1 col-md-3 col-sm-4 col-6">


<Link to={"/internal/doubts/all"}  className="border flex p-2">
<div className="container-fluid">
  <h6>{info.all_doubts} Requests</h6>
  <Badge badgeContent={info.unresolved_doubts} color="secondary">
      <WhatsAppIcon className="text-success" />
  
     </Badge>
 
</div>
<div className="icon">

</div>
</Link>



</div>






<div className="col-lg-3 p-1 col-md-3 col-sm-4 col-6">


<Link to={"/internal/doubts/chat"}  className="border flex p-2">
<div className="container-fluid">
  <h6>{info.whatsapp_doubts} Chats</h6>
  <Badge badgeContent={info.unresolved_chats} color="secondary">
      <WhatsAppIcon className="text-success" />
  
     </Badge>
</div>
<div className="icon">

</div>
</Link>



</div>



<div className="col-lg-3 p-1 col-md-3 col-sm-4 col-6">

<Link to={"/internal/doubts/callback"}   className="border flex p-2">
<div className="container-fluid">
  <h6>{info.call_requests} Calls</h6>

  <Badge badgeContent={info.unresolved_calls} color="secondary">
      <CallbackIcon className="text-primary" />
  
     </Badge>
</div>
<div className="icon">

</div>  
</Link>
</div>

<div className="col-lg-3 p-1 col-md-3 col-sm-4 col-6">

<Link to={"/internal/doubts/zoom"}   className="border flex p-2">
<div className="container-fluid">
  <h6>{info.zoom_requests} Zoom</h6>
  <Badge badgeContent={info.unresolved_zoomcalls} color="secondary">
      <VideoSettingsIcon className="text-primary" />
  
     </Badge>
</div>
<div className="icon">

</div>
</Link>
</div>





<div className="container-fluid mt-3">
  <h4>Placement Requests</h4>
</div>

<div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.resume_requests}</h6>
  <p>Resume Building</p>
</div>
<div className="icon">

</div>
</div>
</div>


<div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.communication_requests}</h6>
  <p>Communication</p>
</div>
<div className="icon">

</div>
</div>
</div>


<div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.interview_requests}</h6>
  <p>Mock Interview</p>
</div>
<div className="icon">

</div>
</div>
</div>


<div className="col-lg-6 p-1 col-md-6 col-sm-6 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.interaction_requests}</h6>
  <p>Interaction Session</p>
</div>
<div className="icon">

</div>
</div>
</div>


<div className="col-lg-6 p-1 col-md-6 col-sm-6 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.certification_requests}</h6>
  <p>Certification Requests</p>
</div>
<div className="icon">

</div>
</div>
</div>









</div>

</>:<>

</>}









{parseInt(permission.customer_support_statistics)?
<>
<div className="col-lg-6 text-dark h-fit flex-wrap">

<div className="container-fluid">
  <h5>Customer Support</h5>
</div>


<Link to={"/internal/support/all"} className="col-lg-4 p-2 col-md-4 col-sm-4 col-4">
    <div className="border p-2">
     
<h6>{info.total_support_requests} Requests</h6>
<Badge badgeContent={info.unresolved_support_requests} color="secondary">
      <VideoSettingsIcon className="text-primary" />
  
     </Badge>
    </div>
</Link>


<Link to={"/internal/support/all"} className="col-lg-4 p-2 col-md-4 col-sm-4 col-4">
    <div className="border p-2">
     
<h6>{info.total_support_requests} Demos</h6>
<Badge badgeContent={info.unresolved_support_requests} color="secondary">
      <VideoSettingsIcon className="text-primary" />
  
     </Badge>
    </div>
</Link>

<Link to={"/internal/support/all"} className="col-lg-4 p-2 col-md-4 col-sm-4 col-4">
    <div className="border p-2">
     
<h6>{info.total_support_requests} Requests</h6>
<Badge badgeContent={info.unresolved_support_requests} color="secondary">
      <VideoSettingsIcon className="text-primary" />
  
     </Badge>
    </div>
</Link>


<div className="container-fluid">
<hr/>

<h4>Leave Requests</h4>

</div>
<Link to={"/internal/support/all"} className="col-lg-4 p-2 col-md-4 col-sm-4 col-4">
    <div className="border p-2">
       
<h6>{info.total_leave_requests} All</h6>
<Badge badgeContent={info.unresolved_leave_requests} color="secondary">
      <VideoSettingsIcon className="text-primary" />
  
     </Badge>
    </div>
</Link>


<Link to={"/internal/support/all"} className="col-lg-4 p-2 col-md-4 col-sm-4 col-4">
    <div className="border p-2">
      
<h6> {info.general_leaves} Geneal</h6>
<Badge badgeContent={info.unresolved_general_leaves} color="secondary">
      <VideoSettingsIcon className="text-primary" />
  
     </Badge>
    </div>
</Link>


<Link to={"/internal/support/all"} className="col-lg-4 p-2 col-md-4 col-sm-4 col-4">
    <div className="border p-2">
        
<h6> {info.medical_leaves} Medical</h6>
<Badge badgeContent={info.unresolved_medical_leaves} color="secondary">
      <VideoSettingsIcon className="text-primary" />
  
     </Badge>
    </div>
</Link>

<Link to={"/internal/support/all"} className="col-lg-4 p-2 col-md-4 col-sm-4 col-4">
    <div className="border p-2">
        
<h6>{info.emergency_leaves} Emergency </h6>
<Badge badgeContent={info.unresolved_emergency_leaves} color="secondary">
      <VideoSettingsIcon className="text-primary" />
  
     </Badge>
    </div>
</Link>


















</div>

</>:
<>

</>}









</div>


{parseInt(permission.sales_statistics)===1?
<>

<InternalSalesSummary counters={false}/>

</>:null}




<hr/>

<hr/>




<div className="container-fluid">
<h4 >Registrations Overview</h4>
</div>
<div className="flex-wrap container-fluid">

<Link to={"/internal/cai-registrations/all"}  className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.total_ambassadors}</h6>
  <p>Scholarship regs</p>
</div>
<div className="icon">

</div>
</div>
</Link>


<Link to={"/internal/cai-registrations/all"}  className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.total_internship_applications}</h6>
  <p>Internship regs</p>
</div>
<div className="icon">

</div>
</div>
</Link>


<Link to={"/internal/cai-registrations/all"}  className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.total_ambassadors}</h6>
  <p>Ambassadors</p>
</div>
<div className="icon">

</div>
</div>
</Link>



<Link to={"/internal/workshop-registrations/all"}  className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.workshop_registrations}</h6>
  <p>Workshop Regs</p>
</div>
<div className="icon">

</div>
</div>
</Link>



<Link to={"/internal/hacking-internship-registrations/all"}  className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.workshop_registrations}</h6>
  <p>Hacking Internship Registrations</p>
</div>
<div className="icon">

</div>
</div>
</Link>



<div className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.hackathon_registrations}</h6>
  <p>Hackathon Regs</p>
</div>
<div className="icon">

</div>
</div>
</div>


<div className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">

<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.hackathon_registrations}</h6>
  <p>Hackathon Regs</p>
</div>
<div className="icon">

</div>
</div>
</div>

<Link to="/internal/hacking-scholarship-registrations" className="col-lg-2 p-2 col-md-2 col-sm-4 col-6">



<div className="border flex p-2">
<div className="container-fluid">
  <h6>{info.total_hacking_workshop_reg}</h6>
  <p>Hacking Scholarship Regs</p>
</div>
<div className="icon">

</div>
</div>

</Link>








</div>
<hr/>



</div>









</div>

</>:
<>

</>}

</>:
<>

</>}






{info?
<>

 










</>:
<>

{permission?
<>


{parseInt(permission.intern)===1?
<Redirect to="/work"></Redirect>
:
<>


<NoPermissionsTemplate/>

</>
}
</>:
<>
<DashboardLoadingTeplate/>


</>}
</>}

</div>
</>
:<>


</>}

</>
    )
}

export default InternalSummaryPage








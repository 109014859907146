import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

import axios from 'axios'



import ENDPOINT from '../../context/EndPoint'


const GetRemainders = async({payload}) => {












      const formData = new FormData();
      
      
      //formData.append('whyus',document.getElementById('whyus').value)
      
      formData.append('session',payload.SessionId);
      
        formData.append('status',"joined");
      
        formData.append('category',"joined");
   
      
      const res = await axios.post(ENDPOINT+"team-reminders.php", formData,{
          headers: {
              'content-type': 'multipart/form-data'
          }
      });
      if(res){
       
       if(res.data){
 
        return res.data
       }
       else{
      
       }
      }
   










        





    
    

    
    


    





    
}

export default GetRemainders


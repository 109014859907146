import React from 'react'

const BasicInformation = ({profile}) => {
  return (
    <div className="">
     <p className="mb-1">Email : {profile.email}</p>

     <p className="mb-1">Phone: {profile.phone_number}</p>

     <p className="mb-1">WhatsApp : {profile.whatsapp_number}</p>

     <p className="mb-1">City : {profile.city}</p>

     <p className="mb-1">Address : {profile.address}</p>
     
     
     
     
     BasicInformation</div>
  )
}

export default BasicInformation
import { Avatar } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import ENDPOINT from '../../../context/EndPoint'
import dateFormat from 'dateformat'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
const InternWorkHistory = () => {
const {user} = useContext(AuthContext)
    const [workhistory,setworkhistory] = useState(null)
//delete task code
const params = useParams()
const intern_id = params.intern_id
const internship_id = params.internship_id
const [history,sethistory] = useState(false)
const WorkHistory = async ()=>{

  const formData = new FormData();
  formData.append('session',user.SessionId);
  formData.append('intern_id',intern_id);
  formData.append('internship_id',internship_id);
  
  const res = await axios.post(ENDPOINT+"admin-get-work-history.php",formData,{
    headers:{
      'content-type': 'multipart/form-data'
    }
  });
  
  if(res){
    // console.log("work "+JSON.stringify(res))
  }
  if(res.data.status==="success"){
   setworkhistory(res.data.work_days)
   sethistory(true)
  }
  else{
    
  }
  }

  useEffect(()=>{
WorkHistory()
  },[])
  return (
    <>
    
{workhistory?

<>


<div className="container-fluid overflow-y pt-3">

<div className="container-fluid align-items-center bg-primary p-2 mb-3 text-white flex space-between">

<div className="flex align-items-center col-lg-5 col-sm-6">

<Avatar src={"currentdp"} className="mr-2"/>
<div >
<h4 className="mb-0">{"currentname"}</h4>
<h6>Work History ( {workhistory.length} )</h6>

</div>

</div>
<Close type="button" className="text-danger" onClick={()=>sethistory(false)}/>
</div>




{workhistory.map((work,index)=>(
<>

<div key={index} className="container-fluid mb-0 mt-1 ">


<div className="flex space-between">
<div className="col-5">
<p><b>LoggedIn : </b>{dateFormat(work.start_time, "dS mmm yy hh:mm:ss tt")}
</p>
<p><b>Logged Out : </b>{work.end_time!==null?dateFormat(work.end_time, "hh:mm:ss tt"):"Working..."}
</p>
</div>


<div className="col-5">
<p><b>Active Hours : </b>{work.end_time!==null?work.duration:"Working..."}
</p>
<p><b>Tasks Done : </b>{work.end_time!==null?work.duration:"Working..."}
</p>
</div>

</div>

</div>
<hr/>
</>

))}

     
</div>

</>

:<>

</>

}
    
    
    </>
  )
}

export default InternWorkHistory
import React, { useContext } from "react";
import axios from "axios";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import { useState } from "react";
import Toast from "react-bootstrap/Toast";
import { useEffect } from "react";
import ENDPOINT from "../../../context/EndPoint";
import { ChevronRight, MoreVert, Share } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { Chip } from "@material-ui/core";
import InternalTemplate from "../../../themes/InternalTemplate";
import { AuthContext } from "../../../context/AuthContext";
import { Container } from "react-bootstrap";
import { TextField } from "@material-ui/core";
const InternalWorkshopsanagement = ({ userdata }) => {
  const { user } = useContext(AuthContext);

  const [category, setcategory] = useState("all");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [workshops, setworkshops] = useState(null);
  const [workshop, setworkshop] = useState(null);
  useEffect(() => {
    getworkshops();
  }, [category]);

  const getworkshops = async () => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("status", category);
    setworkshops(null);
    const res = await axios.post(
      ENDPOINT + "admin-get-workshops.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      // console.log(" data "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      setworkshops(res.data.data);
      setInitialData(res.data.data);
      setResponse(res.data.data);
    } else {
    }
  };

  const [share, setshare] = useState(false);

  const showShare = (e) => {
    setshare(true);
    const id = e.target.id;
    setworkshop(workshops[id].workshop_url);
  };

  const closeShare = () => {
    setshare(false);
    setworkshop(null);
  };

  const UpdateWorkshop = async ({ workshop_id, status }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("workshop_id", workshop_id);
    formData.append("status", status);

    const res = await axios.post(
      ENDPOINT + "admin-workshop-actions.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res.data.status === "success") {
      settoasttitle("Successful !");
      settoastmessage("Workshop status changed successfully");
      settoast(true);
      getworkshops();
    } else {
      settoasttitle("Something went wrong !");
      settoastmessage("Please try againa fter sometime");
      settoast(true);
    }
  };

  const [query, setquery] = useState(null);

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.workshop_title.toLowerCase().includes(term) ||
        item.workshop_mode.toLowerCase().includes(term) ||
        item.workshop_status.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <>
      <div className="w-100 min-vh-100 ">
        <div className="position-fixed justify-center bottom-60 left-0 w-100">
          <Toast
            className="bg-warning"
            onClose={() => settoast(false)}
            show={toast}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">{toasttitle}</strong>
            </Toast.Header>
            <Toast.Body>{toastmessage}</Toast.Body>
          </Toast>
        </div>

        <Modal show={share} onHide={closeShare} animation={true}>
          <Modal.Body>
            {workshops ? (
              <>
                <h4 className="mb-2">Share this Event</h4>
                <div className="flex mt-4 mb-2">
                  <WhatsappShareButton
                    size={30}
                    round={true}
                    url={"https://skillsuprise.com/workshop/" + workshop}
                  >
                    <WhatsappIcon
                      size={30}
                      type="button"
                      round={true}
                      url={"https://skillsuprise.com/workshop/" + workshop}
                      className="mr-2"
                    />
                  </WhatsappShareButton>
                  <LinkedinShareButton
                    size={30}
                    round={true}
                    url={"https://skillsuprise.com/workshop/" + workshop}
                  >
                    <LinkedinIcon
                      size={30}
                      type="button"
                      round={true}
                      url={"https://skillsuprise.com/workshop/" + workshop}
                      className="mr-2"
                    ></LinkedinIcon>
                  </LinkedinShareButton>
                  <TelegramShareButton
                    size={30}
                    round={true}
                    url={"https://skillsuprise.com/workshop/" + workshop}
                  >
                    <TelegramIcon
                      size={30}
                      type="button"
                      round={true}
                      url={"https://skillsuprise.com/workshop/" + workshop}
                      className="mr-2"
                    />
                  </TelegramShareButton>
                  <TwitterShareButton
                    size={30}
                    round={true}
                    url={"https://skillsuprise.com/workshop/" + workshop}
                  >
                    <TwitterIcon
                      size={30}
                      type="button"
                      round={true}
                      url={"https://skillsuprise.com/workshop/" + workshop}
                      className="mr-2"
                    />
                  </TwitterShareButton>
                  <EmailShareButton
                    size={30}
                    round={true}
                    url={"https://skillsuprise.com/workshop/" + workshop}
                  >
                    <EmailIcon
                      size={30}
                      type="button"
                      round={true}
                      url={"https://skillsuprise.com/workshop/" + workshop}
                      className="mr-2"
                    />
                  </EmailShareButton>
                </div>
              </>
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal>

        {/* <div className="flex  align-items-center space-between mb-4">


<div className="flex col-lg-9">
<FormControl >
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
      
<Select
  
  value={category}
  label="Category"
  onChange={(e)=>setcategory(e.target.value)}
>
  <MenuItem value={"all"}>All </MenuItem>
  <MenuItem value={"active"}>Active</MenuItem>
  <MenuItem value={"inactive"}>In-active</MenuItem>
  <MenuItem value={"cancelled"}>Cancelled</MenuItem>
</Select>
      </FormControl>



      <Paper
    onSubmit={(e)=>{e.preventDefault();getworkshops()}}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >

      <InputBase
      
         sx={{ ml: 1, flex: 1 }}
        onChange={(e)=>setquery(e.target.value)}
        placeholder="Search Email or Phone"
        inputProps={{ 'aria-label': 'Search Batch ID ' }}
      />
      <IconButton onClick={()=>getworkshops()} sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      
    </Paper>

</div>


<h4>{workshops?workshops.length:0} Workshops</h4>

 
</div> */}

        <Container fluid className="mb-3 col-8">
          <div>
            <TextField
              label="Search..."
              variant="outlined"
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
              fullWidth
              margin="normal"
            />
          </div>
        </Container>

        <Link
          className="btn btn-primary mb-3"
          to="/internal/workshops-management/add"
        >
          Add Workshop
        </Link>

        <div
          id="workshops"
          className="container-fluid workshops-list flex-wrap space-evenly"
        >
          {response
            ? response.map((workshop, index) => (
                <div className=" p-2 col-12 text-dark">
                  <div className="shadow flex col-12">
                    <div className="col-3">
                    <img
                      src={workshop.workshop_cover_url}
                      className="w-100 mb-2 "
                      alt=""
                    ></img>
                    </div>

                    <div className="p-3 pt-0 col-9">
                    <div className="d-flex col-12 justify-content-between mt-3">
                  <div className="col-10 ">  <h5 className=""> {workshop.workshop_title}</h5></div>
                    <div className=""> 
                      <Dropdown className="options-dropdown ml-3">
                            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                              <MoreVert className="text-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  UpdateWorkshop({
                                    workshop_id: workshop.workshop_id,
                                    status: "active",
                                  })
                                }
                              >
                                Publish
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  UpdateWorkshop({
                                    workshop_id: workshop.workshop_id,
                                    status: "inactive",
                                  })
                                }
                              >
                                De Activate
                              </Dropdown.Item>
                              <Dropdown.Item ><Share onClick={showShare} type="button" id={index} /> Share</Dropdown.Item>
                              <Dropdown.Item href="#">Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown></div>
                    </div>

                      <div className="d-flex mb-3 space-between mt-3">
                      
                        <div className="col-4 border-right  p-0">
                          <p className="">
                            {workshop.workshop_duration}
                          </p>
                        </div>

                        <div className="col-4 border-right p-0 text-center">
                          <p className="mb-0 sm-14">{workshop.date}</p>
                        </div>
                        

                       
                      </div>

                      <div className="flex  space-between">
                        <div className="flex mt-2">
                          <Chip
                            className="bg-primary text-white"
                            label={
                              workshop.workshop_mode === "live"
                                ? "Live Online"
                                : "Offline"
                            }
                          />

                          <Chip
                            className=" ms-2 bg-danger text-white"
                            label={workshop.workshop_status}
                          />
                        </div>

                        <Link
                          to={"/workshop/" + workshop.workshop_url}
                          className="btn-link btn "
                        >
                          View Details <ChevronRight />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default InternalWorkshopsanagement;

import React from 'react'
import  styled  from 'styled-components'

import InternalNavbar from '../components/navbar/InternalNavbar'

import { ref, push, onValue, getDatabase } from "firebase/database";
import audio from "../assets/audio/notif.mp3";
import BottomNavigationBar from '../components/navbar/BottomNavigationBar'
import firebaseConfig from '../firebase-Config.js'
import { useEffect } from 'react'
import { useContext,useState } from 'react'
import axios from 'axios'
import ENDPOINT from '../context/EndPoint'
import { AuthContext } from '../context/AuthContext'
import InternalSidebar from '../components/leftsidebar/InternalSidebar'
const InternalTemplate= ({bottomMenu,children,permission,footer,timeline, page,props}) => {
  const {user} = useContext(AuthContext)
  
  
  const audioRef = React.createRef();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [time,settime]= useState(0);


  setTimeout(
    () => settime(time+1), 
    
    1000
  );
  
  




  const pathname = window.location.pathname;


  useEffect(()=>{
  
  updateActivity();
  
  },[pathname])
  
  
  
  const updateActivity = async (status)=>{
          
  
    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    if(user){

      formData.append('session',user.SessionId);
    
    }
   
    
      formData.append('page',pathname);
    
    
    const res = await axios.post(ENDPOINT+"save-activity.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     // console.log("activity "+JSON.stringify(res))
    
   
    }
    }



  //get user account


  
  const [account,setaccount]=  useState(null)
  useEffect(()=>{
      
    
  if(user){
      
    const  getaccount = async ()=>{
    
      
      fetch(`${ENDPOINT}get-account.php?session=${user.SessionId} `)
        .then(r=>r.json())
        .then(result=>{
         // // console.log(result)
       if(result){

      if((result.status==="success")&&(result.data!=null)){
        setaccount(result.data);
    // // console.log("got account"+result[0].data.first_name)

      }else {
        localStorage.setItem("user",null);
        window.location.replace("/")
      }
    
       }
        else{
          localStorage.setItem("user",null);
       // window.location.replace("/")
        }
        
      
     


        
            
        })
      

 
      }    ;
      if(user!=null||user!=="null"){
        getaccount();
        getnotifications();
      }
  }


       
}

,[])



 
const [notifications,setnotifications] = useState(null);
const [unread,setunread] = useState(0);


const  getnotifications = async ()=>{
   //// console.log("getting notifications")
try{
  fetch(`${ENDPOINT}get-notifications.php?session=${user.SessionId}`)
  .then(r=>r.json())
  .then(result=>{
    
      
 if(result[0].status==="success"){
     

  if((result[0].data)!==null){
    if (result[0].unread>unread) {
      if(document.getElementById("audio")){
        document.getElementById("audio").play()
      }
    }
      setnotifications(result[0].data);
   setunread(result[0].unread)
   
  }

 }
  
              
  
      
  })
}
catch{

}
  
  }    ;





const TimedQuiz = ()=>{


  
}



  const dbRef = ref(
  getDatabase(),
  account ? "/admins/"+account.user_id + "/notifications" : "anonymous",
);


  useEffect(() => {
    // Listen for changes in the 'messages' node in the database
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageList = Object.values(data);
        if (account) {
          getnotifications();
          //console.log("retreving data");
        }
      }
    });
  }, [account]);

    return (
 

 <InternalTemplateStyled>

<div className=" container ">
  <audio className="d-none" id="audio" src={audio} />

{/*
  <InternalSidebar permission={permission} userdata={user} page="Home" account={account}/>



*/}

<InternalNavbar permissions={permission}  time={time} account={account} unread={unread} notifications={notifications}/>

<div className="container-fluid mt-4 p-0 pt-5">
{children}




</div>
<BottomNavigationBar/>

</div>

 </InternalTemplateStyled>
 
    )
}

export default InternalTemplate


const InternalTemplateStyled = styled.div`

padding: 0px;
margin: 0px;
width: 100% ;
.flex{
    display: flex;
}
.page-container{
  margin-top: 55px;
  width: auto !important;
}



`
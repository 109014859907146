import React from 'react'
import { Link } from 'react-router-dom'
import InternalTemplate from '../../themes/InternalTemplate'
import { useEffect } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import { useParams } from 'react-router'

import {  Alert } from '@mui/material'

import ENDPOINT from '../../context/EndPoint'
const InternalGrowthSummary = () => {
const params = useParams();
const batchid = params.batchid; 
const courseurl = params.courseurl; 
const {user} =  useContext(AuthContext)
 const userdata = user;
    const [assignments,setassignments] = useState(null);

    
const [page,setpage]=useState("tasks");


    useEffect(()=>{
       
    
        const  getassignments = async ()=>{

            fetch(`${ENDPOINT}get-assignments.php?session=${user.SessionId}&batch_id=${batchid}&course=${courseurl}`)
            .then(r=>r.json())
            .then(result=>{
           
                  if(result){
                    setassignments(result[0].data);
                   
                  }
            
                        
            
                
            })
            
            }    ;
    getassignments();
           
    }
    
    ,[])
    
    
    
    
    
    const [account,setaccount] = useState(null);
  const [role,setrole] = useState(null);

    
    useEffect(()=>{
       
    
        const  getaccount = async ()=>{
  
          fetch(`${ENDPOINT}get-account.php?session=${user.SessionId} `)
            .then(r=>r.json())
            .then(result=>{
                if(result){

          if((result[0].status==="success")&&(result[0].data!=null)){
            setaccount(result[0].data);
            setrole(result[0].data.role)
        
            
          }else if((result[0].status==="error")&&(result[0].data==="session expired")){
          
            
            localStorage.setItem("user",null);
            window.location.replace("/")
          }
        
           }
            else{
              localStorage.setItem("user",null);
            window.location.replace("/")
            }
            
                        
            
                
            })
          
       
            }    ;

    getaccount();

           
    }
    
    ,[])
    
    
    
    
    
    
    
    
    
    
    
    




    return (

<>

<div className="flex-wrap mt-5 pt-3">


<div className="col-lg-9">


<div className="flex-wrap mobile-reverse  ">

<div className="col-lg-3 p-2">

<div className="border br-4 flex p-3">
<div className="container-fluid">
  <h6>Total Registrations</h6>
  <p>467</p>
</div>
<div className="icon">

</div>
</div>
</div>





<div className="col-lg-3 p-2">

<div className="border br-4 flex p-3">
<div className="container-fluid">
  <h6>Total Revenue</h6>
  <p>467</p>
</div>
<div className="icon">

</div>
</div>
</div>






<div className="col-lg-3 p-2">

<div className="border br-4 flex p-3">
<div className="container-fluid">
  <h6>Total Users</h6>
  <p>467</p>
</div>
<div className="icon">

</div>
</div>
</div>



<div className="col-lg-3 p-2">

<div className="border br-4 flex p-3">
<div className="container-fluid">
  <h6>Total Students</h6>
  <p>467</p>
</div>
<div className="icon">

</div>
</div>
</div>


<div className="col-lg-3 p-2">

<div className="border br-4 flex p-3">
<div className="container-fluid">
  <h6>Partnered Companies</h6>
  <p>467</p>
</div>
<div className="icon">

</div>
</div>
</div>


<div className="col-lg-3 p-2">

<div className="border br-4 flex p-3">
<div className="container-fluid">
  <h6>Total Ambassadors</h6>
  <p>467</p>
</div>
<div className="icon">

</div>
</div>
</div>


<div className="col-lg-3 p-2">

<div className="border br-4 flex p-3">
<div className="container-fluid">
  <h6>Total Employees</h6>
  <p>467</p>
</div>
<div className="icon">

</div>
</div>
</div>



<div className="col-lg-3 p-2">

<div className="border br-4 flex p-3">
<div className="container-fluid">
  <h6>Total Interns</h6>
  <p>467</p>
</div>
<div className="icon">

</div>
</div>
</div>







</div>
</div>



<div className="col-lg-3 border br-4 p-2">
<div className="flex">
    <img src={account?account.profile_pic:null} className="userdp mt-0"></img>
    <div>
        <h5>Manoj Kumar</h5>
        <p>Chief Execuritve Officer</p>

        
    </div>
</div>
<hr/>
<Alert severity="info"><b>1</b> device loggedin into your account</Alert>
<div className="container-fluid">
<div className="flex space-between mt-3">
<div className="col-lg-3 justify-center">
<p>Account</p>
</div>


<div className="col-lg-3 justify-center">
<p>Settings</p>
</div>

<div className="col-lg-3 justify-center">
<p>Logout</p>
</div>

</div>
        </div>


</div>







</div>


<hr/>
<div className=" flex-wrap">



<div className="col-lg-6 flex-wrap">

<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Online Courses</h6>
    </div>
</div>



<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Selfpaced Courses</h6>
    </div>
</div>


<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Offline Courses</h6>
    </div>
</div>

<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>specialization Courses</h6>
    </div>
</div>

<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Workshops</h6>
    </div>
</div>


<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Internships</h6>
    </div>
</div>


<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Hackathons</h6>
    </div>
</div>


<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Tutorials</h6>
    </div>
</div>



</div>










<div className="col-lg-6 flex-wrap">


<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Total Hours Spent</h6>
    </div>
</div>

<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Selfpaced Watch Hours</h6>
    </div>
</div>


<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Live Class Hours</h6>
    </div>
</div>


<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Workshop Hours</h6>
    </div>
</div>



<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Tutorial Watch Hours</h6>
    </div>
</div>
<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Reading Hours</h6>
    </div>
</div>


<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Live Classes Delivered</h6>
    </div>
</div>



<div className="col-lg-3 p-2">
    <div className="border br-4 p-3">
        <p>134</p>
<h6>Workshops Hosted</h6>
    </div>
</div>





</div>








</div>

</>
    )
}

export default InternalGrowthSummary








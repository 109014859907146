import MailIcon from "@mui/icons-material/Mail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Avatar } from "@material-ui/core";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import { AuthContext } from "../../context/AuthContext";
import InternshipItemSkeleton from "../../skeleton/InternshipItemSkeleton";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import { Dropdown, Modal } from "react-bootstrap";
import { MoreVert } from "@material-ui/icons";
import ENDPOINT from "../../context/EndPoint";
import { Link } from 'react-router-dom'
import SecurityHeaders from "../../context/SecurityHeaders";
import { useParams } from "react-router-dom";

const TrainerDashboard = ({ page }) => {
  const { user } = useContext(AuthContext);

  const userdata = user;
  const params = useParams();
  const trainer_id = params.trainer_id;

  const [category, setcategory] = useState("all");

  const [query, setquery] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState("start");

  useEffect(() => {
    getUsers();
  }, [category]);

  const getUsers = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    if (category !== null) {
      formData.append("status", category);
    }

    if (query !== null) {
      formData.append("query", query);
    }

    const res = await axios.post(ENDPOINT + "admin-get-users.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      setquery(null);

      if (res.data.status === "success") {
     
        setinternships(res.data.data);
      } else {
      }
    }
  };

  const [AddPoints, setAddPoints] = useState(false);

  const showAddPoints = () => {
    setAddPoints(true);
  };

  const closeAddPoints = () => {
    setAddPoints(false);
  };

  const ChangeAccountStatus = async ({ user_id, status }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_id", user_id);
    formData.append("status", status);

    const res = await axios.post(
      ENDPOINT + "admin-change-account-status.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
    }
    if (res.data.status === "success") {
      settoasttitle("Successful !");
      settoastmessage("Account status updated successfully");
      settoast(true);
      getUsers();
    } else {
      settoasttitle("Something went wrong !");
      settoastmessage("Account status updated failed");
      settoast(true);
    }
  };

  const DeleteUser = async ({ user_id }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_id", user_id);

    const res = await axios.post(ENDPOINT + "admin-delete-user.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res) {
      // console.log(" deleted "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      settoasttitle("User Deleted !");
      settoastmessage("User account successfully deleted");
      settoast(true);
      getUsers();
    } else {
      settoasttitle("Something went trong !");
      settoastmessage("Account deletion failed . Please try after sometime .");
      settoast(true);
    }
  };

  const SendCode = async ({ user_email }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_email", user_email);

    const res = await axios.post(ENDPOINT + "admin-send-otp.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res) {
    }
    if (res.data.status === "success") {
      settoasttitle("OTP Sent !");
      settoastmessage("OTP has been successfully sent .");
      settoast(true);
      // console.log("deleted  "+user_email)
      getUsers();
    } else {
      settoasttitle("Something went wrong !");
      settoastmessage("Please try after sometime");
      settoast(true);
    }
  };

  const [currentuser, setcurrentuser] = useState(null);
  const [activity, setactivity] = useState(false);

  const userActivity = ({ userdata }) => {
    setcurrentuser(userdata);
    setactivity(true);
  };

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);
  const [enrollments,setEnrollments] = useState("");

  const GetRevenue = async ()=>{
    const data = new FormData();
    data.append('trainer_id',1)
    data.append('session',user.SessionId)
    const res = await axios.post(`${ENDPOINT}trainer_based_enrollments.php`,data,SecurityHeaders);
    if(res){
     
      if(res.data){
        setEnrollments(res.data)
        
      }
    }
  }
  useEffect(() => {
   GetRevenue()
  }, [])

  return (
    <>
      <div className="position-fixed bottom-60 left-0 w-100 justify-center">
        <Toast
          className="bg-warning"
          onClose={() => settoast(false)}
          show={toast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>

      <Modal show={AddPoints} onHide={closeAddPoints}>
        <Modal.Header closeButton>
          <Modal.Title>Add Points</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal size="xl" show={activity} onHide={() => setactivity(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Activity</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>

      <div className="container-fluid min-vh-100">
        <div className="shadow min-vh-30 w-100 mb-3"></div>

        <div className="flex-wrap mt-3">
          <div className="col-lg-3 p-2">
            <div className="shadow text-center">
              <h5>{enrollments.total_purchase_price}</h5>
              <p>Total Revenue</p>
            </div>
          </div>

          <div className="col-lg-3 p-2">
            <div className="shadow text-center">
              <h5>{enrollments.enrollments}</h5>
              <p>Total Enrollments</p>
            </div>
          </div>

         <Link  to={"/trainer/"+trainer_id+"/course-list"} classNam="col-lg-3 p-2">
         <div className="col-lg-12 ">
            <div className="shadow text-center">
              <h5>23</h5>
              <p>Total Courses</p>
            </div>
          </div></Link>

        <Link to={"/trainer/"+trainer_id+"/student-list"} classNam="col-lg-3 p-2">
        <div className="col-12">
            <div className="shadow text-center">
              <h5>23</h5>
              <p>Total Students</p>
            </div>
          </div>
        </Link>

          <div className="col-lg-3 p-2">
            <div className="shadow text-center">
              <h5>23</h5>
              <p>Classes Delivered</p>
            </div>
          </div>

          <div className="col-lg-3 p-2">
            <div className="shadow text-center">
              <h5>23</h5>
              <p>Learning Hours</p>
            </div>
          </div>

          <div className="col-lg-3 p-2">
            <div className="shadow text-center">
              <h5>23</h5>
              <p>Total Tutorials</p>
            </div>
          </div>

          <div className="col-lg-3 p-2">
            <div className="shadow text-center">
              <h5>23</h5>
              <p>Watch Hours</p>
            </div>
          </div>
        </div>

        <div className="container-fluid flex-wrap min-vh-30">
          <div className="col-lg-6">
            <h4>Appreciations</h4>
          </div>

          <div className="col-lg-6">
            <h4>Notifications</h4>
          </div>
        </div>

        <div className="container-fluid d-none">
          <div className="flex  align-items-center space-between mb-4">
            <div className="flex col-lg-9">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>

                <Select
                  value={category}
                  label="Category"
                  onChange={(e) => setcategory(e.target.value)}
                >
                  <MenuItem value={"all"}>All </MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"inactive"}>De-Activated</MenuItem>
                </Select>
              </FormControl>

              <Paper
                onSubmit={(e) => {
                  e.preventDefault();
                  getUsers();
                }}
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  onChange={(e) => setquery(e.target.value)}
                  placeholder="Search Email or Phone"
                  inputProps={{ "aria-label": "Search Batch ID " }}
                />
                <IconButton
                  onClick={() => getUsers()}
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            <h4>{internships ? internships.length : 0} Users</h4>
          </div>
        </div>

        <hr />
        <div className=" sm-p-0  container-fluid">
          {internships === "start" ? (
            <div className="container-fluid">
              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />
            </div>
          ) : internships ? (
            internships.map((internship) => (
              <>
                <div className="flex container-fluid align-items-center space-between  p-3 ">
                  <div className="flex col-lg-3">
                    <Avatar src={internship.profile_pic} className="mr-2" />
                    <div>
                      <p className="mb-0">
                        {internship.first_name + " " + internship.last_name}
                      </p>
                      <p className="mb-0">{internship.username}</p>
                    </div>
                  </div>

                  <p className="mr-3">{internship.status}</p>
                  <div className="col flex">
                    <div>
                      <div className="flex">
                        <p className="mb-0 text-primary ">
                          {internship.phone_number}
                        </p>
                      </div>
                      <div className="flex">
                        <p className="mb-0"> {internship.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-4">
                    <p className="mb-0">{internship.create_datetime}</p>
                  </div>

                  <Dropdown className="options-dropdown">
                    <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                      <MoreVert className="text-dark" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          ChangeAccountStatus({
                            status: "active",
                            user_id: internship.user_id,
                          })
                        }
                      >
                        Activate Account
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() =>
                          ChangeAccountStatus({
                            status: "pending",
                            user_id: internship.user_id,
                          })
                        }
                      >
                        Mark Pending Verification
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() =>
                          ChangeAccountStatus({
                            status: "inactive",
                            user_id: internship.user_id,
                          })
                        }
                      >
                        Deactivate Account
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() =>
                          SendCode({ user_email: internship.email })
                        }
                      >
                        Send Verification Code
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          DeleteUser({ user_id: internship.user_id })
                        }
                      >
                        Delete User
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => userActivity({ userdata: internship })}
                      >
                        User Activity
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <hr className="mb-1 mt-1" />
              </>
            ))
          ) : (
            <div className="container-fluid">No Internships</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TrainerDashboard;

import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Avatar } from '@material-ui/core'

import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'
import axios from 'axios'
import ENDPOINT from '../../../context/EndPoint'
import { Dropdown, Toast } from 'react-bootstrap'
import { MoreVert } from '@material-ui/icons'
import { useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
const StudentWorkshopRegistrations = ({ page}) => {
const params = useParams();
const category = params.category;
const {user} = useContext(AuthContext)
const userdata = user;




useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [internships,setinternships] = useState("start");

    useEffect(()=>{
     
    
  
      getRegistrations()     
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[category])
    
    
   
    
    
   


    






















    
const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)






const updateWorkshopRegistration = async ({wstatus,wid,index})=>{
    

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);
//// console.log("workshop data"+JSON.stringify(wstatus))


  formData.append('workshop_enrollment_id',wid);
  formData.append('workshop_status',wstatus)



const res = await axios.post(ENDPOINT+"update-workshop-enrollment-status.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
 //// console.log("course status "+JSON.stringify(res.data))
 if(res.data[0].status==="success"){
 getRegistrations()
  settoasttitle(""+internships[index-1].full_name+" Application updated")
  settoastmessage("Registration status changed to "+wstatus)

  settoast(true)
 }
 else{
    settoasttitle("Something went wrong !")
    settoastmessage("Please try again... ")
  
    settoast(true)
 }
}
}







const getRegistrations = async ()=>{
    

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    //// console.log("workshop data"+JSON.stringify(wstatus))
    
  
    
    const res = await axios.post(ENDPOINT+"admin-get-workshop-registrations.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     // console.log("registrations "+JSON.stringify(res.data.data))
     if(res.data.status==="success"){
     
       setinternships(res.data.data)
       setInititalData(res.data.data)
       setResponse(res.data.data)
      settoast(true)
     }
     else{
    
     }
    }
    }

    const [initialData,setInititalData] = useState(null)
  
    const [response, setResponse] = useState(initialData);
    const [searchTerm, setSearchTerm] = useState('');
  
    const handleSearch = (e) => {
      const term = e.target.value.toLowerCase();
  
      const filteredData = initialData.filter((item) => {
        return (
          item.email.toLowerCase().includes(term) ||
          item.phone.includes(term) ||
          item.workshop_title.toLowerCase().includes(term) ||
          item.full_name.toLowerCase().includes(term) ||
          item.status.toLowerCase().includes(term)
        );
      });
  
      setResponse(filteredData);
      setSearchTerm(term);
    };
    




    return (
        <>

<div className="fixed-bottom container-fluid justify-center bottom-60 left-0">
<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>


</div>

           
<div className="internships-page flex-wrap  ">
<div className="container-fluid ">
<Container fluid className="mb-2">
      
      <div>
      
      <input
        placeholder="Search name, email phone "
       className="fixed-search"
        value={searchTerm}
        onChange={handleSearch}
      />
    
    </div>
            </Container>

<h4>{internships?internships.length:0} Workshop Registrations</h4>


</div>
<div className="  sm-p-0  container-fluid">

{ internships==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>

:response?

<Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
  <TableRow>
    <TableCell>User</TableCell>
    <TableCell align="left">Title</TableCell>
    <TableCell align="left">Status</TableCell>
    <TableCell align="right">Actions</TableCell>
  </TableRow>
</TableHead>

<TableBody>
  {
    response.map((internship,index)=>(

     <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
     <TableCell className='col-3' component='th' scope='row' >
     <div className="flex w-100 align-items-center col-sm-6">
      <Avatar src={internship.profile_pic} className="me-2"/>
      <div className="">
      <p className="mb-0">{internship.full_name}</p>
      <p className="mb-0">{internship.phone} | {internship.email}</p>
      </div>
      
      </div>

     </TableCell>
  <TableCell align='left' >
  <div className="w-100">
         <p className="truncate truncate-2"> {internship.workshop_title}</p>
      </div>
  </TableCell>
     <TableCell align='left'>
     <div className="col-lg-1">
          {internship.status}
      </div>
     </TableCell>
      
      
     <TableCell align='right'>
       
     <Dropdown className="options-dropdown">   
        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
        <MoreVert className="text-dark"/>
        </Dropdown.Toggle>
      
        <Dropdown.Menu>
            
         <Dropdown.Item  onClick={()=>{updateWorkshopRegistration({wstatus:"registered",wid:internship.id,"index":index})}}  >Mark Registered</Dropdown.Item>
      
         <Dropdown.Item  onClick={()=>{updateWorkshopRegistration({wstatus:"attended",wid:internship.id,"index":index})}}  >Mark Attended</Dropdown.Item>
         <Dropdown.Item  onClick={()=>{updateWorkshopRegistration({wstatus:"unattended",wid:internship.id,"index":index})}}  >Mark Unattended</Dropdown.Item>
         <Dropdown.Item   onClick={()=>{updateWorkshopRegistration({wstatus:"interested",wid:internship.id,"index":index})}}  >Mark Interested</Dropdown.Item>
         <Dropdown.Item   onClick={()=>{updateWorkshopRegistration({wstatus:"not-interested",wid:internship.id,"index":index})}}  >Mark Not Interested</Dropdown.Item>
      
         <Dropdown.Item >Send Workshop Info</Dropdown.Item>
      
        </Dropdown.Menu>
      </Dropdown>
     </TableCell>
     </TableRow>
      ))
  }

</TableBody>
</Table>

:
<div className="container-fluid">

No Internships

    </div>

}





</div>




</div>

   



        </>
    )
}

export default StudentWorkshopRegistrations
import React from 'react'

const AssignmentAdded = ({payload}) => {
  return (
    <div className="shadow p-3 border br-5">
      <img src={payload.image?payload.image:null} className="w-100"/>  

      {payload.image?"":"Image Needed"}
        <p>Hey  <b>{payload.one?payload.one:"{{1}}"}</b> , a new assignment has been added for your course  <b> {payload.two?payload.two:"{{2}}"} </b>. 
        Visit Skills Uprise course dashboard to access your assignments. 
<br></br>
<br></br>
Title :  <b>{payload.three?payload.three:"{{3}}"} </b> 
<br></br>
Deadline  :  <b>{payload.four?payload.four:"{{4}}"} </b> 
<br></br>
<br></br>
 <b>{payload.five?payload.five:"{{5}}"}</b> 
 <br></br>
 <br></br>
All the best !</p>
      </div>
  )
}

export default AssignmentAdded
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const SalesOverviewGraph = ({sales}) => {
  const [data1,setdata1] = useState([23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30])
const [data2,setdata2] = useState([44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43])
const [data3,setdata3] = useState([30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39])
const [dates,setdates] = useState(['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003',
'08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'])





     const [state,setstate] = useState({
          
      series: [{
        name: 'Selfpaced',
        type: 'column',
        data: data1
      }, {
        name: 'Online',
        type: 'area',
        data:data2
      }, {
        name: 'Offline',
        type: 'line',
        data: data3
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: dates,
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          title: {
            text: 'Points',
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;
        
            }
          }
        }
}})


useEffect(()=>{

  if(sales&&sales.sales_overview){
  
    setdata1(sales.sales_overview.selfpaced_sales)
    setdata2(sales.sales_overview.online_sales)
    setdata3(sales.sales_overview.offline_sales)
    setdates(sales.sales_overview.dates)
  }
  
  },[])
  return (
    <div>

<ReactApexChart options={state.options} series={state.series} type="line" height={350} />


    </div>
  )
}

export default SalesOverviewGraph
import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../../context/EndPoint';
import SecurityHeaders from '../../../context/SecurityHeaders';

const UploadCertificateUrl = () => {
    const { user } = useContext(AuthContext);
    const [certificate, setCertificate] = useState(null);
    const [id, setId] = useState(null);

    console.log(certificate)

    const onSubmit = async (e) => {
        e.preventDefault() 
        

        const data = new FormData();
        data.append('certificate_url', certificate);
        data.append('certificate_id', id); 
        data.append('session', user.SessionId);

        try {
            const res = await axios.post(ENDPOINT + 'admin-update-certificate.php', data, SecurityHeaders);
            if (res) {
                console.log('Certificate updated');
            }
        } catch (error) {
            console.error('Error updating certificate:', error);
        }
    };

   

    return (
        <>
            <div className="container">
                <h5 className="text-center mt-5">Update Certificate</h5>
                <div className="col-6 mx-auto mt-4">
                    <form className=" col-12 pb-2" onSubmit={(e) => onSubmit(e)}>
                    <input
                            placeholder="id"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                            className="form-control"
                        />
                        <input
                            placeholder="certificate url"
                            value={certificate}
                            onChange={(e) => setCertificate(e.target.value)}
                            className="form-control mt-3"
                        />
                        <div className=" col-12 d-flex justify-content-center  mt-3">
                        <button className="btn btn-primary">Upload</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UploadCertificateUrl;

import React, { useState, useEffect } from "react";
import axios from "axios";
import ExpenseItem from "./ExpenseItem"; // Create ExpenseItem component for rendering each expense
import { Link } from "react-router-dom";
import ENDPOINT from "../../../context/EndPoint";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Toast, Modal } from "react-bootstrap";
import SecurityHeaders from "../../../context/SecurityHeaders";
import { CircularProgress } from "@material-ui/core";
import ExpensesTable from "./ExpensesTable";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExpenseRow from "./ExpenseRow";
const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const { user } = useContext(AuthContext);
  // fetch Expenses
  const fetchExpenses = async () => {
    try {
      const data = new FormData();
      data.append("session", user.SessionId);

      const response = await axios.post(
        ENDPOINT + "admin-get-expenses.php",
        data,
        SecurityHeaders
      );

      setExpenses(response.data.expenses);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  const openModal = (open) => {
    setModal(open);
  };
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState("pending");

  const [updateStatus, setUpdateStatus] = useState(false);
  //add and updateExpenses
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [expenseId, setExpenseId] = useState("");
  //toast
  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toast, setToast] = useState(false);

  //Add Expenses

  const addExpenses = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData();
      data.append("session", user.SessionId);
      data.append("title", title);
      data.append("description", description);
      data.append("type", type);
      data.append("amount", amount);
      data.append("category", category);
      data.append("status", status);
      const res = await axios.post(
        ENDPOINT + "admin-add-expense.php",
        data,
        SecurityHeaders
      );

      if (res.data.status === "success") {
        setToastMessage("Add successfully");
        setToast(true);
        setModal(false);
        fetchExpenses();
      } else {
        setToastTitle("Something went wrong!");
        setToastMessage("Please try again...");
        setToast(true);
      }
    } catch (error) {
      setToastTitle("Error");
      setToastMessage("An error occurred. Please try again.");
      setToast(true);
    }
  };
  //Update Expenses



  return (
    <>
      <div className="position-fixed bottom-60 left-0 w-100 justify-center">
        <Toast
          className="bg-warning"
          onClose={() => setToast(false)}
          show={toast}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastTitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </div>
      <Modal
        show={modal}
        size="md"
        animation={false}
        onHide={() => setModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={addExpenses}>
              <div className="form-group">
                <label>title</label>
                <input
                  className="form-control"
                  placeholder="title"
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>description</label>
                <input
                  className="form-control"
                  placeholder="description"
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>category</label>
                <input
                required
                  className="form-control"
                  placeholder="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>type</label>
                <input
                  className="form-control "
                  placeholder="type"
                  value={type}
                  required
                  onChange={(e) => setType(e.target.value)}
                />

                <select>
                  <option>expense</option>
                  <option>revenue</option>
                </select>
              </div>
              <div className="form-group">
                <label>amount</label>
                <input
                  className="form-control "
                  placeholder="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="form-group mt-3">
                <label>status</label>
                <select
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option></option>
                  <option value="pending">pending</option>
                  <option value="granted">granted</option>
                </select>
              </div>

              <button type="submit" className="btn btn-primary">
                {updateStatus ? (
                  <CircularProgress className="text-white" size={20} />
                ) : (
                  ""
                )}
                Add Expenses
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <div>
        <div className="container-fluid mt-4">





<div className="d-flex">


<div className="col-lg-5 d-flex">

<div className="col-4 p-2">
<div className="shadow p-2 rounded">
<h6>3,56,000</h6>
  <p>Balance</p>
</div>
</div>


<div className="col-4 p-2">
<div className="shadow p-2 rounded">
<h6>3,56,000</h6>
  <p>Total Spent</p>
</div>
</div>

<div className="col-4 p-2">
<div className="shadow p-2 rounded">
<h6>3,56,000</h6>
  <p>Total Revenue</p>
</div>
</div>

</div>
<div className="col-lg-6">

<button onClick={() => setModal(true)} className="btn btn-primary">
            Add Expense
          </button>
</div>
</div>


        </div>

        <div className="container-fluid sm-p-15 mt-5">
      
      
      <ExpensesTable data={<>
        {expenses
            ? expenses.map((item) => (
      <ExpenseRow item={item}/>
              ))
            : null}
    

      </>}/>
          {/* Map through the expenses array and render ExpenseItem components */}
   
        </div>
      </div>
    </>
  );
};

export default Expenses;

import { Chip } from '@material-ui/core';
import React, { useState } from 'react'

const ListTags = ({data,index}) => {
const [tags,settags] = useState(data)
let info  = tags;
info.splice(index,1);
settags(info)
settags(null)
settags(info)


// console.log(tags)
  return (
    <>
     {tags.map((tag)=>(
<>
<Chip label={tag+" tag "}/>   

</>

     ))}
    </>
  )
}

export default ListTags
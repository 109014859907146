import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import ENDPOINT from '../../../context/EndPoint';
import SecurityHeaders from '../../../context/SecurityHeaders';
import { AuthContext } from '../../../context/AuthContext';
import TeamMileStoneItem from './TeamMileStoneItem';

const TeamMilestones = () => {
  const { user } = useContext(AuthContext);
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getMilestones = async () => {
    try {
      const data = new FormData();
      data.append('session', user.SessionId);

      const res = await axios.post(ENDPOINT + 'admin-get-team-milestones.php', data, SecurityHeaders);

      if (res.data && res.data.status === 'success') {
        setMilestones(res.data.data);
      } else {
        setError(res.data.message || 'Unknown error');
      }
    } catch (error) {
      setError('An error occurred while fetching team milestones');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMilestones();
  }, []);

  return (
    <div className="container-fluid sm-p-15 mt-5">
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
   
    </div>
  );
};

export default TeamMilestones;

import {Container,Col} from 'react-bootstrap';
import reward from './assets/reward.svg';
import user from './assets/dp1.jpg';
import icon1 from './assets/icon-1.png';
import icon2 from './assets/icon2.png';
import { HiLocationMarker } from "react-icons/hi";
import { FaHandshake } from "react-icons/fa";
import { BsFillPlayFill } from "react-icons/bs";



import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const Reward=()=>{
    return(
        <Container>
            <div className="d-flex flex-wrap">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5">
                    <h1 className="mt-5 text-blue fs-1">Share to create <br/>harmony lives</h1>
                    <p className="text-secondary mt-4">Every donation of yours have millions of benefits for others</p>
                    
                    
                    
                    
                    
                    <div className="btn bg-green text-light rounded mt-5">Watch series<BsFillPlayFill className="ms-2"/></div>

                    <div className="d-flex flex-wrap">
                        <Col className="mt-5 d-flex flex-wrap ">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-5"> <img src={icon1} alt="" className="w-50"></img></div>
                       
                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 mt-5">
                        <h4 className="text-blue">3,680</h4>
                        <p className="text-secondary">program complete</p>
                        </div>
                        
                        </Col>
    
                        <Col className="mt-5 d-flex flex-wrap">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-5"> <img src={icon2} alt="" className="w-50"></img></div>
                       
                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 mt-5">
                        <h4 className="text-blue">21 billion</h4>
                        <p className="text-secondary">program complete</p>
                        </div>
                        
                        </Col>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 position-relative">
                    <img src={reward} alt="" className=" w-100"></img>
                    <div className="d-none start-25 top-0 shadow rounded mt-5 p-3 bg-light">
                        <p className="text-secondary"><small>Donator registered</small></p>
                        <h1>2.413</h1>
                        <div className="d-flex flex-wrap mt-3 ms-2">
                            <img src={user} alt="" className="user rounded rounded-circle"></img>
                            <img src={user} alt="" className="user rounded rounded-circle"></img>
                            <img src={user} alt="" className="user rounded rounded-circle"></img>
                            <img src={user} alt="" className="user rounded rounded-circle"></img>
                            <div className="user bg-green rounded rounded-circle text-light"><p><small>2.3k+</small></p></div>
                        </div>
                    </div>
                    <div className="d-none end-0 bottom-0 shadow rounded mb-5 p-3 bg-light">
                    <div className="hand-icon bg-green rounded rounded-circle text-light text-center start-75"><FaHandshake className="mt-1"/></div>
                    <p className="mb-0"><small>Social</small></p>
                    <h6><small>loreum epsum loreum epsum loreum <br/>epsum loreum</small></h6>
                    <p><small><HiLocationMarker className="text-warning me-2 mt-0"/>Hongkong</small></p>
                    <div className="d-flex flex-wrap mb-3">
                        <Col>50%</Col>
                        <Col>IDR:5.000.000/-</Col>

                    </div>
                    <Box sx={{ flexGrow: 1 }} >
    
                    <BorderLinearProgress variant="determinate" value={50} />
                    </Box>

                    </div>
                </div>
            </div>
        </Container>
    );
}
export default Reward
import React from 'react'
import styled from 'styled-components'
import SidebarTemplate from '../../themes/SidebarTemplate'
import PostItem from '../../components/post/PostItem'
const ProfilePage = () => {
    return (

<SidebarTemplate>



<ProfilePageStyled>
            
    
    
            <div class="row profile-reverse"><div class="col-sm-5"><div class="user-profile compact">
                
                
                
                
                <div class="up-head-w" ><div class="up-social">
                <a href="1"><i class="os-icon os-icon-twitter"></i></a><a href="1"><i class="os-icon os-icon-facebook"></i></a></div>
        <div class="up-main-info"><h2 class="up-header">John Mayers</h2><h6 class="up-sub-header">Product Designer at Facebook</h6></div>
        
 
        
        
        </div>
        <div class="up-controls"><div class="row "><div class="col-sm-6"><div class="value-pair"><div class="label">Status:</div>
        <div class="value badge badge-pill badge-success">Online</div>
        </div>
        </div>
        <div class="col-sm-6 text-right"><a class="btn btn-primary btn-sm" href="1"><i class="os-icon os-icon-link-3"></i><span>Add to Friends</span></a></div>
        </div>
        </div>
        <div class="up-contents"><div class="m-b"><div class="row m-b"><div class="col-sm-6 b-r b-b"><div class="el-tablo centered padded-v"><div class="value">25</div>
        <div class="label">Products Sold</div>
        </div>
        </div>
        <div class="col-sm-6 b-b"><div class="el-tablo centered padded-v"><div class="value">315</div>
        <div class="label">Friends</div>
        </div>
        </div>
        </div>
        <div class="padded"><div class="os-progress-bar primary"><div class="bar-labels"><div class="bar-label-left"><span>Profile Completion</span><span class="positive">+10</span></div>
        <div class="bar-label-right"><span class="info">72/100</span></div>
        </div>
        <div class="bar-level-1" ><div class="bar-level-2" ><div class="bar-level-3" ></div>
        </div>
        </div>
        </div>
        <div class="os-progress-bar primary"><div 
        class="bar-labels"><div class="bar-label-left"><span>Status Unlocked</span><span class="positive">+5</span></div>
        <div class="bar-label-right"><span class="info">45/100</span></div>
        </div>
        <div class="bar-level-1" ><div class="bar-level-2" ><div class="bar-level-3" ></div>
        </div>
        </div>
        </div>
        <div class="os-progress-bar primary"><div class="bar-labels"><div class="bar-label-left"><span>Followers</span><span class="negative">-12</span></div>
        <div class="bar-label-right"><span class="info">74/100</span></div>
        </div>
        <div class="bar-level-1" ><div class="bar-level-2"><div class="bar-level-3" ></div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div class="element-wrapper"><div class="element-box"><h6 class="element-header">User Activity</h6><div class="timed-activities compact"><div class="timed-activity"><div class="ta-date"><span>21st Jan, 2017</span></div>
        <div class="ta-record-w"><div class="ta-record"><div class="ta-timestamp"><strong>11:55</strong> am</div>
        <div class="ta-activity">Created a post called <a href="1">Register new symbol</a> in Rogue</div>
        </div>
        <div class="ta-record"><div class="ta-timestamp"><strong>2:34</strong> pm</div>
        <div class="ta-activity">Commented on story <a href="1">How to be a leader</a> in <a href="1">Financial</a> category</div>
        </div>
        <div class="ta-record"><div class="ta-timestamp"><strong>7:12</strong> pm</div>
        <div class="ta-activity">Added <a href="1">John Silver</a> as a friend</div>
        </div>
        </div>
        </div>
        <div class="timed-activity"><div class="ta-date"><span>3rd Feb, 2017</span></div>
        <div class="ta-record-w"><div class="ta-record"><div class="ta-timestamp"><strong>9:32</strong> pm</div>
        <div class="ta-activity">Added <a href="1">John Silver</a> as a friend</div>
        </div>
        <div class="ta-record"><div class="ta-timestamp"><strong>5:14</strong> pm</div>
        <div class="ta-activity">Commented on story <a href="1">How to be a leader</a> in <a href="1">Financial</a> category</div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div class="col-sm-7"><div class="element-wrapper">
            
            
            
            <div class="element-box">
                
                
                
                <PostItem/>
                
                
                <PostItem/>
                
                <PostItem/>
                
                <PostItem/>
                
                <PostItem/>
                
                <PostItem/>
                
                
                
              




        </div>














        </div>
        </div>
        </div>
        
        
        
        
        
        
                </ProfilePageStyled>


</SidebarTemplate>









    )
}

export default ProfilePage



export const ProfilePageStyled = styled.div`
.element-wrapper{
    width: 550px;
    max-width:550px;
    box-shadow:none;
    height: 100vh;
}
.profile-reverse{
    display: flex;
    flex-direction: row-reverse;
}
.element-box{
    width: 100%;
    box-shadow: none;
    background-color:transparent;
    overflow-y: scroll;
    height: 100vh;
}
`

import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import InternalTemplate from '../../themes/InternalTemplate'
import { AuthContext } from '../../context/AuthContext'

import { Link, useParams } from 'react-router-dom'
import Leads from './leads/Leads'
const InternalLeadsManagement = ({ page,course,props}) => {

const {user} = useContext(AuthContext)
const userdata = user;


const params = useParams();
const category = params.category;
const status = params.status;

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    const [internships,setinternships] = useState("start");







    
 const [AddPoints,setAddPoints] = useState(false);

 const showAddPoints = ()=>{
     setAddPoints(true);
 }
    
const closeAddPoints = ()=>{
    setAddPoints(false)
}

const [input,setinput] = useState(null)


const [editstats,seteditstats] = useState(false)
const [studentid,setstudentid] = useState(null)

const editStatistics = (studentid)=>{
setstudentid(studentid)

seteditstats(true)

}


    return (
       <>
   
<>

<>




<Leads/>

        </>
</>

       </>
    )
}

export default InternalLeadsManagement


import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useParams } from 'react-router-dom';
import ENDPOINT from '../../../context/EndPoint'
import CloseIcon from '@mui/icons-material/Close';
import { Toast } from 'react-bootstrap'

import axios from 'axios';
import GetUserPersonalBadges from '../../../actions/user/GetUserPersonalBadges';

import GetStudentCourseBadges from '../../../actions/student/GetStudentCourseBadges';

import ModalStyle from '../../../context/ModalStyle'

import TextField from '@mui/material/TextField';
import AdminEditStudent from '../../../actions/student/AdiminEditStudent'
import AdminUnlockAccess from '../../../actions/course/AdminUnlockAccess'



const InternalAlumniManagement = ({ page}) => {

const {user} = useContext(AuthContext)
const userdata = user;
const params = useParams();
const purchase_id = params.purchase_id
const [status,setstatus] = useState("all")

const [query,setquery] = useState(null)


useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [profile,setprofile] = useState("start");

    useEffect(()=>{
     
    
      
    getinternships();
           
    }
    
    ,[status])
    
    const  getinternships = async ()=>{
      setprofile("start")
      const data = new FormData();
      data.append('session',user.SessionId)
      if(query){
        data.append('query',query)
      }
      if(status){
        data.append('status',status)
      }
      data.append('purchase_id',purchase_id)
      const res = await axios.post(ENDPOINT+"student-course-profile.php",data,{headers:{
        'content-type':'multipart/form-data'
      }})


      if(res){
      console.log("profile is "+JSON.stringify(res))
        if(res.data.status==="success"){
          setprofile(res.data.data)
         
        }else{
               }
      }
     
          
            
            }    ;
    
    
    
    
            const  updateRequest = async ({ticketstatus,ticketid})=>{
      
                if (userdata!==null){
        
                    fetch(`${ENDPOINT}admin-update-doubt-request.php?session=${user.SessionId}&status=${ticketstatus}&id=${ticketid}`)
                    .then(r=>r.json())
                    .then(result=>{
                      
                  // // console.log(JSON.stringify(result))
                            if(result.status==="success"){
        settoasttitle("Successful")
        settoastmessage("Status changed to "+ticketstatus)
        settoast(true)
        getinternships()
                            }
                            else{
                                settoasttitle("Something went wrong !")
                                settoastmessage("Please try again... ")
                                settoast(true)
                              
                            }
                       
                       
                        
                    })
                }
                else{
                
                }
                  
                    
                    }    ;
            
            
    

const [toast,settoast] = useState(false);
const [toasttitle,settoasttitle] = useState(null)

const [toastmessage,settoastmessage] = useState(null)



const NewUserBadges = async ({user_id})=>{

     const res = await GetUserPersonalBadges({payload:{
          user:user.SessionId,
          user_id:user_id
     }})
     if(res){
          if(res.status==="success"){
               setpersonalbadges(res.badges)
          }
      }
}


const DownloadCertificate = ()=>{


     
}

const NewCourseBadges = async ({user_id})=>{

const res = await GetStudentCourseBadges({payload:{
     user:user.SessionId,
     user_id:user_id,
     course_url:profile.course_url
}})
if(res){
     console.log("c badges are "+JSON.stringify(res))
  
}
if(res.status==="success"){
     console.log("c badges are "+JSON.stringify(res))
  
     setcoursebadges(res.badges)
}

}

const [personalbadges,setpersonalbadges] = useState(null)
useEffect(()=>{

   

          NewUserBadges({user_id:profile.user_id})
          NewCourseBadges({user_id:profile.user_id})
     
},[profile])

const [studentid,setstudentid] = useState()
// profile.student_id
const [batchid,setbatchid] = useState(profile.batch_id)
const [teamid,setteamid] = useState(profile.team_id)
const [progress,setprogress] = useState(0)
const [marks,setmarks] = useState(0)
const [points,setpoints] = useState(0)

const [editmodal,seteditmodal] = useState(false)
const [coursebadges,setcoursebadges] = useState(null)

const EditStudent = async (e)=>{
  e.preventDefault()
const res = await AdminEditStudent({
  payload:{
    user:user.SessionId,
    batch_id:batchid,
    student_id:studentid,
    team_id:teamid,
    progress:progress,
    total_marks:marks,
    points:points,
    purchase_id:purchase_id
  }
})
if(res){
  if(res.status==="success"){
    getinternships()
    seteditmodal(false)
  }
 
}
}



const UnlockAccess = async ({data})=>{

const res = await AdminUnlockAccess({
  payload:{
    user:user.SessionId,
  key:data.key,
  value:data.value,
    purchase_id:purchase_id
  }
})
if(res){
  console.log("ACCESS  "+JSON.stringify(res))
  if(res.status==="success"){
    getinternships()
  }
 
}
}




    return (
        <div className="min-vh-100">



<Modal
  open={editmodal}
  onClose={()=>seteditmodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle}>
   <div className="modal-header">

<h5>Edit Student Info</h5>
<CloseIcon className="click" onClick={()=>seteditmodal(false)}/>

   </div>

<div className=" mt-3">
<form onSubmit={(e)=>EditStudent(e)}>


  

<div className="d-flex">

<div className="col">
<TextField value={studentid} onChange={(e)=>setstudentid(e.target.value)} fullWidth label="Student ID" />

</div>
<div className="col">
<TextField value={batchid} onChange={(e)=>setbatchid(e.target.value)}  fullWidth label="Batch ID"  />

</div>
</div>
<div className="d-flex mt-3">

<div className="col">
<TextField value={teamid} onChange={(e)=>setteamid(e.target.value)}  fullWidth label="Team ID" />

</div>


<div className="col">
<TextField value={progress} onChange={(e)=>setprogress(e.target.value)}  fullWidth label="Progress" />

</div>

</div>


<div className="d-flex mt-3">

<div className="col">
<TextField  value={marks} onChange={(e)=>setmarks(e.target.value)} fullWidth label="Marks"  />

</div>

<div className="col">
<TextField value={points} onChange={(e)=>setpoints(e.target.value)}  fullWidth label="Points"  />

</div>
</div>



<div className="mt-4">
  <button type="submit" className="btn btn-primary w-100">Save Changes</button>
</div>
</form>
</div>




  </Box>
</Modal>


<div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>

 




<div className="container-fluid">




     
</div>





        </div>
    )
}

export default InternalAlumniManagement
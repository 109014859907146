import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContext';
import {   Snackbar } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import ENDPOINT from '../../../context/EndPoint'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';




import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import FileUploadIcon from '@mui/icons-material/FileUpload';



import dateFormat from 'dateformat'
import { Check, MoreVert, PlayArrow } from '@material-ui/icons';
import SecurityHeaders from '../../../context/SecurityHeaders';
import { Clock, DistributeVertical, EyeFill, Pause, PauseBtn } from 'react-bootstrap-icons';
import { Add, AddBox, Chair, Delete, RampRight, Schedule } from '@mui/icons-material';
const AdminCourseBatches = ({id,batchid,course_id}) => {



const {user} =  useContext(AuthContext)

const params = useParams();

const courseid = params.courseid;

const courseurl = params.courseurl;



const [category,setcategory] = useState("all")


const [query,setquery] =useState(null)

useEffect(()=>{

getBatches({bid:"all"});
},[])





const [coursedata,setcoursedata] = useState(null)

      const [snackbar,setsnackbar] = useState(false);
      const [snackbarMessage,setsnackbarMessage] = useState(null)
      

      const getBatches = async ({bid})=>{
          const formData = new FormData();
             
        //formData.append('whyus',document.getElementById('whyus').value)
        formData.append('session',user.SessionId);
        formData.append('course_id',courseid);
 if(query!==null){
  formData.append('query',query);

 }
 if(bid!==null){
  formData.append('status',bid);
 }
  
        const res = await axios.post(ENDPOINT+"admin-get-batches.php", formData,{
          
        
        headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
         if(res.data.status==="success"){
           setcoursedata(res.data.data)
     
         }
         else{
        
         }
        }
        }
        
          



        const updateBatchStatus = async ({cid,bid,bstatus})=>{
       
          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          formData.append('course_id',cid);
          formData.append('id',bid);
          formData.append('status',bstatus);
    
          const res = await axios.post(ENDPOINT+"admin-update-batch-status.php", formData,{
            
          
          headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
             if(res.data.status==="success"){
          
       getBatches({bid})
           }
           else{
          
           }
          }
          }
         


     
          const options = ['Option 1', 'Option 2'];
          
        
            const [value, setValue] = React.useState(options[0]);
            const [inputValue, setInputValue] = React.useState('');
          
   

const [delete_id,setdelete_id] = useState(null)

      const DeleteBatch = async ({id})=>{

        const data = new FormData()
        data.append("session",user.SessionId)
        data.append("id",id)
const res = await axios.post(ENDPOINT+"admin-delete-batch.php",data,SecurityHeaders)


if(res){
  if(res.data.status==="success"){
    getBatches({bid:"all"})
    console.log(JSON.stringify(res))
  }
}
      }



    return (
      <>
      <Snackbar
              open={snackbar}
              autoHideDuration={6000}
              onClose={()=>setsnackbar(false)}
              message={snackbarMessage}
           
            />
      
      
      
      <div className="container-fluid p-2  min-vh-100 sm-p-15 ">
      
      
      <div className="flex align-items-center space-between">
      <div className="flex align-items-center col-lg-8 space-between">
     
      
      
      <div className="flex fixed-search">
      
      <FormControl >
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
      
<Select
  
  value={category}
  label="Category"
  onChange={(e)=>{setcategory(e.target.value);getBatches({bid:e.target.value})}}
>
  <MenuItem value={"all"}>All </MenuItem>
  <MenuItem value={"active"}>Active</MenuItem>
  <MenuItem value={"pending"}>Pending</MenuItem>
  <MenuItem value={"completed"}>Completed</MenuItem>
  
</Select>
      </FormControl>

      <Paper
          onSubmit={(e)=>{e.preventDefault();getBatches({bid:"all"})}}
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
          >
      
            <InputBase
            
               sx={{ ml: 1, flex: 1 }}
              onChange={(e)=>setquery(e.target.value)}
              placeholder="Search Batch ID"
              inputProps={{ 'aria-label': 'Search Batch ID ' }}
            />
            <IconButton onClick={()=>getBatches({bid:"all"})} sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            
          </Paper>
       
      </div>
      
      </div>
      <Link className="btn btn-primary" to={"/internal/trainings-management/course/"+courseurl+"/"+courseid+"/addbatch"}>Add Batch</Link>
      
      </div>
      
       
 <div className="d-flex flex-wrap">
 {coursedata?
      <>
      {coursedata.map((batch,index)=>(
      
      <>



      
<div class="col-lg-4 p-2">
           <div class="shadow rounded p-3 hover-active">
           <div class="d-flex justify-content-between">
            <p><b class="text-primary"> Batch ID : </b> {batch.batch_id}</p>
              <div class="d-flex  ">
                  <Chip  className='mr-2 bg-success text-white' size='small' label={batch.course_status} />

                  <Dropdown className="options-dropdown">   
        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
        <MoreVert className="text-dark"/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Dropdown.Item type="button"  onClick={()=>updateBatchStatus({cid:batch.course_id,bid:batch.batch_id,bstatus:'active'})} ><PlayArrow className="mr-1"/>Activate Batch</Dropdown.Item>
         <Dropdown.Item type="button"  onClick={()=>updateBatchStatus({cid:batch.course_id,bid:batch.batch_id,bstatus:'pause'})} > <PauseBtn className="mr-2"/>Pause Batch</Dropdown.Item>
         <Dropdown.Item type="button"  onClick={()=>updateBatchStatus({cid:batch.course_id,bid:batch.batch_id,bstatus:'completed'})} ><Check className="mr-1"/>Mark Completed</Dropdown.Item>
       
       
<div className="mb-2">
<Link className="text-dark" to={"/internal/add-assignment/"+courseurl+"/"+batch.batch_id} >
<p className='pb-0 mb-0'> <AddBox className="mr-1"/>Add Assignment</p></Link>

       </div>

<div className="mb-2">
<Link className="text-dark"  to={"/internal/trainings-management/courses/"+courseurl+"/"+batch.batch_id+"/assignments"} >
<p className='pb-0 mb-0'> <EyeFill className="mr-2"/>View Assignments</p>

</Link>


       </div>
      
        
       <div className="mb-2">
       <Link className="text-dark"  to={"/internal/trainings-management/"+courseurl+"/"+batch.batch_id+"/schedules"}>
       <Schedule className="mr-1"/> Class Schedules
           </Link>
       </div>

       <div className="">
<Link className="text-dark w-100 " to={"/internal/trainings-management/courses/assignment-submission/"+courseurl+"/"+batch.batch_id}   >
<p  className='pb-0 mb-0'> < FileUploadIcon className="mr-2" />Task submission</p>

</Link>

       </div>
      


       <Dropdown.Item type="button" onClick={()=>DeleteBatch({id:batch.id})} > <Delete className="mr-1"/>Delete Batch</Dropdown.Item>
       

          </Dropdown.Menu>
      </Dropdown>
      
                
              </div>
           </div>
           <p><b class="text-secondary">Course starts :</b> {batch.registration_ends}</p>
           <div class="d-flex justify-content-between align-items-center">
       <div className="d-flex align-items-center">
       <Clock className='mr-1 mb-1'/>
            <p class="m-0"><b class="text-secondary">Timings :</b>{batch.class_starts} - {batch.class_ends}</p>
       </div>
       <div>
        <p className=''><b>mode:</b>{batch.mode}</p>
       </div>
           </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-end">
            
            {batch.students!==null&&batch.students!=="null"?
            <>
             <AvatarGroup  className="justify-content-end mini mt-3 ">
            {batch.students.map((student)=>(
              
              <>
                <Avatar className="avatar-mini" alt={student.first_name} src={student.profile_pic} />
         
              
              </>))}
            
                 </AvatarGroup><p class="mb-0"></p>
            </>:
            <>
            </>}
           
            </div>
           <div class="d-flex align-items-end">
              <Chair/>
              <p class="m-0 pl-1"><b class="text-secondary">Seats left : </b>{batch.seats_left}</p>
           </div>
           </div>
            </div>
          </div>
      <div key={index} className="container-fluid d-none border p-2 hover-active sm-p-15  mt-2 mb-2">
        <div className="flex space-between">
      <div className="col-11 text-primary p-0 flex">
      <p className="mr-3"><b>Batch ID : </b>{batch.batch_id}</p>
      
      <p><b>Course Starts : </b>{dateFormat(batch.registration_ends, "dS mmm yy ")}</p>
      
      </div>
      <div>
      <Dropdown className="options-dropdown">   
        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
        <MoreVert className="text-dark"/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Dropdown.Item  onClick={()=>updateBatchStatus({cid:batch.course_id,bid:batch.batch_id,bstatus:'active'})} >Activate Batch</Dropdown.Item>
         <Dropdown.Item onClick={()=>updateBatchStatus({cid:batch.course_id,bid:batch.batch_id,bstatus:'pause'})} >Pause Batch</Dropdown.Item>
         <Dropdown.Item onClick={()=>updateBatchStatus({cid:batch.course_id,bid:batch.batch_id,bstatus:'completed'})} >Mark Completed</Dropdown.Item>
       
          

<div className="mb-2">
<Link  to={"/internal/add-assignment/"+courseurl+"/"+batch.batch_id} >
<p>Add Assignment</p></Link>
       </div>
        

<div className="mb-2">
<Link to={"/internal/trainings-management/courses/"+courseurl+"/"+batch.batch_id+"/assignments"} >
<p>View Assignments</p>

</Link>
       </div>
        
       <div className="">
       <Link to={"/internal/trainings-management/"+courseurl+"/"+batch.batch_id+"/schedules"}>
           Class Schedules
           </Link>
       </div>
      


       <Dropdown.Item onClick={()=>DeleteBatch({id:batch.id})} >Delete Batch</Dropdown.Item>
       

          </Dropdown.Menu>
      </Dropdown>
      
      </div>
        </div>
      
        <div className="flex space-between">
       <div>

       <p><b>Total Students  : </b>{batch.course_students}</p>
       <p><b>Seats Left  : </b>{batch.seats_left}</p>
       
      </div>
   
      <div className="">
      <p><b>Class Timings : </b>{batch.class_starts} -  {batch.class_ends}</p>
     
      <Chip type="button" size="small" className="bg-warning" label={batch.course_status}/>
       </div>
        </div>
      
    
      </div>
      
      </>
      
      ))}
      </>:
      <>
      No Batches
      </>}
 </div>
      
      </div>
      
      </>
      
      
      
      
       
    )
}

export default AdminCourseBatches

import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
const ProgressOverlay = () => {
  return (
    <div className="progress-overlay">
        
        
   <CircularProgress/>

       </div>
  )
}

export default ProgressOverlay
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { CircularProgress, Snackbar } from "@material-ui/core";
import ENDPOINT from "../../../context/EndPoint";
import axios from "axios";
import SecurityHeaders from "../../../context/SecurityHeaders";
const EvaluateTask = ({ id }) => {
  const { user } = useContext(AuthContext);

  const [title, settitle] = useState(null);
  const [description, setdescription] = useState(null);
  const [assignedto, setassignedto] = useState(null);
  const [reference, setreference] = useState(null);
  const [deadline, setdeadline] = useState(null);
  const [marks, setmarks] = useState(null);
  const [earnedmarks, setearnedmarks] = useState(null);
  const arr = [];
  const [corrections, setcorrections] = useState(arr);
  const [assignedby, setassignedby] = useState(null);
  const [taskstatus, settaskstatus] = useState(null);
  const [deadline2, setdeadline2] = useState(null);
  const [account, setaccount] = useState(null);
  useEffect(() => {
    const getaccount = async () => {
      const data = new FormData();
      data.append("session", user.SessionId);
      data.append("id", id);

      const res = await axios.post(
        `${ENDPOINT}evaluate-team-task.php`,
        data,
        SecurityHeaders
      );

      if (res) {
        if (res.data.status === "success" && res.data.data != null) {
          const data = res.data.data;
          settitle(data.title);
          setdescription(data.description);
          setreference(data.reference);
          settaskstatus(data.status);
          setassignedby(data.assignedby);
          setassignedto(data.assignedto);
          setdeadline(data.deadline);
          setmarks(data.marks);
          setearnedmarks(data.marks_received);
          setdeadline2(data.deadline2);
        } else {
        }
      } else {
      }

      // fetch(`${ENDPOINT}admin-single-task.php?session=${user.SessionId}&id=${id}`)
      //   .then(r=>r.json())
      //   .then(result=>{

      //  if(result){

      // if((result[0].status==="success")&&(result[0].data!=null)){
      //     const data = result[0].data;
      //  settitle(data.title)
      //  setdescription(data.description)
      //  setreference(data.reference)
      //  settaskstatus(data.status)
      //  setassignedby(data.assignedby)
      //  setassignedto(data.assignedto)
      //  setdeadline(data.deadline)
      //  setmarks(data.marks)
      //  setearnedmarks(data.marks_received)
      //  setdeadline2(data.deadline2);

      // }else {

      // }

      //  }
      //   else{

      //   }

      //   })
    };

    if (user !== null || user !== "null") {
      getaccount();
    }
  }, [user]);

  const changeTitle = (e) => {
    settitle(e.target.value);
  };
  const changeDescription = (e) => {
    setdescription(e.target.value);
  };
  const changeAssignto = (e) => {
    setassignedto(e.target.value);
  };
  const changeMaxMarks = (e) => {
    setmarks(e.target.value);
  };
  const changeMarksGiven = (e) => {
    setearnedmarks(e.target.value);
  };
  const changeCorrections = (e) => {
    setcorrections(e.target.value);
  };
  const changeReference = (e) => {
    setreference(e.target.value);
  };
  const changeDeadline = (e) => {
    setdeadline(e.target.value);
  };
  const changeStatus = (e) => {
    settaskstatus(e.target.value);
  };
  const [status, setstatus] = useState(false);

  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    setstatus(true);

    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("status", status);
    data.append("corrections", corrections);
    data.append("marks", marks);
    data.append("earned_marks", earnedmarks);
    data.append("id", id);
    data.append("deadline", deadline + " " + time);
    data.append("reference", reference);
    data.append("session", user.SessionId);
    const res = await axios.post(ENDPOINT + "admin-evaluate-task.php", data, {
      headers: { "content-type": "form-data/multipart" },
    });

    if (res) {
      if (res.data[0].status === "success") {
        setstatus(false);
        setsnackbarMessage("Changes Saved Successfully");
        setsnackbar(true);
      } else {
        setstatus(false);

        setsnackbarMessage("Something went wrong");
        setsnackbar(true);
      }
    }
  };

  const [time, settime] = useState(null);

  const [date, setdate] = useState(null);

  return (
    <div>
      {/*account form*/}
      {title ? (
        <>
          <div className="flex-wrap ">
            <div className="w-100">
              <form onSubmit={onSubmit}>
                <div class="form-row w-100">
                  <Snackbar
                    className="bg-success text-light"
                    open={snackbar}
                    autoHideDuration={6000}
                    onClose={() => setsnackbar(false)}
                    message={snackbarMessage}
                  />

                  <div className="form-row w-100">
                    <div class="form-group col-12">
                      <label for="inputPassword4">Marks Given</label>
                      <input
                        type="number"
                        class="form-control "
                        id="inputPassword4"
                        value={earnedmarks}
                        onChange={changeMarksGiven}
                        maxLength={3}
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group mt-2  w-100">
                  <label for="inputPassword4">Corrections</label>
                  <textarea
                    type="text"
                    class="form-control"
                    id="inputPassword4"
                    value={corrections}
                    onChange={changeCorrections}
                    maxLength={500}
                  />
                </div>

                <div class="form-group"></div>
                <button
                  type="submit"
                  class="btn mt-3 btn-primary w-100"
                  onClick={onSubmit}
                >
                  {status ? (
                    <CircularProgress className="text-white" size={20} />
                  ) : (
                    ""
                  )}
                  Submit
                </button>
              </form>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
   
    </div>
  );
};

export default EvaluateTask;

import React from 'react'
import {  Navbar} from 'react-bootstrap'
import userdp from '../../assets/bg/bhuvanasri.jpg'
import { Link } from 'react-router-dom'
import searchicon from '../../assets/svg/SearchIcon.svg'
import NavBarTitles from './NavBarTitles'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { Modal} from 'react-bootstrap'
import { AuthContext } from '../../context/AuthContext'
import AccountOptions from '../../pages/account/AccountOptions'
import ReactGA from 'react-ga'

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import { NotificationsActiveRounded } from '@material-ui/icons'
import { Badge } from '@material-ui/core'
import BottomNavigationBar from './BottomNavigationBar'

const TeamNavbar = ({props,account,unread}) => {


 const {user} = useContext(AuthContext);


  const [showSearch, setshowSearch] = useState(false);
      
   
        const closeSearch = () => setshowSearch(false);




        useEffect(()=>{
       
    
          const  getGA = async ()=>{
        
            
              
ReactGA.initialize('UA-166108797-3');
ReactGA.pageview(window.location.pathname);

                  
         
              
              }    ;
      getGA();
             
      }
      
      ,[])
      
      
      


   
const [menustatus,setmenustatus] = useState(false);

const togglemenu=()=>{
  var sidebar = document.getElementById("sidebar");
  if(sidebar.classList.contains("sidebardisplay")){
setmenustatus(false)
    sidebar.classList.remove("sidebardisplay");
  }
  else{
    setmenustatus(true)
    sidebar.classList.add("sidebardisplay");
  
  }

  



}









const [script,setscript] = useState(null)

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(()=>{
if(transcript!==null){

  setscript(transcript)


}


  },[transcript])



  useEffect(()=>{

    if(!listening&&transcript!==null){

      if(transcript==="send email"){
    
       // // console.log("sending email")
      }
      else if(transcript==="open payments"){
        window.location.replace("/internal/sales-summary")
      }
      else if (transcript==="open users management"){
        window.location.replace("/internal/student-management")
     
      }
    
    
    
    }
  },[listening])


  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
 










    return (
        
        <Navbar class="primary-nav SlideInFromLeft" bg="light" expand="lg" >
        <div className="container-fluid">

<NavBarTitles />

{/*
<div className="search flex border-1 align-items-center sm-d-none">
<Search className="text-dark"/>
<input type="text" placeholder="Search..." className="bg-transparent text-dark" onClick={openSearch}></input>

</div>



*/}






            <Modal show={showSearch} className="search-modal" onHide={closeSearch} animation={false}>
             
              
                <div className="flex search-container align-items-center">
<input className="w-100 p-10" type="text" placeholder="Search courses, internships, workshops, profiles..."></input>

<img className="search-icon" src={searchicon} alt=""></img>
                </div>
<div className="text-center mt-20 mb-20">
<p>No Recent Searches</p>

</div>


            </Modal>


       

           <div class="navbar-options align-center">
           <div class="nav-items">


          
           <p id="version" >1.02</p>



     


           <Link to="/">Home</Link>
{user!==null?     

<>


<p>Microphone: {listening ? 'on' : 'off'}</p>
      <button className="animated-button1 ab2" onClick={()=>SpeechRecognition.startListening()}>Activate AI</button>
      <button className="animated-button1 ab1" onClick={SpeechRecognition.stopListening}>Stop AI</button>
      <button className="btn btn-danger" onClick={resetTranscript}>Reset</button>
      <p  >{transcript}</p>
      <p>{script}</p>

</>

:""}

        {account?
      <>
      

         
      </>  
      :null}
       

           </div> 
         {/*  <img className="search-icon" type="button" onClick={openSearch} src={searchicon} alt="search"></img>*/}
 {user?
"" 
    :
    
    <div className="nav-signup btn ml-5 ">
      
      
      
      <Link to="/login" class="animated-button1">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <svg className="mr-2" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path></svg>
     
  Login / SignUp
</Link>
    </div>
    
    }
        
          {account?
      

<AccountOptions userdp={userdp} account={account} type="button" />
           :
           <Link to="/login" className="btn d-sm-none btn-danger">Login/SignUp</Link>     
        }
           
        <div className="d-block d-sm-block d-md-none">
        {unread?
      <Link to="/notifications">
     
    <Badge type="button" badgeContent={unread} color="secondary">
    <NotificationsActiveRounded />
</Badge>
</Link>
: <Link to="/notifications"><NotificationsActiveRounded /></Link>  
    }
        </div>
           <div class="toggle-menu mb-2" type="button" onClick={togglemenu}>
         
          
         {menustatus?
        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="CloseIcon" tabindex="-1" title="Close"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
         :
         
         <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="MenuIcon" tabindex="-1" title="Menu"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
         }
           </div>
           </div>
         
        </div>

        <BottomNavigationBar/>
      </Navbar>
      
    )
}

export default TeamNavbar

import React, { useContext } from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'
import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'
import dateFormat from "dateformat";
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import ENDPOINT from '../../context/EndPoint'
import SubmitInternshipTask from './SubmitInternshipTask'
import { AuthContext } from '../../context/AuthContext'

import nomeetings from '../../assets/internal-meeting.svg'

const InternMeetings = ({account,timeline}) => {
     useEffect(() => {
          window.scrollTo(0, 0)
         
        }, [])
      
const {user} = useContext(AuthContext)
     const params = useParams();
     const internship_id = params.internshipid;
    // // console.log("internship id"+internship_id);

     useEffect(() => {
          window.scrollTo(0, 0)
          getTasks();
          CheckWorkHours()
        }, [])

const [tasks,settasks] = useState(null)

const [status,setstatus] = useState("all");

     const getTasks = async (status)=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          

          formData.append('internship_id',internship_id)
          
          const res = await axios.post(ENDPOINT+"internship-meetings.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
        //   // console.log('internship meetings are '+JSON.stringify(res.data.status))
           if(res.data.status==="success"){
            settasks(res.data.meetings)
           }
           else{
          
           }
          }
          }
const [workhours,setworkhours] = useState(true)
          const CheckWorkHours= async ()=>{
        

               const formData = new FormData();
               
               
               //formData.append('whyus',document.getElementById('whyus').value)
               
               formData.append('session',user.SessionId);
               
     
               formData.append('internship_id',internship_id)
               
               const res = await axios.post(ENDPOINT+"check-work-hours.php", formData,{
                   headers: {
                       'content-type': 'multipart/form-data'
                   }
               });
               if(res){
              // console.log('status check '+JSON.stringify(res.data))
                if(res.data.status==="success"){
                 setworkhours(res.data.work_hours)
                }
                else{
                    setworkhours(false)
           
                }
               }
               }
          
const openSubmission = ()=>{
     setsubmit(true)
}

const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)

const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
       
     return (
          <>
           <>
              



<Modal
  open={submit}
  onClose={()=>{setsubmit(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
   <>
   <div className="flex space-between">
         <h5>Submit Task</h5> 
    </div>

    <SubmitInternshipTask id={taskid}/>
   </>
  </Box>
</Modal>





























                
<div className="">

<div className=" mb-3 flex container-fluid space-between p-3  align-items-center">
<div className=" sm-p-15">
<h4 className="sm-22">Team Meetings</h4>
</div>


</div>




<div className="sm-p-15">

{tasks?
<>

{tasks.map((task)=>(
<>


<div className="container-fluid">
     <div className="border br-5 p-3">
          <h5 className="sm-18 text-primary">{task.title}</h5>
          <hr/>
         
          <div className="flex text-secondary">
               <div className="col-4">
<h6 className="sm-14">Timings</h6>
<p className="sm-14 mb-0">{task.timings}</p>
               </div>

               <div className="col-4">
<h6 className="sm-14">Date</h6> 
<p className="sm-14 mb-0">{dateFormat(task.date,"dS mmm  HH:MM tt")}</p>
               </div>

               <div className="col-4">
<h6 className="sm-14">Status</h6>
<p className="sm-14 mb-0">{task.status}</p>
               </div>


              
          </div>
    
         <div className="flex space-between mt-3">
          <span></span>
              <a href={task.meeting_link} rel="noreferrer" target="_blank" className="btn btn-primary rounded-pill">Join Meeting</a>
      </div>
     </div>
</div>





</>


))}


</>
:

<>
<div className="container-fluid">


<div className="justify-center">
<img src={nomeetings} className="w-75"/>

</div>
<div className="text-center mt-3">
<h4>No Meetings</h4>
<p>You donot have any meetings scheduled</p>

</div>
</div>

</>
}


</div>






</div>   




              </>
          </>
     )
}

export default InternMeetings

import MailIcon from "@mui/icons-material/Mail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Avatar, TextField } from "@material-ui/core";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import { AuthContext } from "../../../context/AuthContext";
import InternshipItemSkeleton from "../../../skeleton/InternshipItemSkeleton";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import { Container, Dropdown, Modal } from "react-bootstrap";
import { Email, MoreVert, Phone } from "@material-ui/icons";
import ENDPOINT from "../../../context/EndPoint";
import UserActivity from "./UserActivity";
import StudentProfile from "./StudentProfile";
import BasicTable from "../../../components/table/BasicTable";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import LoadingTemplate from "../../../themes/LoadingTemplate";
import LeadsStatistics from "../leads/LeadsStatistics";
import UsersStatistics from "./UsersStatistics";
const UsersList = ({ page, permission }) => {
  const { user } = useContext(AuthContext);
  const userdata = user;

  const [category, setcategory] = useState("all");

  const [query, setquery] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState("start");

  useEffect(() => {
    getUsers();
  }, [category]);

  const [loading, setloading] = useState(true);

  const getUsers = async (status) => {
    setinternships("start");
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    if (category !== null) {
      formData.append("status", category);
    }

    if (query !== null) {
      formData.append("query", query);
    }

    const res = await axios.post(ENDPOINT + "admin-get-users.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      setloading(false);
      setquery(null);

      if (res.data.status === "success") {
       
        setResponse(res.data.data);
        setInititalData(res.data.data)
      } else {
      }
    } else {
      setloading(false);
    }
  };

  const [AddPoints, setAddPoints] = useState(false);

  const showAddPoints = () => {
    setAddPoints(true);
  };

  const closeAddPoints = () => {
    setAddPoints(false);
  };

  const ChangeAccountStatus = async ({ user_id, status }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_id", user_id);
    formData.append("status", status);

    const res = await axios.post(
      ENDPOINT + "admin-change-account-status.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
    }
    if (res.data.status === "success") {
      settoasttitle(res.data.title);
      settoastmessage(res.data.message);
      settoast(true);
      getUsers();
    } else {
      settoasttitle(res.data.title);
      settoastmessage(res.data.message);
      settoast(true);
    }
  };

  const DeleteUser = async ({ user_id }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_id", user_id);
    if (query) {
      formData.append("query", query);
    }

    const res = await axios.post(ENDPOINT + "admin-delete-user.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res) {
      // console.log(" deleted "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      settoasttitle("User Deleted !");
      settoastmessage("User account successfully deleted");
      settoast(true);
      getUsers();
    } else {
      settoasttitle("Something went trong !");
      settoastmessage("Account deletion failed . Please try after sometime .");
      settoast(true);
    }
  };

  const SendCode = async ({ user_email }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_email", user_email);

    const res = await axios.post(ENDPOINT + "admin-send-otp.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res) {
    }
    if (res.data.status === "success") {
      settoasttitle("OTP Sent !");
      settoastmessage("OTP has been successfully sent .");
      settoast(true);
      // console.log("deleted  "+user_email)
      getUsers();
    } else {
      settoasttitle("Something went wrong !");
      settoastmessage("Please try after sometime");
      settoast(true);
    }
  };

  const [currentuser, setcurrentuser] = useState(null);
  const [activity, setactivity] = useState(false);

  const userActivity = ({ userdata }) => {
    setcurrentuser(userdata);
    setactivity(true);
  };

  const userProfile = ({ userdata }) => {
    setcurrentuser(userdata);
    setprofile(true);
  };
  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);
  const [profile, setprofile] = useState(false);


  const [initialData,setInititalData] = useState(null)
  
  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.email.toLowerCase().includes(term) ||
        item.phone_number.includes(term) ||
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };





  return (
    <>
      <div className="position-fixed bottom-60 left-0 w-100 justify-center">
        <Toast
          className="bg-warning"
          onClose={() => settoast(false)}
          show={toast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>

      <Modal disableScrollLock show={AddPoints} onHide={closeAddPoints}>
        <Modal.Header closeButton>
          <Modal.Title>Add Points</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal
        className="container-fluid"
        size="xl"
        show={activity}
        onHide={() => setactivity(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>User Activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserActivity userdata={currentuser} />
        </Modal.Body>
      </Modal>

      <Modal
        className="container-fluid"
        size="xl"
        show={profile}
        onHide={() => setprofile(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudentProfile userdata={currentuser} />
        </Modal.Body>
      </Modal>

      <div className="container-fluid min-vh-100">
       

        <Container fluid className="mb-2">
      
      <div className="d-flex fixed-search">
      <select onChange={handleSearch}>
        <option value="">All Users</option>
        <option value="pending">Pending</option>
        <option value="active">Active</option>
      </select>
      <input
        placeholder="Search name, email phone "

        value={searchTerm}
        onChange={handleSearch}
      />
    
    </div>
            </Container>


<div className="container">
  <UsersStatistics/>
</div>


        <div className="  sm-p-0  container-fluid">
          {response === "start" ? (
            <div className="container-fluid">
              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />
            </div>
          ) : response ?<>
          
          {




<Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
  <TableRow>
    <TableCell>User</TableCell>
    <TableCell align="left"><Email></Email>Email</TableCell>
    <TableCell align="left"> <Phone></Phone>Phone</TableCell>
    <TableCell align="right">Joined</TableCell>
    <TableCell align="right">Status</TableCell>
    <TableCell align="right">Actions</TableCell>
  </TableRow>
</TableHead>
<TableBody>

{
 response.map((internship, index) => (
  <>


<TableRow
      key={index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
      <div className="flex align-items-center ">
          <Avatar src={internship.profile_pic} className="mr-2" />
          <div className="ms-2">
            <p className="mb-0">
              {internship.first_name + " " + internship.last_name}
            </p>
            <p className="mb-0">@{internship.username}</p>
          </div>
        </div>
      <div>

        
      </div>
      </TableCell>
      <TableCell align="right">
<div>

<div className="d-flex">
{internship.email}
</div>

  </div>

      </TableCell>
      <TableCell align="right">
<div>
<div className="d-flex">
 {internship.phone_number}
  
</div>

  </div>

      </TableCell>
      <TableCell align="right">{internship.create_datetime}</TableCell>
      <TableCell align="right">{internship.status}</TableCell>
      <TableCell align="right">

      <Dropdown className="options-dropdown">
        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
          <MoreVert className="text-dark" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {internship.status === "pending" ||
          internship.status === "inactive" ? (
            <>
              <Dropdown.Item
                onClick={() =>
                  ChangeAccountStatus({
                    status: "active",
                    user_id: internship.user_id,
                  })
                }
              >
                Activate Account
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  SendCode({ user_email: internship.email })
                }
              >
                Send Verification Code
              </Dropdown.Item>
            </>
          ) : (
            <>
              {internship.status === "active" ? (
                <>
                  <Dropdown.Item
                    onClick={() =>
                      ChangeAccountStatus({
                        status: "pending",
                        user_id: internship.user_id,
                      })
                    }
                  >
                    Mark Pending Verification
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() =>
                      ChangeAccountStatus({
                        status: "inactive",
                        user_id: internship.user_id,
                      })
                    }
                  >
                    Deactivate Account
                  </Dropdown.Item>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <Dropdown.Item
            onClick={() => userActivity({ userdata: internship })}
          >
            User Activity
          </Dropdown.Item>
          {parseInt(permission.delete_user) === 1 ? (
            <>
              <Dropdown.Item
                onClick={() =>
                  DeleteUser({ user_id: internship.user_id })
                }
              >
                Delete User
              </Dropdown.Item>
            </>
          ) : (
            <></>
          )}
        </Dropdown.Menu>
      </Dropdown>
      </TableCell>
    </TableRow>





    <div className="d-none hover container-fluid align-items-center space-between  p-1 br-5 ">
      <a
        target="_blank"
        className="col-lg-3 col-6"
        rel="noreferrer"
        href={"https://skillsuprise.com/u/" + internship.username}
      >
        <div className="flex align-items-center w-100">
          <Avatar src={internship.profile_pic} className="mr-2" />
          <div className="ms-2">
            <p className="mb-0">
              {internship.first_name + " " + internship.last_name}
            </p>
            <p className="mb-0">UID:{internship.user_id}</p>
          </div>
        </div>
      </a>

      <div className="col flex">
        <div>
          <div className="flex">
            <p className="mb-0 text-primary ">
              {internship.phone_number}
            </p>
          </div>
          <div className="flex">
            <p
              className={
                "d-block d-sm-none d-md-none d-lg-none mb-0 " +
                  internship.status ===
                "active"
                  ? "me-1 text-success"
                  : "me-1 text-danger"
              }
            >
              {internship.status}
            </p>

            <p className="mb-0 truncate text-truncate-1 d-none d-sm-block">
              {" "}
              {internship.email}
            </p>
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-5 d-none d-sm-block col-sm-4">
        <div className="d-flex">
          <b className="mb-0 d-none d-sm-block pb-0 mr-3">Status : </b>{" "}
          <p
            className={
              internship.status === "active"
                ? "mr-3 text-success ms-1"
                : "mr-3 text-danger ms-1"
            }
          >
            {" "}{internship.status}
          </p>
        </div>

        <div className="d-none d-sm-block">
          <p className="mb-0">{internship.create_datetime}</p>
          {internship.referred_by ? (
            <>
              <p className="mb-0 text-danger">
                Code Used: {internship.referred_by}
              </p>
            </>
          ) : null}
        </div>
      </div>

     
    </div>
  </>
))
}


</TableBody>
</Table>
           



           
          }
          </> : (
      <LoadingTemplate/>
          )}
        </div>
      </div>
    </>
  );
};

export default UsersList;

import { CircularProgress } from '@material-ui/core';
import React from 'react'
import styled from 'styled-components'

import { useState } from 'react'
import { useEffect } from 'react'
import {  useParams } from 'react-router'
import { useContext } from 'react'
import {  Toast } from 'react-bootstrap'
import { AuthContext } from '../../../context/AuthContext'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';

import { Business, Edit, EditLocation, Language, Lock, Phone } from '@material-ui/icons'
import ENDPOINT from '../../../context/EndPoint'
import { Stack } from '@mui/material'
import { Chip } from '@material-ui/core'
import axios from 'axios'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Form from 'react-bootstrap/Form'
import ListTags from './ListTags';
import InternalTemplate from '../../../themes/InternalTemplate';
const UploadVideo = (props) => {

const ref = useRef();
const reason = useRef();
const availability = useRef();
const modeofwork = useRef();
const workability = useRef();
var resume = useRef();
const whyus = useRef();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    



const params = useParams();
const id = params.id;
const title = params.title;
sessionStorage.setItem("iid",id);
const {user} =  useContext(AuthContext);
const userdata = user;


    const [application, setapplication] = useState(null);
const [companyinfo,setcompanyinfo] = useState(null)
const [more,setmore]= useState(false);
   const loginRedirect = ({redirect})=>{

sessionStorage.setItem("next",`/internship/${id}`)
sessionStorage.setItem("msg","Login to apply internship")
   }
    useEffect(()=>{
     
    
        const  getapplication = async ()=>{
    
            fetch(`${ENDPOINT}get-internship-details.php?id=${id}`)
            .then(r=>r.json())
            .then(result=>{
           
                //  // console.log("internshiinfo" + JSON.stringify(result))
                   if(result){
                   
                     if(result.status==="success"){

                     
                     
                    }
                    else{
                      setapplication(null)
                    }
                     }
                  
            })
            }    
    getapplication();
           
    }
    
    ,[])





const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

//code dump

const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)
const [file,setfile] = useState(null)
const [status,setstatus]= useState(false)
const [uploadprogress,setuploadprogress] = useState(0)
 const   UPLOAD_ENDPOINT = `${ENDPOINT}upload-video.php`;
   const [toast,settoast] = useState(false)
   const onSubmit = async (e)=> {
       setstatus(true)
        e.preventDefault() 
        let res = await uploadFile(file);
       
if(res){
// console.log(" response "+JSON.stringify(res))
}
        if(res.data.status==="success"&&res.data.message==="applied"){
          settoastmessage("Application Successful.")
          settoasttitle("You application has been successfully submitted ")
settoast(true)
setShow(false)
setuploadprogress(0)
          sessionStorage.setItem("iid",null);
          }else if(res.data.status==="success"&&res.data.message==="application_exists"){
            setuploadprogress(0)
            setShow(false)
           settoastmessage("You have already applied for this internship.")
            settoasttitle("Application Exists !")
            settoast(true)
          }else{
            setuploadprogress(0)
            setShow(false)
           settoastmessage("Please try again.")
            settoasttitle("Something went wrong !")
            settoast(true)
          }
    }
  const  onChange = (e)=> {
        setfile(e.target.files[0])
  
    }
   const uploadFile = async (file)=>{
    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //   // console.log("total "+progressEvent.total)
        setuploadprogress(percentCompleted)
    

      }
    }   
//// console.log("apply internship")
        const formData = new FormData();
        const user = JSON.parse(localStorage.getItem("user"));
        formData.append('session',user.SessionId);
        formData.append('resume',file);
        formData.append('title',"this is title")
        formData.append('description',"description")
        formData.append('duration',videolength)
       
        return  await axios.post(UPLOAD_ENDPOINT, formData,config,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
      }
    const [loginprompt,setloginprompt] = useState(false)


    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
 
      boxShadow: 24,
      p: 4,
    };






const [tags,settags] = useState([])
const [videolength,setvideolength] = useState(null);
const [currentlength,setcurrentlength] = useState(null)
const getDuration = ()=>{

     const video = document.getElementById("video");
     setvideolength((video.duration))

}
   
const updateTime = ()=>{
     const video = document.getElementById("video");
setcurrentlength(video.currentTime)

}
const TogglePlay = ()=>{

     const video = document.getElementById("video");
if(video.onPlaying){
     video.pause()
}
else{
     video.play();
}
}



const [currenttag,setcurrenttag] = useState(null)

const [display,setdisplay]= useState(false)
const updateTags = ()=>{
     tags.push(currenttag);
     setcurrenttag("")
     setdisplay(true)

}

const RemoveTag = ({index})=>{
    setdisplay(false)
          let data = tags; 
          data.splice(index,1);
          settags(data)
          settags(null)
          settags(data)
         
          setdisplay(true)
          // console.log(display + tags)
}
    return (

<>




<div className="position-fixed container-fluid justify-center bottom-60 left-0">

<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={5000} autohide>
    <Toast.Header>
      <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
      <strong className="me-auto">{toasttitle}</strong>

    </Toast.Header>
    <Toast.Body>{toastmessage}</Toast.Body>
  </Toast>

</div>



<Modal
  open={loginprompt}
  onClose={()=>setloginprompt(false)}
  aria-labelledby="modal-modal-title"

  aria-describedby="modal-modal-description"
>
  <Box   className="col-lg-4" sx={style}>
  

    <div className="text-center">

      <div className="flex space-between">
      <h4 className="sm-18">Login into your account to apply</h4>
  <CloseIcon type="button" className="text-danger" onClick={()=>setloginprompt(false)}/>
      </div>
      <hr/>
            <Link to="/login"  onClick={()=>{loginRedirect({redirect:"login"})}} className="btn btn-theme mt-3 mb-4">Login & Continue</Link>

        <div className="text-center">
            <p>Dont have an account ?<Link to="/signup" onClick={()=>{loginRedirect({redirect:"signup"})}} >Signup & Continue</Link> </p>
        </div>
    </div>
  
  </Box>
</Modal>





<InternshipApplicationStyled className="p-3 pb-0">


<div className=" mt-20 ">



<div className="flex-wrap">
<div className="col-lg-6"><video controls className="w-100 mx-auto" id="video"  onLoad={getDuration} onLoadedData={getDuration} src={file?URL.createObjectURL(file):null}></video>

<div className="container-fluid">
     Duration : {Math.round(parseInt(videolength)/60)+" mins"}
     <p>current : {currentlength}</p>
     </div> 
</div>
<div className="col-lg-6">

<form onSubmit={onSubmit} className="position-relative">
{file?
<>
<div className="justify-center">


</div>


        <input className="p-2 mb-3 w-100 form-control" id="whyus"  placeholder="Title" required></input>
      
       <textarea className="p-2 mb-3 w-100 form-control" id="reason" name="reason"  placeholder="Description" required></textarea>
       <div className="container-fluid">
           
           {tags&&display?
           <>

           

           {tags.map((tag,index)=>(

<>
<Chip key={tag} className="mr-1 btn  btn-danger" onClick={()=>RemoveTag({index})} label={tag}/>
</>

           ))}
           </>:null}

<div className="flex mt-2">
     <input placeholder="tag" value={currenttag} onChange={(e)=>setcurrenttag(e.target.value)}></input>
     <Chip className="text-white bg-primary" label="Add Tag" onClick={updateTags}></Chip>
     </div>   
           
           </div>  
       
</>:
<>

</>}
         
    

    
 
      <div className="justify-center">
      <input type="file" className="" placeholder="Photo/Video" onChange={onChange}/>
       
      </div>
       
              <br/>
              
                   <button className="btn btn-primary w-100 mt-2" type="submit">{status?<CircularProgress className="text-white " size={20}/>:""} {uploadprogress>0?
                 <>
                 Uploading {uploadprogress}%...
                 </>  
                 :"Upload Video"
                 }</button>
                 
                 
                
                 </form>




</div>


</div>















    </div>

  








        </InternshipApplicationStyled>
        

</>
           

    )
}

export default UploadVideo


export const InternshipApplicationStyled = styled.div`

`

import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react'

import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'

import ENDPOINT from '../../../context/EndPoint'

import { AuthContext } from '../../../context/AuthContext'

import {  Toast } from 'react-bootstrap'

const InternSendNotification = ({account,timeline,intern_id,close}) => {
    
      
const {user} = useContext(AuthContext)
     const params = useParams();
     const internship_id = params.internshipid;
   //  // console.log("internship id"+internship_id);




const [description,setdescription] = useState(null)

const [actionlink,setactionlink] = useState(null)
const [meetdescription,setmeetdescription]= useState(null)
const [title,settitle] = useState(null)
const [meetingtype,setmeetingtype]= useState("General Meeting")
const [currentinternship,setcurrentinternship] = useState(null)
     const scheduleMeeting = async ()=>{
        

          const formData = new FormData();
          
       
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          

          if(intern_id){
               formData.append('user_id',intern_id)
          }
         
      
          formData.append('action_link',actionlink)
         
          formData.append('title',title)
          formData.append('description',description)
          
          const res = await axios.post(ENDPOINT+"send-notification.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
     
           if(res.data.status==="success"){
                settoasttitle("Notification Sent")
               
                
        settoast(true)
           }
           else{
               settoasttitle("Something went wrong")
               settoast(true)
           }
          }
          }




          
const openSubmission = ()=>{
     setsubmit(true)
}

const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)

const [toast,settoast] = useState(false)


const [toastmessage,settoastmessage] = useState(null)
const [toasttitle,settoasttitle] = useState(null)












     





     return (
          <>
              <>
              

              <div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>



              <div className="flex space-between">
         <h5>Send Notification</h5> 
         <CloseIcon type="button" onClick={()=>setsubmit(false)}/>
    </div>
<div>
{intern_id}



<input onChange={(e)=>settitle(e.target.value)} className="form-control mt-3" placeholder="Title"></input>
<input  onChange={(e)=>setdescription(e.target.value)}  className="form-control mt-3" placeholder="Description"></input>

<input  onChange={(e)=>setactionlink(e.target.value)}  className="form-control mt-3" placeholder="Action"></input>
<button onClick={()=>scheduleMeeting()} className="btn btn-primary mt-5 w-100">Send Notification</button>
<div>

</div>



</div>
    






              </>
       
          </>
     )
}

export default InternSendNotification

import React, { useContext } from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import ENDPOINT from '../../../context/EndPoint'
import { AuthContext } from '../../../context/AuthContext'
import dateFormat from 'dateformat'
import Avatar from '@mui/material/Avatar';
const UserActivity = ({userdata}) => {

     const {user} = useContext(AuthContext)
const [activities,setactivities] = useState(null)

     const getTasks = async ()=>{
        

          const formData = new FormData();
          
          
          
          formData.append('session',user.SessionId);
          
         
            formData.append('user_id',userdata.user_id);
          
          
          const res = await axios.post(ENDPOINT+"admin-get-user-activity.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
             if(res.data.status==="success"){
          setactivities(res.data.data)
           }
           else{
          
           }
          }
          }




useEffect(()=>{
     getTasks();
},[])


     
  return (
    <div>
         

{activities?
<>
{activities.map((activity,index)=>(

<>
<div className="mb-1 mt-1 flex space-between align-items-center">
<div>
<h6  className="mb-0">{activity.page}</h6>
<p className="extra-small">{activity.description}</p>
</div>
<p className="mb-0">{dateFormat(activity.date_time,"dS mmm yy hh:mm tt")}</p>
</div>

</>

))}
</>
:

<>

<div className="container-fluid text-center mb-5 mt-5">

<div className="flex">
<Avatar src={userdata.profile_pic}/>
<div>
<h5>{userdata.first_name+" "+userdata.last_name}</h5>
<p>{userdata.email}</p>
  </div>
</div>
<p>No User Activity</p>


</div>

</>
}



    </div>
  )
}

export default UserActivity
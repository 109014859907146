import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import DashboardLoadingTeplate from '../../themes/DashboardLoadingTemplate'
import { useParams } from 'react-router'
import TextField from '@mui/material/TextField';
import ENDPOINT from '../../context/EndPoint'
import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import axios from 'axios'
import DeleteIcon from '@mui/icons-material/Delete';
import toImg from 'react-svg-to-image';
import SecurityHeaders from '../../context/SecurityHeaders'
import AdminDeleteQuiz from '../../actions/quiz/AdminDeleteQuiz'
const InternalMockTestsManagement = ({test_type}) => {
const params = useParams();
const course_url = params.course_url
const {user} =  useContext(AuthContext)

    const [info,setinfo] = useState(null);

    


    useEffect(()=>{
       
    
       
    getQuizzes();
       
    }
    
    ,[])
    
    const  getQuizzes = async ()=>{

const data = new FormData()
data.append("session",user.SessionId)
data.append("course_url",course_url)
data.append("test_type",test_type)
     const res = await axios.post(ENDPOINT+"mock-test-categories.php",data,SecurityHeaders)

     if(res){
console.log("test")

          if(res.data){
           
               if(res.data.status==="success"){

console.log("test data")
                    setposts(res.data.data)
               }
          }
         
         
        
     }
     
     }    ;
    
  
    const [account,setaccount] = useState(null);
  const [role,setrole] = useState(null);


    
    
    const [question,setquestion] = useState(null)
    
    
    const [option1,setoption1] = useState(null)
    const [option2,setoption2] = useState(null)
    
    const [option3,setoption3] = useState(null)
    
    const [option4,setoption4] = useState(null)
    const [answer,setanswer] = useState(null)
    
    
    const [posts,setposts] = useState(null)
    
    

const [allcomments,setallcomments] = useState(null)

const AddQuiz = async (e)=>{
     e.preventDefault()
const data = new FormData()
data.append("title",title)
data.append("description",description)
data.append("test_code",testcode)
data.append("duration",duration)
data.append("test_type",test_type)
data.append("course_url",course_url)
data.append("demo_certificate",certificateimage)
data.append("session",user.SessionId)
     const res = await axios.post(ENDPOINT+"add-test-topic.php",data,SecurityHeaders)

     if(res){
          console.log(" quiz "+JSON.stringify(res))
          if(res.data.status==="success"){
getQuizzes()
          }
        
     }
}


const DeleteQuiz = async ({quiz_id})=>{
     const res = await AdminDeleteQuiz({
          payload:{
               user:user.SessionId,
               quiz_id:quiz_id
          }
     })
     if(res.status==="success"){
          getQuizzes()
     }
}

const [answer1,setanswer1] = useState(false)
const [answer2,setanswer2] = useState(false)
const [answer3,setanswer3] = useState(false)
const [answer4,setanswer4] = useState(false)



useEffect(()=>{
     if(answer1){
          setanswer("option1")
          setanswer2(false)
          setanswer3(false)
          setanswer4(false)
     }
     if(answer2){
          setanswer("option2")
          setanswer1(false)
      
          setanswer3(false)
          setanswer4(false)
     }
     if(answer3){
          setanswer("option3")
          setanswer1(false)
          setanswer2(false)
     
          setanswer4(false)
     }
     if(answer4){
          setanswer("option4")
          setanswer1(false)
          setanswer2(false)
          setanswer3(false)
        
     }
     console.log("answer is "+answer)
},[answer1,answer2,answer3,answer4])



const ConvertImage = ()=>{
     toImg('svg', 'name', {
          scale: 3,
          format: 'webp',
          quality: 0.01,
          download: false,
          ignore: '.ignored'
        }).then(fileData => {
          //do something with the data
          console.log(fileData)
        });
}
useEffect(()=>{
getQuizzes()
},[])



const DeleteTestTopic = async({exam_code})=>{
     const data = new FormData()
     data.append("course_url",course_url)
     data.append("test_code",exam_code)
     const res = await axios.post(ENDPOINT+"delete-test-topic.php",data,SecurityHeaders)

if(res){
     if(res.data){
          if(res.data.status==="success"){


               getQuizzes()
          }
     }
}

}

const [certificateimage,setcertificateimage] = useState(null)
const [title,settitle] = useState(null)
const [description,setdescription] = useState(null)
const [duration,setduration] = useState(null)
const [testcode,settestcode] = useState(null)
    return (

<>




<div className="flex justify-content-between">
<div className="col-lg-7">
<h4>{posts?posts.length:0} Topics</h4>

<div className="overflow-y vh-90 p-3">

{posts?
<>
{posts.map((quiz)=>(
     <>
     <div className="border br-4 mb-3">
      <div className="d-flex w-100 justify-content-between">
      <Link to={"/"+test_type+"/"+course_url+"/test-code/"+quiz.test_code} className=" w-100 p-3">
   
     <h6>#{quiz.test_code} :{quiz.course_url}</h6>
     </Link>


     
     <DeleteIcon className="click" onClick={()=>DeleteTestTopic({exam_code:quiz.test_code})}/>
     </div>




     </div>

     </>
))}
</>:null}
</div>

</div>

<div className="col-lg-4 pt-2">

{test_type==="course-certification"?
<>
<h5>Add Certification Exam Paper</h5>
</>:
<>
<h5>Add Quiz Paper</h5>
</>}
<form className="" onSubmit={(e)=>AddQuiz(e)}>


<div className=" mt-3">
{test_type==="course-certification"?
<>
<TextField
 className="mb-2 w-100"
  label="Demo Certificate"

  required
  onChange={(e)=>{settitle(e.target.value);}}
/>

</>:
<>
</>}
<TextField
 className="mb-2 w-100"
  label="Title"

  required
  onChange={(e)=>{settitle(e.target.value);}}
/>
</div>


<div className="d-flex">

<TextField
 className="mb-2 w-100"
  label="Description"

  required
  onChange={(e)=>{setdescription(e.target.value);}}
/>
</div>


<div className="d-flex">

<TextField
 className="mb-2 w-100"
  label="Duration in mins"

  required
  onChange={(e)=>{setduration(e.target.value);}}
/>
</div>

<div className="d-flex">

<TextField
 className="mb-2 w-100"
  label="Test code"

  required
  onChange={(e)=>{settestcode(e.target.value);}}
/>
</div>






<button type="submit" className="btn btn-primary w-100">Add Test Paper</button>


</form>
</div>


</div>





</>
    )
}

export default InternalMockTestsManagement








import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import ENDPOINT from '../../../context/EndPoint'
import SecurityHeaders from '../../../context/SecurityHeaders'
import dateFormat from 'dateformat'
import { DataGrid } from '@mui/x-data-grid';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { red } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
import CustomDropdown from '../../../components/dropdowns/CustomDropdown'
import { Dropdown } from 'react-bootstrap'
import { Delete, Edit, Unarchive } from '@material-ui/icons'
const CertificateManagement = ({permission,userdata,account}) => {

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'firstName', headerName: 'First name', width: 130 },
        { field: 'lastName', headerName: 'Last name', width: 130 },
        {
          field: 'age',
          headerName: 'Age',
          type: 'number',
          width: 90,
        },
        {
          field: 'fullName',
          headerName: 'Full name',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 160,
          valueGetter: (params) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
      ];
      
      const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
      ];
      

    const [xdirection,setxdirection] = useState(0)
    const [idcode,setidcode] = useState(null)
    const AlignName = ({direction})=>{
      const name = document.getElementById("NAME")
    const certificate = document.getElementById("certificate")
    
    if(direction==="left"){
    name.setAttribute("x",xdirection-5)
    setxdirection(xdirection-5)
    
    }else{
      name.setAttribute("x",xdirection+5)
     setxdirection(xdirection+5)
    }
     setcertificate(certificate.innerHTML)
    }
    
    
    const AlignCourse = ({direction})=>{
      const role = document.getElementById("role")
    const idcard = document.getElementById("idcard")
    
    if(direction==="left"){
    role.setAttribute("x",xdirection-20)
    setxdirection(xdirection-20)
    
    }else{
      role.setAttribute("x",xdirection+20)
     setxdirection(xdirection+10)
    }
     setidcode(idcard.innerHTML)
    }

const GetCertificate = async ({payload})=>{
    const data = new FormData()
    data.append("start_date",dateFormat(startdate,"dS mmm yy"))
    data.append("end_date",dateFormat(enddate,"dS mmm yy"))
    data.append("program","Wer")
    data.append("name",name)
    data.append("id",id)
    data.append('gender',gender)
    data.append("department",department)
    data.append("role","Internship")
    data.append("certificate_code",certificate)
    if(payload.save){
        data.append("save",true)
    }
    data.append("issued_date",dateFormat(issueddate,"dS mmm yyyy"))
    const res = await axios.post(ENDPOINT+"certificate-template.php",data,SecurityHeaders)
    if(res){
        console.log(res)
        setcertificate(res.data)
        const certificate = document.getElementById("certificate")
        certificate.innerHTML = res.data
    }
}


const [certificatetype,setcertificatetype] = useState("training")

const [type,settype] = useState("internship")
const [gender,setgender] = useState("male")
const [startdate,setstartdate] = useState(null)
const [enddate,setenddate] = useState(null)
const [issueddate,setissueddate]  = useState(null)
const [department,setdepartment] = useState(null)
const [certificate,setcertificate] = useState(null)
const [id,setid] = useState(null)
const [name,setname] = useState(null)
const [certificates,setcertificates] = useState(null)
const {user} = useContext(AuthContext)
const GetCertificates = async ()=>{


    const data = new FormData()
    data.append("session",user.SessionId)
const res = await axios.post(ENDPOINT+"admin-get-certificates.php",data,SecurityHeaders)

if(res){

if(res.data.status==="success"){

    setcertificates(res.data.certificates)

}
}

}
useEffect(()=>{

GetCertificates()
},[])
  return (
    <div>
        
        <div className="container">

        



<hr></hr>
<div className="d-flex justify-content-between">

<div className="col-lg-8">

{certificates?
<>
<Table>
                           <TableHead>
  <TableRow>
  <TableCell align='left'>Certificate ID</TableCell>
    <TableCell >Name</TableCell>
    
   
    <TableCell align="left">Category</TableCell>

    <TableCell align='left'>Status</TableCell>
    <TableCell align="left">Issued On</TableCell>
    <TableCell align="center">Actions</TableCell>
  </TableRow>
</TableHead>
<TableBody>

    
{certificates.map((item)=>(
    
    <>

    <TableRow
           
           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
         >
           <TableCell align="left">{item.certificate_id}</TableCell>
       
           <TableCell align="left">{item.name}</TableCell>
           <TableCell align="left">{item.certificate_type}</TableCell>
           <TableCell align="left">{item.status}</TableCell>
           
           <TableCell align="left">{item.issued_date}</TableCell>
           
           <TableCell align="center"><CustomDropdown>
            
            
            </CustomDropdown>
       <Dropdown.Menu>
  <>
  <Dropdown.Item><Edit/> Edit</Dropdown.Item>
            <Dropdown.Item><Unarchive/> Invalidate</Dropdown.Item>
            <Dropdown.Item><Delete/> Delete</Dropdown.Item>
  </>
       </Dropdown.Menu>
            </TableCell>
         </TableRow>
    
    
    </>))}


</TableBody>
            </Table>

</>:
<>

</>}

</div>

<div className="col-lg-4">
<div className="container d-flex flex-wrap">

<div className="col-lg-6 p-2">
  <div className="shadow p-3">
    <h4>34</h4>
    <p className="m-0">All Certificates</p>
  </div>
</div>


<div className="col-lg-6 p-2">
  <div className="shadow p-3">
    <h4>34</h4>
    <p className="m-0">Workshop</p>
  </div>
</div>


<div className="col-lg-6 p-2">
  <div className="shadow p-3">
    <h4>34</h4>
    <p className="m-0">Training</p>
  </div>
</div>


<div className="col-lg-6 p-2">
  <div className="shadow p-3">
    <h4>34</h4>
    <p className="m-0">Internship</p>
  </div>
</div>

<div className="col p-2">
<Link to="/create-certificate" className='btn btn-primary w-100'>Create Certificate</Link>
</div>
</div>

</div>

</div>

            
        </div>
        </div>
  )
}

export default CertificateManagement
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React, { useState } from 'react';
import HomeTemplate from '../../themes/HomeTemplate';
import Form from 'react-bootstrap/Form'
import { Avatar, Chip } from '@material-ui/core';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/EndPoint';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


const SupportPage = () => {

const [chatheads,setchatheads] = useState(null)

const {user} = useContext(AuthContext)



     const getChatHeads = async (status)=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);

          
          const res = await axios.post(ENDPOINT+"get-chat-heads.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
         //  // console.log("chat heads"+JSON.stringify(res.data))
           if(res.data.status==="success"){
            setchatheads(res.data.chat_heads)
           }
           else{
          
           }
          }
          }
          
          


useEffect(()=>{


     getChatHeads();
},[])



  return<HomeTemplate>

<div className="container-fluid pt-3 pb-5 min-vh-100">

<div className="flex-wrap space-between">

     <div className="col-lg-4 vh-80 overflow-y">




{chatheads?

<>
{chatheads.map((chat)=>(


<>
<Link to={"/support/ticket"+chat.conversation_id} type="button" className="flex  chat-head align-items-center">

<Avatar/>

<div className="ml-2">
     <h6>{chat.first_name+" "+chat.last_name}</h6>
     <p className="mb-0">This is final message</p>
</div>

</Link>

</>


))}
</>

:null
}



     </div>

















<div className="col-lg-7">

<div className=" vh-85 position-relative p-2">





<div type="button" className="flex  align-items-center">

<Avatar/>

<div className="ml-2">
     <h6>Manoj Kumar</h6>
     <p className="mb-0">This is final message</p>
</div>

</div>







<div className="vh-70 pb-5 overflow-y mt-3 p-2">

<div className="message mt-1 mb-1">
       <p>this is maesafw</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">4:89pm</p>
<CheckIcon className="ml-2"/>
   </div>
</div>


<div className="justify-right w-100 mt-1 mb-1">
<div className="message">
       <p>this is maesafw</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">4:89pm</p>
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>









<div className="justify-right w-100 mt-1 mb-1">
<div className="message">
       <p>this is maesafw</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">4:89pm</p>
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>



<div className="justify-right w-100 mt-1 mb-1">
<div className="message">
       <p>this is maesafw</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">4:89pm</p>
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>



<div className="justify-right w-100 mt-1 mb-1">
<div className="message">
       <p>this is maesafw</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">4:89pm</p>
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>





<div className="justify-right w-100 mt-1 mb-1">
<div className="message">
       <p>this is maesafw</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">4:89pm</p>
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>





<div className="justify-right w-100 mt-1 mb-1">
<div className="message">
       <p>this is maesafw</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">4:89pm</p>
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>
















</div>


<div className="bg-white bottom-0 left-0  container-fluid  br-4">
<Form>
<div className="flex align-items-center p-2 space-between">
<Form.Group className=" w-100 mb-0" controlId="exampleForm.ControlInput1">

    <Form.Control type="email" placeholder="Enter Message..." />
  </Form.Group>
<div className="flex ml-2 align-items-center">
<AttachFileIcon  type="button" className="mr-2"/>
<SendIcon type="button"/>
</div>
</div>
 
</Form>
</div>
</div>

</div>


</div>


</div>




  </HomeTemplate>;
};

export default SupportPage;

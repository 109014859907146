import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";

import { Avatar } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContext";
import InternshipItemSkeleton from "../../../skeleton/InternshipItemSkeleton";
import dateFormat from "dateformat";


import { TextField } from "@material-ui/core";

import { Dropdown, Container, Toast } from "react-bootstrap";
import ENDPOINT from "../../../context/EndPoint";
import { MoreVert } from "@material-ui/icons";
import axios from "axios";
import GetStatistics from "../../../actions/statistics/GetStatistics";
import ResolutionStatus from "../../../components/status/ResolutionStatus";
import AdminDeleteSupportRequest from "../../../actions/student/AdminDeleteSupportRequest";
import SecurityHeaders from "../../../context/SecurityHeaders";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
const InternalSupportLogs = ({ page }) => {
  const { user } = useContext(AuthContext);
  const userdata = user;

  const [category, setcategory] = useState("all");
  const [query, setquery] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState("start");

  useEffect(() => {
    getSupportLogs();
  }, [category]);

  const getSupportLogs = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
    if (category !== null) {
      data.append("category", category);
    }
    if (query) {
      data.append("query", query);
    }
    // console.log("getting")
    const res = await axios.post(ENDPOINT + "admin-get-tickets.php", data, {
      headers: { "content-type": "multipart/form-data" },
    });

    if (res) {
      if (res.data.status === "success") {
        setInitialData(res.data.data);
        setResponse(res.data.data);
      } else {
      }
    }
  };

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);

  const [toastmessage, settoastmessage] = useState(null);

  const [loading, setloading] = useState(false);
  const SearchChange = () => {
    // // console.log("search changed"+value)
  };

  const updateRequest = async ({ ticketstatus, ticketid }) => {
    setloading(true);
    if (userdata !== null) {
      const data = new FormData();
      data.append("session", user.SessionId);
      data.append("status", ticketstatus);
      data.append("id", ticketid);
      const res = await axios.post(
        `${ENDPOINT}admin-update-support-request.php`,
        data,
        SecurityHeaders
      );

      if (res.data.status === "success") {
       
        setloading(false);
        settoasttitle(res.data.title);
        settoastmessage(res.data.message+ticketstatus );
        settoast(true);
        getSupportLogs();
      } else {
        settoasttitle(res.data.title);
        settoastmessage(res.data.message + ticketstatus);
        settoast(true);
      }

      //       fetch(`${ENDPOINT}admin-update-support-request.php?session=${user.SessionId}&status=${ticketstatus}&id=${ticketid}`)
      //       .then(r=>r.json())
      //       .then(result=>{
      // setloading(false)
      //               if(result.status==="success"){
      // settoasttitle("Successful")
      // settoastmessage("Status changed to "+ticketstatus)
      // settoast(true)
      // getSupportLogs()
      //               }
      //               else{
      //                   settoasttitle("Something went wrong !")
      //                   settoastmessage("Please try again... ")
      //                   settoast(true)

      //               }

      //       })
    } else {
    }
  };

  const [info, setinfo] = useState(null);

  const GetStats = async () => {
    const res = await GetStatistics({ payload: { SessionId: user.SessionId } });

    if (res) {
      if (res.status === "success") {
        setinfo(res.data);
      }
 
    }
  };

  const deleteRequest = async ({ ticketid }) => {
    const res = await AdminDeleteSupportRequest({
      payload: {
        id: ticketid,
      },
    });
    if (res) {
      if (res.data.status === "success") {
        getSupportLogs();
       
        settoasttitle(res.data.data);
        
        settoast(true);
      } else {
        settoasttitle(res.data.data);
        settoastmessage("Please try again... ");
        settoast(true);
      }
    }
  };

  useEffect(() => {
    GetStats();
  }, []);

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.phone.toLowerCase().includes(term) ||
        item.name.toLowerCase().includes(term) ||
        item.email.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term) ||
        item.message.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };
  return (
    <>
    
    <div className="position-fixed container-fluid left-0 bottom-60 justify-center">
        <Toast
          className="bg-warning"
          show={toast}
          onClose={() => {
            settoast(false);
          }}
          autohide={true}
          delay={5000}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>
      <div className=" flex-wrap  ">
        <Container fluid className="my-3">
          <div>
            <input
              placeholder="Search..."
              variant="outlined"
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
              className="fixed-search"
              margin="normal"
            />
          </div>
        </Container>
        <div className="internships-list  sm-p-0  container-fluid">
          {response === "start" ? (
            <div className="container-fluid">
           
            </div>
          ) : response ? (

            <Table>
                 <TableHead>
                    <TableRow>
                      <TableCell align="left">User</TableCell>

                      <TableCell align="left">Category</TableCell>

                      <TableCell align="left">Message</TableCell>

                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Status</TableCell>

                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                        response.map((internship) => (
              
                          <>
                          <TableRow key={internship.id}>
                        <TableCell className="col-2" component="th" scope="row">
                        <div className="flex w-100">
                                <Avatar src={internship.profile_pic} className="me-2" />
                                <div className="">
                                  <p className="mb-0">
                                    {" "}
                                    {internship.name + "( " + internship.phone + " )"}{" "}
                                  </p>
                                  <p className="mb-0 truncate text-truncate-1">
                                    {internship.email}
                                  </p>
                                </div>
                              </div>
                        </TableCell>
                        <TableCell className="col-2" align="left">
                            <div className="w-100 d-none d-sm-block text-secondary">
                                <h6>{internship.subject}</h6>
                            
                              </div>
                            </TableCell>
                            <TableCell className="col-3" align="left">
                            <div className="w-100 d-none d-sm-block text-secondary">
                       
                                <p className="truncate truncate2">{internship.message}</p>
                              </div>
                            </TableCell>
            
                            <TableCell align="left">
                            <div className="w-100 ">
                                <div>
                                  <p>
                                    {dateFormat(
                                      internship.create_datetime,
                                      "dS mmm HH:MM tt"
                                    )}
                                  </p>
                                </div>
                             
                              </div>
                            </TableCell>
                            <TableCell align="left">
                            <div>
                                  <ResolutionStatus
                                    key={internship.id}
                                    status={internship.status}
                                  />
                                </div>
                            </TableCell>
            
                             <TableCell align="right">
                             <Dropdown className="options-dropdown">
                                <Dropdown.Toggle className="bg-transparent options p-0 w-fit h-fit  border-0">
                                  <MoreVert className="text-dark" />
                                </Dropdown.Toggle>
            
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      updateRequest({
                                        ticketstatus: "resolved",
                                        ticketid: internship.id,
                                      });
                                    }}
                                  >
                                    Resolved
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      updateRequest({
                                        ticketstatus: "unresolved",
                                        ticketid: internship.id,
                                      });
                                    }}
                                  >
                                    Un Resolved
                                  </Dropdown.Item>
            
                                  <Dropdown.Item
                                    onClick={() => {
                                      updateRequest({
                                        ticketstatus: "initiated",
                                        ticketid: internship.id,
                                      });
                                    }}
                                  >
                                    Initiated
                                  </Dropdown.Item>
            
                                  <Dropdown.Item
                                    onClick={() => {
                                      updateRequest({
                                        ticketstatus: "rejected",
                                        ticketid: internship.id,
                                      });
                                    }}
                                  >
                                    Reject
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      deleteRequest({ ticketid: internship.id });
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                             </TableCell>
                          
                          </TableRow>
                          </>
                        ))
                    }

                  </TableBody>
            </Table>
          
          ) : (
            <div className="container-fluid">No Internships</div>
          )}
        </div>
      </div>
    </>
  );
};

export default InternalSupportLogs;

import React from 'react'
import  styled  from 'styled-components'

import TopNavBar from '../components/navbar/TopNavBar'
import SideBar from '../components/leftsidebar/SideBar'
import { useEffect } from 'react'
import { useContext,useState } from 'react'
import { Link } from 'react-router-dom'
import ENDPOINT from '../context/EndPoint'
import { AuthContext } from '../context/AuthContext'
import FeedIcon from '@mui/icons-material/Feed';
import GridViewIcon from '@mui/icons-material/GridView';
import RewardsIcon from '@mui/icons-material/EmojiEvents';
import Localbase from 'localbase'
import InternshipIcon from '@mui/icons-material/BusinessCenter';


import GroupsIcon from '@mui/icons-material/Groups';

import { useParams } from 'react-router'

import InternOptions from '../pages/internships/InternOptions'


const InternshipTemplate= ({bottomMenu,children,footer, page,timeline,props}) => {
  const {user} = useContext(AuthContext)
  const userdata = user;
  const params = useParams()
  const internshipid = params.internshipid;



const db = new Localbase('db')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [time,settime]= useState(0);


  setTimeout(
    () => settime(time+1), 
    
    1000
  );
  
  








  //get user account


  
  const [account,setaccount]=  useState(null)
  useEffect(()=>{
      
    
    


if(user!=null||user!=="null"){
  getaccount();
  getnotifications();
}

       
}

,[])


const  getaccount = async ()=>{
    
      
  fetch(`${ENDPOINT}get-account.php?session=${user.SessionId}`)
    .then(r=>r.json())
    .then(result=>{
   
   if(result){

    console.log(result)

  if((result.status==="success")&&(result.data!=null)){
    setaccount(result.data);


  }else {
    localStorage.setItem("user",null);
    window.location.replace("/")
  }

   }
    else{
      localStorage.setItem("user",null);
    window.location.replace("/")
    }
    
  
 


    
        
    })
  


  }    ;
 
const [notifications,setnotifications] = useState(null);
const [unread,setunread] = useState(0);


const  getnotifications = async ()=>{
 
try{
  fetch(`${ENDPOINT}get-notifications.php?session=${user.SessionId}`)
  .then(r=>r.json())
  .then(result=>{
    
      
 if(result[0].status==="success"){
     

  if((result[0].data)!==null){
      setnotifications(result[0].data);
   setunread(result[0].unread)
      
  

  }
    

 }
  
              
  
      
  })
}
catch{

}
  
  }    ;





  React.useEffect(() => {
    
    const interval = setInterval(() => {
      getnotifications();
    }, 5000);
  
    return () => {
      
      clearInterval(interval);
    };
  }, []); // has no dependency - this will be called on-component-mount







    return (
        <HomeStyled>
<>




<>

<div className="container-fluid page-container p-0 mt-5">

<TopNavBar time={time} account={account} key="1" unread={unread} notifications={notifications}/>



  {account?
  <>
  
  
<div className="container  internship-internal" >

<div className="flex-wrap ">


{timeline==="true"?
<>
<div className="col-lg-6 pb-5 col-md-6 sm-p-0 col-xl-7 vh-90  overflow-y">
     {children}



     </div>

</>
:
<>
<div className="col-lg-6 col-md-7 sm-p-0 pb-5 d-none d-sm-block col-xl-7 vh-100  overflow-y">
     {children}



     </div>

</>

}


    

<div className=" col-xl-5 col-lg-6 sm-p-0  pb-5   mt-1">




<div className=" pb-5 d-sm-block">
<InternOptions account={account}/>
</div>





</div>

</div>


               </div>


  </>:
  <>
  
  
  </>}



</div>


<div className="bottom-menu col-12 p-0">



{page==="home"?

<>
<Link to={"/my-internship/"+internshipid+"/feed"} className="col p-0 text-dark">
<div className="bottom-menu-item hovered-footer-menu solid-top">
  <FeedIcon/>
   <p>Updates</p>   
</div>

</Link>
</>
:

<>
<Link to={"/my-internship/"+internshipid+"/feed"} className="col p-0 text-dark">
<div className="bottom-menu-item ">
<FeedIcon/>
   <p>Updates</p>  
</div>

</Link>
</>}










{page==="internships"?

<>
<Link to={"/my-internship/"+internshipid+"/tasks"} className="col p-0 ">

<div className="bottom-menu-item solid-top hovered-footer-menu">

<InternshipIcon/>
   <p className="hovered-footer-menu">Work</p>
</div>
</Link>
</>
:
<>

<Link to={"/my-internship/"+internshipid+"/tasks"} className="col p-0 text-dark">

<div className="bottom-menu-item">
<InternshipIcon/>
   <p>Work</p>
</div>
</Link>
</>
}

{page==="videos"?


<>
<Link to={"/my-internship/"+internshipid+"/meetings"} className="col  p-0  text-dark">

<div className="bottom-menu-item solid-top hovered-footer-menu">

<GroupsIcon/>
  <p>Meetings</p>
</div>

</Link>
</>


:

<>

<Link to={"/my-internship/"+internshipid+"/meetings"} className="col  p-0  text-dark">

<div className="bottom-menu-item">

<GroupsIcon/>
  <p>Meetings</p>
</div>

</Link>
</>}


{page==="workshops"?

<>

<Link to={"/my-internship/"+internshipid+"/achievements"} className="col  p-0 text-dark">
<div className="bottom-menu-item hovered-footer-menu solid-top">
<RewardsIcon/>
 <p>Rewards</p>
</div>

</Link>

</>

:
<>

<Link to={"/my-internship/"+internshipid+"/achievements"} className="col  p-0 text-dark">
<div className="bottom-menu-item">
<RewardsIcon/>
 <p>Rewards</p>
</div>

</Link>

</>
}











{userdata?
 <>
 
 {page==="dashboard"?
 

<>
<Link to={"/my-internship/"+internshipid+"/dashboard"}  className="col p-0  text-dark">

<div className="bottom-menu-item hovered-footer-menu solid-top">

<GridViewIcon/>
  <p>Dashboard</p>
</div>

</Link>
</>

:

<>
<Link to={"/my-internship/"+internshipid+"/dashboard"} className="col p-0  ">

<div className="bottom-menu-item">
<GridViewIcon/>
  <p>Dashboard</p>
</div>

</Link>
</>
}
 
 </>
:
<Link to="/login" className="col  p-0 text-dark">

<div className="bottom-menu-item">

<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path></svg>

  <p>Account</p>
</div>

</Link>
}


</div>

</>





</>
        </HomeStyled>
    )
}

export default InternshipTemplate


export const HomeStyled = styled.div`
padding: 0px;
margin: 0px;
width: 100%;
.flex{
    display: flex;
}
.page-container{
  margin-top: 55px;
  width: auto !important;
}

.live-courses, .offline-courses{
   margin-top: 70px;
}

.feature-item{
    margin: 15px;
}
.slider-content{
    height: auto;
   
    
}
.slider-content img{
  width: 100%;
  height: auto;
}
.slide1{
    background-color: blue;
}
.slide2{
    background-color: red;
}
.slide3{
    background-color: orange;
}
.navbar-nav{
    display: flex;
}
.navbar .container{
    display: flex;
}
.home-options{
  
  margin-bottom: 30px;
}
@media only screen and (max-width:576px){
  .text-center{
    font-size: 22px;
  }
 
  .home-options{
    display: flex !important;
  }
 
  .how-number{
    min-width: 30px !important;
    min-height:30px !important;
    
    height: 30px !important;
    width: 30px !important;
    border-radius: 15px;
  }
  .how-number h2{
    font-size:18px !important;
  }
}

@media only screen and (max-width:576px){

.course-item-main{
    width: 100%;
    margin:  0px 10px;
}
.course-item{
display: flex;
min-width: 85%;
width: 100%;
max-width:450px;
margin: 10px 0px;
height: fit-content;
}
.course-image{
width:100px !important;
height: 100px !important;
border-radius: 0px;
}
.course-buttons{
display: none;
}
.courses-list{
justify-content: center !important;
}
.course-prices{
position: relative;

bottom: 0px;
}
.rating-value{
margin-left:0px !important;
}
.cutoff-price{
display: none;
}
.course-prices{
margin-left: 0px !important;
}
.ratings-container{
margin-bottom:0px;
}

.current-price, .cutoff-price{
margin-bottom: 0px;
}
}




`
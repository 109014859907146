import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

import axios from 'axios'



import ENDPOINT from '../../context/EndPoint'


const AddDailyReportRequest = async({payload}) => {












      const formData = new FormData();
      
      
      //formData.append('whyus',document.getElementById('whyus').value)
      
      formData.append('session',payload.SessionId);
      
        formData.append('report',payload.report);
     
      const res = await axios.post(ENDPOINT+"admin-add-daily-report.php", formData,{
          headers: {
              'content-type': 'multipart/form-data'
          }
      });
      if(res){
       
       if(res.data.status==="success"){
 
        return res.data.data
       }
       else{
      
       }
      }
   










        





    
    

    
    


    





    
}

export default AddDailyReportRequest
import React from 'react'
import { Edit } from "@material-ui/icons";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";
import axios from 'axios'
import ENDPOINT from '../../context/EndPoint'
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import {useContext} from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {AuthContext} from '../../context/AuthContext'
import 'react-calendar-heatmap/dist/styles.css';
import { Avatar } from '@material-ui/core';
const CompanyActivityLog = () => {


const {user} = useContext(AuthContext)


useEffect(()=>{
GetActivity()
},[])
const [startdate,setstartdate] = useState("2022-01-01");
const [enddate,setenddate] = useState("2022-05-05")
const [dates,setdates] = useState([
  { date: '2022-01-01', count: 12 },
  { date: '2016-01-22', count: 122 },
  { date: '2022-01-30', count: 38 },
  // ...and so on
])
const GetActivity = async ()=>{



  const formData = new FormData();
  formData.append('session',user.SessionId)


  const res = await axios.post(ENDPOINT+"user-activity-map.php",formData,{
    headers:{'content-type':'multipart/form-data'}
  })

if(res){
  if(res.data.status==="success"){
    setdates(res.data.data)
    setstartdate(res.data.start_date)
setenddate(res.data.end_date)
  }
 
}
}




  return (
    <div>
      <div className="container-fluid  p-2">


<div className="d-flex flex-wrap ">

<div className="col-lg-7">

</div>

<div className="col-lg-4">
  <div className="d-flex p-2">
<Avatar className="mr-1"/>
<div>
  <h6 className="mb-0 pb-0">Manoj Kumar Koravangi</h6>
  <p className="mb-0 pb-0">4 pending tasks</p>
</div>
  </div>
</div>
</div>
</div>


   

    </div>
  )
}

export default CompanyActivityLog
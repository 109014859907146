import React, { useContext } from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'
import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'
import { CircularProgress } from '@material-ui/core';
import dateFormat from 'dateformat'
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import ENDPOINT from '../../context/EndPoint'


import { Toast } from 'react-bootstrap'
import notask from '../../assets/team-working.svg'
import WorkHoursEnd from './WorkHoursEnd'
import Close from '@mui/icons-material/Close'
import { AuthContext } from '../../context/AuthContext'

const InternTasks = ({account,timeline}) => {
     useEffect(() => {
          window.scrollTo(0, 0)
         
        }, [])
      
const {user} = useContext(AuthContext)
     const params = useParams();
     const internship_id = params.internshipid;
   //  // console.log("internship id"+internship_id);

     useEffect(() => {
          window.scrollTo(0, 0)
          getTasks();
          CheckWorkHours()
        }, [])

const [tasks,settasks] = useState(null)

const [status,setstatus] = useState("all");

     const getTasks = async (status)=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          
            formData.append('status',status);
          formData.append('internship_id',internship_id)
          
          const res = await axios.post(ENDPOINT+"internship-tasks.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
           if(res.data.status==="success"){
            settasks(res.data.data)
           }
           else{
          
           }
          }
          }
          
const openSubmission = ()=>{
     setsubmit(true)
}

const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)

const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
       






const [toasttitle,settoasttitle]= useState(null)





const [toast,settoast] = useState(false)

const [toastmessage,settoastmessage]= useState(null)


   const [file,setfile] = useState(null)
   const [submissionstatus,setsubmissionstatus]= useState(false)
    const   UPLOAD_ENDPOINT = `${ENDPOINT}internship-upload-task.php`;
      
      const onSubmit = async (e)=> {
          setstatus(true)
           e.preventDefault() 
           let res = await uploadFile(file);
       
   
           if(res.data.status==="success"){
   getTasks()
           setsubmit(false)
           settoasttitle("Submission successful !")
           settoastmessage("Your task has been successfully submitted.")
           settoast(true)

            
           }else{
             setsubmissionstatus(false)
             settoasttitle("Something went wrong !")
           settoastmessage("Please try again after sometime.")
           settoast(true)
      
           }
       }
     const  onChange = (e)=> {
           setfile(e.target.files[0])
        }
      const uploadFile = async (file)=>{
           
   
           const formData = new FormData();
           const user = JSON.parse(localStorage.getItem("user"));
      
           formData.append('resume',file);
           formData.append('task_id',taskid)
        
           formData.append('session',user.SessionId);
       
           return  await axios.post(UPLOAD_ENDPOINT, formData,{
               headers: {
                   'content-type': 'multipart/form-data'
               }
           });
         }
       
   





         const [workhours,setworkhours] = useState(true)
         const CheckWorkHours= async ()=>{
       

              const formData = new FormData();
              
              
              //formData.append('whyus',document.getElementById('whyus').value)
              
              formData.append('session',user.SessionId);
              
    
              formData.append('internship_id',internship_id)
              
              const res = await axios.post(ENDPOINT+"check-work-hours.php", formData,{
                  headers: {
                      'content-type': 'multipart/form-data'
                  }
              });
              if(res){
             // console.log('status check '+JSON.stringify(res.data.work_hours))
               if(res.data.status==="success"){
                setworkhours(res.data.work_hours)
               }
               else{
                   setworkhours(false)
          
               }
              }
              }




     return (
          <>
           <>
              
         

<div className="position-fixed container-fluid bottom-60 left-0 justify-center">
<Toast delay={5000} className="bg-warning" autohide show={toast} onClose={()=>{settoast(false)}}>
          <Toast.Header>
            
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>


</div>


              <Modal
  open={submit}
  onClose={()=>{setsubmit(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
   <>
   <div className="flex space-between">
         <h5>Submit Task</h5> 
         <Close className="click" onClick={()=>setsubmit(false)}/>
    </div>

    <form onSubmit={onSubmit} className="position-relative">
          




          <input type="file" className=""  accept=".zip,.rar" onChange={onChange}/>
              <br/>
              
                   <button className="btn btn-primary w-100 mt-5" disabled={submissionstatus} type="submit">{submissionstatus?<CircularProgress className="text-white " size={20}/>:""}Upload Task</button>
                 
                 
                
                 </form>
       
   </>
  </Box>
</Modal>





























{workhours?
<>
                
<div className="">

<div className=" mb-3 flex container-fluid space-between p-3  align-items-center">
<div className="col-6">
<h3 className="sm-22">{tasks?tasks.length:null} Tasks</h3>
</div>


<div className="col-3">

</div>
</div>



<div className="sm-p-15">


{tasks?
<>

{tasks.map((task)=>(
<>


<div className="container-fluid">
     <div className="border br-5 p-3">
          <h4 className="sm-18 text-primary">{task.title}</h4>
          <hr/>
         
          <div className="flex space-between">
             
               <div className="col-5">
<h6 className="sm-14">Deadline</h6>
<p className="sm-14">{dateFormat(task.deadline,"dS mmm hh:MM tt")}</p>
               </div>

               <div className="col-3">
<h6 className="sm-14">Status</h6>
<p className="sm-14">{task.status}</p>
               </div>


              
          </div>

     
         <div className="flex space-between mt-0">
<span></span>
     {task.status==="pending"?
         <button onClick={()=>{settaskid(task.id);setsubmit(true);}} className="btn btn-warning">Submit Task</button>
   :null     
}      </div>
     </div>
</div>





</>


))}


</>
:<>


<div className="container-fluid">
<div className="justify-center">
     <img src={notask} className="w-75"/>
</div>
<div className="text-center mt-3">
     <h4>No Tasks</h4>
     <p>You donot have any tasks. Please come after sometime.</p>
</div>
</div>
</>

}



</div>





</div>   

</>
:
<>
<WorkHoursEnd internshipid={internship_id} account={account}/>
</>}

              </>
          </>
     )
}

export default InternTasks

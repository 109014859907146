import React, { useContext, useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import { AuthContext } from '../../../context/AuthContext';
import AdminGetCheckList from '../../../actions/student/AdminGetCheckList';
import AdminUpdateChecklist from '../../../actions/student/AdminUpdateChecklist';
import AdminAddJourney from '../../../actions/student/AdminAddJourney';
import dateFormat from 'dateformat';
const CourseCheckList = ({profile}) => {


const {user} = useContext(AuthContext)

const [checklist,setchecklist]= useState(null)
     const GetCheckList = async ()=>{
  
          const res = await AdminGetCheckList({
               payload:{
                    user:user.SessionId,
                    user_id:profile.user_id,
                    course_url:profile.course_url

     
               }
          })
          if(res.status==="success"&&res.checklist!==null){
setchecklist(res.checklist)
          }
     }

     const UpdateChecklist = async ({data})=>{
  
          const res = await AdminUpdateChecklist({
               payload:{
                    user:user.SessionId,
                    user_id:profile.user_id,
                    course_url:profile.course_url,
                    key:data.key,
                batch_id:profile.batch_id,
                mode:profile.mode
                
     
               }
          })
          if(res.status==="success"){
GetCheckList()
          }
     }



     
     const AddJourney = async ()=>{
  
          const res = await AdminAddJourney({
               payload:{
                    user:user.SessionId,
                    user_id:profile.user_id,
                    course_url:profile.course_url,
                    purchase_id:profile.purchase_id,
                    batch_id:profile.batch_id,
                    enrolled_on:profile.purchase_datetime
               }
          })
          
          if(res){
               GetCheckList()
          }
          if(res.status==="success"){

          }
     }





     useEffect(()=>{
GetCheckList()
     },[profile])
  return (
    <div>
     <ol className="p-0">


{checklist?
<>
{checklist.interacted_on?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Interacted with trainer</p>
<p className="extra-small mb-0">{dateFormat(checklist.interacted_on,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"interacted_on"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Interacted with trainer</p>
</li>


</>}












{checklist.started_training?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Started training program</p>
<p className="extra-small mb-0">{dateFormat(checklist.started_training,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"started_training"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Started training program</p>
</li>


</>}





{checklist.interactive_session?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Participated in interactive sessions</p>
<p className="extra-small mb-0">{dateFormat(checklist.interactive_session,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"interactive_session"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Participated in interactive sessions</p>
</li>


</>}











{checklist.group_discussion?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Participated in group discussions</p>
<p className="extra-small mb-0">{dateFormat(checklist.group_discussion,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"group_discussion"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Participated in group discussions</p>
</li>


</>}





{checklist.professional_interaction?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Interacted with a working professional</p>
<p className="extra-small mb-0">{dateFormat(checklist.professional_interaction,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"professional_interaction"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Interacted with a working professional</p>
</li>


</>}





{checklist.lead_team?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Worked as team leader</p>
<p className="extra-small mb-0">{dateFormat(checklist.lead_team,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"lead_team"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Worked as team leader</p>
</li>


</>}






{checklist.completed_assignments?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Completed assignments</p>
<p className="extra-small mb-0">{dateFormat(checklist.completed_assignments,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"completed_assignments"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Completed assignments</p>
</li>


</>}







{checklist.completed_projects?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Completed projects</p>
<p className="extra-small mb-0">{dateFormat(checklist.completed_projects,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"completed_projects"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Compelted projects</p>
</li>


</>}










{checklist.completed_placement_training?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Attended placement trainings</p>
<p className="extra-small mb-0">{dateFormat(checklist.completed_placement_training,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"completed_placement_training"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Attended placement trainings</p>
</li>


</>}





{checklist.overall_training_completion?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Completed overall training</p>
<p className="extra-small mb-0">{dateFormat(checklist.overall_training_completion,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"overall_training_completion"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Completed overall training</p>
</li>


</>}







{checklist.final_certification_date?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Issued certificate</p>
<p className="extra-small mb-0">{dateFormat(checklist.final_certification_date,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"final_certification_date"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Issued certficate</p>
</li>


</>}







{checklist.got_opportunity?
<>
<li className="d-flex align-items-center mt-3 mb-3">  
<Checkbox className='text-success' disabled defaultChecked/>
<div>
<p className="mb-0">Got internship / Placement Opportunity</p>
<p className="extra-small mb-0">{dateFormat(checklist.got_opportunity,"dS mmm yyyy, hh:mm tt")}</p>

</div>

</li>


</>:
<>
<li onClick={()=>{UpdateChecklist({data:{key:"got_opportunity"}})}} className="d-flex click align-items-center mt-2 b-2">  
<Checkbox  disabled  />
<p className="mb-0">Got internship / Placement Opportunitiy</p>
</li>


</>}








</>:
<>
<button onClick={()=>AddJourney()} className="btn btn-primary mt-3 btn-sm rounded-pill">Add Journey</button>
</>}





</ol>




    </div>
  )
}

export default CourseCheckList
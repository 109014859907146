import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ForwardIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useContext } from "react";
import { useState } from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import ENDPOINT from "../../../context/EndPoint";
import { useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import UploadClassRecording from "../schedules/UploadClassRecording";
import { Dropdown } from "react-bootstrap";
import { Class, MoreVert } from "@material-ui/icons";
import {
  Calendar as CalendarIcon,
  Clock,
  ClockFill,
} from "react-bootstrap-icons";
import { Chip, TextField } from "@material-ui/core";
import { CopyAll, Edit } from "@mui/icons-material";
import RescheduleClass from "../../../actions/course/RescheduleClass";
import ClassesCalendar from "../../../components/calendar/ClassesCalendar";
import UploadClassRecordingModal from "../../../components/modals/UploadClassRecordingModal";
import ScheduleItem from "./ScheduleItem";
const InternalClassSchedules = () => {
  const params = useParams();
  const course_url = params.courseurl;
  const batchid = params.batchid;

  const { user } = useContext(AuthContext);

  const [currentdate, setcurrentdate] = useState(null);
  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var newdate = dd + mm;
    setcurrentdate(dd + mm);
    console.log("Cu" + (dd + mm));
  }, []);

  const [startdate, setstartdate] = useState(null);
  const [enddate, setenddate] = useState(null);
  const [reschedule, setreschedule] = useState(false);
  const Reschedule = async () => {
    const res = await RescheduleClass({
      payload: {
        user: user.SessionId,
        class_starts: startdate,
        class_ends: enddate,
        class_id: classid,
      },
    });

    if (res) {
      if (res.status === "success") {
        setreschedule(false);
      }
    }
  };

  const getChapters = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    formData.append("course_url", course_url);

    formData.append("batch_id", batchid);

    const res = await axios.post(ENDPOINT + "admin-get-classes.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      if (res.data.status === "success") {
        setResponse(res.data.data);
        setInitialData(res.data.data);
      } else {
      }
    }
  };

  const [classid, setclassid] = useState(null);
  const UpdateRecording = ({ cid, class_starts }) => {
    setclassid(cid);
    setclassstarts(class_starts);
    setupload(true);
  };

  const [upload, setupload] = useState(false);
  const AddClass = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    formData.append("session", user.SessionId);

    formData.append("course_url", course_url);
    formData.append("title", title);
    formData.append("topics", topics);

    formData.append("meeting_link", meetinglink);

    formData.append("date", date);

    formData.append("starts", starts);

    formData.append("batch_id", batchid);

    formData.append("ends", ends);

    const res = await axios.post(
      ENDPOINT + "admin-add-schedule.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      if (res.data.status === "success") {
        // setchapters(res.data[0].data)
        settoasttitle(res.data.title);
        settoastmessage(res.data.title);
        settoast(true);
        setctitle(null);
        setcurrentchapter(null);
        getChapters();
      } else {
        settoasttitle(res.data.title);
        settoastmessage(res.data.title);
        settoast(true);
      }
    }
  };

  const [schedule, setschedule] = useState(false);
  const [ctitle, setctitle] = useState(null);
  const [currentchapter, setcurrentchapter] = useState(null);

  useEffect(() => {
    getChapters();
  }, []);

  const [classstarts, setclassstarts] = useState(null);
  const StartUpload = ({ class_starts }) => {
    setclassstarts(class_starts);
    setupload(true);
  };

  const deleteClass = async ({ batch_id, id }) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    formData.append("session", user.SessionId);

    formData.append("class_id", id);
    formData.append("batch_id", batch_id);

    const res = await axios.post(
      ENDPOINT + "admin-delete-class.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      if (res.data.status === "success") {
        settoasttitle(res.data.title);
        settoastmessage(res.data.title);
        settoast(true);
        getChapters();
      } else {
        settoasttitle(res.data.title);
        settoastmessage(res.data.title);
        settoast(true);
      }
    }
  };

  useEffect(() => {
    getChapters();
  }, []);

  const [title, settitle] = useState(null);
  const [starts, setstarts] = useState(null);
  const [ends, setends] = useState(null);
  const [date, setdate] = useState(null);
  const [topics, settopics] = useState(null);
  const [meetinglink, setmeetinglink] = useState(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.class_title.toLowerCase().includes(term) ||
        item.class_status.toLowerCase().includes(term) ||
        item.batch_id.toLowerCase().includes(term) ||
        item.class_starts.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());

  const tileClassName = ({ date, view }) => {
    // Check if the date is the 15th or 16th of the current month
    if (view === "month" && (date.getDate() === 15 || date.getDate() === 16)) {
      return "highlighted-date";
    }
    return null;
  };

  const tileContent = ({ date, view }) => {
    // Check if the date is the 15th or 16th of the current month
    if (view === "month" && (date.getDate() === 15 || date.getDate() === 16)) {
      return (
        <div className="badge calander-badge">
          {date.getDate() === 15 ? 3 : 4}
        </div>
      );
    }
    return null;
  };

  const CloseUploadModal = ()=>{
  setupload(true)}

  return (
    <>
      <div className="position-fixed w-100 bottom-60 justify-center">
        <Toast
          className="bg-warning"
          onClose={() => settoast(false)}
          show={toast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>

      <div className="container-fluid">
        <Modal
          open={schedule}
          onClose={() => setschedule(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 500 }}>
            <p>Class Title :</p>
            <input
              className="form-control"
              value={title}
              onChange={(e) => {
                settitle(e.target.value);
              }}
            ></input>
            <br />
            <p>Topics List:</p>
            <input
              className="form-control"
              value={topics}
              onChange={(e) => {
                settopics(e.target.value);
              }}
            ></input>
            <p>Meeting Link:</p>
            <input
              className="form-control"
              value={meetinglink}
              onChange={(e) => {
                setmeetinglink(e.target.value);
              }}
            ></input>

            <p className="mb-0">Date</p>
            <input
              className="form-control"
              type="date"
              onChange={(e) => {
                setdate(e.target.value);
              }}
            ></input>
            <div className="flex mt-3">
              <input
                className="form-control"
                type="time"
                onChange={(e) => {
                  setstarts(e.target.value);
                }}
              ></input>
              <input
                className="form-control"
                type="time"
                onChange={(e) => {
                  setends(e.target.value);
                }}
              ></input>
            </div>

            <button className="btn btn-primary w-100 mt-3" onClick={AddClass}>
              Schedule Class
            </button>
          </Box>
        </Modal>

        <Modal open={reschedule} onClose={() => setreschedule(false)}>
          <Box className="col-lg-4 col-md-5 col-sm-6 col-12" sx={style}>
            <div className="container-fluid mb-3">
              <div className="flex space-between">
                <h5>Reschedule class</h5>
                <CloseIcon
                  type="button"
                  className="text-danger"
                  onClick={() => setreschedule(false)}
                />
              </div>
              <p>Selected Class ID : {classid}</p>
            </div>

            <hr />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                Reschedule();
              }}
            >
              <div className="container">
                <h6>Class starts</h6>
                <div className="d-flex">
                  <input
                    required
                    className="form-control"
                    onChange={(e) => setstartdate(e.target.value)}
                    type="datetime-local"
                  ></input>
                </div>
              </div>

              <div className="container mt-4">
                <h6>Class ends</h6>
                <div className="d-flex">
                  <input
                    required
                    className="form-control"
                    onChange={(e) => setenddate(e.target.value)}
                    type="datetime-local"
                  ></input>
                </div>
              </div>

              <button className="btn btn-primary mt-3 w-100" type="submit">
                Save Changes
              </button>
            </form>
          </Box>
        </Modal>

        <div className="my-2 d-flex">
          <div className="col-lg-5">
            <input
              placeholder="Search Title, Status, Date"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
             className="fixed-search"
              margin="normal"
            />
          </div>

        </div>

        <div className="d-flex container-fluid sm-p-15 flex-wrap">
          {response ? (
            <>
              <div className="my-3 d-none container-fluid">
                <ClassesCalendar classSchedules={response} />
              </div>

              {response.map((item, index) => (
                <>
               <ScheduleItem item={item} index={index}/>
                </>
              ))}
            </>
          ) : (
            <>
              <div className="text-center mt-5">
                <h5>No Schedules</h5>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InternalClassSchedules;

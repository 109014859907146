import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import AdminGetProjectSubmissions from '../../../actions/student/AdminGetProjectSubmissions'
import { AuthContext } from '../../../context/AuthContext'

const ProjectSubmissions = ({profile}) => {


const {user} = useContext(AuthContext)

const [submissions,setsubmissions] = useState(null)
const GetSubmissions = async()=>{

     const res = await AdminGetProjectSubmissions({payload:{
          user:user.SessionId,
          course_url:profile.course_url,
          user_id:profile.user_id
     }})
     if(res){
          if(res.status==="success"){
               setsubmissions(res.data)
          }
     }
}

useEffect(()=>{
GetSubmissions()
},[profile])
  return (
    <div>
     {submissions?
     <>
   {submissions.map((item)=>(
     <>

<div className="w-100">
<div className="w-100">
     <h6>{item.title}</h6>
     <div className="d-flex">
          <div className="col">
               <p className="mb-0 extra-small">Marks : {item.marks}</p>
          </div>
          <div className="col">
               <p className="mb-0 extra-small">Marks Earned: {item.marks_given}</p>
          </div>
          <div className="col">
               <p className="mb-0 extra-small">Marks : {item.marks}</p>
          </div>
     </div>
     <div className="d-flex">
          <div className="col">
               <p className="mb-0 extra-small">Submitted on : {item.submission_time}</p>
          </div>
          <div className="col">
               <p className="mb-0 extra-small">Status: {item.status}</p>
          </div>
          <div className="col">
               <p className="mb-0 extra-small">ID : {item.assignment_id}</p>
          </div>
     </div>
     <p className="mb-2 extra-small">{item.description}</p>

<div className="d-flex justify-content-between">
<a href={item.solution_link} className="btn btn-sm rounded-pill btn-outline-primary">Download Solution</a>
     <button className="btn btn-sm btn-primary rounded-pill">Evaluate</button>
</div>

</div>



     </div>
     <hr/>
     </>
   ))}
     </>:null}
     </div>
  )
}

export default ProjectSubmissions
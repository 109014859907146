import MailIcon from "@mui/icons-material/Mail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import InternalTemplate from "../../../themes/InternalTemplate";
import { Avatar, Chip, TextField } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContext";
import InternshipItemSkeleton from "../../../skeleton/InternshipItemSkeleton";
import dateFormat from "dateformat";
import { useParams } from "react-router-dom";
import { Container, Dropdown } from "react-bootstrap";
import ENDPOINT from "../../../context/EndPoint";
import Unresolved from "@mui/icons-material/RadioButtonUnchecked";
import Resolved from "@mui/icons-material/CheckCircle";
import Rejected from "@mui/icons-material/Cancel";
import { MoreVert, VideoCall } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Toast } from "react-bootstrap";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import ResolutionStatus from "../../../components/status/ResolutionStatus";
import ProgressOverlay from "../../../components/status/ProgressOverlay";
import GetStatistics from "../../../actions/statistics/GetStatistics";
import SecurityHeaders from "../../../context/SecurityHeaders";
import AdminDeleteTickets from "../../../actions/student/AdminDeleteTickets";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import LeadsStatistics from "../leads/LeadsStatistics";
const InternalDoubtRequests = ({ page }) => {
  const { user } = useContext(AuthContext);
  const userdata = user;
  const params = useParams();
  const [status, setstatus] = useState("all");

  const [query, setquery] = useState(null);

  const [resolutions, setresolutions] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState("start");

  useEffect(() => {
    setinternships("start");

    getinternships();
  }, [status]);

  const getinternships = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
    if (query) {
      data.append("query", query);
    }
    if (status) {
      data.append("status", status);
    }

    const res = await axios.post(
      ENDPOINT + "admin-get-doubt-requests.php",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      if (res.data.status === "success") {
        setinternships(res.data.data);
        setInititalData(res.data.data);
        setResponse(res.data.data);
        setresolutions(res.data.resolutions);
      } else {
      }
    }
  };

  const [loading, setloading] = useState(false);
  const updateRequest = async ({ ticketstatus, ticketid }) => {
    setloading(true);
    if (userdata !== null) {
      const data = new FormData();
      data.append("session", user.SessionId);
      data.append("status", ticketstatus);
      data.append("id", ticketid);
      const res = await axios.post(
        `${ENDPOINT}admin-update-doubt-request.php`,
        data,
        SecurityHeaders
      );

      // // console.log(JSON.stringify(result))
      if (res.data.status === "success") {
        setloading(false);
        settoasttitle("Successful");
        settoastmessage("Status changed to " + ticketstatus);
        settoast(true);
        getinternships();
      } else {
        settoasttitle("Something went wrong !");
        settoastmessage("Please try again... ");
        settoast(true);
      }
    } else {
    }
  };

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [info, setinfo] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);
  const GetStats = async () => {
    const res = await GetStatistics({ payload: { SessionId: user.SessionId } });

    if (res) {
      if (res.status === "success") {
        setinfo(res.data);
      }
      console.log("stat is" + JSON.stringify(res));
    }
  };

  const [requestDelete, setRequestDelete] = useState("");

  const deleteRequest = async ({ ticketid }) => {
    const res = await AdminDeleteTickets({
      payload: {
        id: ticketid,
      },
    });
    if (res) {
      if (res.data.status === "success") {
        getinternships();
        console.log("getting");
        settoasttitle("Successful");
        settoastmessage("Deleted ");
        settoast(true);
      } else {
        settoasttitle("Something went wrong !");
        settoastmessage("Please try again... ");
        settoast(true);
      }
    }
  };

  useEffect(() => {
    GetStats();
  }, []);

  // Initialize the response state with the provided data
  const [initialData, setInititalData] = useState(null);

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle changes in the search input
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    // Filter the data based on search term
    const filteredData = initialData.filter((item) => {
      return (
        item.email.toLowerCase().includes(term) ||
        item.phone_number.includes(term) ||
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term) ||
        item.subject.toLowerCase().includes(term) ||
        item.category.toLowerCase().includes(term) ||
        item.course_url.toLowerCase().includes(term) ||
        item.batch_id.toLowerCase().includes(term) ||
        item.message.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <>
      {loading ? (
        <>
          <ProgressOverlay />
        </>
      ) : (
        <></>
      )}

      <div className="position-fixed container-fluid left-0 bottom-60 justify-center">
        <Toast
          className="bg-warning"
          show={toast}
          onClose={() => {
            settoast(false);
          }}
          autohide={true}
          delay={5000}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>

      <div className="flex-wrap ">
        <Container fluid>
          <div>
            <input

            className="fixed-search"
              placeholder="Search name, email phone "
              variant="outlined"
             
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </Container>

        <div className="container mb-3 mt-1">

        <LeadsStatistics/>
        </div>


        <div className="internships-list  sm-p-0  container-fluid">
          {response === "start" ? (
            <div className="container-fluid">
              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />
            </div>
          ) : response ? (
            <Table>
                           <TableHead>
  <TableRow>
    <TableCell >User</TableCell>
    
   
    <TableCell align="left">Category</TableCell>
    <TableCell align="left">Date</TableCell>
    <TableCell align='center'>Status</TableCell>
    <TableCell align="right">Actions</TableCell>
  </TableRow>
</TableHead>
<TableBody>
  {
       response.map((internship) => (
        <TableRow
        key={internship.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          
          <TableCell component="th" scope="row">
          <div className="flex w-100">
              <Avatar src={internship.profile_pic} className="me-2" />
              <div>
                <p className="mb-0">
                  {" "}
                  {internship.first_name +
                    " " +
                    internship.last_name}{" "}
                </p>
                <div className="flex">
                  <PhoneInTalkIcon className="mr-2 text-primary" />
                  <WhatsAppIcon className="text-success mr-2" />
                  <p className="mb-0 ">{internship.phone_number}</p>
                </div>
              </div>
            </div>
          </TableCell>

     

         <TableCell align="left">
         <div className="d-flex w-100">   

{/**call back */}
  <div className="mt-0">
    {internship.category === "callback" ? (
      <>
        <PhoneInTalkIcon className=" text-primary" />
      </>
    ) : internship.category === "zoom" ? (
      <>
        <VideoCall className="text-primary" />
      </>
    ) : (
      <>
        <WhatsAppIcon className="text-success " />
      </>
    )}
  </div> 

  {/**chat */}

  <div className="ms-2">
    {internship.category === "chat" ? (
      <h6 className="mb-0">Chat Support</h6>
    ) : (
      <>
        <h6 className="mb-0" >{internship.subject}</h6>
        <p>{internship.message}</p>
      </>
    )}
  </div>

<div className="d-none">
{internship.category === "callback" ? (
    <>
      <PhoneInTalkIcon className="mr-2 text-primary" />
    </>
  ) : internship.category === "zoom" ? (
    <>
      <VideoCall className="text-primary" />
    </>
  ) : (
    <>
      <WhatsAppIcon className="text-success mr-2" />
    </>
  )}
</div>
</div>
         </TableCell>

           <TableCell align="left">
           <div className="  w-100">
              <p className="sm-10 mx-auto">
                
                {dateFormat(
                  internship.create_datetime,
                  "dd mmm yy hh:mm:ss TT"
                )}
              </p>
            </div>
           </TableCell>
           <TableCell align="center">
         <div className="w-100 text-align-center">
              <div className="d-none">{internship.create_datetime}</div>
              <ResolutionStatus status={internship.status} />
            </div>
         </TableCell>
         <TableCell align="right">
         <Dropdown className="options-dropdown">
              <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                <MoreVert className="text-dark" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    updateRequest({
                      ticketstatus: "initiated",
                      ticketid: internship.id,
                    })
                  }
                >
                  Initiate Resolution
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    updateRequest({
                      ticketstatus: "resolved",
                      ticketid: internship.id,
                    })
                  }
                >
                  Mark Resolved
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    updateRequest({
                      ticketstatus: "unresolved",
                      ticketid: internship.id,
                    })
                  }
                >
                  Mark UnResolved
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    updateRequest({
                      ticketstatus: "rejected",
                      ticketid: internship.id,
                    })
                  }
                >
                  Mark Rejected
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    deleteRequest({ ticketid: internship.id })
                  }
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
         </TableCell>
          
        </TableRow>
      ))
  }

</TableBody>
            </Table>

         
          ) : (
            <div className="container-fluid">No Internships</div>
          )}
        </div>
      </div>
    </>
  );
};

export default InternalDoubtRequests;

import React from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'

const InternResources = () => {
     return (
          <>
         <InternshipTemplate>
              <>
              
              
                
<div className="">

<div className=" mb-3 flex container-fluid pt-3  align-items-center">



     <div className="flex container-fluid">

     <h6>Uploaded</h6>


     <h6>Downloadable</h6>

     </div>

</div>









<div className="container-fluid">
     <div className="shadow p-3">
          <h4>This is task 1</h4>
          <hr/>
          <div className="flex">
               <div className="col-4">
<h6>Marks</h6>
<p>100</p>
               </div>

               <div className="col-4">
<h6>Deadline</h6>
<p>100</p>
               </div>

               <div className="col-4">
<h6>Status</h6>
<p>Pending</p>
               </div>


              
          </div>
         <div className="flex space-between">
              <button className="btn btn-primary">Request Support</button>
              <button className="btn btn-warning">Submit Task</button>
         </div>
     </div>
</div>










</div>    
              </>
         </InternshipTemplate>
          </>
     )
}

export default InternResources

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext } from 'react';
import {useState} from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import ENDPOINT from '../../../context/EndPoint'
import { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast'
import dateFormat from 'dateformat'
import { Link } from 'react-router-dom';
import UploadClassRecording from '../schedules/UploadClassRecording';
import ScheduleItem from '../schedules/ScheduleItem';
const InternalClassSchedules = () => {
const params = useParams();
const course_url = params.courseurl;
const batchid = params.batchid;
const [chapters,setchapters] = useState(null)
const {user} = useContext(AuthContext)




const getChapters = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);

  formData.append('course_url',course_url);

  formData.append('batch_id',batchid);


const res = await axios.post(ENDPOINT+"admin-get-course-schedules.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
 if(res.data.status==="success"){
  setchapters(res.data.data)
 }
 else{

 }
}
}


const [classid,setclassid] = useState(null)
const UpdateRecording = ({cid,class_starts})=>{
setclassid(cid)
setclassstarts(class_starts)
setupload(true)
}

const [upload,setupload] = useState(false)
const AddClass = async (status)=>{
        

     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     formData.append('session',user.SessionId);
     
       formData.append('course_url',course_url);
       formData.append('title',title);
       formData.append('topics',topics);
     
       formData.append('meeting_link',meetinglink);
     
       formData.append('date',date);
     
       formData.append('starts',starts);
     
       formData.append('batch_id',batchid);
     
       formData.append('ends',ends);
     
     const res = await axios.post(ENDPOINT+"admin-add-schedule.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
        if(res.data.status==="success"){
    // setchapters(res.data.data)
      settoasttitle(res.data.title)
      settoastmessage(res.data.message)
      settoast(true)
      setctitle(null)
      setcurrentchapter(null)
      setschedule(false);
      getChapters()
      }
      else{
        settoasttitle(res.data.title)
        settoastmessage(res.data.message)
        settoast(true)
      }
     }
     }
     
const [schedule,setschedule] = useState(false)
const [ctitle,setctitle] = useState(null);
const [currentchapter,setcurrentchapter] = useState(null)

useEffect(()=>{

getChapters();
},[])





const [classstarts,setclassstarts] = useState(null)
const StartUpload = ({class_starts})=>{
setclassstarts(class_starts)
setupload(true)
}


const deleteClass = async ({batch_id,id})=>{
        

     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     formData.append('session',user.SessionId);
     
       formData.append('class_id',id);
       formData.append('batch_id',batch_id);
     
     const res = await axios.post(ENDPOINT+"admin-delete-class.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
    
       if(res.data.status==="success"){
       
      // setchapters(res.data.data)
      settoasttitle(res.data.title)
      settoastmessage(res.data.message)
      settoast(true)
      getChapters();
      }
      else{
        settoasttitle(res.data.title)
        settoastmessage(res.data.message)
        settoast(true)
      }
     }
     }
     

useEffect(()=>{

getChapters();
},[])




const [title,settitle] = useState(null);
const [starts,setstarts] = useState(null);
const [ends,setends] = useState(null)
const [date,setdate] = useState(null)
const [topics,settopics] = useState(null)
const [meetinglink,setmeetinglink] = useState(null)

const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     pt: 2,
     px: 4,
     pb: 3,
   };
   




const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)

  return (
<>

<div className="position-fixed w-100 bottom-60 justify-center">

<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>



<div className="container-fluid">





<Modal
  open={schedule}
  onClose={()=>setschedule(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...style, width: 500 }}>
  <p>Class Title  :</p>
    <input className="form-control"  value={title} onChange={(e)=>{settitle(e.target.value)}}></input>
    <br/>
    <p>Topics List:</p>
    <input className="form-control" value={topics} onChange={(e)=>{settopics(e.target.value)}}></input>
    <p>Meeting Link:</p>
    <input className="form-control" value={meetinglink} onChange={(e)=>{setmeetinglink(e.target.value)}}></input>
   
    <p className="mb-0">Date</p>
    <input className="form-control" type="date" onChange={(e)=>{setdate(e.target.value)}}></input>
    <div className="flex mt-3">
    <input className="form-control" type="time"  onChange={(e)=>{setstarts(e.target.value)}}></input>
    <input className="form-control" type='time'  onChange={(e)=>{setends(e.target.value)}}></input>
    
    </div>
   
 
<button className="btn btn-primary w-100 mt-3" onClick={AddClass}>Schedule Class</button>

  </Box>
</Modal>











<Modal
  open={upload}
  onClose={()=>setupload(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...style, width: 500 }}>
  <UploadClassRecording  batch_id={batchid} course_url={course_url} classid={classid} class_starts={classstarts}/>
  </Box>
</Modal>
















<div className="flex text-white space-between align-items-center p-3 bg-dark">
<div>
<h3>Batch Schedule management</h3>
<p>Batch ID : {batchid}</p>
</div>

<button  onClick={()=>{setschedule(true)}} className="btn btn-primary">Add Class Schedule</button>
   
</div>




{chapters?



<>

<div className="d-flex flex-wrap">

{chapters.map((chapter,index)=>(

<>

<ScheduleItem item={chapter} className="col-lg-6" index={index}/>


</>


))}

</div>

</>
:<>

<div className="text-center mt-5">

  <h5>No Schedules</h5>
</div>

</>}
</div>
</>

  );
};

export default InternalClassSchedules;

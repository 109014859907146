import React from 'react'
import ENDPOINT from '../../context/EndPoint'

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import defaultdp from '../../assets/user.png'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
const InternLeaderboard = () => {






const {user} = useContext(AuthContext)



     
    const params = useParams();
    const internship_id = params.internshipid;
   
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    
    
    
    
    
    
    
    
    const [badges,setbadges] = useState(null);
    
    useEffect(()=>{
       
    
        const  getbadges = async ()=>{
      const result = await axios.post(`${ENDPOINT}interns-leaderboard.php?session=${user.SessionId}&internship_id=${internship_id}`)
      if(result){
          // // console.log("leaderboard "+result.data)
      }
      if(result.data.status==="success"&&result.data.leaderboard!==null){
          
          setbadges(result.data.leaderboard);
         
      }
                        
            
          
            
            }    ;
    getbadges();
           
    }
    
    ,[])
    
    

    
    
     return (
          <>
      
              <>
              
              
       <hr/>         
<div className="">


<div className="flex space-between ">
     <div className="col-6 pl-5">
<h5>Name</h5>
     </div>
     <div className="col-3 text-center">
          <h5>Effeciency</h5>
     </div>
     <div className="col-3 ">
          <h5>Rank</h5>
     </div>
</div>
<hr/>
<div className="flex-wrap">
{badges?
badges.map((user,index)=>(
<>
<div className="container-fluid p-2">
     <div className=" align-items-center space-between p-2 flex ">

<div className="flex col-6">
<img src={user.profile_pic?user.profile_pic:defaultdp} className="userdp mt-0"/>
   <div className="">
   <h6 className="truncate-1">{user.first_name+" "+user.last_name}</h6>
    <p className="mb-0 pb-0">{user.company_email}</p>
   </div>
</div>

<div className="col-3 text-center">
 
     <p>{user.efficiency}%</p>
</div>
<div className="col-3">
    
     <p>#{index+1}</p>
</div>
     </div>
</div>

</>

))
:null}























</div>














</div>    
              </>
       
          </>
     )
}

export default InternLeaderboard

import React from 'react'

const InternshipItemSkeleton = () => {
    return (
        <div className="internship-item bg-white col-lg-12 col-md-11 col-sm-12 p-10">
        <h1 className="internship-title shimmer h-30 col h-30 shimmer animate mt-2"> </h1>
        
        
   
        <hr className="mt-0 mt-4 mb-4 "/>
        <div className="short-info flex-wrap space-between mb-20 mt-4 mb-4">
        
       
        
        
        <div className="internship-info col-3 h-30 shimmer animate">
      </div>
        
        
        
      <div className="internship-info col-3 h-30 shimmer animate">
      </div>
        
        
      <div className="internship-info col-3 h-30 shimmer animate">
      </div>
        
        
        </div>
        <div className="bottom-flex">
        <div className="internship-tags">
        <div className="internship-info col-3 h-30 shimmer animate">
      </div>
        </div>
        
        
        
        </div>
        
        </div>
    )
}

export default InternshipItemSkeleton

import React, { useContext, useState } from 'react'

import { AttachFile, CloseOutlined, Delete, DoneAllOutlined, Edit, MoreVert, Schedule, Search, ThumbDown } from '@material-ui/icons'

import CustomDropdown from '../../../components/dropdowns/CustomDropdown'
import { Dropdown } from 'react-bootstrap'
import { Avatar, Chip } from '@mui/material'
import EvaluateTeamTaskModal from '../../../components/modals/EvaluateTeamTaskModal'
import axios from 'axios'
import ENDPOINT from '../../../context/EndPoint'
import { AuthContext } from '../../../context/AuthContext'

const InternTaskItem = ({item}) => {
    
  const {user} = useContext(AuthContext)
const [evaluate,setevaluate] =useState(false)
const CloseEvaluation = ()=>{

setevaluate(false)
}


const deleteTask = async ()=>{
  const formData = new FormData();
  formData.append('session',user.SessionId);
  formData.append('id',item.id);
  
  const res = await axios.post(ENDPOINT+"admin-delete-task.php",formData,{
    headers:{
      'content-type': 'multipart/form-data'
    }
  });
  
  
  if(res.data.status==="success"){
  
  }
  else{
  
  }
  }
  return (
    <>
    <EvaluateTeamTaskModal visibility={evaluate} handleClose={CloseEvaluation} item={item}/>
<div class="p-2 col-lg-6 col-md-6 col-sm-12">
      <div class="hover-active  rounded shadow p-4">
        <div class="d-flex flex-row justify-content-between">
          <h5 className="mb-0  ">{item.title}</h5>

          <div className="d-flex">
          <Chip size="small" label={item.status} className={item.status==="submitted"?"bg-success text-light":item.status==="pending"?"bg-warning text-light":"bg-danger text-light"}/>
     <CustomDropdown>
<>
  <Dropdown.Item  id={"delete"+item.id} onClick={()=>setevaluate(true)} className='p-2'><DoneAllOutlined className='mr-2'/>Evaluate</Dropdown.Item>
   <Dropdown.Item  onClick={()=>deleteTask()} className='p-2'><Delete className='mr-2'/>Delete</Dropdown.Item>

</>

     </CustomDropdown>
          </div>
       

        </div>
     
          <p class="text-secondary truncate truncate-2">{item.description}</p>
          <div class="d-flex flex-row align-items-end justify-content-between">
            <div class=" d-flex align-items-end">
              <Avatar src={item.intern.profile_pic} className="me-1"/>
              <div class="ml-2"><h6 class="mb-0">{item.intern.first_name+" "+item.intern.last_name}</h6><h6 class="mb-0 text-secondary">Efficiency:%</h6></div>
            </div>
            
            <div class="d-flex flex-row align-items-end">
            <Schedule className='me-1'/>Deadline  : {item.deadline} 
          
           <a href={item.solution_link} target="_blank">  <AttachFile className="ms-2"/></a>
         
            </div>
            
            
          </div>
      </div>
      </div>


    </>
  )
}

export default InternTaskItem
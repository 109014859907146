import React, { useContext } from 'react';
import {useState} from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import ENDPOINT from '../../../context/EndPoint'
import InternalTemplate from '../../../themes/InternalTemplate'
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit, MoreVert } from '@material-ui/icons';
import { Box, Modal } from '@material-ui/core';
import { Dropdown, Toast } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
const InternalTopicsManagement = () => {
const params = useParams();
const course_url = params.courseurl;
const chapter_id = params.chapterid;
const [topics,settopics] = useState(null)
const {user} = useContext(AuthContext)
const [edit,setedit] = useState(false)
const [currenttopic,setcurrenttopic] = useState(null)

const [total,settotal] = useState(0);
const getTopics = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);

  formData.append('course_url',course_url);
  formData.append('chapter_id',chapter_id);


const res = await axios.post(ENDPOINT+"admin-get-topics.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
  if(res.data.status==="success"){
  settopics(res.data.data)
  if(res.data.data){
    settotal(res.data.data.length)
  }else{
    settotal(0)
  }

 }
 else{

 }
}
}







const addTopicDB = async (status)=>{
        

  const formData = new FormData();
  
  
 
  formData.append('session',user.SessionId);
  
    formData.append('course_url',course_url);
    formData.append('chapter_id',chapter_id);
    formData.append('topic_title',cttitle);
    formData.append('topic_id',ctid);
  
    formData.append('cover_pic',coverpic);
  
  if(file){
    formData.append('resume',file);
  
  }
  const res = await axios.post(ENDPOINT+"admin-add-topic.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
  
   //// console.log("topics of"+course_url+JSON.stringify(res.data))
   if(res.data.status==="success"){
    getTopics()
    setcttitle("")
    setfile(null)
    setctid((total+1))
   }
   else{
  
   }
  }
  }
const [topicid,settopicid] = useState(null)
const StartEdit = (topic_id,index)=>{
  settopicid(topic_id)
  setcurrenttopic(index);
  setcttitle(topics[index].topic_title)
  setcoverpic(topics[index].cover_pic)
console.log(topic_id)

  setedit(true)
}
const [uploadprogress,setuploadprogress] = useState(null)
  const EditTopic = async ({payload})=>{
        

    const formData = new FormData();
    
    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //   // console.log("total "+progressEvent.total)
        setuploadprogress(percentCompleted)
    
  
      }
    }   
   
    formData.append('session',user.SessionId);
    

      formData.append('topic_id',topicid);
      formData.append('topic_title',cttitle);
      if(payload.cover_pic&&payload.cover_pic!=null){
        formData.append('resume',file);
      }
 formData.append("course_url",course_url)
     
    
    const res = await axios.post(ENDPOINT+"admin-edit-topic.php", formData,config,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     //// console.log("topics of"+course_url+JSON.stringify(res.data))
     if(res.data.status==="success"){
      getTopics()
   settoasttit("Edited successfully")
   settoast(true)
 
     }
     else{
    
     }
    }
    }
  

  const deleteTopic = async ({topic})=>{
        

    const formData = new FormData();
    
    
   
    formData.append('session',user.SessionId);
    
      
      formData.append('topic_id',topic.id);
    
    
    const res = await axios.post(ENDPOINT+"admin-delete-topic.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     //// console.log("topics of"+course_url+JSON.stringify(res.data))
     if(res.data[0].status==="success"){
      getTopics()
      setcttitle("")
      setctid((total+2))
     }
     else{
    
     }
    }
    }
  

useEffect(()=>{

getTopics();
},[])









const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};



const [ctid,setctid] = useState(null)
const [cttitle,setcttitle] = useState(null);
const [ctdata,setctdata] = useState(null);
const [ctduration,setctduration] = useState(null)


const addTopic = ()=>{
  var total_topics;
  if(topics){
     total_topics = (topics.length+1);
  }
else{
   total_topics = 0;
}
  //// console.log("total topics "+total_topics)

  setcurrenttopic(total_topics+1);
  setcttitle("")
setctduration(0.0)
setctdata(" ")
setadd(true)

}



const [coverpic,setcoverpic] = useState(null)

const [add,setadd] = useState(false)
const [toast,settoast] = useState(false)
const [toasttitle,settoasttit] = useState(false)




const [file,setfile] = useState(null)

const  onChange = (e)=> {
  setfile(e.target.files[0])

}





  return (<>
  <>



<div className="toast-container">

<Toast className="bg-warning"  onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
        
          </Toast.Header>
            </Toast>
</div>


<Modal
  open={edit}
  onClose={()=>setedit(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...style, width: 500 }}>
  <p>Title  :</p>
    <input className="form-control" type="text"  value={cttitle} onChange={(e)=>{setcttitle(e.target.value)}}></input>
    <br/>
    <br/>
    <p>Cover Pic:</p>
    <input className="form-control"  type="file"  onChange={(e)=>{onChange(e)}}></input>
    
{file?
<>
<img src={file} className="w-100 mt-2 mb-2"/>

</>:
<>

</>}
   
 
<button className="btn btn-primary w-100 mt-3" onClick={()=>EditTopic({payload:{cover_pic:file}})}>Save Changes</button>
  </Box>
</Modal>








<Modal
  open={add}
  onClose={()=>setadd(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...style, width: 500 }}>
  <p>Add Topic</p>
    <input className="form-control" type="text"  value={cttitle} onChange={(e)=>{setcttitle(e.target.value)}}></input>
    <br/>
    <br/>
    <p>Cover Pic:</p>
    <input className="form-control"  type="file"  onChange={(e)=>{onChange(e)}}></input>
    
    <p>Topic ID :</p>
    <input className="form-control" value={ctid} type="number" minLength={1} maxLength={2} onChange={(e)=>{setctid(e.target.value)}}></input>
    
   
 
<button className="btn btn-primary w-100 mt-3" onClick={addTopicDB}>Add Topic</button>
  </Box>
</Modal>







<div className="container-fluid mt-3">

<div className="flex-wrap d-flex justify-content-between">
<h1>Topic management</h1>


<button  onClick={addTopic} class="btn bg-primary h-fit text-white">Add Topic </button>


  
</div>

<hr></hr>
<div className="d-flex flex-wrap">
{topics?<>
{topics.map((topic,index)=>(
<>


<div class="col-lg-3 col-md-4 col-sm-6 p-2">
      <div class="shadow rounded p-3 d-flex d-sm-block d-md-block d-lg-block">
      <div class="width-class-25"> <img src={topic.cover_image} class="w-100 h-100"/></div>
      <div class="d-flex flex-row width-class-75">
      <div class="mt-0 mt-sm-3 ml-2 ml-sm-0 w-100">
              <h6 >{index+1}.{topic.topic_title}</h6>
              <p class="mb-0"> {topic.description}</p>
      
          </div>
          <div class="mt-0 mt-sm-3">
     <Dropdown class="options-dropdown">
                <Dropdown.Toggle className="bg-dark options p-0 w-fit h-fit  border-0">
                  <MoreVert className="taxt-dark"/>
                  
                </Dropdown.Toggle>
                <DropdownMenu>
                  <DropdownItem  onClick={()=>StartEdit(topic.id,index)}><Edit className="mr-2"/>Edit</DropdownItem>
                  <DropdownItem onClick={()=>deleteTopic({topic})} ><DeleteIcon className='mr-2'/> Delete</DropdownItem>
                </DropdownMenu>
              </Dropdown>
     </div>
      </div>
    
      </div>   
  </div>
  


</>


))}

</>
:"no topics"}
</div>
</div>

</>

  </> );
};

export default InternalTopicsManagement;

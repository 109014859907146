import React from "react";

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Avatar } from "@material-ui/core";

import { AuthContext } from "../../../context/AuthContext";
import InternshipItemSkeleton from "../../../skeleton/InternshipItemSkeleton";

import { Dropdown, Modal } from "react-bootstrap";
import { MoreVert } from "@material-ui/icons";
import ENDPOINT from "../../../context/EndPoint";
import UpdateStudentStatistics from "./UpdateStudentStatistics";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import InternalTemplate from "../../../themes/InternalTemplate";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import AdminGetStudentsList from "../../../actions/student/AdminGetStudentsList";

const TrainerStudentsList = ({ page, course, props }) => {
  const params = useParams();

  const course_url = params.courseurl;
  const { user } = useContext(AuthContext);
  const userdata = user;

  const [query, setquery] = useState(null);
  const [category, setcategory] = useState("all");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState("start");

  const StudentsList = async () => {
    const res = await AdminGetStudentsList({
      payload: {
        session: user.SessionId,
        mode: mode,
        query: query,
      url:course_url?course_url:null
      
      },
    });

    if (res) {
      console.log(res)
      if (res.status === "success") {
      
        setinternships(res.data.data);
      } else {
      }
    }
  };

  useEffect(() => {
    StudentsList();
  }, [category]);

  const [mode, setmode] = useState(null);

  const [AddPoints, setAddPoints] = useState(false);

  const showAddPoints = () => {
    setAddPoints(true);
  };

  const closeAddPoints = () => {
    setAddPoints(false);
  };

  const [editstats, seteditstats] = useState(false);
  const [studentid, setstudentid] = useState(null);

  const editStatistics = (studentid) => {
    setstudentid(studentid);
    //// console.log("student id"+studentid)
    seteditstats(true);
  };

  return (
    <>
      <div className=" flex-wrap">
        <Link
          to={
            "/internal/trainings-management/courses/" +
            course_url +
            "/students/all"
          }
          className="col-lg-3 p-1"
        >
          <div className="shadow p-1">
            <p>All</p>
          </div>
        </Link>

        <Link
          to={
            "/internal/trainings-management/courses/" +
            course_url +
            "/students/offline"
          }
          className="col-lg-3 p-1"
        >
          <div className="shadow p-1">
            <p>Offline</p>
          </div>
        </Link>
        <Link
          to={
            "/internal/trainings-management/courses/" +
            course_url +
            "/students/live"
          }
          className="col-lg-3 p-1"
        >
          <div className="shadow p-1">
            <p>Online</p>
          </div>
        </Link>

        <Link
          to={
            "/internal/trainings-management/courses/" +
            course_url +
            "/students/selfpaced"
          }
          className="col-lg-3 p-1"
        >
          <div className="shadow p-1">
            <p>Selfpaced</p>
          </div>
        </Link>

        <Link
          to={
            "/internal/trainings-management/courses/" +
            course_url +
            "/students/specialization"
          }
          className="col-lg-3 p-1"
        >
          <div className="shadow p-1">
            <p>Specialization</p>
          </div>
        </Link>
      </div>

      <Modal
        className="min-vh-90"
        {...props}
        size="xl"
        centered
        show={editstats}
        onHide={() => seteditstats(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b className="text-primary">Student Statistics</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateStudentStatistics studentid={studentid} />
        </Modal.Body>
      </Modal>

      <Modal show={AddPoints} onHide={closeAddPoints}>
        <Modal.Header closeButton>
          <Modal.Title>Add Points</Modal.Title>
        </Modal.Header>
      </Modal>

      <div className="internships-page flex-wrap mobile-reverse ">
        <div className="flex align-items-center">
          <div className="container-fluid ">
            <h4 className="mb-4">
              {internships ? internships.length : 0} Students
            </h4>
          </div>

          <div>
            <select>
              <option value="live">Live Online Mode</option>
              <option value="offline">Offline Mode</option>
              <option value="selfpaced">Selfpaced Mode</option>
              <option value="specialization">Selfpaced Mode</option>
            </select>
          </div>
        </div>

        <div className="container-fluid">
          <div className="flex mt-5 align-items-center space-between mb-4">
            <div className="flex col-lg-9">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>

                <Select
                  value={category}
                  label="Category"
                  onChange={(e) => setcategory(e.target.value)}
                >
                  <MenuItem value={"all"}>All </MenuItem>
                  <MenuItem value={"joined"}>Joined</MenuItem>
                  <MenuItem value={"selected"}>Selected</MenuItem>
                  <MenuItem value={"shortlisted"}>Shortlisted</MenuItem>
                  <MenuItem value={"rejected"}>Rejected</MenuItem>
                </Select>
              </FormControl>

              <Paper
                onSubmit={(e) => {
                  e.preventDefault();
                  StudentsList()
                }}
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  onChange={(e) => setquery(e.target.value)}
                  placeholder="Search Email or Phone"
                  inputProps={{ "aria-label": "Search Batch ID " }}
                />
                <IconButton
                  onClick={() => StudentsList()}
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            
          </div>
        </div>

        <div className="internships-list  sm-p-0  container-fluid">
          {internships === "start" ? (
            <div className="container-fluid">
              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />
            </div>
          ) : internships ? (
            internships.map((internship) => (
              <>
                <div className="flex container-fluid align-items-center space-between shadow p-3 mb-2">
                  <div className="flex col">
                    <Avatar src={internship.profile_pic} className="mr-2" />
                    <div>
                      <p className="mb-0">
                        {internship.first_name + " " + internship.last_name}
                      </p>
                      <p className="mb-0">{internship.email}</p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-4">
                    <p className="mb-0">{internship.create_datetime}</p>
                  </div>

                  <Dropdown className="options-dropdown">
                    <Dropdown.Toggle className="bg-dark text-light options p-0 w-fit h-fit  border-0">
                      <MoreVert className="text-light" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-dark text-white">
                      <Dropdown.Item className="text-white">
                        Schedule a Meet
                      </Dropdown.Item>
                      <Dropdown.Item className="text-white">
                        Gift a reward
                      </Dropdown.Item>
                      <Dropdown.Item className="text-white">
                        Send a Notification
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-white"
                        onClick={() => {
                          editStatistics(internship.user_id);
                        }}
                      >
                        Update Statistics
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ))
          ) : (
            <div className="container-fluid">No Internships</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TrainerStudentsList;

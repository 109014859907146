import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Container, Dropdown } from 'react-bootstrap'
import {  MoreVert } from '@material-ui/icons'
import dateFormat from 'dateformat'
import ENDPOINT from '../../../context/EndPoint'

import { Toast } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { Avatar, TextField } from '@material-ui/core'
import Scroll from 'react-scroll'
import { CheckCircleOutlineOutlined, ErrorOutlineOutlined, StarBorderPurple500 } from '@mui/icons-material'






const AdminInternalLeaves = ({ page,role}) => {
const params = useParams();

const [category,setcategory] = useState("all")

const {user} = useContext(AuthContext)


const [edit,setedit] = useState(false);


const tasktype= useRef()

const changeTaskStatus = (e)=>{
 
  settaskstatus(tasktype.current.value);
  getTasks(tasktype.current.value);

  
}

useEffect(() => {
    window.scrollTo(0, 0)
    getTasks();
  }, [category])


    const [tasks,settasks] = useState("start");

const [taskstatus,settaskstatus]= useState("all")
    
    const [taskid,settaskid] = useState(null)
    const [remove,setremove] = useState(false)
    const [removeid,setremoveid] = useState(null);
    const [submit,setsubmit] = useState(false);

const showSubmit =(e)=>{
  const id= e.target.id;
settaskid((id.replace("submit","")));
setsubmit(true)
}


    const closeSubmit = ()=>{
      setsubmit(false);
    }
    const editTask = (e)=>{

const id= e.target.id;
settaskid((id.replace("edit","")));


setedit(true)
    }

const closeEdit = ()=>{
  setedit(false)

}
const [add,setadd] = useState(false)

const closeAddition =()=>{
  setadd(false)
  getTasks(taskstatus);
}

const showRemove = (e)=>{
    
  const id= e.target.id;
  
settaskid(id.replace("delete",""));
  setremove(true)
  
  

}
const closeRemove = ()=>{
  setremove(false)
}




useEffect(() => {
 getTasks()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [category])





const getTasks = async ()=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)
// console.log("getting")
formData.append('session',user.SessionId);


  formData.append('status',category);
  if(query){
    formData.append('query',query);
  }
//// console.log("getting leads")
const res = await axios.post(ENDPOINT+"admin-get-leaves.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){



 if(res.data.status==="success"){
   if(res.data.leaves){
console.log(res.data.leaves)
   
    setInitialData(res.data.leaves)
    setResponse(res.data.leaves)
   }
 }
 else{

 }
}
}

const [toast,settoast] = useState(false);
const [toasttitle,settoasttitle] = useState(null)

const [toastmessage,settoastmessage] = useState(null)


const [query,setquery] = useState(null)

const  updateRequest = async ({ticketstatus,ticketid})=>{

    if (user!==null){

        fetch(`${ENDPOINT}admin-update-lead.php?session=${user.SessionId}&status=${ticketstatus}&id=${ticketid}`)
        .then(r=>r.json())
        .then(result=>{
       // console.log(JSON.stringify(result))
                if(result.status==="success"){
settoasttitle("Successful")
settoastmessage("Status changed to "+ticketstatus)
settoast(true)
getTasks()
                }
                else{
                    settoasttitle("Something went wrong !")
                    settoastmessage("Please try again... ")
                    settoast(true)
                  
                }
           
           
            
        })
    }
    else{
    
    }
      
        
        }    ;

        const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
         minWidth:400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        };
           
        
const loadLeads = ()=>{

  getTasks();
  setaddlead(false)
}


const [addlead,setaddlead] = useState(false)





const [lead,setlead] = useState(null)



const  ChangeLeaveStatus = async ({payload})=>{


  if (user!==null){

const data = new FormData();
data.append('id',payload.id);
data.append("status",payload.status)
data.append('session',user.SessionId)

const res = await axios.post(ENDPOINT+"change-leave-status.php",data,{
  headers:{'content-type':'multipart/form-data'}
})
if(res){
// console.log("lead "+JSON.stringify(res))
  if(res.status==="success"){
    settoasttitle("Leave updated successfully")
  setedit(false)
  settoast(true)
    getTasks();
  
  }
  else{
    settoasttitle("Something went wrong !")
    settoastmessage("Please try again")
    settoast(true)
  }
  

}


  
  }
 
      
      }    ;






const [currentid,setcurrentid] = useState(null)
const [leadname,setleadname] = useState(null)
const [lemail,setlemail] = useState(null);
const [lphone,setlphone] = useState(null)
const [lwhatsapp,setlwhatsapp] = useState(null)
const [lnote,setlnote] = useState(null)
const [lstatus,setlstatus] = useState(null);
const [lcategory,setlcategory] = useState(null)

const [initialData, setInitialData] = useState([]);
const [response, setResponse] = useState([]);
const [searchTerm, setSearchTerm] = useState('');

const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();

  const filteredData = initialData.filter((item) => {
    return (
      item.leave_type.toLowerCase().includes(term) ||
      item.status.toLowerCase().includes(term) ||
      item.first_name.toLowerCase().includes(term) ||
      item.last_name.toLowerCase().includes(term)
    );
  });

  setResponse(filteredData);
  setSearchTerm(term);
};


    return (
        <>












<div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>
        
           
<div className="container-fluid">



<div className="flex  align-items-center space-between mb-2">





<div className="container-fluid flex ">


<h4  className="sm-18 mb-0"><b className="me-2">{tasks?tasks.length:0}</b>{category==="all"?"Total":category} requests </h4>



 

</div>
 
</div>





{/* edited by Bharati */}


  
<Container fluid className="mb-3">
      
      <div>
   
      <TextField
        label="Search Leave Type, status, Name"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => handleSearch(e)}
        fullWidth
        margin="normal"
      />
    
    </div>
            </Container>



 <div className="internships-list d-flex flex-wrap  container-fluid">

{ response==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>

:response?response.map((internship,index)=>(

<>




 <>

<di className="col-lg-6 p-1">
<div className="col-lg-12 ">
<div class="shadow p-3 m mb-2">
  <div class="flex space-between">
   
  <div className="flex  align-items-center">
  <Avatar className="me-2"src={internship.profile_pic}/>
 

  <div>

  <h6 class="mb-0 ml-2"> {internship.first_name+" "+internship.last_name}</h6>
  <p className="mb-0 ml-2">{internship.time}</p>
  </div>
  </div>
  <div>
    
  </div>
  <div className="d-flex align-items-center">
    {
      internship.status==="accepted"?
      <chip class="hero mr-2 ">Accepted</chip>:  <chip class="text-danger mr-2 ">pending</chip>
    }


  
    <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0 m-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
  

  <Dropdown.Item className="p-0" onClick={()=>ChangeLeaveStatus({payload:{id:internship.id,status:"accepted"}})}><CheckCircleOutlineOutlined className="mr-1"/>Accept</Dropdown.Item>
  <Dropdown.Item className="p-0" onClick={()=>ChangeLeaveStatus({payload:{id:internship.id,status:"review"}})}><StarBorderPurple500 className="mr-1"/>Mark for review</Dropdown.Item>
 
  <Dropdown.Item className="p-0" onClick={()=>ChangeLeaveStatus({payload:{id:internship.id,status:"rejected"}})}><ErrorOutlineOutlined className="mr-1"/>Rejected</Dropdown.Item>
  
  </Dropdown.Menu>
</Dropdown>

      </div>
      

      </div>
      <hr/>
      <div className="flex container-fluid">
  <div className="col-8  p-0">
  <p><b>Category : </b>General Leave</p>
  <p>  <b>Date : </b>{internship.start_time+" "+internship.end_time}</p>
  
  </div>
  <div className="col-4 p-0">
 <p><b>Leaves remaining : </b></p>
 

 
  </div>
</div>





  
  
    
  </div>
  



  </div>

</di>

  
  </> 


</>
)):
<div className="container-fluid">

No Leaves

    </div>

}





</div>


 



</div>




  




 





        </>
    )
}

export default AdminInternalLeaves


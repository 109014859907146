import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
    Avatar,
    LinearProgress,
    TextField,
    Typography,
  } from "@material-ui/core";
  import {  Dropdown, Toast } from "react-bootstrap";
import dateFormat from "dateformat";
import { MoreVert } from "@material-ui/icons";
  import axios from "axios";
  import InputLabel from "@mui/material/InputLabel";
  import FormControl from "@mui/material/FormControl";
  
import PropTypes from "prop-types";
  import SearchIcon from "@mui/icons-material/Search";
  import Paper from "@mui/material/Paper";
  import InputBase from "@mui/material/InputBase";
  import IconButton from "@mui/material/IconButton";
import InternAddMeetingModal from '../../components/modals/InternAddMeetingModal';
import InternSendNotificationModal from '../../components/modals/InternSendNotificationModal';
import InternAddTeamTaskModal from '../../components/modals/AddAddTeamTaskModal';
  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

  function LinearWithValueLabel() {
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 10 : prevProgress + 10
        );
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);
  }
const TeamItem = ({item,index}) => {


    const [notification,setnotification] = useState(false)
    const [update,setupdate] = useState(false)
    const [schedule,setschedule] = useState(false)
    const [addmeeting,setaddmeeting] = useState(false)
    const CloseMeetingModal = ()=>{
    
    setaddmeeting(false)}


    const [sendnotification,setsendnotification] = useState(false)
     const closeNotificationModal = ()=>{
    setsendnotification(false)}


    const [addtask,setaddtask] = useState(false)

    const CloseTaskModal = ()=>{
    
    setaddtask(false)
  }
  
  return (
    <>

<InternSendNotificationModal item={item} visibility={sendnotification} handleClose={closeNotificationModal}/>
<InternAddMeetingModal item={item} visibility={addmeeting} handleClose={CloseMeetingModal}/>
<InternAddTeamTaskModal item={item} visibility={addtask} handleClose={CloseTaskModal}/>
<div className="col-lg-4 p-2">
                  <div
                    key={index}
                    className="sm-p-15 container-fluid  border rounded p-3 mb-2"
                  >
                    <div className="flex w-100  space-between align-items-center mb-2">
                      <div className="w-100">
                        <div className="flex w-100 align-items-center ">
                          <Avatar
                            src={item.profile_pic}
                            className="me-2"
                          />
                          <div>
                            <h5 className="mb-0">
                              {item.first_name +
                                " " +
                                item.last_name}{" "}
                            </h5>
                            <p className="mb-0">
                              {item.role
                                ? item.role
                                : "Role Unassigned"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <Dropdown className="options-dropdown">
                        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                          <MoreVert className="text-dark" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                               
                              setaddmeeting(true)
                            }}
                          >
                            Schedule Meeting
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                        
                              setsendnotification(true);
                            }}
                          >
                            Send Notification
                          </Dropdown.Item>

                        
                          <Dropdown.Item
                            onClick={() => {
                                setupdate(true);
                            }}
                          >
                            Update Profile
                          </Dropdown.Item>

                          <Dropdown.Item
                           
                          >
                            <Link
                              to={"/internal/permissions/" + item.user_id}
                            >
                              Permissions
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <LinearProgressWithLabel
                      value={item.efficiency}
                    />
                    <div className="d-flex justify-content-between mt-2">
                      <p>
                        Total : {item.total_tasks} 
                      </p>
                      <p>{item.working_status?item.working_status:"Logged Out"}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Submitted: {item.submitted_tasks}</p>
                      <p>Pending: {item.pending_tasks}</p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <Link to={"/work-history/"+item.user_id+"/internship/"+item.internship_id} className="btn btn-outline-primary">Work History</Link>

                      <button
                        onClick={() => {
                          setaddtask(true)
                        }}
                        className="btn btn-primary "
                      >
                        Add Task
                      </button>
                    </div>
                  </div>
                </div>

    </>
  )
}

export default TeamItem
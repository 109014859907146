import React from 'react'

import HomeTemplate from '../../themes/HomeTemplate'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import defaultboy from '../../assets/user.png'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
 

  LinkedinIcon,
 
  LinkedinShareButton,

 
  TelegramIcon,

 
  TelegramShareButton,
  
  TwitterIcon,
  
  TwitterShareButton,

  WhatsappIcon,

  WhatsappShareButton,

} from "react-share";
import ENDPOINT from '../../context/EndPoint'
import { PlayCircle } from '@mui/icons-material'
const RewardEarnedPage = ({userdata,props}) => {
const params = useParams();
const badge_id = params.badgeid;


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    
    
    
    
    
    
    
    
   
    
    //get course badges


    
    
    const [badge,setbadge] = useState(null);
    
    useEffect(()=>{
       
    
        const  getbadge = async ()=>{
       //   // console.log(badge_id)
      const result2 =  await 
      axios.post(`${ENDPOINT}personal-badge.php?badge_id=${badge_id}`);
  
  if(result2!==null){
      const list = result2.data;
      setbadge(list.data);
   //  // console.log("bdage "+JSON.stringify(result2))
                    
  }          
            }
            
            
    getbadge();
           
    }
    
    ,[])
    
    
    
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
  
  




    return (

<>


<Modal show={show} onHide={handleClose} 

{...props}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered
>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <video height="auto" autoPlay width="auto" src="https://skillsuprise.com/videos/rewards-demo.mp4" title="Offline Trainings Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen controls controlsList="nodownload"></video>
     
    </Modal>

    
<RewardsStyled >












{/*badge section start*/}




{badge?


<>

<div className="container-fluid blue-bg sm-p-15 min-vh-90  pt-5 pb-5">
<div className="flex-wrap space-evenly">

<div className="col-lg-4">


<div className="justify-center w-100">
<div className=" p-2 text-center  bg-white br-4 shadow col-10">
<img className="w-50 rounded-circle" src={badge.profile_pic?badge.profile_pic:defaultboy}/>
<h3 className="mt-3 sm-22 text-orangered">{badge.title}</h3>


<p>Rewarded To</p>
<h4 className="sm-18">{badge.first_name+" "+badge.last_name}</h4>



  </div>



</div>


<div className="justify-center">
  <div className="col-10">
  <hr/>
  </div>
</div>

  <div className="justify-center">
<div className="col-10 bg-white br-4 shadow p-0">
<h5 className="p-2 sm-18 text-center">Share this achievement</h5>
<div className="flex p-2 br-4 bg-white w-100 space-evenly">
<WhatsappShareButton size={30}   round={true} url={window.location.href}><WhatsappIcon size={30}  type="button"  round={true} url={window.location.href} className="mr-2"/></WhatsappShareButton>
<LinkedinShareButton size={30}   round={true} url={window.location.href}><LinkedinIcon size={30}  type="button"  round={true} url={window.location.href} className="mr-2"/></LinkedinShareButton>
<FacebookShareButton size={35}  round={true} url={window.location.href}> <FacebookIcon size={30} round={true} className="mr-2"/></FacebookShareButton>
<TelegramShareButton size={30}   round={true} url={window.location.href}><TelegramIcon size={30}  type="button"  round={true} url={window.location.href} className="mr-2"/></TelegramShareButton>
<TwitterShareButton size={30}   round={true} url={window.location.href}><TwitterIcon size={30}  type="button"  round={true} url={window.location.href} className="mr-2"/></TwitterShareButton>
<EmailShareButton size={30}   round={true} url={window.location.href}><EmailIcon size={30}  type="button"  round={true} url={window.location.href} className="mr-2"/></EmailShareButton>


</div>

</div>


  </div>

 </div>


<div className="col-lg-6 sm-mt-5 bg-white h-fit p-3 br-4 shadow">

<div className="">
<h3 className="sm-22">Reward Description</h3>
  <p>{badge.description}</p>

</div>
</div>




</div>



</div>


</>
:

<>

</>}







</RewardsStyled>
</>
    )
}

export default RewardEarnedPage







export const RewardsStyled = styled.div`

`
import React from 'react'

import { AttachFile, CloseOutlined, Delete, DoneAllOutlined, Edit, MoreVert, Schedule, Search, ThumbDown } from '@material-ui/icons'

import { Dropdown, Modal, Toast } from 'react-bootstrap'
const CustomDropdown = ({children}) => {
  return (
    <>
      <Dropdown className="options-dropdown">   
        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
        <MoreVert className="text-dark"/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
        
        {children}
         </Dropdown.Menu>
      </Dropdown>




    </>
  )
}

export default CustomDropdown
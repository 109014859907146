import React, { useContext, useEffect, useState } from 'react'
import CustomModal from './CustomModal';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import { AuthContext } from '../../context/AuthContext';
import SecurityHeaders from '../../context/SecurityHeaders';
import { Snackbar } from '@mui/material';
import CircularProgress from '../progress/CircularProgress';

const EvaluateTeamTaskModal = ({visibility,handleClose,item}) => {
    const { user } = useContext(AuthContext);

    const [title, settitle] = useState(null);

    const [earnedmarks, setearnedmarks] = useState(null);
    const arr = [];
    const [corrections, setcorrections] = useState(arr);
   
    useEffect(() => {
      const getaccount = async () => {
        const data = new FormData();
        data.append("session", user.SessionId);
        data.append("id", item.id);
  data.append("action","get")
        const res = await axios.post(
          `${ENDPOINT}evaluate-team-task.php`,
          data,
          SecurityHeaders
        );
  
        if (res) {
          if (res.data.status === "success" && res.data.data != null) {
            const data = res.data.data;
         setcorrections(data.corrections)
         setearnedmarks(data.marks_received)
          } else {
          }
        } else {
        }
  
      
      };
  
      if (user !== null || user !== "null") {
        getaccount();
      }
    }, [user]);

    const [status, setstatus] = useState(false);
  
    const [snackbar, setsnackbar] = useState(false);
    const [snackbarMessage, setsnackbarMessage] = useState(null);
  
    const onSubmit = async (e) => {
      e.preventDefault();
      setstatus(true);
  
      const data = new FormData();
    
      data.append("marks_received", earnedmarks);
      data.append("id", item.id);
      data.append("corrections", corrections);
data.append("action","update")
      data.append("session", user.SessionId);
      const res = await axios.post(ENDPOINT + "evaluate-team-task.php", data, {
        headers: { "content-type": "form-data/multipart" },
      });
  
      if (res) {
        if (res.data[0].status === "success") {
          setstatus(false);
          setsnackbarMessage("Changes Saved Successfully");
          setsnackbar(true);
        } else {
          setstatus(false);
  
          setsnackbarMessage("Something went wrong");
          setsnackbar(true);
        }
      }
    };
  
    const [time, settime] = useState(null);
  
    const [date, setdate] = useState(null);
  return (
    <>

    <CustomModal visibility={visibility} handleClose={handleClose}>
    <div>
    <div className="flex-wrap ">
            <div className="w-100">
              <form onSubmit={onSubmit}>
                <div class="form-row w-100">
                  <Snackbar
                    className="bg-success text-light"
                    open={snackbar}
                    autoHideDuration={6000}
                    onClose={() => setsnackbar(false)}
                    message={snackbarMessage}
                  />

                  <div className="form-row w-100">
                    <div class="form-group col-12">
                      <label for="inputPassword4">Marks Given</label>
                      <input
                        type="number"
                        class="form-control "
                        id="inputPassword4"
                        value={earnedmarks}
                        onChange={(e)=>setearnedmarks(e.target.value)}
                        maxLength={3}
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group mt-2  w-100">
                  <label for="inputPassword4">Corrections</label>
                  <textarea
                    type="text"
                    class="form-control"
                    id="inputPassword4"
                    value={corrections}
                    onChange={(e)=>setcorrections(e.target.value)}
                    maxLength={500}
                  />
                </div>

                <div class="form-group"></div>
                <button
                  type="submit"
                  class="btn mt-3 btn-primary w-100"
                  onClick={onSubmit}
                >
                  {status ? (
                    <CircularProgress className="text-white" size={20} />
                  ) : (
                    ""
                  )}
                  Submit
                </button>
              </form>
            </div>
          </div>
   
    </div>

    </CustomModal>



    </>
  )
}

export default EvaluateTeamTaskModal
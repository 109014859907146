import React, { useContext, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import axios from 'axios';
import AddDailyReportRequest from '../../../actions/team/AddDailyReport';
import { isUnitless } from '@mui/material/styles/cssUtils';
import { AuthContext } from '../../../context/AuthContext';

const AddDailyReport = () => {

const {user} = useContext(AuthContext)
const [report,setreport] = useState(null)
const payload = {SessionId:user.SessionId,report:report}
const AddReport = async(e)=>{
e.preventDefault()

const res = await AddDailyReportRequest({payload:payload})

}
  return (
    <div>
        <div className="container-fluid">


<div className="col-lg-7 pt-3">

     <form onSubmit={(e)=>AddReport(e)}>

     <textarea onChange={(e)=>setreport(e.target.value)} required className="form-control min-vh-50" placeholder="Enter Daily Report"></textarea>
     
     <button className="btn btn-primary w-100 mt-3">Save Report</button>

     </form>
</div>
         </div>
        
        </div>
  )
}

export default AddDailyReport
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import AdminGetStudentDoubts from '../../../actions/student/AdminGetStudentDoubts'
import { AuthContext } from '../../../context/AuthContext'

const DoubtRequests = ({profile}) => {


const {user} = useContext(AuthContext)

const [submissions,setsubmissions] = useState(null)
const GetSubmissions = async()=>{

     const res = await AdminGetStudentDoubts({payload:{
          user:user.SessionId,
          course_url:profile.course_url,
          user_id:profile.user_id
     }})
     if(res){
          if(res.status==="success"){
               setsubmissions(res.data)
          }
     }
}

useEffect(()=>{
GetSubmissions()
},[profile])
  return (
    <div>
     {submissions?
     <>
   {submissions.map((item)=>(
     <>

<div className="w-100">
<div className="w-100">
     <h6>{item.message}</h6>
     <div className="d-flex">

<div className="col">
<p className="extra-small mb-0">Subject : {item.subject}</p>
     
</div>
<div className="col">
<p className="extra-small mb-0">Mode : {item.category}</p>
     
</div>
     <p className="extra-small mb-0">Status : {item.status}</p>
     </div>
    
     <div className="d-flex">
          <div className="col">
               <p className="mb-0 extra-small">Created : {item.create_datetime}</p>
          </div>
          <div className="col">
               <p className="mb-0 extra-small">Initiatedd: {item.initiated_datetime}</p>
          </div>
          <div className="col">
               <p className="mb-0 extra-small">Resolved: {item.resolved_datetime}</p>
          </div>
     </div>
     <p className="mb-2 extra-small">{item.description}</p>

<div className="d-flex justify-content-between">
<a href={item.solution_link} className="btn btn-sm rounded-pill btn-outline-primary">Download Solution</a>
     <button className="btn btn-sm btn-primary rounded-pill">Evaluate</button>
</div>

</div>



     </div>
     <hr/>
     </>
   ))}
     </>:null}
     </div>
  )
}

export default DoubtRequests
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import InternalTemplate from "../../../themes/InternalTemplate";
import { Avatar } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContext";
import InternshipItemSkeleton from "../../../skeleton/InternshipItemSkeleton";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import { Dropdown } from "react-bootstrap";
import ENDPOINT from "../../../context/EndPoint";
import { MoreVert } from "@material-ui/icons";
import { Container } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import Chip from '@mui/material/Chip';
const InternalCourseReviews = ({ page }) => {
  const { user } = useContext(AuthContext);
  const userdata = user;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState("start");

  useEffect(() => {
    getSupportLogs();
  }, []);

  const getSupportLogs = async () => {
    if (userdata !== null) {
      fetch(`${ENDPOINT}reviews.php?session=${user.SessionId} `)
        .then((r) => r.json())
        .then((result) => {
          setinternships(result.data);
          setResponse(result.data)
          setInitialData(result.data)

          // console.log("user", JSON.stringify(result))
        });
    } else {
    }
  };

  const [value, setValue] = React.useState(internships[0]);
  const [inputValue, setInputValue] = React.useState("");

  const SearchChange = () => {
    // // console.log("search changed"+value)
  };

  const [category, setcategory] = useState("all");
  const [query, setquery] = useState(null);

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.phone.toLowerCase().includes(term) ||
        item.email.toLowerCase().includes(term) ||
      
        item.name.toLowerCase().includes(term) 
     
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <>
      <div className=" flex-wrap  ">
        <div className="container-fluid  ">
          <div className="flex  align-items-center space-between mb-4">
            {/* <div className="flex col-lg-9">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>

                <Select
                  value={category}
                  label="Category"
                  onChange={(e) => setcategory(e.target.value)}
                >
                  <MenuItem value={"all"}>All </MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"inactive"}>De-Activated</MenuItem>
                </Select>
              </FormControl>

              <Paper
                onSubmit={(e) => {
                  e.preventDefault();
                  getSupportLogs();
                }}
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  onChange={(e) => setquery(e.target.value)}
                  placeholder="Search Name, Email or Phone"
                  inputProps={{ "aria-label": "Search Batch ID " }}
                />
                <IconButton
                  onClick={() => getSupportLogs()}
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div> */}

            <Container fluid className="mb-3 col-8">
              <div>
                <TextField
                  label="Search..."
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e)}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Container>

            <h4>{internships ? internships.length : 0} Support Requests</h4>
          </div>

          <hr />
        </div>
        <div className="internships-list  sm-p-0  container-fluid">
          {response === "start" ? (
            <div className="container-fluid">
              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />

              <InternshipItemSkeleton />
            </div>
          ) : response ? (
            response.map((internship) => (
              <>
                <div
                  className={
                    "flex container-fluid space-between align-items-center shadow p-3 mb-2 " 
               
                  }
                >
                  <div className="flex col-lg-4 col-sm-6">
                    <Avatar src={internship.profile_pic} className="me-2" />
                    <div>
                      <p className="mb-0">
                        {" "}
                        {internship.name + "( " + internship.phone + " )"}{" "}
                      </p>
                      <p className="mb-0">{internship.email}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <p className="truncate truncate-3">
                      <b>{internship.rating} : </b>
                      {internship.review}
                    </p>
                  </div>

            

                  <p>{internship.status}</p>



                  <Dropdown className="options-dropdown">
                    <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                      <MoreVert className="text-dark" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">Schedules</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ))
          ) : (
            <div className="container-fluid">No Internships</div>
          )}
        </div>
      </div>
    </>
  );
};

export default InternalCourseReviews;

import { Avatar } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext'
import ENDPOINT from '../../../context/EndPoint'
import SecurityHeaders from '../../../context/SecurityHeaders'
import dateFormat from 'dateformat'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const WhatsAppSupport = () => {
const {user} = useContext(AuthContext)

const params = useParams()
const phone_number = params.phone_number;
const GetChats = async ()=>{
  setloading(true)
const data = new FormData()
data.append("session",user.SessionId)
data.append("phone",phone_number)
  const res = await axios.post(ENDPOINT+"whatsapp-chats.php",data,SecurityHeaders)

  if(res){
setloading(false)
if(res.data.status==="success"){

  
 
  setchats(res.data.chat_heads)
  setmessages(res.data.messages)
}
    console.log(JSON.stringify(res.data))
  }
}
useEffect(()=>{
GetChats()
},[phone_number])

/*
{"messages":[{"from":"919490174876","id":"ABEGkZSQF0h2AhA9J7p8OWjSi0ccbx8djb49","image":{"id":"b6952a89-f771-4c1c-8d23-e286fa29baf3","mime_type":"image/jpeg","sha256":"2e9b11a3915c3a199c93e5aaba25af74409c58160de95da589508bdad69c553d"},"timestamp":"1663580433","type":"image"}]}

*/
const [chats,setchats] = useState(null)
const [loading,setloading] = useState(false)
const [message,setmessage] = useState(null)
const [messages,setmessages] = useState(null)
const SendMessage = async ()=>{

  if(message){
    const data = new FormData()
    data.append("phone",phone_number)
    data.append("session",user.SessionId)
    data.append("message",message)
    const res = await axios.post("https://awetecks.com/test3.php",data,SecurityHeaders)
  setmessage("")
  GetChats()
  setmessage(null)
  SpeechRecognition.startListening()
    if(res){
      console.log("send "+JSON.stringify(res))
    }
  }else{

  }
  
}
























//ai section





const [script,setscript] = useState(null)

  const {
    transcript,
    listening,
  } = useSpeechRecognition();

  useEffect(()=>{
if(transcript!==null){

  setscript(transcript)


}


  },[transcript])

const [automatic,setautomatic] = useState(false)

  useEffect(()=>{

    setmessage(message+" "+transcript)

    if(!listening&&transcript!==null&&message!==null){
    
var input = message.toLowerCase() ;
var text = null

if(input){
 text = input.replace(" ","")
 //setmessage(text.replace("send",""))

if(text.includes("send")){
 SendMessage()
}
}

    
    }
    if(automatic){
  
  SpeechRecognition.startListening()
  }
  },[transcript])





  useEffect(()=>{

    SpeechRecognition.startListening()
  },[window.location.href])
  const [meeting,setmeeting] = useState(false)




//ai section










  return (
    <div>
<div className="flex">

<div className="col-lg-5 overflow-y min-vh-90 vh-90">
{chats?
<>
{chats.map((chat,index)=>(
  <>
  <Link to={"/whatsapp/"+chat.from} className={"d-flex click mt-2 "+chat.from}>
  <Avatar src={chat.profile_pic}/>
  <div className="ml-2">
<h5>{chat.from?chat.username:null}</h5>
<p>{chat.from}</p>
  </div>
  </Link>
<hr/>
  </>
))}
</>:
<>

</>}

</div>


<div className="col-lg-6 container-fluid ">

<div className="whatsapp-messages">

{messages?
<>


{messages.map((item)=>(
  <>
  <div  className={parseInt(item.team)===1?"conversation self ":"conversation incoming"}>
  <div className="message">
  <p>{item.message}</p>


<p className="extra-small">{dateFormat(item.datetime,"dS hh:mm:tt")}</p>
</div>

  </div>
 

  </>
))}


</>:
<>


</>}
     


</div>
<div className="shadow">
  <div className="">
    {automatic?
    <>
     <button className="btn btn-sm btn-danger"  onClick={()=>{setautomatic(false);SpeechRecognition.startListening()}}>AI Off</button>

    </>:
    <> <button className="btn btn-sm btn-success"  onClick={()=>{setautomatic(true);SpeechRecognition.startListening()}}>AI On</button>

     
    </>}
 <div className="pl-2 pr-2 pb-0">
<p className="extra-small text-dark">{script}</p>
</div>

  </div>

<hr/>
  <div className="d-flex ">
<textarea value={message} className="form-control wa-input" onChange={(e)=>setmessage(e.target.value)}></textarea>
<button onClick={()=>SendMessage()} className="btn btn-secondary">Send</button>
      
     </div>

</div>
     
</div>

</div>


    </div>
  )
}

export default WhatsAppSupport
import React from 'react'
import Skeleton from '@mui/material/Skeleton';
const DasoboardLoadingTemplate = () => {
  return (
    <div className="container pt-1 pb-5">



<div className="flex-wrap">

<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>

<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>


<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>


<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>




<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>

<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>


<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>


<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>


<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>

<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>


<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>


<div className="col-lg-3 p-2">


<Skeleton  variant="text"  height={100} />

</div>



</div>



<div className="container-fluid flex-wrap">

<div className="col-lg-6">

<Skeleton variant="circular" width={300} height={300} />
</div>


<div className="col-lg-6">
<Skeleton className="w-100 h-100" variant="text"   />

</div>
</div>

  
    </div>
  )
}

export default DasoboardLoadingTemplate
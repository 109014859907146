import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

import axios from 'axios'



import ENDPOINT from '../../context/EndPoint'


const DeleteTeamReminder = async({payload}) => {












      const formData = new FormData();
      
      
      //formData.append('whyus',document.getElementById('whyus').value)
      
      formData.append('session',payload.SessionId);
      
        formData.append('id',payload.id);
      
     
      
      const res = await axios.post(ENDPOINT+"delete-team-reminder.php", formData,{
          headers: {
              'content-type': 'multipart/form-data'
          }
      });
      if(res){
       
       if(res.data){
 
        return res.data
       }
       else{
      
       }
      }
   










        





    
    

    
    


    





    
}

export default DeleteTeamReminder


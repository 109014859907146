import { CheckCircle } from '@material-ui/icons'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import LoopIcon from '@mui/icons-material/Loop';
import CircularProgress from '@mui/material/CircularProgress';
import { Chip } from '@mui/material';
const ResolutionStatus = ({status,loading}) => {
  return (
    <div className="text-center res-status d-flex justify-content-center column col">
{loading?
<>

</>:

<>
<Chip label={status} size="small" className={status==="resolved"?"text-light bg-success":"text-light bg-danger"}/>

</>
}
    </div>
  )
}

export default ResolutionStatus
import React from 'react'
import { Link } from 'react-router-dom'
import HomeTemplate from '../../themes/HomeTemplate'
import { useEffect } from 'react'
import { useState } from 'react'

import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import ENDPOINT from '../../context/EndPoint'
import styled from 'styled-components'
import nopayments from '../../assets/empty-cart.svg'
import { Dropdown } from 'react-bootstrap'

import { Email, Equalizer, Group,  NotificationsOff, PermIdentity, PictureAsPdf,  Report, VideoCall, Work } from '@material-ui/icons'
const RegistrationsPage = () => {

const {user} = useContext(AuthContext)

const userdata = user;
 
    const [registrations,setregistrations] = useState(null);
    
    useEffect(()=>{
       
    
        const  getregistrations = async ()=>{
       
            fetch(`${ENDPOINT}get-registrations.php?session=${user.SessionId} `)
            .then(r=>r.json())
            .then(result=>{
    
              
           if(result[0].status==="success"){
               

            if((result[0].data)!==null){
                setregistrations(result[0].data);
        
                
                
            

            }
         
           }
            
                        
            
                
            })
            
            }    ;
    getregistrations();
           
    }
    
    ,[])
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    




    return (

<HomeTemplate>


    
<PaymentsStyled className="pt-5 pb-5 col-lg-8">




{ registrations?registrations[0].status!=="error"?registrations[0].data!==null?registrations.map(internship=>(





<div className="internship-item p-3 col-lg-12 col-md-12 col-sm-12">

<div className="flex space-between">
<h3 className="internship-title">{internship.title}</h3>
{internship.status==="selected"?

<Dropdown>
  <Dropdown.Toggle className="bg-white border-0" >
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="22" viewBox="0 0 6 22">
  <g id="Group_77" data-name="Group 77" transform="translate(-13 -5)">
    <g id="options">
      <path id="Path_95" data-name="Path 95" d="M13,8a3,3,0,1,1,3,3A3,3,0,0,1,13,8Zm3,5a3,3,0,1,0,3,3A3,3,0,0,0,16,13Zm0,8a3,3,0,1,0,3,3A3,3,0,0,0,16,21Z" fill="#0750f5"/>
    </g>
  </g>
</svg>

  </Dropdown.Toggle>

  <Dropdown.Menu> 
  
    <Dropdown.Item href="#/action-3"><PictureAsPdf/> Offer Letter</Dropdown.Item>
   
  </Dropdown.Menu>
</Dropdown>
:internship.status==="joined"?

<Dropdown>
  <Dropdown.Toggle className="bg-white border-0" >
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="22" viewBox="0 0 6 22">
  <g id="Group_77" data-name="Group 77" transform="translate(-13 -5)">
    <g id="options">
      <path id="Path_95" data-name="Path 95" d="M13,8a3,3,0,1,1,3,3A3,3,0,0,1,13,8Zm3,5a3,3,0,1,0,3,3A3,3,0,0,0,16,13Zm0,8a3,3,0,1,0,3,3A3,3,0,0,0,16,21Z" fill="#0750f5"/>
    </g>
  </g>
</svg>

  </Dropdown.Toggle>

  <Dropdown.Menu> 
  <Dropdown.Item href="#/action-3"><VideoCall/> Meetings</Dropdown.Item>
    
    <Dropdown.Item href="#/action-1"><Work/> Tasks</Dropdown.Item>
    <Dropdown.Item href="#/action-2"><Email/> Official Mail</Dropdown.Item>
   
    <Dropdown.Item href="#/action-2"><Equalizer/> Leaderboard</Dropdown.Item>
    <Dropdown.Item href="#/action-3"><Group/> Join Your Team</Dropdown.Item>
    <Dropdown.Item href="#/action-3"> <NotificationsOff/> Request Leave</Dropdown.Item>
    
     <Dropdown.Item href="#/action-2"><PermIdentity/> ID Card</Dropdown.Item>
   
    <Dropdown.Item href="#/action-3"><Report/> Report Issues</Dropdown.Item>
    
  </Dropdown.Menu>
</Dropdown>

:""

}
</div>

<div className="flex">

    <div className="short-info p-0 col-6 col-lg-3 col-md-4 col-xl-4">
        <div className="flex">
<h6 className="mr-2">Status : </h6>
<p>{internship.status}</p>
        </div>
    
    </div>



</div>
<hr className="mt-0 mb-2"/>
<div className="short-info space-between flex">

<div className="internship-info sm-d-none">
<h6>Location</h6>
<p>{internship.mode==="online"?"Work from home":"In Office"}</p>
</div>


<div className="internship-info">
<h6>Stipend</h6>
<p> {internship.stipend==="0"?<p>Unpaid</p>:"₹ " +internship.stipend + "/month"}</p>
</div>



<div className="internship-info">
<h6>Duration</h6>
<p>{internship.duration>1?internship.duration+" months":internship.duration+" month"}

</p>
</div>

<div className="internship-info">
<h6>Apply by</h6>
<p>{internship.deadline}</p>
</div>

</div>
<div className="bottom-flex flex space-between">
<div className="internship-tags">
<span>Part-time allowed</span>
</div>
<div className="flex">
<Link to={"/internship/"+internship.title+"/"+internship.internship_id}>View Details</Link>

<Link to={"/offerletter/"+internship.internship_id+"/"+internship.internship_id}>download</Link>

</div>


</div>

</div>





)):"error":"batch unset"
:

<div className="flex-wrap container-fluid bg no-courses p-20">

<div className="col-lg-4 col-md-5 col-sm-12">

<div className="flex">
<img className="w-100" src={nopayments} alt=""></img>

    </div>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 text-dark">
<h2 className="mt-5">No Registrations !</h2>
<h6>It seems like you have not registered for any internships, workshops, events or hackathons...</h6>


</div>


</div>





}













</PaymentsStyled>



</HomeTemplate>
    )
}

export default RegistrationsPage


export const PaymentsStyled = styled.div`


.plans-section{
    background-image: none;
}
li{
    color: #202020;
}


`





import React, { useContext, useState } from 'react'
import { Dropdown, Toast } from 'react-bootstrap'
import { MoreVert, Visibility } from '@material-ui/icons'
import { Avatar } from '@material-ui/core'
import { Download, Upload } from '@mui/icons-material'
import { FaCertificate } from 'react-icons/fa'
import { Eye } from 'react-bootstrap-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ENDPOINT from '../../../context/EndPoint'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'
import SecurityHeaders from '../../../context/SecurityHeaders'

const HackingWorkshopRegistrationItem = ({registration,index,handleSelection}) => {
     
   const [toast,setToast]= useState(false)
   const [message,setMessage] = useState("")
const {user} = useContext(AuthContext)
   
    const updateStatus = async ({payload})=>{
        const data = new FormData;
        data.append("status",payload.status);
        data.append("id",payload.id)
        data.append("session",user.SessionId)
        const res = await axios.post(`${ENDPOINT}admin-update-hacking-scholarship-status.php`,data,SecurityHeaders);
        if(res){
         setToast(true)
         setMessage(res.data.message)
      
         return res
         
        
         
        }
     }
     const [expand,setexpand] = useState(false)
  return (
  <>
     <TableRow onClick={()=>setexpand(true)} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

   

<TableCell className='col-3' component="th" scope='row'>
  
<div className="flex col  w-100">
  <input type='checkbox' onClick={()=>handleSelection(registration.id)}  className='me-2 custom-select'></input>
<Avatar src={registration.profile_pic} className="me-2"/>
<div>
<a target='_blank' href={"https://wa.me/"+registration.wa_phone}>
<p className="mb-0">{registration.name}</p>
<p className="mb-0">{registration.phone}</p>
</a>
</div>
</div>
</TableCell>

{/* <div className="col-lg-1">
{registration.mode}
</div> */}
<TableCell align='left'>
  
  <div className="w-100">
  <p className="mb-0">{registration.phone}</p> 
  </div>
  </TableCell>


<TableCell align='left'>
  
<div className="w-100">
<p className="mb-0">{registration.create_datetime}</p> 
</div>
</TableCell>
<TableCell align='left'>
  
  <div className="w-100">
  <p className="mb-0">{registration.status}</p> 
  </div>
  </TableCell>
  <TableCell align='left'>
  
  <div className="w-100">
    <Eye/>
 <Upload/>
 <Download/>
  </div>
  </TableCell>
<TableCell align='right'>
<Dropdown className="options-dropdown">   
<Dropdown.Toggle className="bg-dark text-light options p-0 w-fit h-fit  border-0" >
<MoreVert className="text-light"/>
</Dropdown.Toggle>
<Dropdown.Menu className="bg-dark text-white">
<Dropdown.Item className="text-white" onClick={()=>updateStatus({payload:{id:registration.id,status:"active"}})} >active</Dropdown.Item>
<Dropdown.Item className="text-white" onClick={()=>updateStatus({payload:{id:registration.id,status:"inactive"}})} >inactive</Dropdown.Item>

<Dropdown.Item className="text-white" onClick={()=>updateStatus({payload:{id:registration.id,status:"attended"}})} >Attended</Dropdown.Item>
<Dropdown.Item className="text-white" onClick={()=>updateStatus({payload:{id:registration.id,status:"inactive"}})} >Delete</Dropdown.Item>

</Dropdown.Menu>
</Dropdown>
</TableCell>



      </TableRow>


      {expand??<>
      
      <div className="p-3">
        <p>desctipyion</p>
      </div>
      </>}
  </>
  )
}

export default HackingWorkshopRegistrationItem
import React from 'react'

import errorimage from '../../assets/error-404.svg'

import { useLocation } from 'react-router'
import HomeTemplate from '../../themes/HomeTemplate'
const Default = () => {
const location = useLocation();

const goback = ()=>{
  window.history.back();
}


    return (


      <HomeTemplate>

<div className="container-fluid">
            
      <div className="flex-wrap justify-content-center">
<div className="col-lg-5 mt-5 text-center">

<img src={errorimage} alt="error 404" className="w-100"></img>
<button onClick={goback} className="mt-5 btn btn-primary rounded-pill pl-5 pr-5">Go Back</button>
</div>




      </div>

        </div>

      </HomeTemplate>
    
    )
}

export default Default


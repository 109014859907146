import { Delete } from '@material-ui/icons'
import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext'
import ENDPOINT from '../../../context/EndPoint'
import SecurityHeaders from '../../../context/SecurityHeaders'

const CheatSheetsManagement = () => {

    const [title,settitle] = useState(null)
    const params = useParams()
    const course_url = params.course_url
    const [description,setdescription] = useState(null)
    const [type,settype] = useState("link")
    const [url,seturl] = useState(null)
const {user} = useContext(AuthContext)
const AddResource = async (e)=>{
e.preventDefault()
const data = new FormData()
data.append("title",title)
data.append("description",description)
data.append("type",type)
data.append("link",url)
data.append("course_url",course_url)
data.append("session",user.SessionId)
const res = await axios.post(ENDPOINT+"admin-add-cheat-sheet.php",data,SecurityHeaders)

if(res){
    if(res.data.status==="success"){
GetResources()
    }
}

}

const [resouces,setresources] = useState(null)

const GetResources =  async ()=>{
  const data = new FormData()
  data.append("course_url",course_url)
  data.append("session",user.SessionId)
  const res = await axios.post(ENDPOINT+"admin-get-cheat-sheets.php",data,SecurityHeaders)


  if(res){
    if(res.data.status==="success"){
      setsheets(res.data.data)
    }
console.log("Resources "+JSON.stringify(res))
  }
}

const DeleteSheet =  async ({id})=>{
  const data = new FormData()
  data.append("id",id)
  data.append("session",user.SessionId)
  const res = await axios.post(ENDPOINT+"delete-cheat-sheet.php",data,SecurityHeaders)


  if(res){
    if(res.data.status==="success"){
  GetResources()
    }
console.log("Resources "+JSON.stringify(res))
  }
}
const [sheets,setsheets] = useState(null)

useEffect(()=>{
GetResources()
},[])
  return (
    <div>
        <div className="d-flex justify-content-between align-items-center">
            <h4>Resources</h4>
          
        </div>
        
        <div className="mt-3">
            <div className="d-flex flex-wrap ">


<div className="col-lg-7">
{sheets?
<>
{sheets.map((sheet)=>(
<>

<div className="border p-3">

<div className="d-flex justify-content-between">
<h6>{sheet.title}</h6>
<Delete onClick={()=>DeleteSheet({id:sheet.id})}/>
</div>
</div>
</>
))}

</>:
<>

</>}
</div>
    
    
    <div className="col-lg-5">
        <form onSubmit={(e)=>AddResource(e)}>
        <input required className="form-control mt-2" value={title} onChange={(e)=>settitle(e.target.value)} placeholder="title"></input>
        <input required className="form-control mt-2" onChange={(e)=>setdescription(e.target.value)} placeholder="Description"></input>
      
      <select required className="form-control mt-2"  onChange={(e)=>settype(e.target.value)}>
        <option value="pdf">PDF</option>
        <option value="link">Link</option>
      
      </select>
        <input required className="form-control mt-2" onChange={(e)=>seturl(e.target.value)} placeholder="url"></input>
      
        <button className="btn btn-primary mt-4">Add resource</button>
   
        </form>
    
       </div>
            </div>
        </div>
      </div>
  )
}

export default CheatSheetsManagement
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React, { useState } from 'react';
import HomeTemplate from '../../themes/HomeTemplate';
import Form from 'react-bootstrap/Form'
import { Avatar } from '@material-ui/core';
import axios from 'axios';
import dateFormat from 'dateformat';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/EndPoint';
import { useEffect } from 'react';
import {useRef} from 'react'
import friendjump from '../../assets/friend-jump.svg'
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import * as timeago from 'timeago.js'
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Modal from '@mui/material/Modal';
const ChatPage = () => {

const [chatheads,setchatheads] = useState(null)
const params = useParams()
const conversation_id = params.conversation_id;

const scrollRef = useRef()
const width = window.innerWidth;
const [device,setdevice] = useState("lg")

const {user} = useContext(AuthContext)

const desktopchatbox = document.getElementById("d-chatbox");



     const getChatHeads = async (status)=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);

          
          const res = await axios.post(ENDPOINT+"chat-heads2.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res.data){
            if(res.data.status==="success"){
            setchatheads(res.data.chat_heads)
            //// console.log("chat heads"+JSON.stringify(res.data))
   
           }
           else{
          
           }
          }
          }
          
          






const updateMessageReadStatus = ()=>{
desktopchatbox.scrollTo()

if(conversation!==null){

  if(width>=576 && conversation.length>5){
    if(desktopchatbox.scrollTop>50){
      // console.log("message read")
    }
      }
}

   //desktopchatbox.onscroll(desktopchatbox.scrollTop)
 // // console.log(desktopchatbox.scrollTop);
}



const [conversation,setconversation] = useState(null)
const [receiver,setreceiver] = useState(null)
          const getConversation = async (status)=>{
        

               const formData = new FormData();
               
               
               //formData.append('whyus',document.getElementById('whyus').value)
               
               formData.append('session',user.SessionId);
     
               formData.append('conversation_id',sessionStorage.getItem("cid"));
     
                 const res = await axios.post(ENDPOINT+"get-conversation.php", formData,{
                   headers: {
                       'content-type': 'multipart/form-data'
                   }
               });
               if(res){


                const desktopchatbox = document.getElementById("d-chatbox");

                const cheight = (window.innerHeight-190);
              document.getElementById("conversation").style.height = cheight;
              desktopchatbox.style.height= cheight+"px";
          

             //  // console.log("conversation "+JSON.stringify(res.data))
                if(res.data.status==="success"&&res.data.chats!==null){
                 setconversation(res.data.chats)
                 settotalmessages(res.data.chats.length)
                if(scrollRef){
                 }
                
                }
                if(res.data.status==="success"&&res.data.receiver!==null){
                    setreceiver(res.data.receiver)
                }
                if(res.data.status==="success"&&res.data.chats===null){

                    setconversation(null)
                }
                else{
               
                }
               }
               }
               

const [totalmessages,settotalmessages] = useState(null)

const [message,setmessage] = useState(null)

               const sendMessage = async (status)=>{
        
//// console.log("sending "+message)
                    const formData = new FormData();
                    
                    
                    //formData.append('whyus',document.getElementById('whyus').value)
                    
                    formData.append('session',user.SessionId);
          
                    formData.append('conversation_id',sessionStorage.getItem('cid'));
                    formData.append('message',message);
          
                    
                    const res = await axios.post(ENDPOINT+"send-conversation.php", formData,{
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    if(res){
                   //  // console.log("sent "+JSON.stringify(res.data.status))
                     if(res.data.status==="success"){

                         setmessage("");
                         getChatHeads()
                     getConversation()
                     }
                     else{
                    
                     }
                    }
                    }





useEffect(()=>{

getConversation();
},[params.conversationid])



               const startConversation = async ({status,receiver_id,initial_id,e})=>{
        sessionStorage.setItem('cid',initial_id)


if(width<576){
  setchat(true)
 



}

                    const formData = new FormData();
                    
                    
                    //formData.append('whyus',document.getElementById('whyus').value)
                    
                    formData.append('session',user.SessionId);
          
                   formData.append('receiver_id',receiver_id);
                 
                    const res = await axios.post(ENDPOINT+"start-conversation.php", formData,{
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                    if(res){
                   // console.log("conversation started")
                     if(res.data.status==="success"){
                          getConversation()

                     // // console.log("conversation started");
                     }
                     else{
                    
                     }
                    }
                    }



               React.useEffect(() => {
                    // console.log(`initializing chat retrieval`);
                    const interval = setInterval(() => {
                      getConversation();
                      getChatHeads();
                    }, 5000);
                  
                    return () => {
                      // console.log(`clearing chat counter`);
                      clearInterval(interval);
                    };
                  }, []); // has no dependency - this will be called on-component-mount
                
                



                  useEffect(() => {
                setreceiver(null)
                setconversation(null)
                getConversation();
               
                getChatHeads();
                    
                    
                  }, [conversation_id,user])
                
useEffect(()=>{


if(conversation!==null){

if(conversation.length>5 && scrollRef){
  scrollRef.current.scrollIntoView({behaviour:"smooth",block: "end"})
 // // console.log(JSON.stringify("scrolled "+JSON.stringify(scrollRef)))
}

   
}
           
},[totalmessages])




const selectedChat = ({i})=>{
     const chats = document.getElementsByClassName('chat-head');
//// console.log("chats "+chats.length)
const selectedchat = chats[i];
for(var k=0;k<chats.length;k++){
  if(chats[k].classList.contains("active-chat")){
     chats[k].classList.remove("active-chat")
   }  
}
selectedchat.classList.add("active-chat")
}


const style = {
  position: 'absolute',
zindex:600000,
bottom:0,
screenLeft:0,
 width:window.innerWidth,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 0,
  p: 4,
};

const [chat,setchat] = useState(false)


const clearRead = async (status)=>{
        

                      const formData = new FormData();
                      
                      
                      //formData.append('whyus',document.getElementById('whyus').value)
                      
                      formData.append('session',user.SessionId);
            
                      formData.append('conversation_id',sessionStorage.getItem('cid'));

                      
                      const res = await axios.post(ENDPOINT+"clearRead.php", formData,{
                          headers: {
                              'content-type': 'multipart/form-data'
                          }
                      });
                      if(res.data){
                     // // console.log("cleared "+JSON.stringify(res.data))
                       if(res.data.status==="success"){
  //// console.log("read cleared")
                           setmessage("");
                           getChatHeads()
                       getConversation()
                       
                       }
                       else{
                      
                       }
                      }
                      }






const openChat = ()=>{

  if(width<576){


    setchat(true)
  setHeight()
  }
}



const setHeight = ()=>{
  const mchatbox = document.getElementById("m-chatbox");
  if(mchatbox!==null){

    mchatbox.style.height = (window.innerHeight - 180)+"px";

  }


}





  return(

    <>
    
    
<div className="container-fluid  ">




<Modal
  open={chat}
  onClose={()=>setchat(false)}
  className="container-fluid chat-modal "
  aria-labelledby="modal-modal-title"
  
  aria-describedby="modal-modal-description"
> 
  <Box sx={style}>
 {/*chat section*/}
<div className="vh-90 position-relative  p-2">


{receiver?

<>

<div type="button" className="flex shadow sm-p-15 bg-white position-fixed pt-2 pb-2 top-0 left-0 w-100  align-items-center">
<div className="flex align-items-center">

<KeyboardBackspaceIcon type="button" onClick={()=>setchat(false)} className="mr-3"/>
{width<576?"":<Avatar src={receiver.profile_pic?receiver.profile_pic:null}/>
}
</div>
<div className="ml-2">
     <h6>{receiver.first_name+" "+receiver.last_name}</h6>
     <p className="mb-0">{receiver.status}</p>
</div>

</div>


</>
:null
}



<div id="m-chatbox" onLoad={()=>setHeight()} className=" max-vh-100 position-relative overflow-y mt-3 p-2">


{conversation?


<>
{conversation.map((message)=>(

<>
{message.own===true?

<>
<div ref={scrollRef} className="justify-right w-100 mt-1 mb-1">
<div className="message">
       <p>{message.message}</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">{timeago.format(message.create_datetime)}</p>
 
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>


</>

:<>


<div  ref={scrollRef}  className="message mt-1 mb-1">
       <p>{message.message}</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">{timeago.format(message.create_datetime)}</p>
<CheckIcon className="ml-2"/>
   </div>
</div>


</>


}

</>


))}
</>
:<>

<div className="container-fluid pt-5">

  <p className="text-center">No Messages</p>
</div>

</>}






























</div>



<div className="bg-white bottom-0 left-0  container-fluid  br-4">
<Form onSubmit={(e)=>{e.preventDefault();sendMessage()}}>
<div className="flex align-items-center p-2 space-between">
<Form.Group className=" w-100 mb-0" controlId="exampleForm.ControlInput1">

    <input type="text" className="form-control" onChange={(e)=>{setmessage(e.target.value)}} value={message} placeholder="Enter Message..." />
  </Form.Group>
<div className="flex ml-2 align-items-center">
<AttachFileIcon  type="button" className="mr-2"/>
<SendIcon onClick={sendMessage} type="button"/>
</div>
</div>
 
</Form>
</div>
</div>
{/*chat section*/}
  </Box>
</Modal>


<div className="flex-wrap space-between">

   {chat?
   
  <>
  
  </>
  :
  
  <>
    <div className="col-lg-5 chat-heads  overflow-y">









{chatheads?chatheads.map((chat,index)=>(

<>

<>
<Link to={"/chat/"+chat.conversation_id} onClick={()=>{startConversation({initial_id:chat.conversation_id});openChat()}}  type="button" className="flex  sm-p-15  text-dark  chat-head align-items-center">

<Avatar src={chat.profile_pic?chat.profile_pic:null}/>

<div className="ml-2 w-100 pr-2">
<div className="flex w-100 space-between align-items-center">
    <h6>{chat.first_name+" "+chat.last_name}</h6>
   
   <div className="text-right">
   <p className="extra-small mb-0">{chat.status==="offline"?timeago.format(chat.last_active):chat.status}</p>
   {chat.unread>0?
  <Badge badgeContent={chat.unread} color="success"> </Badge>
 :null  
  }
     </div>
   
    </div>
     <p className="mb-0 text-secondary">{chat.last_message}</p>
    
</div>

</Link>

</>

</>


)):

<>

{/*no chat heads*/}

<div className="container-fluid pt-5 justify-center">

<div className="text-center">

  <img src={friendjump} className="w-75 mb-3"/>
  <h5>No Connections</h5>
  <p>Join any training program to make connections and start a conversation</p>
  <Link to="/live-online-courses" className="btn btn-primary mt-2">Browse Courses</Link>
</div>


</div>


</>

}

     </div>
  </>}

















<div id="conversation" className="col-lg-7 d-none d-sm-block">
{/*chat section*/}
<div className=" h-100 position-relative p-2 pt-4">


{receiver?

<>

<div type="button" className="flex  align-items-center">

<Avatar src={receiver.profile_pic?receiver.profile_pic:null}/>

<div className="ml-2">
     <h6>{receiver.first_name+" "+receiver.last_name}</h6>
     <p className="mb-0">{receiver.status}</p>
</div>

</div>


</>
:null
}




<div id="d-chatbox" onTouchMove={()=>clearRead()} onMouseOver={()=>clearRead()} onMouseEnter={()=>updateMessageReadStatus()} onScroll={()=>updateMessageReadStatus()} onMouseLeave={()=>updateMessageReadStatus()} className=" mb-5 overflow-y  p-2">





{conversation?


<>
{conversation.map((message,index)=>(

<>
{message.own===true?

<>
<div ref={scrollRef} className={conversation.length===(index+1)?"justify-right w-100 mt-1 mb-1 final-msg":"justify-right w-100 mt-1 mb-1"}>
<div className="message">
       <p className="mb-1">{message.message}</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">{dateFormat(message.create_datetime,"h:mm TT")}</p>
<CheckIcon className="ml-2"/>
   </div>
</div>

</div>


</>

:<>


<div ref={scrollRef} className={conversation.length===(index+1)?"message mt-1 mb-1 final-msg":"message mt-1 mb-1"}>
       <p>{message.message}</p>
   <div className="flex w-100 justify-right extra-small text-right">
        <p className="mb-0">{timeago.format(message.create_datetime)}</p>
<CheckIcon className="ml-2"/>
   </div>
</div>


</>


}

</>


))}
</>
:
<div className="container-fluid pt-5">

<p className="text-center">No Messages</p>
</div>}






























</div>


{chatheads?
<>
<div className="bg-white bottom-0 left-0  container-fluid  br-4">
<Form onSubmit={(e)=>{e.preventDefault();sendMessage()}}>
<div className="flex align-items-center p-2 space-between">
<Form.Group className=" w-100 mb-0" controlId="exampleForm.ControlInput1">

    <input type="text" className="form-control" onChange={(e)=>{setmessage(e.target.value);updateMessageReadStatus();}} value={message} placeholder="Enter Message..." />
  </Form.Group>
<div className="flex ml-2 align-items-center">
<AttachFileIcon  type="button" className="mr-2"/>
<SendIcon onClick={sendMessage} type="button"/>
</div>
</div>
</Form>
</div>
</>:
<>


</>}
</div>
{/*chat section*/}
</div>


</div>


</div>




    
    </>
  )
};

export default ChatPage;

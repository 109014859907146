import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { useContext } from 'react'
import { Avatar, Chip, TextField } from '@material-ui/core'
import Box from '@mui/material/Box';
import dateFormat from 'dateformat'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import ENDPOINT from '../../../context/EndPoint'
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios'
import { CheckCircle, Close, MoreVert} from '@material-ui/icons'
import { Container, Dropdown, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import DeletePaymentHistory from '../../../actions/payments/DeletePaymentHistory'
import ModalStyle from '../../../context/ModalStyle'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import LeadsStatistics from '../leads/LeadsStatistics'
const StudentPayments = ({permission}) => {

const {user} = useContext(AuthContext)
const userdata = user;
const params = useParams();
 const payment_status = params.status;
    const [payments,setpayments] = useState(null);
    const [query,setquery] = useState(null)






    const getpayments = async ()=>{
        

      const formData = new FormData();
      
      
      //formData.append('whyus',document.getElementById('whyus').value)
      
      formData.append('session',user.SessionId);
      
        formData.append('status',payment_status);
      if(query!==null){
        formData.append('query',query);
      
      }
      // console.log("pay "+payment_status)
      const res = await axios.post(ENDPOINT+"admin-get-payments.php", formData,{
          headers: {
              'content-type': 'multipart/form-data'
          }
      });
      if(res){
       // console.log("payments "+JSON.stringify(res))
       if(res.data.status==="success"){

        setInitialData(res.data.data)
        setResponse(res.data.data)
        setpayments(res.data.data)
       }
       else{
      
       }
      }
      }


    useEffect(()=>{
     
    getpayments();
           
    }
    
    ,[payment_status])
    
    
    
    
    
    const [toast,settoast] = useState(false)
    const [toasttitle,settoasttitle] = useState(null)
    const [toastmessage,settoastmessage] = useState(null)
    


    
    
    
    
const updateCourseEnrollment = async ({pid,pstatus})=>{
        

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    

   
      formData.append('purchase_id',pid);
      formData.append('course_status',pstatus)
      if(pstatus==="paused"){
formData.append("reason",reason)
formData.append("instruction",instruction)
      }
   if(query!==null){
    formData.append('query',query)
   }
   
    
    const res = await axios.post(ENDPOINT+"update-student-course-status.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
     //("course status "+JSON.stringify(res.data))
     console.log(res)
     if(res.data.status==="success"){
     
      settoasttitle(res.data.title)
      settoastmessage(res.data.message+pstatus)
      getpayments()
      settoast(true)
      setmodal(false)
     }
     else{
    
     }
    }
    }
    
    
    const getpayments2 = (e)=>{
      e.preventDefault()
      getpayments()
    }


const Delete = async ({purchase_id})=>{

  const res = await DeletePaymentHistory({
    payload:{
      session:user.SessionId,
      purchase_id:purchase_id
    }
  })
  if(res){
    if(res.status==="success"){
      getpayments()
    }
  }
}

const [modal,setmodal] = useState(false)
const PauseTraining = (purchase_id)=>{

setpurchaseid(purchase_id)
setmodal(true)
}
const [instruction,setinstruction] = useState(false)
const [reason,setreason] = useState(null)
const [purchaseid,setpurchaseid] = useState(null)

const [initialData,setInitialData] = useState('')


const [response, setResponse] = useState(initialData);
const [searchTerm, setSearchTerm] = useState('');

const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();

  const filteredData = initialData.filter((item) => {
    return (
      item.user_email.toLowerCase().includes(term) ||
      item.phone_number.includes(term) ||
      item.first_name.toLowerCase().includes(term) ||
      item.last_name.toLowerCase().includes(term) ||
      item.batch_status.toLowerCase().includes(term)
    );
  });

  setResponse(filteredData);
  setSearchTerm(term);
};
    return (
<>
<>


<Modal
  open={modal}
  onClose={()=>setmodal(false)}

>

  <Box sx={ModalStyle}>
  <div className="d-flex justify-content-between">
    <h4>Enter Details</h4>
    <Close className="click" onClick={()=>setmodal(false)}/>
  </div>
  <hr/>
 <input placeholder="Enter reason" className="mt-2 form-control mb-3" onChange={(e)=>setreason(e.target.value)}></input>
 <input placeholder="Enter instructions" className="mt-2 form-control mb-3" onChange={(e)=>setinstruction(e.target.value)}></input>
 <button className="btn btn-primary w-100" onClick={()=>(updateCourseEnrollment({pid:purchaseid,pstatus:"paused"}) )}>Pause Training</button>
  </Box>
</Modal>





<Container fluid className="my-2">
      
      <div>
      
      <input
        Placeholder ="Search name, email phone "
        variant="outlined"
        className='fixed-search'
        value={searchTerm}
        onChange={handleSearch}
      />
    
    </div>
            </Container>

<div className="fixed-bottom justify-center container-fluid w-100 bottom-60 left-0">
<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
           
          </Toast.Header> 
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>


</div>




<div className="">


<div className="container mb-3">
  <LeadsStatistics/>
</div>

<Table>
<TableHead>
                    <TableRow>
                      <TableCell align="left">User</TableCell>

                      <TableCell align="left">Course Name</TableCell>

                    
                      <TableCell align="left">Status</TableCell>

                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>

                  { response?response.map((payment,index)=>(
<>


<TableRow>

       
<TableCell component='th' scope='row'>
<div className="d-flex w-100 p-0   align-items-center">
<Avatar  src={payment.profile_pic} className="me-2 user-dp"/>

       
<div className="p-0">
<h6 className="mb-0 sm-14">{payment.first_name+" "+payment.last_name}</h6>
<p   className="mb-0 sm-14 d-none d-sm-block">ID: {payment.purchase_id}</p>
<p   className="mb-0 sm-12 d-block d-sm-none">{payment.course_title}</p>
</div>
</div>
</TableCell>


<TableCell className='col-4' align='left'>
<div className=" w-100 p-0 d-none d-sm-block">
<p   className="mb-0 sm-14"><b>{payment.course_title}</b></p>
   <div className="flex">
   <p>{dateFormat(payment.purchase_datetime,"dS mmm yy hh:mm tt")}</p>
    
   </div>

     </div>
</TableCell>

<TableCell className='col-3' align='left'>
<div className="w100">
<p className="mb-0">{payment.purchase_status} <CheckCircle  className={payment.purchase_status==="success"?"text-success sm-14":"text-danger sm-14"}/> </p>
          
<div className="flex">
<p className="mr-2 text-success">₹{payment.purchase_price}</p>
    <p><s className="text-danger">₹{payment.course_cutoff_price}</s></p>
  
</div>
</div>
</TableCell>


        <TableCell align='left'>
        <div>
       
    
       <div className="ml">
       <Dropdown className="options-dropdown ml-4">   
 <Dropdown.Toggle className="bg-transparent options p-0 w-fit h-fit  border-0" >
 <MoreVert className="text-dark"/>
 </Dropdown.Toggle>
 <Dropdown.Menu>
   {payment.purchase_status==="pending"||payment.purchase_status==="paused"?
   <>
    <Dropdown.Item   onClick={()=>{updateCourseEnrollment({pid:payment.purchase_id,pstatus:"success"})}}  >Activate Course</Dropdown.Item>

   </>:null}
   {payment.purchase_status==="pending"?
   <>


   <Dropdown.Item     onClick={()=>{updateCourseEnrollment({pid:payment.purchase_id,pstatus:"not-interested"})}}   >Mark Not Interested</Dropdown.Item>
   </>:
   <>
   {payment.purchase_status==="success"?
   <>
   
   <Dropdown.Item   onClick={()=>{updateCourseEnrollment({pid:payment.purchase_id,pstatus:"refund"})}}  >Initiate Refund</Dropdown.Item>
 <Dropdown.Item    onClick={()=>{updateCourseEnrollment({pid:payment.purchase_id,pstatus:"refunded"})}}   >Mark Refunded</Dropdown.Item>
 <Dropdown.Item     onClick={()=>{PauseTraining(payment.purchase_id)}}   >Pause Training</Dropdown.Item>
   
   </>:
   <>
   {payment.purchase_status==="refund"?
   <>
   
    <Dropdown.Item    onClick={()=>{updateCourseEnrollment({pid:payment.purchase_id,pstatus:"refunded"})}}   >Mark Refunded</Dropdown.Item>

   </>:
   <>
      <Dropdown.Item   onClick={()=>{updateCourseEnrollment({pid:payment.purchase_id,pstatus:"success"})}}  >Activate Course</Dropdown.Item>

   </>}
   
   </>}
   
   </>}

{parseInt(permission.delete_payment_history)===1?
<>
<Dropdown.Item     onClick={()=>{Delete({purchase_id:payment.purchase_id})}}   >Delete Payment History</Dropdown.Item>

</>:null}
  </Dropdown.Menu>
</Dropdown>

       </div>
       
       </div>
        </TableCell>
 



  
</TableRow>

</>

))
:""
}

                  </TableBody>
</Table>














</div>
</>
</>
    )
}

export default StudentPayments


import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'
import dateFormat from 'dateformat'
import { Dropdown, Modal, Toast } from 'react-bootstrap'
import { AttachFile, CloseOutlined, Delete, DoneAllOutlined, Edit, MoreVert, Schedule, Search, ThumbDown } from '@material-ui/icons'
import TaskEditForm from './EditTask'
import ENDPOINT from '../../../context/EndPoint'
import AdminAddTask from './AddTask'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SubmitTask from './SubmitTask'
import CountDown from '../components/CountDown'
import Alert from '@mui/material/Alert';
import { Avatar } from '@material-ui/core'
import { Download } from '@mui/icons-material'
import { Check, ChevronCompactDown, ChevronDown } from 'react-bootstrap-icons'
import AddTeamTask from '../actions/AddTeamTask'
import EvaluateTask from './EvaluateTask'
import { Chip } from '@mui/material'
import { TextField } from '@material-ui/core'
import { Container } from 'react-bootstrap'
import CustomDropdown from '../../../components/dropdowns/CustomDropdown'
import InternTaskItem from './InternTaskItem'
const AdminInternsTasks = ({ page,role}) => {

const {user} = useContext(AuthContext)


const [edit,setedit] = useState(false);


const tasktype= useRef()

const changeTaskStatus = (e)=>{
 
  settaskstatus(tasktype.current.value);
  getTasks(tasktype.current.value);

}
const [toast, settoast] = useState(false);
const [toasttitle, settoasttitle] = useState(null);

const [toastmessage, settoastmessage] = useState(null);

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [tasks,settasks] = useState("start");
const [category,setcategory] = useState("all")

const [taskstatus,settaskstatus]= useState("all")
    
    const [taskid,settaskid] = useState(null)
    const [remove,setremove] = useState(false)
    const [removeid,setremoveid] = useState(null);
    const [submit,setsubmit] = useState(false);

const showSubmit =(e)=>{
  const id= e.target.id;
settaskid((id.replace("submit","")));
setsubmit(true)
}


    const closeSubmit = ()=>{
      setsubmit(false);
    }
    const editTask = ({id})=>{


settaskid(id);

setedit(true)
    }

const closeEdit = ()=>{
  setedit(false)

}
const [add,setadd] = useState(false)

const closeAddition =()=>{
  setadd(false)
  getTasks(taskstatus);
}

const showRemove = (e)=>{
    
  const id= e.target.id;
  
settaskid(id.replace("delete",""));
  setremove(true)

}
const closeRemove = ()=>{
  setremove(false)
}




useEffect(() => {
 getTasks(taskstatus)
}, [])





const getTasks = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);

  formData.append('status',status);


const res = await axios.post(ENDPOINT+"admin-get-tasks.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){

 if(res.data.status==="success"){
  settasks(res.data.data)
  setstats(res.data.stats)
  setResponse(res.data.data)
  setInitialData(res.data.data)
 }
 else{

 }
}
}

const ToggleMore = ({id})=>{
  const info = document.getElementById("info"+id)
  if(info){
    if(info.classList.contains("d-none")){
      info.classList.replace("d-none","d-block")
    }else{
      info.classList.replace("d-block","d-none")
  
    }
  }
}

//delete task code

const deleteTask = async ()=>{
const formData = new FormData();
formData.append('session',user.SessionId);
formData.append('id',taskid);

const res = await axios.post(ENDPOINT+"admin-delete-task.php",formData,{
  headers:{
    'content-type': 'multipart/form-data'
  }
});


if(res.data.status==="success"){
  getTasks()
  closeRemove();
  settoast(true)
  settoasttitle(res.data.title)
}
else{
  settoast(true)
  settoasttitle(res.data.title) 
}
}

const Evaluate = ({id})=>{

  settaskid(id)
  setevaluate(true)
}

const [evaluate,setevaluate] =useState(false)
const [query,setquery] = useState(null)

const [stats,setstats] = useState(null)
const [displaysearch,setdisplaysearch] = useState(false)

const [initialData, setInitialData] = useState([]);
const [response, setResponse] = useState([]);
const [searchTerm, setSearchTerm] = useState('');

const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();

  const filteredData = initialData.filter((item) => {
    return (
      item.title.toLowerCase().includes(term) ||
      item.description.toLowerCase().includes(term) ||
      item.status.toLowerCase().includes(term) ||
      item.given_by.first_name.toLowerCase().includes(term) ||
      item.given_by.last_name.toLowerCase().includes(term) ||
      item.intern.first_name.toLowerCase().includes(term) ||
      item.intern.last_name.toLowerCase().includes(term)
    );
  });

  setResponse(filteredData);
  setSearchTerm(term);
};


    return (
        <>
         <div className="position-fixed container-fluid left-0 bottom-60 justify-center">
        <Toast
          className="bg-warning"
          show={toast}
          onClose={() => {
            settoast(false);
          }}
          autohide={true}
          delay={5000}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>


           
<div className="tasks-page container-fluid sm-p-15">




<div className="container-fluid mt-3 flex-wrap space-between w-100">

  {displaysearch?
  <>
  <div className="flex fixed-search">
  <FormControl >
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
      
<Select
  className="select"
  value={category}
  label="Category"
  onChange={(e)=>setcategory(e.target.value)}
>
  <MenuItem value={"all"}>All </MenuItem>
  <MenuItem value={"active"}>Active</MenuItem>
  <MenuItem value={"pending"}>Pending</MenuItem>
  <MenuItem value={"inactive"}>De-Activated</MenuItem>
  
</Select>
      </FormControl>

  <Paper
    onSubmit={(e)=>{e.preventDefault();}}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >

      <InputBase
      
         sx={{ ml: 1, flex: 1 }}
        onChange={(e)=>setquery(e.target.value)}
        placeholder="Search task or description"
        inputProps={{ 'aria-label': 'Search Batch ID ' }}
      />
      <IconButton onClick={()=>{}} sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      
    </Paper>




    </div>  
  
  </>:
  <>
  
  
  </>}
    {stats?
<>
<div className="d-flex  col-sm-12 col-12 container-fluid align-items-center  flex-wrap p-mb-0">


  <div className="col-lg-3 col-sm-6 col-6 p-1">

    <div className="border  p-3 d-flex justify-content-between">
    <h6 >{stats.pending}</h6>
    <p>Pending</p>
    </div>

  </div>



  <div className="col-lg-3 col-sm-6 col-6 p-1">

<div className="border p-3 d-flex justify-content-between">
<h6 className="me-2">{stats.submitted}</h6>
<p>Submitted</p>
</div>

</div>




<div className="col-lg-3 col-sm-6 col-6 p-1">

<div className="border p-3 d-flex justify-content-between">
<h6 className="me-2">{stats.evaluated}</h6>
<p>Evaluated</p>
</div>

</div>



<div className="col-lg-3 col-sm-6 col-6 p-1">

<div className="border p-3 d-flex justify-content-between">
<h6>{stats.rejected}</h6>
<p>Rejected</p>
</div>

</div>
</div>

</>:
<>


</>}

{/* <div className="d-flex mt-2 align-items-center col-12 col-sm-12 col-lg-3">
  <Search className="mr-3 click d-none d-md-block" onClick={()=>setdisplaysearch(!displaysearch)}/>
<button className="btn h-fit w-fit btn-primary col-12 col-sm-12 col-lg-2" onClick={()=>setadd(true)}>Add Task</button>


</div> */}

</div>




<Container fluid className="mb-3">
      
      <div>
   
      <input
        placeholder="Search..."
        variant="outlined"
        value={searchTerm}
        onChange={(e) => handleSearch(e)}
        className='fixed-search'
        margin="normal"
      />
    
    </div>
            </Container>
<div className="internships-list  d-flex flex-wrap  container-fluid">

{ response==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>

:response?response.map(internship=>(

<>




<InternTaskItem item={internship}/>



</>
)):
<div className="container-fluid">

No tasks

    </div>

}





</div>




</div>

<Modal show={evaluate} 
animation={false}

onHide={()=>setevaluate(false)}>

 
        <Modal.Header closeButton>
          <Modal.Title>Evaluate Task</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          
        <EvaluateTask id={taskid}/>
        </Modal.Body>
      
      </Modal>

<Modal show={edit} 
animation={false}

onHide={closeEdit}>

 
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          
        <TaskEditForm id={taskid}/>
        </Modal.Body>
      
      </Modal>

      <Modal show={submit} 
animation={false}
onHide={closeSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <SubmitTask id={taskid}/>
        </Modal.Body>
      
      </Modal>


      <Modal show={add} 
animation={false}
onHide={closeAddition}>
        <Modal.Header closeButton>
          <Modal.Title>Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <AddTeamTask/>
        </Modal.Body>
      
      </Modal>



      <Modal show={remove} 
animation={false}
onHide={closeRemove}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <TaskEditForm id={removeid}/>
        <button className="btn btn-danger" onClick={deleteTask}>Delete</button>
        </Modal.Body>
      
      </Modal>






        </>
    )
}

export default AdminInternsTasks


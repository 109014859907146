import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const MonthlySalesGraph = ({sales}) => {


const [data1,setdata1] = useState([24, 15, 37, 46, 51, 58, 63, 60, 66])
const [data2,setdata2] = useState([26, 15, 10, 48, 57, 91, 91, 114, 194])
const [data3,setdata3] = useState([35, 41, 36, 26, 45, 48, 59, 83, 141])
const [months,setmonths] = useState(['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June'])
const [state,setstate] = useState({
          
     series: [{
       name: 'Expenses',
       data: data1
     }, {
       name: 'Revenue',
       data: data2
     }, {
       name: 'Net Profit',
       data: data3
     }],
     options: {
       chart: {
         type: 'bar',
         height: 350
       },
       plotOptions: {
         bar: {
           horizontal: false,
           columnWidth: '55%',
           endingShape: 'rounded'
         },
       },
       dataLabels: {
         enabled: false
       },
       stroke: {
         show: true,
         width: 2,
         colors: ['transparent']
       },
       xaxis: {
         categories: months,
       },
       yaxis: {
         title: {
           text: 'INR (thousands)'
         }
       },
       fill: {
         opacity: 1
       },
       tooltip: {
         y: {
           formatter: function (val) {
             return "$ " + val + " thousands"
           }
         }
       }
}})


useEffect(()=>{

  if(sales&&sales.monthly_sales){
  
    setdata1(sales.monthly_sales.spending)
    setdata2(sales.monthly_sales.revenue)
    setdata3(sales.monthly_sales.net_profit)
    setmonths(sales.monthly_sales.months)
  }
  
  },[])
  return (
    <div>

<ReactApexChart options={state.options} series={state.series} type="bar" height={350} />

    </div>
  )
}

export default MonthlySalesGraph
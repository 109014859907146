import React, { useContext } from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'
import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import ENDPOINT from '../../context/EndPoint'

import { AuthContext } from '../../context/AuthContext'

import Toast from 'react-bootstrap/Toast'
import dateFormat from 'dateformat'

import * as timeago from 'timeago.js';
import leaveicon from '../../assets/leave-icon.svg'


const InternAttendance = ({account,timeline}) => {
     useEffect(() => {
          window.scrollTo(0, 0)
         
        }, [])
      
const {user} = useContext(AuthContext)
     const params = useParams();
     const internship_id = params.internshipid;
  //   // console.log("internship id"+internship_id);

     useEffect(() => {
          window.scrollTo(0, 0)
          getTasks();
        }, [])

const [leaves,setleaves] = useState(null)

const [status,setstatus] = useState("all");

     const getTasks = async (status)=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          

          formData.append('internship_id',internship_id)
          
          const res = await axios.post(ENDPOINT+"intern-leaves.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
          // // console.log('internship leavesare '+JSON.stringify(res.data.leaves))
           if(res.data.status==="success"){
            setleaves(res.data.leaves)
            
           }
           else{
          
           }
          }
          }












          //request leave

          const requestLeave = async (status)=>{
        

               const formData = new FormData();
               
               
               //formData.append('whyus',document.getElementById('whyus').value)
               
               formData.append('session',user.SessionId);
               
     
               formData.append('internship_id',internship_id)
               
               formData.append('start_date',startdate)
               
               formData.append('end_date',enddate)
               formData.append('leave_type',leavetype)
               
               const res = await axios.post(ENDPOINT+"request-leave.php", formData,{
                   headers: {
                       'content-type': 'multipart/form-data'
                   }
               });
               if(res){
              //  // console.log('leave request '+JSON.stringify(res.data))
                if(res.data.status==="success"){
                     settoasttitle("Request Successful !")
                     settoastmessage("Your request was successfully submitted !")
                     settoast(true)
                 getTasks()
                }
                else{
               
                }
               }
               }
          
const openSubmission = ()=>{
     setsubmit(true)
}
const [toasttitle,settoasttitle] = useState(null);
const [toastmessage,settoastmessage] = useState(null)
const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)

const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
    
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
       

const [toast,settoast] = useState(false)


const [startdate,setstartdate] = useState(null)

const [enddate,setenddate] = useState(null)
const [leavetype,setleavetype] = useState("General Leave")


     return (
          <>
       <>
              

              <div className="position-fixed bottom-60 left-0 w-100 justify-center">
              
              <Toast onClose={() => settoast(false)} show={toast} delay={3000} autohide>
                        <Toast.Header>
                          <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                          />
                          <strong className="me-auto">{toasttitle}</strong>
                      
                        </Toast.Header>
                        <Toast.Body>{toastmessage}</Toast.Body>
                      </Toast>
              </div>
              
              
                            <Modal
                open={submit}
                onClose={()=>{setsubmit(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                 <>
                 <div className="flex space-between">
                       <h5>Leave Form</h5> 
                       <CloseIcon type="button" onClick={()=>setsubmit(false)}/>
                  </div>
              <div>
              <div className="flex mt-4 space-between w-100">
              <div >
                   <h6>Start Date</h6>
                   <input  onChange={(e)=>setstartdate(e.target.value)}  className="form-control" type="date"></input>
              </div>
              
              <div >
                   <h6>End Date</h6>
                   <input onChange={(e)=>setenddate(e.target.value)} className="form-control" type="date"></input>
              </div>
              </div>
              
              <div className="w-100 mt-3">
                   <h6>Leave Type</h6>
              
                   <select  onChange={(e)=>{setleavetype(e.target.value)}} className="form-control" >
                   <option>Select Options</option>
              <option value="General Leave">General Leave</option>
              <option  value="Medical Leave">Medical Leave</option>
              <option  value="Emergency Leave">Emergency Leave</option>
                   </select>
              
              </div>
              <button onClick={()=>requestLeave()} className="btn btn-primary mt-5 w-100">Submit Request</button>
              <div>
              
              </div>
              
              
              
              </div>
                 </>
                </Box>
              </Modal>
              
              
              
              
              
              <div className="container-fluid pt-3 sm-p-15">
              
              
              
              
              
              <div className="mt-4">
              
              
              <div className="justify-center">
              
              <img src={leaveicon} className="w-50"/>
              </div>
              
              <div className="justify-center mt-3">
              <div className="w-75 text-center">
              <h5>Need some time to refresh ?</h5>
              <p>Submit a leave request with your leave dates.</p>
              </div>
              </div>
              <div className="justify-center">
              <div className="flex mt-2 mb-2 ">
              <button className="btn btn-primary mr-3" onClick={()=>setsubmit(true)}>Request Leave</button>
              <a href="mailto:hr@skillsuprise.com" className="btn btn-primary">Contact HR</a>
              </div>
              </div>
              
              
              <hr/>
              
              
              {leaves?leaves.map((leave,index)=>(
              <>
              
              
              <div className="shadow leave-item w-100 p-3 mt-3">
              
              
              <div className="w-100">
              
              <h4 className="text-text sm-18">{leave.leave_type}</h4>
              <p><b>Request Status : </b>{leave.status}</p>
              
              </div>
              <hr/>
              <div className="flex space-between">
                
              
              <div>
              <h6>Start Date </h6>
                   <p>{dateFormat(leave.start_time,"dS mmm yy")}</p>
                   </div>
              
              
              <div>
              <h6>End Date </h6>
              <p>{dateFormat(leave.end_time, "dS mmm yy")}</p>
              </div>
              
              
              
              <div>
                   <h6>Duration </h6>
                   <p>{Math.round(leave.duration)} day's</p>
                   </div>
              
              </div>
                   </div>
              
              </>
              
              )):
              
              <>
              
              
              </>}
              
              
              
              
              </div>
              </div>
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
                            </>
          </>
     )
}

export default InternAttendance

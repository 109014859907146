import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../../context/EndPoint'
import axios from 'axios';
import EvaluateTask from '../../../actions/assignments/EvaluateTask';
const StudentAssignmentEvaluate = ({id}) => {



const {user} =  useContext(AuthContext)







const [title,settitle] = useState(null)
const [description,setdescription] = useState(null)
const [assignedto,setassignedto] = useState(null)
const [reference,setreference] = useState(null)
const [deadline,setdeadline] = useState(null)
const [marks,setmarks] = useState(null)
const [earnedmarks,setearnedmarks] = useState(null)
const arr = [];
const [corrections,setcorrections] = useState(arr)

const [taskstatus,settaskstatus]= useState(null);
const [submission,setsubmission] = useState(null)
useEffect(()=>{
    
  
  
  const  getaccount = async ()=>{
  
    
            fetch(`${ENDPOINT}admin-single-assignment.php?session=${user.SessionId}&id=${id}`)
              .then(r=>r.json())
              .then(result=>{
             
             if(result){
      
            if((result.status==="success")&&(result.data!=null)){
                const data = result.data;
         
             settaskstatus(data.status)
    setsubmission(data)
             setearnedmarks(data.marks_received)
 setcorrections(data.corrections)
        
            }else {
              
            }
          
             }
              else{
               
              }
              
                          
              
                  
              })
            
    
       
            }    ;


           
if(user!==null||user!=="null"){
getaccount();
}

     
}

,[user])











    const changeTitle = (e)=>{
        settitle(e.target.value)
        
      }
      const changeDescription = (e)=>{
          setdescription(e.target.value)
      }
      const changeAssignto = (e)=>{
          setassignedto(e.target.value)
      }
    const changeMaxMarks = (e)=>{
        setmarks(e.target.value)
    }
    const changeMarksGiven = (e)=>{
        setearnedmarks(e.target.value)
    }
    const changeCorrections = (e)=>{
        setcorrections(e.target.value)
    }
    const changeReference = (e)=>{
        setreference(e.target.value);

    }
    const changeDeadline = (e)=>{
        setdeadline(e.target.value)
      
    }
    const changeStatus = (e)=>{
        settaskstatus(e.target.value)
    }
      const [status,setstatus]= useState(false)

      const [snackbar,setsnackbar] = useState(false);
      const [snackbarMessage,setsnackbarMessage] = useState(null)
      
      

      const onSubmit = async (e)=> {
        e.preventDefault();
          setstatus(true)

const res = await EvaluateTask({payload:{
  session:user.SessionId,
  corrections:corrections,
  status:taskstatus,
  id:id,
  earned_marks:earnedmarks
}})

if(res){

if(res.status==="success"){
  setstatus(false);
  setsnackbarMessage("Changes Saved Successfully")
  setsnackbar(true)   
}else{
  setstatus(false);
  setsnackbarMessage("Something went wrong")
  setsnackbar(true)   
}
}

       
        
               
          }
          
         




const [time,settime] = useState(null)

   const [date,setdate] = useState(null)   



    return (
        <div>
            
            {/*account form*/}
            {submission?
            
        <>
<div className="flex-wrap w-100">
            
            <div className="w-100">
            
            
            
            <form onSubmit={onSubmit}>
            
            <div class="form-group  w-100">
                  <label for="inputPassword4">Corrections</label>
                  <textarea type="text" class="form-control" value={corrections} onChange={changeCorrections}  maxLength={1000}/>
                </div>
            
            <div class="form-row">
         
              
             
              

   

<Snackbar
 className="bg-success text-light"
        open={snackbar}
        autoHideDuration={6000}
        onClose={()=>setsnackbar(false)}
        message={snackbarMessage}
    
    
      />





<div className="form-row">

                <div class="form-group col-lg-6">
                  <label for="inputPassword4">Marks Given</label>
                  <input type="number" class="form-control" required value={earnedmarks} onChange={changeMarksGiven} maxLength={3}/>
                </div>  
             
             
              </div>
              <div class="form-group col-md-6">
                  <label for="inputEmail4">Status ({taskstatus} )</label>
                  <select type="text" class="form-control" id="inputEmail4" value={taskstatus} onChange={changeStatus} maxLength={15}>
                  
<option value="pending">Pending</option>
<option value="submitted">Submitted</option>
<option value="completed">Completed</option>
<option value="rejected">Rejected</option>
                    </select>

                
                    
                </div>
            
             
</div>

           
               
            
  
            
            
            
           
            
             
            
            
            
              <div class="form-group">
                
              </div>
              <button type="submit" class="btn btn-primary w-100" onClick={onSubmit}  >{status?<CircularProgress className="text-white" size={20}/> :""}Save Changes</button>
            </form>
            
            </div>
  
            
            
            
                        </div>

        </>

        :

        <>
        
        <div className="justify-center mt-3">


        <CircularProgress/>
        
        </div>
        
        </>
        
        }
                           {/*account form*/}
        </div>
    )
}

export default StudentAssignmentEvaluate

import React, { useContext, useState } from 'react'
import InternalTemplate from '../../../themes/InternalTemplate'
import axios from 'axios'
import ENDPOINT from '../../../context/EndPoint'
import { AuthContext } from '../../../context/AuthContext'
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
const InternalAddWorkshop = () => {
const {user} = useContext(AuthContext)

//delete task code
const [responsibilities,setresponsibilities] = useState([])
const [benifits,setbenifits] = useState([]);
const [skills,setskills] = useState([])
const [duration,setduration] = useState(1);

const [workmode,setworkmode] = useState(null)

const [title,settitle] = useState(null)

const [description,setdescription] = useState(null)
const [date,setdate] = useState(null)
const [starts,setstarts] = useState(null);
const [ends,setends] = useState(null)
const [topics,settopics] = useState([])
const [results,setresults] = useState(null);
const [certification,setscertification] = useState(null)
const [registrationends,setregistrationends] = useState(null)
const [cissue,setcissue] = useState(null)
const [url,seturl] = useState(null)
const AddInternship = async ()=>{

     const formData = new FormData();
     formData.append('session',user.SessionId);
  
     formData.append('title',title);
     formData.append('description',description);
     formData.append('duration',duration);
  
     formData.append('mode',workmode);
     formData.append('registration_ends',registrationends);
     formData.append('ends',ends);
     formData.append('starts',starts);
     formData.append('benifits',benifits);
     formData.append('results',results);
     formData.append('topics',topics);
     formData.append('url',url);
     formData.append('workshop_date',date);
     formData.append('certificate_issue',cissue);
     
     const res = await axios.post(ENDPOINT+"admin-add-workshop.php",formData,{
       headers:{
         'content-type': 'multipart/form-data'
       }
     });
     
   
     if(res.data.status==="success"){
    settoastmessage("Internship Posted");
    settoast(true)
     }
     else{
          settoastmessage("Something went wrong ! Try again");
          settoast(true)
     }
     }





const [resp,setresp] = useState(null)
const [skill,setskill] = useState(null);
const [benifit,setbenifit] = useState(null)
const [topic,settopic] = useState(null)
const AddResponsibility = ()=>{
     responsibilities.push(resp)
     setresp("")
}


const AddSkill = ()=>{
     skills.push(skill)
     setskill("")
}
const AddBenefit = ()=>{
     benifits.push(benifit)
     setbenifit("")
}



const AddTopics = ()=>{
     topics.push(topic)
     settopic("")
}

const [toast,settoast] = useState(false)
const [toastmessage,settoastmessage] = useState(null)
const Alert = React.forwardRef(function Alert(props, ref) {
     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
   });
  return (
  
<>

<div className="position-fixed justify-center bottom-60 left-0 container-fluid">

<Snackbar open={toast} autoHideDuration={6000} onClose={()=>settoast(false)}>
        <Alert onClose={()=>settoast(false)} severity="success" >
          {toastmessage}
        </Alert>
      </Snackbar>


</div>

<div className="container-fluid sm-p-15 pb-5 pt-3">

<div className="row ">

<div className="col-lg-6">
<input className="form-control" value={title} onChange={(e)=>settitle(e.target.value)} placeholder="Title"></input>


<div className="flex w-100 mt-3">

<TextField label="Workshop Date" 
className="w-100" 
type="date"
value={date} onChange={(e)=>setdate(e.target.value)}  
         />


<TextField label="Certificate issue" 
className="w-100" 
type="date"
value={cissue} onChange={(e)=>setcissue(e.target.value)}  
         />


<TextField label="Start Time" 
className="w-100" 
type="time"
value={starts} onChange={(e)=>setstarts(e.target.value)}  
         />


<TextField label="End Time" 
className="w-100" 
type="time"
value={ends} onChange={(e)=>setends(e.target.value)}  
         />




</div>



<TextField label="Registration Ends" 
className="w-100" 
type="date"
value={registrationends} onChange={(e)=>setregistrationends(e.target.value)}  
         />


<div className="flex w-100 mt-3">

<TextField label="Results Declaration" 
className="w-100" 
type="date"
value={results} onChange={(e)=>setresults(e.target.value)}  
         />


<TextField label="Certificate Issue" 
className="w-100" 
type="date"
value={certification} onChange={(e)=>setscertification(e.target.value)}  
         />


<TextField label="Workshop Mode" 
className="w-100" 
value={workmode} onChange={(e)=>setworkmode(e.target.value)}  
         />




</div>




<TextField label="Url" 
className="w-100" 
value={url} onChange={(e)=>seturl(e.target.value)}  
         />



<div className="w-100 mt-3">
<div className="flex">
   
<TextField label="Description" 
className="w-100" 
value={description} onChange={(e)=>setdescription(e.target.value)}  
         />

        <button className="btn btn-primary" onClick={(e)=>setdescription(e.target.value)}>Add</button>
</div>
</div>



<div className="w-100 mt-3">
<div className="flex">
<TextField label="Topics" 
className="w-100" 
value={topic} onChange={(e)=>settopic(e.target.value)}  
 />
<button className="btn btn-primary" onClick={()=>AddTopics(topic)}>Add</button>
</div>
</div>







<div className="flex mt-3">

<TextField label="Benifits" 
className="w-100" 
value={benifit} onChange={(e)=>setbenifit(e.target.value)}  
         />

<button className="btn btn-primary" onClick={()=>AddBenefit(benifit)}>Add</button>

</div>


<div className="justify-center mt-3">
<buton onClick={AddInternship} className="btn btn-primary">Add Internship</buton>

</div>


</div>


<div className="col-lg-6">



<h5>Description</h5>
{description?description:null}
<hr/>
<div className="container-fluid mt-3  p-2 ">

<h5>Topics</h5>
<div className="min-vh-30">
{topics?
<>

<ol>
{topics.map((item)=>(

<>
<li>{item}</li>
</>

))}
</ol>
</>
:null
}
</div>

</div>


<div className="container-fluid  p-2 mt-3">

<h5>Benifits</h5>
<div className="min-vh-30">
{benifits?
<>

<ol>
{benifits.map((item)=>(

<>
<li>{item}</li>
</>

))}
</ol>
</>
:null
}
</div>

</div>




</div>


</div>












</div>



</>
  )
}

export default InternalAddWorkshop
import React, { useContext, useState } from 'react'
import InternalTemplate from '../../../themes/InternalTemplate'
import axios from 'axios'
import ENDPOINT from '../../../context/EndPoint'
import { AuthContext } from '../../../context/AuthContext'
import { Chip } from '@material-ui/core'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import dateFormat from 'dateformat'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
const InternalAddInternship = () => {
const {user} = useContext(AuthContext)

//delete task code
const [responsibilities,setresponsibilities] = useState([])
const [benifits,setbenifits] = useState([]);
const [skills,setskills] = useState([])
const [duration,setduration] = useState(1);
const [stipend,setstipend] = useState(null);
const [workmode,setworkmode] = useState(null)
const [openings,setopenings] = useState(null)
const [title,settitle] = useState(null)
const [deadline,setdeadline] = useState(null)
const [description,setdescription] = useState(null)
const AddInternship = async ()=>{

     const formData = new FormData();
     formData.append('session',user.SessionId);
     formData.append('id',"");
     formData.append('title',title);
     formData.append('description',description);
     formData.append('duration',duration);
     formData.append('stipend',stipend);
     formData.append('openings',openings);
     formData.append('mode',workmode);
     formData.append('deadline',deadline);
     formData.append('responsibilities',responsibilities);
     formData.append('skills',[skills]);
     formData.append('benifits',[benifits]);
     
     const res = await axios.post(ENDPOINT+"admin-add-internship.php",formData,{
       headers:{
         'content-type': 'multipart/form-data'
       }
     });
     
   
     if(res.data.status==="success"){
    settoastmessage("Internship Posted");
    settoast(true)
     }
     else{
          settoastmessage("Something went wrong ! Try again");
          settoast(true)
     }
     }





const [resp,setresp] = useState(null)
const [skill,setskill] = useState(null);
const [benifit,setbenifit] = useState(null)

const AddResponsibility = ()=>{
     responsibilities.push(resp)
     setresp("")
}


const AddSkill = ()=>{
     skills.push(skill)
     setskill("")
}
const AddBenefit = ()=>{
     benifits.push(benifit)
     setbenifit("")
}
const [toast,settoast] = useState(false)
const [toastmessage,settoastmessage] = useState(null)
const Alert = React.forwardRef(function Alert(props, ref) {
     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
   });
  return (
  <>
  
  

<div className="position-fixed justify-center bottom-60 left-0 container-fluid">

<Snackbar open={toast} autoHideDuration={6000} onClose={()=>settoast(false)}>
        <Alert onClose={()=>settoast(false)} severity="success" >
          {toastmessage}
        </Alert>
      </Snackbar>


</div>

<div className="container-fluid sm-p-15 pb-5 pt-5">

<div className="space-between flex-wrap">

<div className="col-lg-5 ">

<TextField label="Internship Title" 
className="w-100" 
value={title} onChange={(e)=>settitle(e.target.value)} 
         />
<div className="w-100 mt-3 flex">



<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Duration</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={duration}
    label="Age"
    onChange={(e)=>setduration(e.target.value)}
  >
    <MenuItem value={1}>1 Month</MenuItem>
    <MenuItem value={2}>2 Months</MenuItem>
    <MenuItem value={3}>3 Months</MenuItem>
    <MenuItem value={4}>4 Month</MenuItem>
    <MenuItem value={5}>5 Months</MenuItem>
    <MenuItem value={6}>6 Months</MenuItem>
  </Select>
</FormControl>

<TextField label="Stipend" 
className="w-100" 
value={stipend} onChange={(e)=>setstipend(e.target.value)} 
         />

<TextField label="Openings" 
className="w-100" 
         value={openings} onChange={(e)=>setopenings(e.target.value)} 
         />


</div>


<div className="w-100 mt-3 flex">
     <select className="form-control"  onChange={(e)=>setworkmode(e.target.value)}>
          <option value="online">Internship Mode</option>
<option value="online">Remote (Online)</option>
<option value="offline">In-Office (Offline)</option>
<option value="hybrid">Hybrid </option>
     </select>

<input className="form-control " onChange={(e)=>setdeadline(e.target.value)} type="date" placeholder="Deadline"></input>



</div>





<TextField label="Description"  multiline
          maxRows={7}
            onChange={(e)=>setdescription(e.target.value)} 
            value={description} 
            className="w-100 mt-3 h-auto  form-control" 
             color="secondary" />












<div className="container-fluid mt-3 p-2 ">


<div className="flex">
<TextField label="Responsibility" 
className="w-100" 
 value={resp} onChange={(e)=>setresp(e.target.value)}  
         />
         

     <button className="btn btn-primary" onClick={()=>AddResponsibility(resp)}>Add</button>
</div>




</div>



<div className="container-fluid mt-3 p-2 ">


<div className="flex">
<TextField label="Skills Required" 
 value={skill} onChange={(e)=>setskill(e.target.value)}  className="w-100" 
         />

     <button onClick={()=>AddSkill()} className="btn btn-primary">Add</button>
</div>
</div>


<div className="container-fluid p-2 mt-3">


<div className="flex">

<TextField label="Internship Benifit" 
className="w-100" 
value={benifit} onChange={(e)=>setbenifit(e.target.value)}  
         />
      <button onClick={()=>AddBenefit()} className="btn btn-primary">Add</button>
</div>
</div>





</div>



<div className="col-lg-6 p-2">
<h4 className="text-danger">Preview</h4>
<hr/>
{title?
<>
<h4 className="text-primary">{title}</h4>
</>
:null
}

{duration?
<>
<h6>Duration : {duration} month's</h6>
</>
:null}
{stipend?
<>
<h6>Stipend : ₹ {stipend}</h6>
</>
:null}


{workmode?
<>
<h6>Work Location : {workmode}</h6>
</>
:null}




{deadline?
<>
<h6>No of Openings :  {dateFormat(deadline, "dS mmm yyyy")}</h6>
</>
:null}


{openings?
<>
<h6>No of Openings :  {openings}</h6>
</>
:null}




<hr/>

<h5>Description</h5>
{description?
<>
<p>{description}</p>
</>
:null}

<hr/>
<h5>Responsibilities</h5>
{responsibilities?
responsibilities.map((item,index)=>(
<>
<div className="flex mb-2 space-between">
<li>{item}</li>
<RemoveIcon type="button" className="text-danger"/>
</div>

</>


)):null}




<hr/>
<h5>Skills Required</h5>
{skills?
skills.map((item,index)=>(
<>
<div className="flex mb-2 space-between">
<li>{item}</li>
<RemoveIcon type="button" className="text-danger"/>
</div>

</>


)):null}





<hr/>


<h5>Benefits</h5>
{benifits?
benifits.map((item,index)=>(
<>
<div className="flex mb-2 space-between">
<li>{item}</li>
<RemoveIcon type="button" className="text-danger"/>
</div>

</>


)):null}








</div>


</div>








<div className="justify-center mb-5 mt-5">
<buton onClick={AddInternship} className="btn btn-primary">Add Internship</buton>

</div>



</div>


  </>
  )
}

export default InternalAddInternship
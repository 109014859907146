import React, { useContext } from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'
import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import ENDPOINT from '../../context/EndPoint'
import SubmitInternshipTask from './SubmitInternshipTask'
import { AuthContext } from '../../context/AuthContext'

import TimelinePage from '../timeline/TimeLine'
import TeamTemplate from '../../themes/TeamTemplate'
import { Chip } from '@mui/material'

const TeamDashboard = ({account,timeline}) => {

const {user} = useContext(AuthContext)
     const params = useParams();
     const internship_id = params.internshipid;
    // // console.log("internship id"+internship_id);

     useEffect(() => {
          window.scrollTo(0, 0)
          getTasks();
        }, [])

const [tasks,settasks] = useState(null)

const [status,setstatus] = useState("all");

     const getTasks = async (status)=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          

          formData.append('internship_id',internship_id)
          
          const res = await axios.post(ENDPOINT+"interns-posts.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
           // console.log('internship posts are '+JSON.stringify(res.data.posts))
           if(res.data.status==="success"){
            settasks(res.data.posts)
           }
           else{
          
           }
          }
          }
          
const openSubmission = ()=>{
     setsubmit(true)
}



const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)
const [value,setvalue]= useState(null)

const save = ()=>{
var arr = data;
arr = data.push(value)
setdata(arr)
// console.log(" data"+JSON.stringify(data))
}

const [data,setdata] = useState([])
const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
       
     return (
          <>
         <TeamTemplate account={account} timeline={timeline}>
              <>
              




              <Modal
  open={submit}
  onClose={()=>{setsubmit(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
   <>
   <div className="flex space-between">
         <h5 >Submit Task</h5> 
    </div>

    <SubmitInternshipTask id={taskid}/>
   </>
  </Box>
</Modal>











<div className="container-fluid mt-5 pt-5">

<form method="post" >



</form>
<input placeholder="values " onChange={(e)=>{setvalue(e.target.value)}}></input>
<button onClick={save}>Save</button>
</div>



<div className="flex">
{data&&data.length>0?data.map((value,index)=>(

     <>
       <Chip label={value} onClick={()=>data.splice(index,1)}/>
     </>
)):null}
   
</div>



{tasks?
<>

<div className="d-none d-sm-block">
<TimelinePage account={account} posts={tasks} timeline={timeline}/>

</div>
</>


:null}






              </>
         </TeamTemplate>
          </>
     )
}

export default TeamDashboard

import axios from 'axios';

import ENDPOINT from '../../context/EndPoint';

const AdminAddJourney = async ({payload}) => {

          const formData = new FormData();

           formData.append('session',payload.user);
           formData.append('user_id',payload.user_id);

           formData.append('course_url',payload.course_url);
       
           formData.append('enrolled_on',payload.enrolled_on);
       
           formData.append('batch_id',payload.batch_id);
       
           formData.append('purchase_id',payload.purchase_id);
       
           
           
           

           try{
               const res = await axios.post(ENDPOINT+"add-journey.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res.data){
                    return res.data
             
                }
           }
           catch(error){

return error
           }

     




}

export default AdminAddJourney
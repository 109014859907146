import React, { useEffect } from "react";
import InternalTemplate from "../../../themes/InternalTemplate";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { CircularProgress, Snackbar } from "@material-ui/core";
import ENDPOINT from "../../../context/EndPoint";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Toast } from "react-bootstrap";
const TrainerEditCourseOverview = ({ id, courseurl, batchid, course_id }) => {
  const { user } = useContext(AuthContext);

  const params = useParams();

  const courseid = params.courseid;

  useEffect(() => {
    getCourse();
  }, []);

  const [ctitle, setctitle] = useState(null);
  const [cduration, setcduration] = useState(null);
  const [cdescription, setcdescription] = useState(null);
  const [crating, setcrating] = useState(null);
  const [cratings, setcratings] = useState(null);
  const [cprice, setcprice] = useState(null);
  const [cutoff, setcutoff] = useState(null);
  const [offerends, setofferends] = useState(null);
  const [seatsleft, setseatsleft] = useState(null);
  const [nextbatch, setnextbatch] = useState(null);
  const [coursedata, setcoursedata] = useState(null);
  const [cstatus, setcstatus] = useState(null);
  const [promourl, setpromourl] = useState(null);
  const [coverurl, setcoverurl] = useState(null);

  const [status, setstatus] = useState("pending");
  const [addstatus, setaddstatus] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);

  const updateCourse = async (e) => {
    // console.log("posting"+ENDPOINT+"admin-edit-overview.php" )

    e.preventDefault();
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);
    formData.append("course_id", courseid);
    formData.append("course_status", cstatus);
    formData.append("course_title", ctitle);
    formData.append("description", cdescription);
    formData.append("course_price", cprice);
    formData.append("cutoff_price", cutoff);
    formData.append("offer_ends", offerends);
    formData.append("seats_left", seatsleft);
    formData.append("next_batch", nextbatch);
    formData.append("rating", crating);
    formData.append("ratings", cratings);
    formData.append("duration", cduration);

    formData.append("promo_url", promourl);

    const res = await axios.post(
      ENDPOINT + "admin-edit-overview.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      // console.log("posted result "+ JSON.stringify(res))
      if (res.data[0].status === "success") {
        settoasttitle("Update successful");
        settoastmessage("Changes saved successfully");
        settoast(true);
      } else {
        settoasttitle("Something went wrong !");
        settoastmessage("Please try again...");
        settoast(true);
      }
    }
  };

  const getCourse = async () => {
    fetch(`${ENDPOINT}admin-get-course-details.php?course_id=${courseid}`)
      .then((r) => r.json())
      .then((result) => {
        if (result) {
          if (result.status === "success") {
            setcoursedata(result.data[0]);
            const data = result.data[0];
            setcrating(data.rating);
            setctitle(data.course_title);
            setcdescription(data.course_description);
            setcratings(data.ratings);
            setnextbatch(data.next_batch);
            setseatsleft(data.seats_left);
            setcduration(data.course_duration);
            setcprice(data.course_price);
            setcutoff(data.course_cutoff_price);
            setofferends(data.offer_ends);
            setpromourl(data.course_promo_url);
            setcoverurl(data.course_cover_url);
          } else {
            setaddstatus(false);

            setsnackbarMessage("Something went wrong");
            setsnackbar(true);
          }
        }
      });
  };

  const options = ["Option 1", "Option 2"];

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);

  return (
    <>
      <div className="position-fixed bottom-60 left-0 w-100 justify-center">
        <Toast
          className="bg-warning"
          onClose={() => settoast(false)}
          show={toast}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>

      {coursedata ? (
        <>
          <>
            <div className="container-fluid sm-p-15 pt-5">
              {/*account form*/}

              <>
                <div className="flex-wrap ">
                  <div className="">
                    <form method="POST">
                      <div class="form-row">
                        <div class="form-group col-lg-10">
                          <label for="inputEmail4">Title</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={ctitle}
                            onChange={(e) => setctitle(e.target.value)}
                            maxLength={100}
                          />
                        </div>
                        <div class="form-group col-lg-2">
                          <select
                            value={cstatus}
                            onChange={(e) => setcstatus(e.target.value)}
                            maxLength={30}
                          >
                            <option value="active">Active</option>
                            <option value="paused">Paused</option>
                            <option value="completed">Completed</option>
                          </select>
                        </div>

                        <div class="form-group col-lg-12">
                          <label for="inputEmail4">Description</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={cdescription}
                            onChange={(e) => setcdescription(e.target.value)}
                          />
                        </div>
                        <div class="form-group col-lg-12">
                          <label for="inputEmail4">Promo</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={promourl}
                            onChange={(e) => setpromourl(e.target.value)}
                          />
                        </div>
                        <div class="form-group col-lg-12">
                          <label for="inputEmail4">Cover Pic</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={coverurl}
                            onChange={(e) => setcoverurl(e.target.value)}
                          />
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="inputEmail4">
                            Actual Price ({cprice + 0.18 * cprice})
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={cprice}
                            onChange={(e) => setcprice(e.target.value)}
                            maxLength={30}
                          />
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="inputEmail4">Cutoff Price</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={cutoff}
                            onChange={(e) => setcutoff(e.target.value)}
                            maxLength={30}
                          />
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="inputEmail4">Offer Ends</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={offerends}
                            onChange={(e) => setofferends(e.target.value)}
                            maxLength={30}
                          />
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="inputEmail4">Seats Left</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={seatsleft}
                            onChange={(e) => setseatsleft(e.target.value)}
                            maxLength={30}
                          />
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="inputEmail4">
                            Next Batch ({nextbatch})
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            id="inputEmail4"
                            value={nextbatch}
                            onChange={(e) => setnextbatch(e.target.value)}
                            maxLength={30}
                          />
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="inputEmail4">Rating</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={crating}
                            onChange={(e) => setcrating(e.target.value)}
                            maxLength={30}
                          />
                        </div>

                        <div class="form-group col-lg-3">
                          <label for="inputEmail4">Ratings</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={cratings}
                            onChange={(e) => setcratings(e.target.value)}
                            maxLength={30}
                          />
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="inputEmail4">Duration</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputEmail4"
                            value={cduration}
                            onChange={(e) => setcduration(e.target.value)}
                            maxLength={30}
                          />
                        </div>

                        <Snackbar
                          open={snackbar}
                          autoHideDuration={6000}
                          onClose={() => setsnackbar(false)}
                          message={snackbarMessage}
                        />
                      </div>

                      <button
                        type="submit"
                        class="btn btn-primary w-100"
                        onClick={updateCourse}
                      >
                        {addstatus ? (
                          <CircularProgress className="text-white" size={20} />
                        ) : (
                          ""
                        )}
                        Save Changes
                      </button>
                    </form>
                  </div>
                </div>
              </>

              {/*account form*/}
            </div>
          </>
        </>
      ) : (
        <>loading...</>
      )}
    </>
  );
};

export default TrainerEditCourseOverview;

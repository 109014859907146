import React from "react";

import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Avatar, TextField } from "@material-ui/core";

import { AuthContext } from "../../context/AuthContext";
import InternshipItemSkeleton from "../../skeleton/InternshipItemSkeleton";
import axios from "axios";
import { Container, Dropdown, Modal } from "react-bootstrap";
import { MoreVert } from "@material-ui/icons";
import dateFormat from "dateformat";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import ENDPOINT from "../../context/EndPoint";
import { Link, useParams } from "react-router-dom";
import UpdateStudentStatistics from "../../pages/awetecksteam/courses/UpdateStudentStatistics";
import InternSendNotification from "./internships/InternSendNotification";
import AdminGetStudentsList from "../../actions/student/AdminGetStudentsList";
import LoadingTemplate from "../../themes/LoadingTemplate";
import LeadsStatistics from "./leads/LeadsStatistics";
const InternalStudentsList = ({ page, course, props }) => {
  const { user } = useContext(AuthContext);
  const userdata = user;

  const params = useParams();
  const url = params.courseurl;

  const [category, setcategory] = useState("all");
  const [query, setquery] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState("start");

  const searchStudent = () => {
    if (input.length > 4) {
      StudentsList();
    }
  };

  const [loading,setloading] = useState(false)
  const StudentsList = async () => {
    setloading(true)
    const res = await AdminGetStudentsList({
      payload: {
        session: user.SessionId,
        mode: mode,
        query: query,
        url: url ? url : null,
      },
    });

    if (res) {
      setloading(false)
      if (res.status === "success") {
        setInitialData(res.data);
        setResponse(res.data);
        setinternships(res.data.data);
      } else {
      }
    }
  };

  useEffect(() => {
    StudentsList();
  }, [category]);

  const [mode, setmode] = useState("all");

  const [AddPoints, setAddPoints] = useState(false);

  const showAddPoints = () => {
    setAddPoints(true);
  };

  const closeAddPoints = () => {
    setAddPoints(false);
  };

  const [input, setinput] = useState(null);

  const [editstats, seteditstats] = useState(false);
  const [studentid, setstudentid] = useState(null);
  const [reward, setreward] = useState(false);
  const editStatistics = (studentid) => {
    setstudentid(studentid);

    seteditstats(true);
  };

  const [studentrewards, setstudentrewards] = useState(null);

  //delete task code

  const GiftReward = async ({ student_id }) => {
    setreward(true);
    setstudentid(student_id);
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("student_id", student_id);

    const res = await axios.post(
      ENDPOINT + "admin-student-badges.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      //// console.log("badges "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      console.log(res.data.badges);
      setstudentrewards(res.data.badges);
    } else {
    }
  };

  const UnlockReward = async ({ badge_id }) => {
    setreward(true);
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("student_id", studentid);
    formData.append("badge_id", badge_id);

    const res = await axios.post(
      ENDPOINT + "admin-gift-personal-badge.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      // // console.log("unloacked "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      GiftReward({ student_id: studentid });
    } else {
    }
  };

  const [sendnotification, setsendnotification] = useState(false);

  const [initialData, setInitialData] = useState("");

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.user_email.toLowerCase().includes(term) ||
        item.phone_number.includes(term) ||
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.batch_status.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <>
      <>
        <>
          <Modal
            className="min-vh-90"
            {...props}
            size="xl"
            centered
            show={editstats}
            onHide={() => seteditstats(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <b className="text-primary">Student Statistics</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <UpdateStudentStatistics studentid={studentid} />
            </Modal.Body>
          </Modal>

          <Modal show={AddPoints} onHide={closeAddPoints}>
            <Modal.Header closeButton>
              <Modal.Title>Add Points</Modal.Title>
            </Modal.Header>
          </Modal>

          <Modal
            {...props}
            size="xl"
            centered
            show={reward}
            onHide={() => setreward(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Gift a Reward</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container-fluid">
                {studentrewards ? (
                  <>
                    {studentrewards.map((item) => (
                      <>
                        <div className="container-fluid mt-1 mb-1 align-items-center space-between flex">
                          <div>
                            <h5> {item.title}</h5>
                            <p>{item.description}</p>
                          </div>
                          {item.status === "not-earned" ? (
                            <>
                              <button
                                onClick={() => {
                                  UnlockReward({ badge_id: item.badge_id });
                                }}
                                className="btn btn-success h-fit"
                              >
                                Unlock
                              </button>
                            </>
                          ) : (
                            <>
                              <button className="btn btn-danger h-fit">
                                Lock
                              </button>
                            </>
                          )}
                        </div>
                        <hr />
                      </>
                    ))}
                  </>
                ) : (
                  <>no rewards</>
                )}
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            closeButton
            show={sendnotification}
            onHide={() => setsendnotification(false)}
          >
            <InternSendNotification
              close={setsendnotification}
              intern_id={studentid}
            />
          </Modal>

          <div className="container-fluid ">
            <Container fluid className="mt-3">
              <div>
                <input
                  placeholder="Search name, email phone "
                  variant="outlined"
                  className="fixed-search"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </Container>
            <LeadsStatistics/>
            <hr />

       

            <div className="internships-list  sm-p-0  container-fluid">
              {response === "start" ? (
                <div className="container-fluid">
                  <InternshipItemSkeleton />

                  <InternshipItemSkeleton />

                  <InternshipItemSkeleton />

                  <InternshipItemSkeleton />

                  <InternshipItemSkeleton />
                </div>
              ) : response ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">User</TableCell>

                      <TableCell align="left">Course</TableCell>
                      <TableCell align="left">Mode</TableCell>
                      <TableCell align="left">Batch</TableCell>
                      <TableCell align="left">Team</TableCell>
                      <TableCell align="left">Joined</TableCell>

                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {response.map((internship, index) => (
                      <>
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Link
                              className="flex w-100"
                              to={
                                "/student-course-profile/" +
                                internship.purchase_id
                              }
                            >
                              <div className="flex w-100">
                                <Avatar
                                  src={internship.profile_pic}
                                  className="me-2"
                                />
                                <div>
                                  <h6 className="mb-0 text-dark">
                                    {internship.first_name +
                                      " " +
                                      internship.last_name}
                                  </h6>
                                  <p className="mb-0 truncate text-truncate-1 d-none d-sm-block text-secondary">
                                    {internship.email}
                                  </p>
                                  <p className="mb-0 truncate text-truncate-1 d-sm-none d-block text-secondary">
                                    {internship.course_url} | {internship.mode}{" "}
                                    | B:
                                    {internship.batch_id} | T:
                                    {internship.team_id}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </TableCell>

                          <TableCell align="left">
                            <div className="w-100 d-none d-sm-block">
                              <h6>{internship.course_url}</h6>
                       
                            </div>
                          </TableCell>

                          
                          <TableCell align="left">
                            <div className="w-100 d-none d-sm-block">
                              <h6>{internship.mode}</h6>
                       
                            </div>
                          </TableCell>

                          <TableCell align="left">
                            <div className="w-100 d-none d-sm-block">
                        
                              <p className="mb-0">
                            
                                 {internship.batch_id} 
                              </p>
                            </div>
                          </TableCell>

                          
                          <TableCell align="center">
                            <div className="w-100 d-none d-sm-block">
                        
                              <p className="mb-0">
                          
                                {internship.team_id}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="w-100 d-none d-sm-block col-sm-4">
                             
                              <p className="mb-0">
                                {dateFormat(
                                  internship.purchase_datetime,
                                  "dS mmm yy, hh:mm tt"
                                )}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Dropdown className="options-dropdown mr-2">
                              <Dropdown.Toggle className="bg-dark text-light options p-0 w-fit h-fit  border-0">
                                <MoreVert className="text-light" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="bg-dark text-white">
                                <Link
                                  to={
                                    "/student-course-profile/" +
                                    internship.purchase_id
                                  }
                                >
                                  <Dropdown.Item to="/" className="text-white">
                                    View Student Profile
                                  </Dropdown.Item>
                                </Link>
                                <Dropdown.Item
                                  className="text-white"
                                  onClick={(e) => {
                                    setstudentid(internship.user_id);
                                    setsendnotification(true);
                                  }}
                                >
                                  Send a Notification
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="text-white"
                                  onClick={() => {
                                    GiftReward({
                                      student_id: internship.user_id,
                                    });
                                  }}
                                >
                                  Gift a reward
                                </Dropdown.Item>

                       
                              </Dropdown.Menu>
                            </Dropdown>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              ) : (
              <>
              
              {loading?
              <>

              <LoadingTemplate/>
              
              
              </>:
              <>
              <div className="d-flex w-100 justify-content center pt-5">
              <div className="container-fluid">No Internships</div>
              </div>
              
              </>}
              
              </>
              )}
            </div>
          </div>
        </>
      </>
    </>
  );
};

export default InternalStudentsList;

import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import InternalTemplate from '../../themes/InternalTemplate'
import { AuthContext } from '../../context/AuthContext'

import { Link, useParams } from 'react-router-dom'
import StudentWorkshopRegistrations from './workshops/StudentWorkshopRegistrations'
const InternalWorkshopRegistrations = ({ page,course,props}) => {

const {user} = useContext(AuthContext)
const userdata = user;


const params = useParams();
const category = params.category;

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    const [internships,setinternships] = useState("start");







    
 const [AddPoints,setAddPoints] = useState(false);

 const showAddPoints = ()=>{
     setAddPoints(true);
 }
    
const closeAddPoints = ()=>{
    setAddPoints(false)
}

const [input,setinput] = useState(null)


const [editstats,seteditstats] = useState(false)
const [studentid,setstudentid] = useState(null)

const editStatistics = (studentid)=>{
setstudentid(studentid)

seteditstats(true)

}


    return (
       <>
    
<>

<>



<div className="flex-wrap">

<Link to={"/internal/workshop-registrations/all"} className="col-lg-2 p-1">
    <div className="shadow p-1">
    <p>All</p>
    </div>
  </Link>

  <Link to={"/internal/workshop-registrations/attended"} className="col-lg-2 p-1">
    <div className="shadow p-1">
    <p>Attended</p>
    </div>
  </Link>

  <Link to={"/internal/workshop-registrations/un-attended"} className="col-lg-2 p-1">
    <div className="shadow p-1">
    <p>UnAttended</p>
    </div>
  </Link>



  <Link to={"/internal/workshop-registrations/interested"} className="col-lg-2 p-1">
    <div className="shadow p-1">
    <p>Interested</p>
    </div>
  </Link>

  <Link to={"/internal/workshop-registrations/not-interested"} className="col-lg-2 p-1">
    <div className="shadow p-1">
    <p>Not Interested</p>
    </div>
  </Link>

  <Link to={"/internal/internship-applications/rejected"} className="col-lg-2 p-1">
    <div className="shadow p-1">
    <p>Rejected</p>
    </div>
  </Link>



</div>

<StudentWorkshopRegistrations/>



        </>
</>

       </>
    )
}

export default InternalWorkshopRegistrations


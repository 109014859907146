import React, { useContext, useEffect, useState } from 'react'

import HomeTemplate from '../../themes/HomeTemplate'
import { FormControlLabel, Switch } from '@material-ui/core'
import Alert from 'react-bootstrap/Alert'
import preferencesicon from '../../assets/preferences.svg'
import { styled } from '@mui/material/styles';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import generalsettingsicon from '../../assets/general-settings-icon.svg'
import ENDPOINT from '../../context/EndPoint';
import { Toast } from 'react-bootstrap';
import LoadingTemplate from '../../themes/LoadingTemplate'
const SecuritySettings = () => {
useEffect(()=>{
window.scrollTo(0,0)

})
const loginnotif = useRef()
const {user} = useContext(AuthContext)

const [key,setkey] = useState(null);
const [value,setvalue] = useState(false)

const [status,setstatus]= useState(false);
const [toast,settoast] = useState(false)

const [loginnotification,setloginnotification] = useState(true);
const [classes,setclasses] = useState(true);
const [recordings,setrecordings] = useState(true);
const [rewards,setrewards] = useState(true);
const [assignments,setassignments] = useState(true);
const [projects,setprojects] = useState(true);
const [workshops,setworkshops] = useState(true);
const [internships,setinternships] = useState(true);
const [certificates,setcertificates] = useState(true);

const [preferences,setpreferences] = useState(null)

const updatePreferences = async ({key2,val})=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  
    formData.append('key',key2);
     if(val===false){
      formData.append('value',0);
    //  // console.log("key is"+key2+" val "+0)

    }else{

      formData.append('value',1);
    //  // console.log("key is"+key2+" val "+1)


    }

  const res = await axios.post(ENDPOINT+"update-preferences.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
//// console.log(" data "+JSON.stringify(res))
   if(res.data.status==="success"){
     setstatus(true)
    settoast(true)
    getPreferences()
   }
   else{
     settoast(false)
  settoast(true)
  getPreferences()
   }
  }
  }
  




const [pref,setpref] = useState(null)


const [account_login,setaccount_login] = useState(0)

//GET PREFERENCES
const getPreferences = async ()=>{
        

  const formData = new FormData();
  
  
 
  formData.append('session',user.SessionId);
  


  const res = await axios.post(ENDPOINT+"get-preferences.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

 
   if(res.data.status==="success"&&res.data.data!==null){

   //  // console.log("preferences "+JSON.stringify(res.data.data))
     setpreferences(res.data.data)
     const data = res.data.data;
     setaccount_login(data.account_login)
     setpref(res.data.data)
   //  // console.log("data is "+JSON.stringify(res.data))
     setloginnotification(res.data.data.login_notification)
     setclasses(res.data.data.upcoming_classes)
     setrecordings(data.recording_uploaded)
     setrewards(data.reward_earned)
     setassignments(data.assignment_added)
     setprojects(data.project_added)
     setworkshops(data.workshops)
     setinternships(data.internships);
     setcertificates(data.certificates)
     setstatus(true)
 
    // console.log("server stat "+res.data.data.upcoming_classes)
   }
   else if(res.data.status==="success"&&res.data.data===null){
    setpreferences(res.data.data)
    setloginnotification(true);
    setclasses(true);
    setrecordings(true);
    setrewards(true);
    setassignments(true);
    setprojects(true);
    setworkshops(true);
    setinternships(true);
    setcertificates(true);
    
   }
   else{
     //error
   }
  }
  }


useEffect(()=>{
getPreferences()

},[])


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          marginleft:10,
          margintop:5
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));
      








    return (
     
<>


{preferences?
<>

<div className="container-xl mx-auto p-2">


<div className="position-fixed bottom-60 left-0 container-fluid justify-center">


<Toast className={status?"bg-warning":"bg-warning"} onClose={() => settoast(false)} show={toast} delay={3000} autohide>
         
         
         
       

          <Toast.Body>{status?"Changes Saved Successfully !":"Something went wrong !"}</Toast.Body>
          </Toast>
</div>


<div className="flex-wrap mb-5 pt-3 ">








<div className="col-lg-7 mt-0">




























<div className="setting-section p-2 w-100">
<h5>Security Settings</h5>



<div className="flex space-between align-items-center mt-2 mb-2">
    <div>
<p>Send a notification whenever my account is loggedin into a device.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.account_login==="0"?false:true}
        onChange={(e)=>{updatePreferences({key2:"account_login",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center mt-2 mb-2">
    <div>
<p>Send a notification whenever my account is loggedin into an unrecognised device.
</p>
  </div>
     <div>
     <FormControlLabel 
        checked={preferences.unrecognised_device_login==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"unrecognised_device_login",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center mt-2 mb-2">
    <div>
<p>Send a notification whenever my account is loggedin from an unrecognised location.
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.unrecognised_location_login==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"unrecognised_location_login",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>






</div>
























































































</div>




</div>




</div>

</>:

<>
<LoadingTemplate/>
</>


}


</>


    
    )
}

export default SecuritySettings



import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../../context/EndPoint'
import axios from 'axios';
const TaskEditForm = ({id}) => {



const {user} =  useContext(AuthContext)







const [title,settitle] = useState(null)
const [description,setdescription] = useState(null)
const [assignedto,setassignedto] = useState(null)
const [reference,setreference] = useState(null)
const [deadline,setdeadline] = useState(null)
const [marks,setmarks] = useState(null)
const [earnedmarks,setearnedmarks] = useState(null)
const arr = [];
const [corrections,setcorrections] = useState(arr)
const [assignedby,setassignedby]=  useState(null)
const [taskstatus,settaskstatus]= useState(null);
const [deadline2,setdeadline2] = useState(null)
const [account,setaccount]=  useState(null)
useEffect(()=>{
    
  
  
  const  getaccount = async ()=>{
  
    
            fetch(`${ENDPOINT}admin-single-task.php?session=${user.SessionId}&id=${id}`)
              .then(r=>r.json())
              .then(result=>{
             
             if(result){
      
            if((result[0].status==="success")&&(result[0].data!=null)){
                const data = result[0].data;
             settitle(data.title)
             setdescription(data.description)
             setreference(data.reference)
             settaskstatus(data.status)
             setassignedby(data.assignedby)
             setassignedto(data.assignedto)
             setdeadline(data.deadline)
             setmarks(data.marks)
             setearnedmarks(data.marks_received)
             setdeadline2(data.deadline2);
        
            }else {
              
            }
          
             }
              else{
               
              }
              
                          
              
                  
              })
            
    
       
            }    ;

if(user!==null||user!=="null"){
getaccount();
}

     
}

,[user])













    const changeTitle = (e)=>{
        settitle(e.target.value)
        
      }
      const changeDescription = (e)=>{
          setdescription(e.target.value)
      }
      const changeAssignto = (e)=>{
          setassignedto(e.target.value)
      }
    const changeMaxMarks = (e)=>{
        setmarks(e.target.value)
    }
    const changeMarksGiven = (e)=>{
        setearnedmarks(e.target.value)
    }
    const changeCorrections = (e)=>{
        setcorrections(e.target.value)
    }
    const changeReference = (e)=>{
        setreference(e.target.value);

    }
    const changeDeadline = (e)=>{
        setdeadline(e.target.value)
      
    }
    const changeStatus = (e)=>{
        settaskstatus(e.target.value)
    }
      const [status,setstatus]= useState(false)

      const [snackbar,setsnackbar] = useState(false);
      const [snackbarMessage,setsnackbarMessage] = useState(null)
      
      

      const onSubmit = async (e)=> {
        e.preventDefault();
          setstatus(true)

 const data = new FormData()
 data.append('title',title)
 data.append('description',description)
 data.append('status',status)
 data.append('corrections',corrections)
 data.append('marks',marks)
 data.append('earned_marks',earnedmarks);
 data.append('id',id)
 data.append('deadline',deadline+" "+time)
 data.append('reference',reference)
 data.append('session',user.SessionId)
const res = await axios.post(ENDPOINT+"admin-update-task.php",data,{headers:{'content-type':'form-data/multipart'}})

         
if(res){


  if(res.data[0].status==="success"){

    setstatus(false);
    setsnackbarMessage("Changes Saved Successfully")
    setsnackbar(true)   

  }else{
    setstatus(false);
 
    setsnackbarMessage("Something went wrong")
    setsnackbar(true)  
  }
}
           
                
               
        
               
          }
          
         




const [time,settime] = useState(null)

   const [date,setdate] = useState(null)   



    return (
        <div>
            
            {/*account form*/}
            {title?
            
        <>
<div className="flex-wrap ">
            
            <div className="">
            
            
            
            <form onSubmit={onSubmit}>
            
         
            <div class="form-row">
                <div class="form-group col-lg-12">
                
                  <input type="text" placeholder="Title" class="form-control" id="inputEmail4" value={title} onChange={changeTitle} maxLength={100}/>
                </div>

                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Description</label>
                  <textarea type="text" class="form-control" id="inputEmail4" value={description} onChange={changeDescription} maxLength={500}/>
                </div>

                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Reference</label>
                  <input type="text" class="form-control" id="inputEmail4" value={reference} onChange={changeReference} maxLength={300}/>
                </div>
                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Deadline ( {deadline} )</label>
               
               
               <div className="flex">
               <input type="date" class="form-control" id="inputEmail4" value={date} onChange={(e)=>{setdate(e.target.value);setdeadline(date+" "+time)}} maxLength={30}/>
              
              <input type="time" class="form-control" id="inputEmail4" value={time} onChange={(e)=>{settime(e.target.value);setdeadline(date+" "+time)}} maxLength={30}/>

               </div>
                            
              
                </div>
              

   

<Snackbar
 className="bg-success text-light"
        open={snackbar}
        autoHideDuration={6000}
        onClose={()=>setsnackbar(false)}
        message={snackbarMessage}
    
    
      />





<div className="form-row">
<div class="form-group col-md-4">
                  <label for="inputEmail4">Status ({taskstatus} )</label>
                  <select type="text" class="form-control" id="inputEmail4" value={taskstatus} onChange={changeStatus} maxLength={15}>
                  
<option value="pending">Pending</option>
<option value="submitted">Submitted</option>
<option value="completed">Completed</option>
<option value="rejected">Rejected</option>
                    </select>

                
                    
                </div>
<div class="form-group col-md-4">
                  <label for="inputPassword4">Max Marks</label>
                  <input type="number" class="form-control" id="inputPassword4" value={marks} onChange={changeMaxMarks} maxLength={3}/>
                </div>
             
                <div class="form-group col-md-4">
                  <label for="inputPassword4">Marks Given</label>
                  <input type="number" class="form-control" id="inputPassword4" value={earnedmarks} onChange={changeMarksGiven} maxLength={3}/>
                </div>  
             
              
              </div>
            
            
             
</div>

           
                <div class="form-group  ">
                  <label for="inputPassword4">Corrections</label>
                  <input type="text" class="form-control" id="inputPassword4" value={corrections} onChange={changeCorrections} maxLength={500}/>
                </div>
            
            
            
          
            
            
           
            
             
            
            
            
              <div class="form-group">
                
              </div>
              <button type="submit" class="btn btn-primary w-100" onClick={onSubmit}  >{status?<CircularProgress className="text-white" size={20}/> :""}Save Changes</button>
            </form>
            
            </div>
  
            
            
            
                        </div>

        </>

        :""
        
        }
                           {/*account form*/}
        </div>
    )
}

export default TaskEditForm

import React from 'react'

const WhatsAppChat = () => {
  return (
    <div>
     
     <div className="d-flex">

<div className="col-lg-5">
<h2>Chat Heads</h2>



</div>

<div className="col-lg-6">


</div>

     </div>
     
     
     WhatsAppChat</div>
  )
}

export default WhatsAppChat
import React from 'react'

import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Avatar } from '@material-ui/core'
import InternalTemplate from '../../themes/InternalTemplate'
import { AuthContext } from '../../context/AuthContext'
import InternshipItemSkeleton from '../../skeleton/InternshipItemSkeleton'
import dateFormat from 'dateformat'
import { Dropdown, Modal } from 'react-bootstrap'
import { MoreVert } from '@material-ui/icons'
import ENDPOINT from '../../context/EndPoint'
import { Link, useParams } from 'react-router-dom'
import UpdateStudentStatistics from '../../pages/awetecksteam/courses/UpdateStudentStatistics';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
const InternalStudentsList = ({ page,course,props}) => {

const {user} = useContext(AuthContext)
const userdata = user;


const params = useParams();
const category = params.category;

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [internships,setinternships] = useState("start");




    const  getinternships = async ()=>{
      let URL = `${ENDPOINT}admin-get-ambassadors.php?session=${user.SessionId}&mode=${category}`;
       if(input){
URL = URL+`&input=${input}`;
       }

            fetch(URL)
            .then(r=>r.json())
            .then(result=>{
           
             
                    setinternships(result.data);
           
                
            })
       
          
            
            }    ;

            const [input,setinput] = useState(null)


    useEffect(()=>{
     
    
      
    getinternships();
           
    }    
    ,[category,input])

 
    
    
    
    const [mode,setmode] = useState(null);
    
 const [AddPoints,setAddPoints] = useState(false);

 const showAddPoints = ()=>{
     setAddPoints(true);
 }
    
const closeAddPoints = ()=>{
    setAddPoints(false)
}


const [editstats,seteditstats] = useState(false)
const [studentid,setstudentid] = useState(null)

const editStatistics = (studentid)=>{
setstudentid(studentid)

seteditstats(true)

}



 console.log(internships)
    return (
       <>
   
<>

<>

<div className="mt-3 flex-wrap">
<Link to={"/internal/cai-registrations/all"} className="col-lg-3 p-1">
  <div className="shadow p-1">
<p>All</p>
  </div>
</Link>

<Link to={"/internal/cai-registrations/active"} className="col-lg-3 p-1">
  <div className="shadow p-1">
<p>Active</p>
  </div>
</Link>

<Link to={"/internal/cai-registrations/applied"} className="col-lg-3 p-1">
  <div className="shadow p-1">
<p>Applied</p>
  </div>
</Link>
<Link to={"/internal/cai-registrations/inactive"} className="col-lg-3 p-1">
  <div className="shadow p-1">
<p>Inactive</p>
  </div>
</Link>

<Link to={"/internal/cai-registrations/selected"} className="col-lg-3 p-1">
  <div className="shadow p-1">
<p>Selected</p>
  </div>
</Link>

<Link to={"/internal/cai-registrations/rejected"} className="col-lg-3 p-1">
  <div className="shadow p-1">
<p>Rejected</p>
  </div>
</Link>


<Link to={"/internal/cai-registrations/completed"} className="col-lg-3 p-1">
  <div className="shadow p-1">
<p>Denied</p>
  </div>
</Link>
<Link to={"/internal/cai-registrations/completed"} className="col-lg-3 p-1">
  <div className="shadow p-1">
<p>Completed</p>
  </div>
</Link>

</div>
           
<Modal
      className="min-vh-90"
      {...props}
      size="xl"
  
      centered
      
     show={editstats} onHide={() => seteditstats(false)}>
        <Modal.Header closeButton>
          <Modal.Title><b className="text-primary">Student Statistics</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
    <UpdateStudentStatistics studentid={studentid}/>


        </Modal.Body>
      </Modal>
 


<Modal show={AddPoints} onHide={closeAddPoints}>
        <Modal.Header closeButton>
          <Modal.Title>Add Points</Modal.Title>
        </Modal.Header>
   
      </Modal>









<div className="internships-page flex-wrap mobile-reverse ">
<div className="flex container-fluid align-items-center">
<div className="container-fluid mt-3">
    
<h4 className="mb-4">{internships?internships.length:0} Students</h4>

</div>

<div>
  <select  >
    <option value="live">Live Online Mode</option>
    <option value="offline">Offline Mode</option>
    <option value="selfpaced">Selfpaced Mode</option>
    <option value="specialization">Selfpaced Mode</option>
  </select>
</div>

<div className="container-fluid">
  <input className="form-control" value={input?input:null} onChange={(e)=>{setinput(e.target.value)}} placeholder="Search student by Email, Invoice ID"></input>
</div>

</div>
<div className="internships-list  sm-p-0  container-fluid">

{ internships==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>




:internships?

<Table sx={{ minWidth: 650 }} aria-label="simple table">

<TableHead>
  <TableRow>
    <TableCell>User</TableCell>
    <TableCell align="center">Contact</TableCell>
    <TableCell align="center">Date</TableCell>
  
    <TableCell align="right">Actions</TableCell>
  </TableRow>
</TableHead>

<TableBody>
  {
    internships.map((internship,index)=>(

      <>
      <TableRow key={index}     sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

    
  
      <TableCell className='col-3' component="th" scope='row'>
      <div className="flex col w-100">
      <Avatar src={internship.profile_pic} className="mr-2"/>
      <div>
      <p className="mb-0">{internship.first_name+" "+internship.last_name}</p>
      <p className="mb-0">{internship.email}</p>
      </div>
      </div>
      
      </TableCell>
      
      
     <TableCell className='col-lg-3 col-sm-4 ' align='center'>
     <div className=" w-100">
        <p>{internship.phone_number}</p>
        <a target='_blank' href={internship.resume_link}>resume</a>
      </div>
     </TableCell>
     <TableCell className='col-lg-4 col-sm-4' align='center'>
     <div className="w-100">
      <p className="mb-0">{dateFormat(internship.applied_date,"dS mmm yyyy hh:mm tt") }</p> 
      </div>
     </TableCell>
      
      <TableCell align='right'>
      <Dropdown className="options-dropdown">   
        <Dropdown.Toggle className="bg-dark text-light options p-0 w-fit h-fit  border-0" >
        <MoreVert className="text-light"/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-dark text-white">
        <Dropdown.Item className="text-white" >Mark Joined</Dropdown.Item>
        <Dropdown.Item className="text-white" >Terminate</Dropdown.Item>
          <Dropdown.Item className="text-white" >Mark Completed</Dropdown.Item>
       
          <Dropdown.Item className="text-white" >Mark Rejected</Dropdown.Item>
       
        <Dropdown.Item  className="text-white" onClick={()=>{editStatistics(internship.user_id)}}>Update Statistics</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      
      </TableCell>
  
      </TableRow>
      </>
      ))
  }

</TableBody>
  
  </Table>



:
<div className="container-fluid">

No Internships

    </div>

}





</div>




</div>

   



        </>
</>
       </>
    )
}

export default InternalStudentsList


import React from 'react'
import UpcomingClassesCalendar from './schedules/UpcomingClassesCalendar'
import { Avatar } from '@mui/material'
import UserListItem from './users/UserListItem'

const InternalDashboard = () => {
  return (
    <div>
        <div className="d-flex">


<div className="col-lg-4">
<h4>Users</h4>


    <UserListItem/>
    <UserListItem/>
    <UserListItem/>
    <UserListItem/>
    <UserListItem/>

</div>

<div className="col-lg-4">


</div>

<div className="col-lg-4">
    <UpcomingClassesCalendar/>
</div>
        </div>
        
        
        </div>
  )
}

export default InternalDashboard
import { Avatar } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext'
import ENDPOINT from '../../../context/EndPoint'
import SecurityHeaders from '../../../context/SecurityHeaders'
import dateFormat from 'dateformat'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import AssignmentAdded from '../whatsapptemplates/AssignmentAdded'
import AssignmentEvaluated from '../whatsapptemplates/AssignmentEvaluated'

const WhatsAppNotifications = () => {
const {user} = useContext(AuthContext)

const params = useParams()
const phone_number = 9490174876;
const GetChats = async ()=>{
  setloading(true)
const data = new FormData()
data.append("session",user.SessionId)
data.append("phone",phone_number)
  const res = await axios.post(ENDPOINT+"whatsapp-chats.php",data,SecurityHeaders)

  if(res){
setloading(false)
if(res.data.status==="success"){

  
 
  setchats(res.data.chat_heads)
  setmessages(res.data.messages)
}
    console.log(JSON.stringify(res.data))
  }
}
useEffect(()=>{
GetChats()
},[phone_number])

/*
{"messages":[{"from":"919490174876","id":"ABEGkZSQF0h2AhA9J7p8OWjSi0ccbx8djb49","image":{"id":"b6952a89-f771-4c1c-8d23-e286fa29baf3","mime_type":"image/jpeg","sha256":"2e9b11a3915c3a199c93e5aaba25af74409c58160de95da589508bdad69c553d"},"timestamp":"1663580433","type":"image"}]}

*/
const [chats,setchats] = useState(null)
const [loading,setloading] = useState(false)
const [message,setmessage] = useState(null)
const [messages,setmessages] = useState("hi")
const [template,settemplate] = useState("added_assignment")




const SendMessage = async ({payload})=>{

  
    const data = new FormData()
    data.append("phone",phone_number)
    data.append("session",user.SessionId)
   
  
    if(payload.template){
      data.append("template",payload.template)
    }
    if(payload.one){
      data.append("one",payload.one)
    }
    if(payload.two){
      data.append("two","hi")
    }
    if(payload.three){
      data.append("three","hi")
    }
    if(payload.four){
      data.append("four","hi")
    }
    if(payload.five){
      data.append("five","hi")
    }
    if(payload.six){
      data.append("six","hi")
    }
    if(payload.seven){
      data.append("seven","hi")
    }
    const res = await axios.post(ENDPOINT+"whatsapp-api.php",data,SecurityHeaders)
  setmessage("")

  setmessage(null)

    if(res){
      console.log("send "+JSON.stringify(res))
    }

  
}
























//ai section





const [script,setscript] = useState(null)

  const {
    transcript,
    listening,
  } = useSpeechRecognition();

  useEffect(()=>{
if(transcript!==null){

  setscript(transcript)


}


  },[transcript])

const [automatic,setautomatic] = useState(false)

  useEffect(()=>{
    setmessage(transcript)
    if(!listening&&transcript!==null){

var input = transcript.toLowerCase();
var text = input.replace(" ","")
 setmessage(transcript.replace("send",""))
  if(automatic){
    SendMessage()
  SpeechRecognition.startListening()
  }
if(text.includes("send")){
 
}
    
    }
  },[listening,transcript])






  const [meeting,setmeeting] = useState(false)




//ai section
const [courseurl,setcourseurl] = useState(null)
const [batchid,setbatchid] = useState(null)
const [all,setall] = useState(false)
const [students,setstudents] = useState(false)
const [image,setimage] = useState(null)

const [title,settitle] = useState(null)
const [category,setcategory] = useState("students")

const [one,setone] = useState(null)
const [two,settwo] = useState(null)

const [three,setthree] = useState(null)

const [four,setfour] = useState(null)

const [five,setfive] = useState(null)

const [six,setsix] = useState(null)

const [seven,setseven] = useState(null)
const [payload,setpayload] = useState({"image":image,"one":one,"two":two,"three":three,"four":four,
"five":five,"six":six,"seven":seven})



useEffect(()=>{

if(template==="added_assignment"){
  settwo(courseurl)
}

setpayload({"image":image,"one":one,"two":two,"three":three,"four":four,
"five":five,"six":six,"seven":seven})

},[title,one,two,three,four,five,six,seven,category,all,courseurl,students,payload,template])

  return (
    <div>
 
  <div className="d-flex">

    

    <div className="col-lg-5 shadow p-2 border br-4">
<img className="w-75 mx-auto" src={image}></img>


<div className="p-2">
  <h4>Batch : {batchid}</h4>

{template==="added_assignment"?
<>
<AssignmentAdded payload={payload}/>
</>:
<></>}


{template==="assignment_evaluated"?
<>

 <AssignmentEvaluated payload={payload}/>
</>:
<></>}



{template==="class_commencement_notification"?
<>
<AssignmentAdded payload={payload}/>
<p>Dear {1} , your trainer is waiting for you at your classroom. Kindly join the class and bring light to the classroom with your presence. Please login into your website dashboard to join the class by marking your attendance.

Join Skills Uprise & Keep Rising Up !
Sofy | Skills Uprise AI Bot</p>


</>:
<></>}



{template==="class_early_reminder"?
<>
<p>Class Starts Soon !

Dear {1} , this is a remainder for your {2} class that has been scheduled at {3}. Join the class and learn the best lessons. Visit Skills Uprise course dashboard to mark class details
</p>
</>:
<></>}




{template==="otp_verification"?
<>
<p>Dear {1}, Your 6 Digit Verification Code is {2} . Verify your account and start building a successful career with us. 

In case, you have any issues verifying your account , feel free to contact us via Email :  contact@skillsuprise.com or  via Phone : +919490174876

Happy Uprising ! 
Team Skills Uprise</p>


</>:
<></>}





{template==="pending_course_registration"?
<>
<p>Hey {1} Here's a welcome bonus for you !

Save  ₹ {2}  by using this  Coupon Code : {3} 

Hurry up , the seats are filling quick. Complete your course registration by paying the registration fee and start a successful career journey. Join 5000+ student who have already joined our trainings.

Offer ends soon ! </p>


</>:
<></>}





{template==="recording_added"?
<>
<p>Class Recording Available !

Hey {1}, today's class recording is now available in your course dashboard. 

Class Title : {2} 
Schedule : {3}


</p>


</>:
<></>}


{template==="reset_password_otp"?
<>
<p>Dear {1}, Your 6 digit verification code is {2} . This OTP is valid only for 10 minutes. Reset your password now to login into your account. In case you have problems resetting your password or logging into your account, feel free to reach us at contact@skillsuprise.com

</p>


</>:
<></>}



{template==="assignment_submission_notification"?
<>
<p>Hey {1}, You have {2} pending assignments. Submit your assignment before the deadline to unlock certificates, badges and points
</p>


</>:
<></>}




{template==="recording_added"?
<>
<p>Class Recording Available !

Hey {1}, today's class recording is now available in your course dashboard. 

Class Title : {2} 
Schedule : {3}


</p>


</>:
<></>}




{template==="recording_added"?
<>
<p>Class Recording Available !

Hey {1}, today's class recording is now available in your course dashboard. 

Class Title : {2} 
Schedule : {3}


</p>


</>:
<></>}



{template==="recording_added"?
<>
<p>Class Recording Available !

Hey {1}, today's class recording is now available in your course dashboard. 

Class Title : {2} 
Schedule : {3}


</p>


</>:
<></>}

  <p>1 : {one}</p>
  <p>2 : {two}</p>
  <p>3 : {three}</p>
  <p>4 : {four}</p>
  <p>5: {five}</p>
  <p>6 : {six}</p>
</div>
    </div>
    <div className="col-lg-5">
       
       <div className="mt-3">
        <select className="form-control" onChange={(e)=>settemplate(e.target.value)}>
          <option>Message category</option>
          <option  value="general_message">General message</option>
          <option  value="class_details">Class Meeting details</option>
          <option  value="events">Events</option>
          <option  value="otp_verification">OTP Verification</option>
          <option  value="password_reset_otp">Password reset</option>
          <option  value="added_assignment">Assignment Added</option>
          <option  value="assignment_evaluated">Assignment Evaluated</option>
          <option  value="added_schedule">Updated class schedule</option>
          <option  value="added_project">Added project</option>
          <option  value="user_engagement">User engagement</option>
          <option  value="pending_course_registration">Pending course registration</option>
          <option  value="coupon_code">Coupon code</option>
          <option  value="pending_payment">Pending payment</option>
          <option  value="placement_training">Placement trainings</option>
          <option  value="doubt_support_update">Doubt support updates</option>
          <option  value="course_termination">Course termination</option>
        </select>
       </div>
<div className="d-flex mt-4">
<select onChange={(e)=>setcategory(e.target.value)}  className="form-control" >
<option  value="all">All Users</option>
<option value="students">All Students</option>
<option value="pending_payment">Website devlopment from scratch</option>

 </select>
 <select className="form-control" onChange={(e)=>setcourseurl(e.target.value)}>
  <option>Select Course</option>
 <option value="website-development">Full stack</option>
<option value="fullstack-specialization">Full stack specialization- MERN</option>
<option value="mean-stack-specialization">Full stack specialization - MEAN</option>
<option value="ethical-hacking-specialization">Ethical Hacking Specialization</option>
<option value="data-science-specialization">Data science specialization</option>
<option value="cloud-computing">Cloud computing</option>
<option value="data-science">Data science</option>
<option value="python-programming">Python programming</option>
<option value="ui-ux-development">UI / UX Development</option>
<option value="corejava">Java Programming</option>
<option value="reactjs">ReactJs</option>
<option value="cpp">C programming</option>
<option value="software-testing">Software testing</option>
<option value="internet-of-things-iot">Internet of Things</option>
<option value="machine-learning">Machine Learning</option>
 </select>
 <select  className="form-control" >
  <option>All Batches</option>
  <option>Specific Batch</option>
 </select>
</div>


   <div className="mt-2">
   <input className="form-control " onChange={(e)=>setbatchid(e.target.value)} placeholder="Batch ID"></input>
   

   </div>

   <div className="mt-2">
    <input className="form-control" placeholder="Image URL" onChange={(e)=>setimage(e.target.value)}></input>
   </div>
   

   <div className="mt-2">
   <input className="form-control "  onChange={(e)=>setone(e.target.value)} placeholder="One"></input>
   

   </div>
   


   <div className="mt-2">
   <input className="form-control " onChange={(e)=>settwo(e.target.value)}  placeholder="Two"></input>
   

   </div>
   


   <div className="mt-2">
   <input className="form-control " onChange={(e)=>setthree(e.target.value)}  placeholder="Three"></input>
   

   </div>
   <div className="mt-2">
   <input className="form-control " onChange={(e)=>setfour(e.target.value)}  placeholder="Four"></input>
   

   </div>
   <div className="mt-2">
   <input className="form-control "  onChange={(e)=>setfive(e.target.value)} placeholder="Five"></input>
   

   </div>
   <div className="mt-2">
   <input className="form-control " onChange={(e)=>setsix(e.target.value)}  placeholder="Six"></input>
   

   </div>
   <button className="btn btn-primary w-100 mt-3" onClick={()=>SendMessage({payload:{one:"Manoj, I",two:"Manoj, I.",three:"Manoj, I.",four:"Manoj, I.",template:"meeting_details"}})}>Send Message</button>
  
    </div>
  </div>
   
    </div>
  )
}

export default WhatsAppNotifications

import DownloadIcon from '@mui/icons-material/Download';
import React from 'react'

import HomeTemplate from '../../themes/HomeTemplate'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import offerimage from '../../assets/offer-letter-template.svg'

import { AuthContext } from '../../context/AuthContext'
import ENDPOINT from '../../context/EndPoint'

import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react'
import {useParams} from 'react-router'

import { Check, Close } from '@material-ui/icons'
import { Box, Modal } from '@material-ui/core';
import UploadOfferLetter from './UploadOfferLetter';
const OfferLetterPage = () => {

const {user} = useContext(AuthContext)
const userdata= user;
const params = useParams();
const internship_id = params.internshipid;
 const content = useRef();
 const [status,setstatus] = useState("initial")
    const [certificates,setcertificates] = useState(null);
    const [internship,setinternship] = useState(null)
    useEffect(()=>{
       
    
      
    getcertificates();
           
    }
    
    ,[])
    
    
    const  getcertificates = async ()=>{

        fetch(`${ENDPOINT}internship-status.php?session=${user.SessionId}&internship_id=${internship_id}`)
        .then(r=>r.json())
        .then(result=>{
         //   // console.log("internship status"+JSON.stringify(result[0].data[0]))
       
             
              
      setinternship(result[0].data[0])
            
        })
        
        }    ;
    
    
    
    const  acceptInternship = async ()=>{

        fetch(`${ENDPOINT}accept-internship.php?session=${user.SessionId}&internship_id=${internship_id}&status=accept`)
        .then(r=>r.json())
        .then(result=>{
       //     // console.log("acceptance status"+JSON.stringify(result[0]))
       const data = result[0];
             if(data.status==="success"){
getcertificates();
             }
              else{

              }

            
        })
        
        }    ;
    
    
    
    
    
    
  const downloadOfferLetter = ()=>{


   window.print()
  }
    const hideOfferLetter = ()=>{
       // const offerletter = document.getElementById("offer-letter");
       // offerletter.style.display = "none";
        
    }
    const displayOfferLetter = ()=>{
       // const offerletter = document.getElementById("offer-letter");
       // offerletter.style.display = "block";
    }
  
  const handlePrint = useReactToPrint({
    onBeforeGetContent: ()=> {

displayOfferLetter();
    },
    onAfterPrint:()=> {
      hideOfferLetter()
            },
            onBeforePrint:()=> {
           hideOfferLetter();
                
                    },
                    onPrintError:()=> {
                        hideOfferLetter();
                             
                                 },
     content: () => content.current,
     documentTitle: internship?internship.first_name+" "+internship.last_name+" "+internship.position_title+" Internship Offer Letter":"Internship Offer Letter"
  });
    
    const [upload,setupload] = useState(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  


    return (

<HomeTemplate>

<>



<Modal
  open={upload}
  onClose={()=>{setupload(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <UploadOfferLetter id={internship_id}/>
  </Box>
</Modal>








{internship?

<>
{/*has internship*/}
<div>


<div className="flex-wrap pt-3 pb-5">


    <div className="col-lg-7">
    <div className="container-fluid justify-center">


   





</div>
    </div>

    <div className="col-lg-4">
        <h2 className="text-danger mt-2">Important Instruction's</h2>
    
    <p>Once you have agreed with our terms, your day-to-day responsibilities and have accepted the offer letter shared to you, follow the detailed instructions mentioned below.
        
        You can either Accept / Reject the offer letter. Once you have accepted the offer letter, you cannot terminate your work as per the terms and conditions framed in your offer letter.</p>
    
    <h5 className="mt-5 text-primary">Step's to follow :</h5>

    {}
    <p>1. Accept Offer Letter</p>
    <p>2. Download Offer Letter</p>
    <p>3. Take a hard copy of the offer letter</p>
    <p>4. Sign the offer letter at the required places along with the date.</p>
    <p>5. Once you accept the offer letter, a submission button will be displayed. Click "Submit Offer Letter " and submit a scanned copy of your signed offer letter. </p>
    <button onClick={handlePrint} className="btn btn-primary "><DownloadIcon/> Download Offer Letter </button>
    <canvas id="myCanvas" width="200" height="100" >
    <img src={offerimage} className="w-100"/>

      </canvas>

   <div className="flex space-between">
      {internship.status==="accepted"?
 
 
 <>


<button onClick={()=>setupload(true)} className="btn btn-warning ">Upload Offer Letter </button>

 
 
 </>
 : internship.status==="selected"?   
<>
<button className="btn btn-danger"><Close/> Reject Offer Letter</button>


<button onClick={acceptInternship} className="btn btn-success"><Check/> Accept Offer Letter</button>


</>
:null

}
    </div>
   
    </div>
</div>
   





</div>
</>
:null}

</>
</HomeTemplate>
    )
}

export default OfferLetterPage








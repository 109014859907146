import axios from 'axios';

import ENDPOINT from '../../context/EndPoint';

const AdminGetStudentsList = async ({payload}) => {

          const formData = new FormData();

           formData.append('session',payload.session);
           formData.append('mode',payload.mode);
           
           if(payload.query!==null){
               formData.append('query',payload.query);
       

           }
           if(payload.url){
            formData.append('course_url',payload.url)
           }
      
           
           
           

           try{
               const res = await axios.post(ENDPOINT+"admin-get-students.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res.data){
                    return res.data
             
                }
           }
           catch(error){

return error
           }

     




}

export default AdminGetStudentsList
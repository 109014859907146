import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useParams } from 'react-router-dom';
import ENDPOINT from '../../../context/EndPoint'
import CloseIcon from '@mui/icons-material/Close';
import { Toast } from 'react-bootstrap'
import Checkbox from '@mui/material/Checkbox';

import axios from 'axios';
import GetUserPersonalBadges from '../../../actions/user/GetUserPersonalBadges';
import BadgeItem from '../badges/BadgeItem';
import GetStudentCourseBadges from '../../../actions/student/GetStudentCourseBadges';
import CircularProgress from '../../../components/progress/CircularProgress'
import CircularStatic from './CircularProgressWithLabel'
import BasicInformation from './BasicInformation'
import CourseCheckList from './CourseCheckList'
import ModalStyle from '../../../context/ModalStyle'

import TextField from '@mui/material/TextField';
import AdminEditStudent from '../../../actions/student/AdiminEditStudent'
import AdminUnlockAccess from '../../../actions/course/AdminUnlockAccess'
import StudentReport from './StudentReport'
import AssignmentSubmissions from './AssignmentSubmissions'
import ProjectSubmissions from './ProjectSubmissions'
import DoubtRequests from './DoubtRequests'
import SecurityHeaders from '../../../context/SecurityHeaders'

import dateFormat from 'dateformat'
import StudentReports from './StudentReports'
import UserProfileBasic from '../../account/UserProfileBasic'
import { Avatar, Chip } from '@material-ui/core'
import { Download } from '@mui/icons-material'
import UserActivity from '../users/UserActivity'
const StudentCourseProfile = ({ page}) => {

const {user} = useContext(AuthContext)
const userdata = user;
const params = useParams();
const purchase_id = params.purchase_id
const [status,setstatus] = useState("all")

const [query,setquery] = useState(null)


const [section,setsection] = useState("reports")

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    let [profile,setprofile] = useState("start");

    useEffect(()=>{
     
    
      
    getinternships();
           
    }
    
    ,[status])
    
    const  getinternships = async ()=>{
      setprofile("start")
      const data = new FormData();
      data.append('session',user.SessionId)
      if(query){
        data.append('query',query)
      }
      if(status){
        data.append('status',status)
      }
      data.append('purchase_id',purchase_id)
      const res = await axios.post(ENDPOINT+"student-course-profile.php",data,{headers:{
        'content-type':'multipart/form-data'
      }})


      if(res){
      console.log("profile is "+JSON.stringify(res))
        if(res.data.status==="success"){
          setprofile(res.data.data)
          setselectedname(res.data.data.first_name)
         
        }else{
               }
      }
     
          
            
            }    ;
    
    
    
    
            const  updateRequest = async ({ticketstatus,ticketid})=>{
      
                if (userdata!==null){
        
                    fetch(`${ENDPOINT}admin-update-doubt-request.php?session=${user.SessionId}&status=${ticketstatus}&id=${ticketid}`)
                    .then(r=>r.json())
                    .then(result=>{
                      
                  // // console.log(JSON.stringify(result))
                            if(result.status==="success"){
        settoasttitle("Successful")
        settoastmessage("Status changed to "+ticketstatus)
        settoast(true)
        getinternships()
                            }
                            else{
                                settoasttitle("Something went wrong !")
                                settoastmessage("Please try again... ")
                                settoast(true)
                              
                            }
                       
                       
                        
                    })
                }
                else{
                
                }
                  
                    
                    }    ;
            
            
    

const [toast,settoast] = useState(false);
const [toasttitle,settoasttitle] = useState(null)

const [toastmessage,settoastmessage] = useState(null)



const NewUserBadges = async ({user_id})=>{

     const res = await GetUserPersonalBadges({payload:{
          user:user.SessionId,
          user_id:user_id
     }})
     if(res){
          if(res.status==="success"){
               setpersonalbadges(res.badges)
          }
      }
}


const DownloadCertificate = ()=>{


     
}

const NewCourseBadges = async ({user_id})=>{

const res = await GetStudentCourseBadges({payload:{
     user:user.SessionId,
     user_id:user_id,
     course_url:profile.course_url
}})
if(res){
     console.log("c badges are "+JSON.stringify(res))
  
}
if(res.status==="success"){
     console.log("c badges are "+JSON.stringify(res))
  
     setcoursebadges(res.badges)
}

}

const [personalbadges,setpersonalbadges] = useState(null)
useEffect(()=>{

   

          NewUserBadges({user_id:profile.user_id})
      if(section==="course_badges"){
        NewCourseBadges({user_id:profile.user_id})
  
    }
},[profile,section])

const [studentid,setstudentid] = useState(profile.payment_id)
const [batchid,setbatchid] = useState(profile.batch_id)
const [teamid,setteamid] = useState(profile.team_id)
const [progress,setprogress] = useState(0)
const [marks,setmarks] = useState(0)
const [points,setpoints] = useState(0)

const [editmodal,seteditmodal] = useState(false)
const [coursebadges,setcoursebadges] = useState(null)

const EditStudent = async (e)=>{
  e.preventDefault()
const res = await AdminEditStudent({
  payload:{
    user:user.SessionId,
    batch_id:batchid,
    student_id:studentid,
    team_id:teamid,
    progress:progress,
    total_marks:marks,
    points:points,
    purchase_id:purchase_id
  }
})
if(res){
  if(res.status==="success"){
    getinternships()
    seteditmodal(false)
  }
 
}
}



const UnlockAccess = async ({data})=>{

const res = await AdminUnlockAccess({
  payload:{
    user:user.SessionId,
  key:data.key,
  value:data.value,
    purchase_id:purchase_id
  }
})
if(res){
  console.log("ACCESS  "+JSON.stringify(res))
  if(res.status==="success"){
    getinternships()
  }
 
}
}

const [loadingidcard,setloadingidcard] = useState(false)
const [idcard,setidcard] = useState(null)
const [selectedname,setselectedname] = useState(profile.first_name||null)
useEffect(()=>{
  GetIdCard({method:"get"})
},[selectedname])

const GetIdCard = async({method})=>{
const data = new FormData()
setloadingidcard(true)
data.append("username",profile.username)
data.append("student_id",profile.purchase_id)
data.append("image",profile.profile_pic)
data.append("phone",profile.phone_number)
data.append("joining_date",dateFormat(profile.purchase_datetime,"d:mm:yyyy"))
if(method==="save"){
  data.append("save",true)

}
data.append("course",profile.course_url)
data.append("name",selectedname)
  const res = await axios.post("https://skillsuprise.com/id-card-template.php",data,SecurityHeaders)

  if(res){
    setloadingidcard(false)

    if(res.data.status==="success"){

   if(section==="idcard"){
    setidcard(res.data.idcard)
    let id = document.getElementById("idcard")
    id.innerHTML = res.data.idcard
setidcode(res.data)}
if(res.data.msg==="saved"){
  settoasttitle("ID Card Updated !")
          settoastmessage(null)            
                                settoast(true)
  getinternships()
}

    }else{
      settoasttitle("Something went wrong !")
                                settoastmessage("Please try again... ")
                                settoast(true)
    }
 

  
  }

}

const [xdirection,setxdirection] = useState(0)
const [idcode,setidcode] = useState(null)
const AlignName = ({direction})=>{
  const name = document.getElementById("name")
const idcard = document.getElementById("idcard")

if(direction==="left"){
name.setAttribute("x",xdirection-2)
setxdirection(xdirection-2)

}else{
  name.setAttribute("x",xdirection+2)
 setxdirection(xdirection+2)
}
 setidcode(idcard.innerHTML)
}


const AlignCourse = ({direction})=>{
  const role = document.getElementById("role")
const idcard = document.getElementById("idcard")

if(direction==="left"){
role.setAttribute("x",xdirection-5)
setxdirection(xdirection-5)

}else{
  role.setAttribute("x",xdirection+5)
 setxdirection(xdirection+5)
}
 setidcode(idcard.innerHTML)
}



useEffect(()=>{

  if(section==="idcard"){
  GetIdCard({method:"get"})
  }
},[section])
    return (
        <div className="min-vh-100">



<Modal
  open={editmodal}
  onClose={()=>seteditmodal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={ModalStyle}>
   <div className="modal-header">

<h5>Edit Student Info</h5>
<CloseIcon className="click" onClick={()=>seteditmodal(false)}/>

   </div>

<div className=" mt-3">
<form onSubmit={(e)=>EditStudent(e)}>


  

<div className="d-flex">

<div className="col">
<div className="d-flex">
<TextField value={studentid} onChange={(e)=>setstudentid(e.target.value)} fullWidth label="Student ID" />

</div>
</div>
<div className="col">
<TextField value={batchid} onChange={(e)=>setbatchid(e.target.value)}  fullWidth label="Batch ID"  />

</div>
</div>
<div className="d-flex mt-3">

<div className="col">
<TextField value={teamid} onChange={(e)=>setteamid(e.target.value)}  fullWidth label="Team ID" />

</div>


<div className="col">
<TextField value={progress} onChange={(e)=>setprogress(e.target.value)}  fullWidth label="Progress" />

</div>

</div>


<div className="d-flex mt-3">

<div className="col">
<TextField  value={marks} onChange={(e)=>setmarks(e.target.value)} fullWidth label="Marks"  />

</div>

<div className="col">
<TextField value={points} onChange={(e)=>setpoints(e.target.value)}  fullWidth label="Points"  />

</div>
</div>



<div className="mt-4">
  <button type="submit" className="btn btn-primary w-100">Save Changes</button>
</div>
</form>
</div>




  </Box>
</Modal>


<div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>

 












{profile!=="start"?
<>

<div className=" container-fluid pt-3">


<div className="d-flex "{...section==="idcard"?" justify-content-end":null}>

{section==="idcard"?<>


</>:
<>
<div className="col-lg-5">
  <div className="d-flex">
<div className="profile-dp mr-3 position-relative">
  
<div className="d-flex align-items-center position-absolute bottom-10 bg-dark p-1 text-light rounded-circle right-10">
        <CircularStatic className="me-2 text-white" value={profile.progress}/>
          
        </div>
          <img className="w-100 br-5 mr-2 shadow border" src={profile.profile_pic}/>
     </div>
     <div className='ms-3'>
          <h5>{profile.first_name+" "+profile.last_name}</h5>
          <p className="mb-1">@{profile.username}</p>
    

  <Chip size="small" label={profile.course_url} />
       
  <p className="mb-2 mt-2">Student ID: {profile.purchase_id}</p>
      

          <div className="d-flex mb-0">
          <p className=" me-3">Batch ID: {profile.batch_id}</p>
        
          <p className="">Team ID: {profile.team_id}</p>
        
          </div>
         
{profile.id_card?
<>
<a href={profile.id_card} target='_blank'><Download/> Download ID Card</a>
  
</>:
<>

</>}
     </div>
</div>
  </div>
<div className="col-lg-6">

    
<div className="p-2  pt-0">


<div className="flex mt-3">

<div className="col">
   <h6>{profile.progress} %</h6>
   <p>Progress</p>
</div>

<div className="col">
   <h6>{profile.points}</h6>
   <p>Points</p>
</div>
<div className="col">
   <h6>{profile.rank}</h6>
   <p>Course Rank</p>
</div>
<div className="col">
   <h6>{profile.rank}</h6>
   <p>Community Rank</p>
</div>
</div>




<div className="flex">

<div className="col">
   <h6>{profile.progress?profile.progress:0}</h6>
   <p>Quizzes</p>
</div>

<div className="col">
   <h6>{profile.badges_earned?profile.badges_earned:0}</h6>
   <p>Badges</p>
</div>
<div className="col">
   <h6>{profile.doubts?profile.doubts:0}</h6>
   <p>Doubts</p>
</div>
<div className="col">
   <h6>{profile.course_posts?profile.course_posts:0}</h6>
   <p>Posts</p>
</div>
</div>

<div className="flex">

<div className="col">
   <h6>{profile.progress?profile.progress:0}</h6>
   <p>Soft Status</p>
</div>

<div className="col">
   <h6>{profile.usage_time?profile.usage_time:0}</h6>
   <p>Usage Time</p>
</div>
<div className="col">
   <h6>{profile.watch_hours?profile.watch_hours:0}</h6>
   <p>Watch Hours</p>
</div>
<div className="col">
   <h6>{profile.read_time?profile.read_tinme:0}</h6>
   <p>Read Time</p>
</div>
</div>

</div>
  <div>




  </div>



</div>
</>}
  <div className="w-fit float-right">
    <select  onChange={(e)=>{setsection(e.target.value)}} className='form-control'>
      <option>Select Option</option>
      <option value={"reports"}>Reports</option>

      <option value={"info"}>User Info</option>
      <option value={"idcard"}>ID Cards</option>

      <option value={"personal_badges"}>Personal Badges</option>
      <option value={"course_badges"}>Course Badges</option>
      <option value={"doubts"}>Doubts</option>
      <option value={"assignments"}>Assignments</option>
      <option value={"projects"}>Projects</option>
      <option value={"course_journey"}>Course Journey</option>
      <option value={"activity_log"}>Website Activity</option>

      <option value={"course_access"}>Course Access</option>

    </select>
    </div>
</div>


</div>
<hr></hr>
<div className="container-fluid">



<div className="colontainer-fluid">

<div className="">
{profile!=="start"?
<>




{section==="reports"?
<>


<div className="d-flex justify-content-between">

  <div className="col-lg-6">
  <h4 className='left-border'>Student Reports</h4>

  <div className="vh-50 overflow-y mt-2 pe-1">

<StudentReports   profile={profile}/>


</div>
  </div>

  <div className="col-lg-5">

  <StudentReport  profile={profile}/>

  </div>
</div>


</>:
<>
{section==="statistics"?
<>


</>:

<>
{section==="info"?
<>

<div className="p-2  pt-0">

<hr className="mt-0"/>
<BasicInformation profile={profile}/>


</div>

</>:
<>
{section==="idcard"?
<>

<div className="d-flex">
<div className="col-lg-3 p-2 position-relative">


{idcard?
<>


<span className="flex shadow  w-100 position-absolute left-0 top-0"   id="idcard"></span>



</>:<>

{loadingidcard?
<>

<CircularProgress/>
</>:null}

</>}





</div>
<div className="col-lg-5 ms-5">
{section==="idcard"?
<>
<div className="flex ">



<button className='me-3' onClick={()=>{setselectedname(profile.first_name)}}>Select First Name</button>

<button className='me-3' onClick={()=>{setselectedname(profile.last_name)}}>Select Last Name</button>

<button onClick={()=>{setselectedname(profile.first_name+" "+profile.last_name);}}>Select Full Name</button>
</div>



 
 <div className="d-flex mt-3 ">
 <button onClick={()=>GetIdCard({method:"get"})} className="btn w-fit  btn-primary me-4">Preview ID Card</button>
 <button onClick={()=>GetIdCard({method:"save"})} className="btn w-fit btn-primary ">Save ID Card</button>
 
  </div>
</>:
<>
</>}

</div>
</div>
</>:
<>

{section==="personal_badges"?
<>
<div className="p-2  pt-0">

<hr className="mt-0"/>
<div className="grid grid lg-grid-6 grid-2 md-grid-5 ">
{personalbadges?
<>
{personalbadges.map((badge)=>(

<>
<BadgeItem badge={badge} profile={profile}/>

</>

))}

</>:null}


</div>


</div>


</>:
<>

{section==="course_badges"?
<>
<div className="p-2  pt-0">

<hr className="mt-0"/>
<div className="grid grid lg-grid-6 ">
{coursebadges?
<>
{coursebadges.map((badge)=>(

<>
<BadgeItem badge={badge} course={profile.course_url} profile={profile}/>

</>

))}

</>:null}


</div>

</div>

</>:
<>


{section==="doubts"?
<>
<div className="p-2  pt-0">

<hr className="mt-0"/>
<DoubtRequests profile={profile}/>

</div>


</>:
<>

{section==="assignments"?
<>

<div className="p-2  pt-0">

<hr className="mt-0"/>
<AssignmentSubmissions profile={profile}/>
</div>

</>:
<>
{section==="projects"?
<>
<div className="p-2  pt-0">

<hr className="mt-0"/>
<ProjectSubmissions profile={profile}/>
</div>

</>:
<>
{section==="course_journey"?
<>
<div className="p-2  pt-0">

<hr className="mt-0"/>
<CourseCheckList profile={profile}/>

</div>

</>:
<>
{section==="course_access"?
<>

<div className="p-2  pt-0">

<hr className="mt-0"/>
<div className="grid lg-grid-6 col-lg-9  mt-2 ">
{profile.lessons==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"lessons",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Lessons</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"lessons",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Lessons</button>

</>}

{profile.classes==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"classes",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Classes</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"classes",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Classes</button>

</>}


{profile.notes==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"notes",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Notes</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"notes",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Notes</button>

</>}

{profile.lab==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"lab",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Lab</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"lab",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Lab</button>

</>}


{profile.assignments==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"assignments",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Tasks</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"assignments",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Tasks</button>

</>}

{profile.project==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"project",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Projects</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"project",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Projects</button>

</>}


{profile.rewards==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"rewards",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Rewards</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"rewards",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Rewards</button>

</>}




{profile.team==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"team",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Team</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"team",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Team</button>

</>}




{profile.placement_support==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"placement_support",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Placement Support</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"placement_support",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Placement Support</button>

</>}

{profile.trainer_support==="1"?
<>
<button onClick={()=>UnlockAccess({data:{key:"trainer_support",value:"lock"}})} className="btn btn-primary-outline btn-sm  rounded-pill">Lock Trainer Support</button>

</>:
<>
<button onClick={()=>UnlockAccess({data:{key:"trainer_support",value:"unlock"}})} className="btn btn-primary btn-sm  rounded-pill">Unlock Trainer Support</button>

</>}
<button onClick={()=>seteditmodal(true)} className="btn  btn-warning btn-sm  rounded-pill">Edit Info</button>



</div>








</div>

</>:
<>
{section==="activity_log"?
<>

<div className="p-2  pt-0">


<h4>Recent Activity</h4>
<hr className="mt-0"/>

<div className="mt-3">
<UserActivity userdata={profile}/>
</div>



</div>
</>:
<>


</>}
</>}

</>}

</>}

</>}
</>}








</>}
</>}
</>}

</>}

</>}

</>}






</>:
null}
</div>
 













</div>





</div>


</>:
<>
<div className="text-center mt-5">

  <CircularProgress/>
</div>

</>}

        </div>
    )
}

export default StudentCourseProfile
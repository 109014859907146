
import { Modal } from "react-bootstrap";
import ENDPOINT from "../../context/EndPoint";
import { useState } from "react";
import { Link } from "react-router-dom";
import editicon from '../../assets/edit.svg'
import bell from '../../assets/svg/bell.svg'
import wallet from '../../assets/wallet.svg'
import settings from '../../assets/settings.svg'
import defaultuser from '../../assets/user.png'
import { AuthContext} from "../../context/AuthContext";
import { useContext } from "react";
import Localbase from 'localbase'
import styled from "styled-components";
import { ExitToApp} from "@material-ui/icons";

const AccountOptions =({props,account })=> {
    const [show, setShow] = useState(false);
const [session,setsession] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const {user}= useContext(AuthContext);
let db = new Localbase('db');

    
const logout = ()=>{


      
    fetch(`${ENDPOINT}logout.php?session=${user.SessionId} `)
    .then(r=>r.json())
    .then(result=>{
          

      
      if(result.status==="loggedout"){
        db.collection('paths').doc('user').delete()

db.collection("paths").doc("notifications").delete()

localStorage.setItem("user", null);
localStorage.setItem("msg", null);
window.location.replace("/");
      }
      else{
       
      }
          

    
                
    
        
    })
    


}

const closeSession = ()=>{
  setsession(false)
}


    return (
      <Styled>
        





        <Modal
        show={session}
        onHide={closeSession}
        backdrop="static"
        keyboard={false}
        {...props}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Session Expired !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Logging out in 3 seconds...
        </Modal.Body>
      
      </Modal>








        <img className="userdp account-dp" type="button" alt="" src={account?account.profile_pic?account.profile_pic:defaultuser:defaultuser}  onClick={handleShow}></img>
       
        <Modal show={show} onHide={handleClose} className="account-dropdown mt-0" animation={false}>
      
             <div className="flex align-center minus-20">
             <img className="userdp account-dp" alt="" src={account?account.profile_pic?account.profile_pic:defaultuser:defaultuser}  onClick={handleShow}></img>
       <div>

<h5 className="username">{account?account.first_name:""} {account?account.last_name:""}</h5>
<Link to="/" className="username">@{account?account.username:""}</Link>
           </div>

             </div>
             <hr className="w-100"></hr>

          
        
           
                  <Link to="/account" className="account-option align-center">
                  <img className="option-icon" src={editicon} alt=""></img>
                 My Account
                  </Link>
               {
                account?account.role==="admin"?<>
                   <Link to={"/trainer-dashboard/"+account.trainer_id} className="account-option align-center">
                  <img className="option-icon" src={editicon} alt=""></img>
                 Trainer
                  </Link>
                </>:<>
               
                </>:""
               }
                  <Link to="/notifications" className="account-option align-center">
                  <img className="option-icon" src={bell} alt=""></img>
                  Notifications
                  </Link>

                  <Link to="/payments" className="account-option align-center">
                  <img className="option-icon" src={wallet} alt=""></img>
               Payments
                  </Link>
       
                  <hr className="w-100"/>
                  <Link onClick={logout} className="account-option align-center logout-btn">
                 <ExitToApp className="mr-2"/>
                Logout
                  </Link>
        </Modal>
      </Styled>
    );
  }
  
export default AccountOptions

export const Styled = styled.div`


.userdp{
  margin-top:0px;
}
.modal-backdrop{
  background-color:transparent;
}

`
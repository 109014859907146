import axios from 'axios';

import ENDPOINT from '../../context/EndPoint';

const AdminDeleteComment = async ({payload}) => {

          const formData = new FormData();

           formData.append('session',payload.user);
           formData.append('id',payload.id);



           try{
               const res = await axios.post(ENDPOINT+"delete-comment.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res.data){
                    return res.data
             
                }
           }
           catch(error){

return error
           }

     




}

export default AdminDeleteComment
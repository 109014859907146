import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import { ChevronBarDown, ChevronDown, ChevronUp } from 'react-bootstrap-icons'

const DailyReport = ({report}) => {

    const [display,setdisplay] = useState(false)
  return (
    <div>
        
        
        <div className=" p-3 mt-2 mb-2 click" onClick={()=>setdisplay(!display)}>

<div   className="d-flex click justify-content-between">


<div className="col">
<h5>{report.create_datetime?report.create_datetime:null}</h5>

</div>


<div className="col">

<p className="mr-4"><Avatar src={report.profile_pic?report.profile_pic:null}/> <b></b>{report.reported_by} </p>

</div>
{display?
<>
<ChevronUp className="click" />
</>:
<>
<ChevronDown className="click" />
</>}



</div>
<div className="d-flex"><p className="ms-4">Time : {report.create_datetime?report.create_datetime:null}</p>
</div>
{display?
<>
<div>
    <hr/>
    <p>{report.report}</p>
</div>
</>:
<>

</>}

</div></div>
  )
}

export default DailyReport
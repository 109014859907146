import React from 'react';
import AdminCoursesList from './AdminCoursesList';
import InternalTemplate from '../../../themes/InternalTemplate'
import { Chip } from '@material-ui/core';
import {Link }from 'react-router-dom'
const InternalCoursesManagement = () => {
  return (<>
  
<div className="container-fluid">

<div className="w-100">



<AdminCoursesList/>

</div>
</div>
  
  </>);
};

export default InternalCoursesManagement;

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import React, { useState } from 'react'
import Box from '@mui/material/Box';
import MessageIcon from '@mui/icons-material/Message';

import * as timeago from 'timeago.js'
import axios from 'axios'
import Dropdown from 'react-bootstrap/Dropdown'
import MoreVert from '@mui/icons-material/MoreVert';
import ENDPOINT from '../../context/EndPoint'
import Modal from '@mui/material/Modal';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import FavouriteIcon from '@mui/icons-material/FavoriteBorder';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Avatar } from '@material-ui/core';
const TimelinePost = ({post,account,internship_id,course_url,batch_id}) => {
const params = useParams()
const courseurl = params.courseurl;
const batchid = params.batchid;
const {user}= useContext(AuthContext)

const [addcomment,setaddcomment] = useState(false)
const [comments,setcomments] = useState(null)
const [totalcomments,settotalcomments] = useState(post.total_comments)
const [liked,setliked]= useState(post.liked||false)
const [comment,setcomment] = useState(null)
const [saved,setsaved] = useState(post.saved)
const [totallikes,settotallikes]= useState(parseInt(post.total_likes)||0)
    const toggleLike = async (status,account)=>{
        

        const formData = new FormData();
        
        
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',user.SessionId);
        
          formData.append('post_id',post.post_id);
        
        
        const res = await axios.post(ENDPOINT+"like-post.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
       //  // console.log("post "+JSON.stringify(res))
         if(res.data.status==="success" && res.data.message==="liked"){
    setliked(true)
    settotallikes((totallikes+1))
         }
         else if(res.data.status==="success" && res.data.message==="disliked"){
            setliked(false)
            settotallikes((totallikes-1))
         }
        }
        }



//DELETE POST


const deletePost = async (status,account)=>{
        

    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    
      formData.append('post_id',post.post_id);
    
    
    const res = await axios.post(ENDPOINT+"student-delete-post.php", formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    if(res){
    // // console.log("post "+JSON.stringify(res))
     if(res.data.status==="success"){

     }
     else if(res.data.status==="error"){
     
     }
    }
    }



        const getComments = async ({post})=>{
        

            const formData = new FormData();
            
            
            //formData.append('whyus',document.getElementById('whyus').value)
            
            formData.append('session',user.SessionId);
            
              formData.append('post_id',post.post_id);
            
            
            const res = await axios.post(ENDPOINT+"get-comments.php", formData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            if(res){
            // console.log("comments "+JSON.stringify(res.data))
             
             if(res.data.status==="success"){
       setcomments(res.data.comments)
       settotalcomments(res.data.comments.length)
      
             }
             else{
              
             }
            }
            }
    




const [toast,settoast] = useState(false)






            const postComment = async ({post})=>{
      
// console.log("posting")
                const formData = new FormData();
                
                
                //formData.append('whyus',document.getElementById('whyus').value)
                
                formData.append('session',user.SessionId);
                
                  formData.append('post_id',post.post_id);
                formData.append('comment',comment)
                formData.append('internship_id',internship_id)
                
                const res = await axios.post(ENDPOINT+"add-comment.php", formData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                if(res){
               // console.log("commented "+JSON.stringify(res))
                 
                 if(res.data.status==="success"){
                   setcomment("")
           getComments({post})
                 }
                 else{
                  
                 }
                }
                }
        

                const [report,setreport] = useState(false)

                const [report_postid,setreport_postid] = useState(null)
                
                
                
                
                const startReport = ({post})=>{
                
                  setreport_postid(post.post_id)
                  setreport(true)
                }
                
                const [edit,setedit] = useState(false)


                const startEdit = ({post})=>{
                
                setedit(true)
                }


                const toggleComments = ({post})=>{
                    setcomments(null)
                    const container = document.getElementById("comment"+post.post_id)
                    const containers = document.getElementsByClassName("comments-wrap");
                    if(container.classList.contains("is-hidden")){
                    getComments({post});
                    
                    
                    for(var i =0;i<containers.length;i++){
                    containers[i].classList.add("is-hidden")
                    
                    }
                    
                    container.classList.remove("is-hidden")
                    }else{
                    container.classList.add("is-hidden")
                    }
                    }











            

const [toasttitle,settoasttitle] = useState(null)

    //SAVE POST
    

    const savePost = async ({post})=>{
        

        const formData = new FormData();
        
        
        //formData.append('whyus',document.getElementById('whyus').value)
        
        formData.append('session',user.SessionId);
        
          formData.append('post_id',post.post_id);
        
      
        
        
        const res = await axios.post(ENDPOINT+"save-post.php", formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        if(res){
         // console.log("saved "+JSON.stringify(res))
         if(res.data[0].status==="success"){
          

          if(res.data[0].saved===true||res.data[0].saved==="true"){
            setsaved(true)
          }
          else{
            setsaved(false)
          }
     
      settoasttitle("Post saved")
      
      settoast(true)
         }
         else if(res.data[0].status==="error"){
          settoasttitle("Post removed from saved library")
      
          settoast(true)
         }
        }
        }
      const [changed,setchanged] = useState(false)

        const commentChanged =()=>{

            if((comment!==null)&&(comment.length>=1)){
              setchanged(true)
            }else{
              setchanged(false)
            }
          
          }

const [editmodal,seteditmodal] = useState(false)
const [commentid,setcommentid] = useState(null)



const editmodalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
    return (
        <>
          {/* POST #1 */}
          <>
          <Modal
  open={editmodal}
  onClose={()=>seteditmodal(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...editmodalstyle, width: 400 }}>
    <h2 id="parent-modal-title">Comment id : {commentid}</h2>
    <p id="parent-modal-description">
      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
    </p>

  </Box>
</Modal>




<div className=" card is-post">
<div class="content-wrap col pt-2">
<div className="flex w-100 space-between">

<div className="flex w-100 ">
<Avatar className="mr-2" src={post.profile_pic}/>
<div className="w-100">
<h6 className="mb-1">{post.first_name+" "+post.last_name}</h6>
<p className="mb-0 extra-small">{timeago.format(post.date_time)}</p>
  </div>
</div>
<div>
            <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
   <Dropdown.Item  onClick={()=>deletePost({post})}>Delete</Dropdown.Item>
  <Dropdown.Item onClick={()=>startReport({post})} >Share Post</Dropdown.Item>
  <Dropdown.Item onClick={()=>startReport({post})} >Copy Link</Dropdown.Item>
 
  <Dropdown.Item onClick={()=>startReport({post})} >Report</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>


            </div>

</div>
  </div>

<div className="mt-2 col">
{post.description}

  </div>
<div className="card-body pb-0">


<div class="post-image ">
                <a data-fancybox="post1" data-lightbox-type="comments" data-thumb={post.image} href={"/community/"+courseurl+"/batch"+batchid+"/"+post.post_id} data-demo-href={post.image}>
                    <img src={post.image}   alt=""/>
                </a>
                {/* Action buttons */}
                {/* /partials/pages/feed/buttons/feed-post-actions.html */}
              
            </div>





      {/* Post footer */}
      <div class="">
           

        
  
              <div className="flex mb-2 mt-2 space-between">
   
   <div className="col flex justify-center">
   
     <div className="flex align-items-center">
    
     {liked!==true?
   <>
   <ThumbUpIcon id={"l"+post.post_id} onClick={()=>toggleLike({post})}  type="button" className="mr-2"/>
   
   </>  
   :
   <>
   <ThumbUpIcon  id={"l"+post.post_id}  onClick={()=>toggleLike({post})}  type="button" className=" text-primary mr-2"/>
   
   </>}
    </div>
   </div>
   
   <div className="col text-center">
  
      <MessageIcon  onClick={()=>{toggleComments({post})}} type="button"/>
   </div>
   
   
   <div className="col text-center">
     
   
     {saved===true||saved==="true"?
     
     <>
       <BookmarkBorderIcon className="text-primary" onClick={()=>{savePost({post})}} type="button"/>
   
     </>
     :<>
     
     <BookmarkBorderIcon onClick={()=>{savePost({post})}} type="button"/>
   
     </>}
     </div>
              </div>
          
              <div className="w-100 space-between flex mb-2 mt-2">
   
   <div className=" ">
       <p className="extra-small mb-0">
             <b>{totallikes!==0||"null"?totallikes+" Likes":"be the first to like"}</b></p>
   </div>
   <div className="">
       <p className="extra-small mb-0">
   <b type="button" onClick={()=>{getComments({post})}}>View all {totalcomments} Comments</b></p>
     
   </div>
              </div>     
           </div>
           {/* /Post footer */}



           <div id={"comment"+post.post_id} class=" is-comment">

{comments?
<>

{comments.map((comment)=>(

<>
<div className="w-100 mt-3 comment mb-3 flex">
<Avatar  src={comment.profile_pic}/>
<div className="w-100 ml-2">
<div className="flex w-100 space-between">
<p className="mb-1"><b className="mr-2">{comment.first_name+" "+comment.last_name}</b>{comment.comment}</p>
  

  <div className="comment-option">
  <FavouriteIcon className="text-danger" />
  <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
   <Dropdown.Item  onClick={()=>{setcommentid(comment.id);seteditmodal(true)}}>Edit Comment</Dropdown.Item>

  <Dropdown.Item onClick={()=>startReport({post})} >Report</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
  </div>
 
</div>
  <p className="extra-small mb-0 mt-2">{timeago.format(comment.date_time)}</p>
</div>

</div>

</>

))}
</>

:
<>

</>
}

             </div>




<hr className="mt-0"/>
<div className="w-100 mb-2">
<div className="comment-input flex">
  <Avatar  src={post.profile_pic}/>
  <div className="w-100 flex">
  <input value={comment} class=" comment-textarea" rows="5" 
  
  onChange={(e)=>{setcomment(e.target.value);commentChanged()}} placeholder="Write a comment..."></input>
  <button  onClick={(e)=>{e.preventDefault();postComment({post})}}  className={changed?"btn-comment text-primary":"btn-comment"}>Post</button>
  </div>
</div>


</div>



</div>



</div>
      
        </>


{/* POST #1 */}
        </>
    )
}

export default TimelinePost

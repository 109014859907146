import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Dropdown } from 'react-bootstrap'
import {  MoreVert } from '@material-ui/icons'
import dateFormat from 'dateformat'
import ENDPOINT from '../../../context/EndPoint'

import { Toast } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { Avatar } from '@material-ui/core'
import { Container } from "react-bootstrap";
import { TextField } from "@material-ui/core";




const InternalScholarshipApplications = ({ page,role}) => {
const params = useParams();

const [category,setcategory] = useState("all")

const {user} = useContext(AuthContext)


const [edit,setedit] = useState(false);


const tasktype= useRef()

const changeTaskStatus = (e)=>{
 
  settaskstatus(tasktype.current.value);
  getTasks(tasktype.current.value);

  
}

useEffect(() => {
    window.scrollTo(0, 0)
    getTasks();
  }, [category])


    const [tasks,settasks] = useState("start");

const [taskstatus,settaskstatus]= useState("all")
    
    const [taskid,settaskid] = useState(null)
    const [remove,setremove] = useState(false)
    const [removeid,setremoveid] = useState(null);
    const [submit,setsubmit] = useState(false);

const showSubmit =(e)=>{
  const id= e.target.id;
settaskid((id.replace("submit","")));
setsubmit(true)
}


    const closeSubmit = ()=>{
      setsubmit(false);
    }
    const editTask = (e)=>{

const id= e.target.id;
settaskid((id.replace("edit","")));


setedit(true)
    }

const closeEdit = ()=>{
  setedit(false)

}
const [add,setadd] = useState(false)

const closeAddition =()=>{
  setadd(false)
  getTasks(taskstatus);
}

const showRemove = (e)=>{
    
  const id= e.target.id;
  
settaskid(id.replace("delete",""));
  setremove(true)
  
  

}
const closeRemove = ()=>{
  setremove(false)
}




useEffect(() => {
 getTasks()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [category])





const getTasks = async ()=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)
// console.log("getting")
formData.append('session',user.SessionId);


  formData.append('status',category);
  if(query){
    formData.append('query',query);
  }
//// console.log("getting leads")
const res = await axios.post(ENDPOINT+"admin-get-scholarship-applications.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){



 if(res.data.status==="success"){
   if(res.data.data){

    settasks(res.data.data)
    setInitialData(res.data.data)
    setResponse(res.data.data)
   }
 }
 else{

 }
}
}

const [toast,settoast] = useState(false);
const [toasttitle,settoasttitle] = useState(null)

const [toastmessage,settoastmessage] = useState(null)


const [query,setquery] = useState(null)

const  updateRequest = async ({ticketstatus,ticketid})=>{

    if (user!==null){

        fetch(`${ENDPOINT}admin-update-lead.php?session=${user.SessionId}&status=${ticketstatus}&id=${ticketid}`)
        .then(r=>r.json())
        .then(result=>{
       // console.log(JSON.stringify(result))
                if(result.status==="success"){
settoasttitle("Successful")
settoastmessage("Status changed to "+ticketstatus)
settoast(true)
getTasks()
                }
                else{
                    settoasttitle("Something went wrong !")
                    settoastmessage("Please try again... ")
                    settoast(true)
                  
                }
           
           
            
        })
    }
    else{
    
    }
      
        
        }    ;

        const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
         minWidth:400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        };
           
        
const loadLeads = ()=>{

  getTasks();
  setaddlead(false)
}


const [addlead,setaddlead] = useState(false)





const [lead,setlead] = useState(null)



const  EditLead = async ({index,ticketstatus,ticketid})=>{

  setlead(tasks[index])
  if (user!==null){

const data = new FormData();
data.append('name',leadname);
data.append('email',lemail)
data.append('phone',lphone)
data.append('whatsapp',lwhatsapp)
data.append('status',lstatus)
data.append('category',lcategory)
data.append('note',lnote)
data.append('id',currentid)
data.append('session',user.SessionId)

const res = await axios.post(ENDPOINT+"admin-edit-lead.php",data,{
  headers:{'content-type':'multipart/form-data'}
})
if(res){
// console.log("lead "+JSON.stringify(res))
  if(res.data.status==="success"){
    settoasttitle("Lead updated successfully")
  setedit(false)
  settoast(true)
    getTasks();
  
  }
  else{
    settoasttitle("Something went wrong !")
    settoastmessage("Please try again")
    settoast(true)
  }
  

}


  
  }
 
      
      }    ;





const StartEdit = ({index})=>{
  setlead(tasks[index])
  setedit(true)
  const data = tasks[index]
  setleadname(data.name)
  setlemail(data.email)
  setlphone(data.phone)
  setlwhatsapp(data.whatsapp)
  setlstatus(data.status)
  setlcategory(data.category)
  setlnote(data.note)
setcurrentid(data.id)

}
const [currentid,setcurrentid] = useState(null)
const [leadname,setleadname] = useState(null)
const [lemail,setlemail] = useState(null);
const [lphone,setlphone] = useState(null)
const [lwhatsapp,setlwhatsapp] = useState(null)
const [lnote,setlnote] = useState(null)
const [lstatus,setlstatus] = useState(null);
const [lcategory,setlcategory] = useState(null)


const [initialData, setInitialData] = useState([]);
const [response, setResponse] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();

  const filteredData = initialData.filter((item) => {
    return (
      item.phone.toLowerCase().includes(term) ||
      item.email.toLowerCase().includes(term) ||
      item.last_name.toLowerCase().includes(term) ||
      item.first_name.toLowerCase().includes(term) ||
      item.status.toLowerCase().includes(term) 
   
    );
  });

  setResponse(filteredData);
  setSearchTerm(term);
};


    return (
        <>






<Modal
  open={addlead}
  onClose={()=>loadLeads()}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box  sx={style}>
   <>
   <div className="flex space-between">
     <h4>Add New Lead</h4>
     <CloseIcon type="button" onClick={()=>loadLeads()}/>
     </div>
     
   </>
  </Box>
</Modal>



<Modal
  open={edit}
  onClose={()=>setedit(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box  sx={style}>
   <>
   <div className="flex space-between">
     <h4>Edit Lead</h4>
     <CloseIcon type="button" onClick={()=>setedit(false)}/>
     </div>
<hr/>
<div>
  <h6>Name</h6>
<input  className="form-control" 
  placeholder="name" 
  onChange={(e)=>setleadname(e.target.value)} 
  value={leadname}
  minLength={4}
  ></input>

</div>
   

   <div>

     <h6>Email</h6>

   <input  className="form-control"
     placeholder="name"
      onChange={(e)=>setlemail(e.target.value)} value={lemail}
      minLength={10}
      maxLength={100}
      ></input>
  
   </div>
   <div>
<h6>Phone</h6>
   <input className="form-control" 
     placeholder="name" onChange={(e)=>setlphone(e.target.value)} 
     value={lphone}
     minLength={10}
     maxLength={12}
     ></input>
  
   </div>


   <div>
<h6>Whatsapp</h6>
   <input className="form-control" 
     placeholder="name" onChange={(e)=>setlwhatsapp(e.target.value)} 
     value={lwhatsapp}
     minLength={10} maxLength={12}
     ></input>
  
   </div>

 
 <div>
   <h6>Category</h6>
 <select className="form-control"   onChange={(e)=>{setlcategory(e.target.value)}}>
<option value={"Interested to join course"}>Interested to join course</option>
<option value={"General Query"}>General query</option>
<option value={"Workshop query"}>Workshop query</option>
<option value={"Others"}>Others</option>
</select>

 </div>

  <div>
    <h6>Status</h6>
  <select  className="form-control"  onChange={(e)=>{setlstatus(e.target.value)}}>
<option value={"interested"}>Interested</option>
<option value={"notinterested"}>Not interested</option>
<option value={"pending"}>Pending Payment</option>
<option value={"joined"}>Joined Course</option>
     </select>
  </div>
  

  <div className="">
    <h6>Note</h6>
    <input className="form-control" 
     placeholder="name" value={lnote} onChange={(e)=>setlnote(e.target.value)}
     maxLength={300}
     ></input>
     
  </div>
   <button onClick={EditLead} className="btn btn-primary w-100 mt-3">Save Changes</button>
   </>
  </Box>
</Modal>









<div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>
        
           
<div className="container-fluid">



<div className="flex  align-items-center space-between mb-4">


{/* <div className="flex col-lg-8">
<FormControl >
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
      
<Select
  
  value={category}
  label="Category"
  onChange={(e)=>setcategory(e.target.value)}
>
  <MenuItem value={"all"}>All </MenuItem>
  <MenuItem value={"interested"}>Interested</MenuItem>
  <MenuItem value={"not-interested"}>Not Interested</MenuItem>
  <MenuItem value={"pending"}>Pending Decision</MenuItem>
  
</Select>
      </FormControl>



      <Paper
       component="form"
       onSubmit={(e)=>{e.preventDefault();getTasks()}}
   
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >

      <InputBase
    
         sx={{ ml: 1, flex: 1 }}
        onChange={(e)=>setquery(e.target.value)}
        placeholder="Search Name, Email or Phone"
        inputProps={{ 'aria-label': 'Search Batch ID ' }}
      />
      <IconButton onClick={()=>getTasks()} sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      
    </Paper>

</div> */}
<Container fluid className="mb-3 col-8">
              <div>
                <TextField
                  label="Search..."
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e)}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Container>

<div className="container-fluid flex mt-3 my-auto ">


<h4  className="sm-18 mb-0"><b className="mr-2">{tasks?tasks.length:0}</b>{category==="all"?"Total":category} applications </h4>





</div>
 
</div>





<div className="internships-list  sm-p-0  container-fluid">

{ tasks==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>

:tasks?tasks.map((internship,index)=>(

<>
<div className=" shadow p-3 mb-2">

<div className=" w-100 flex space-between">
  <div className="flex align-items-center">
   
<div>
<h5 className="mb-0 w-100">{internship.first_name+" "+internship.last_name} </h5>

<p className="mb-0">{internship.phone}</p>
</div>

  </div><div>
    

<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>

  <Dropdown.Item  >Edit</Dropdown.Item>
   </Dropdown.Menu>
</Dropdown>

      </div>

</div>
<hr/>


<div className="flex container-fluid">
 
  <div className="col-5 p-0">

 <p className="mb-0">  <b>City : </b> {internship.city}</p>
 
 <p className="mb-0">  <b>College: </b> {internship.college}</p>
 
 <p className="mb-0">  <b>Year : </b> {internship.year}</p>
 
  </div>
  <div className="col-4  p-0">
  <p className="mb-0"><b>Category : </b> {internship.course_opted}</p>
  <p className="mb-0"><b>Status : </b> {internship.status}</p>
  <p className="mb-0">  <b>Scholarship : </b> {internship.scholarship_amount}</p>
 
  </div>
  <div className="col-4  p-0">
  <p className="mb-0"><b>Applied : </b> {internship.create_datetime}</p>
  <p className="mb-0"><b>Updated : </b> {internship.last_updated}</p>
  
  </div>

</div>









</div>
</>
)):
<div className="container-fluid">

No tasks

    </div>

}





</div>




</div>




  




 





        </>
    )
}

export default InternalScholarshipApplications


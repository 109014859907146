import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import styled from 'styled-components';
import ENDPOINT from '../../context/EndPoint';
import { CircularProgress } from '@material-ui/core';
import { Toast } from 'react-bootstrap';
const   UploadOfferLetter = ({internshipid})=>{
const id = internshipid
const [file,setfile] = useState(null)
const [status,setstatus]= useState(false)
 const   UPLOAD_ENDPOINT = `${ENDPOINT}upload-offer-letter.php`;
   const [toast,settoast] = useState(false)
   const onSubmit = async (e)=> {
       setstatus(true)
        e.preventDefault() 
        let res = await uploadFile(file);
       
if(res){
 // // console.log(JSON.stringify(res))
}
        if(res.data.status==="success"){
settoast(true)
          sessionStorage.setItem("iid",null);
          window.location.replace("/my-internships")
        }
    }
  const  onChange = (e)=> {
        setfile(e.target.files[0])
  
    }
   const uploadFile = async (file)=>{
        
//// console.log("apply internship")
        const formData = new FormData();
        const user = JSON.parse(localStorage.getItem("user"));
   
        formData.append('resume',file);
  
        formData.append('session',user.SessionId);
        formData.append('id',id);
       
        return  await axios.post(UPLOAD_ENDPOINT, formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
      }
    


    return(

<>

<ApplyInternshipStyled>

<form onSubmit={onSubmit} className="position-relative">
          

<p className="mb-1 mt-4">Upload Your Signed Offer Letter</p>


   <input type="file" className="" placeholder="Photo/Video" accept="application/pdf" onChange={onChange}/>
       <br/>
       
            <button className="btn btn-primary w-100 mt-5" type="submit">{status?<CircularProgress className="text-white " size={20}/>:""}Upload Now</button>
          
          
         
          </form>

<Toast toast={toast} title="Success"/>

          </ApplyInternshipStyled>
</>

    )
} 

   

export default  UploadOfferLetter;

const ApplyInternshipStyled = styled.div`

input, textarea{
  border: 1px solid lightgrey;
  border-radius:5px;
  margin-bottom: 10px;
}

`
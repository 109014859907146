import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import {
  Avatar,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import PropTypes from "prop-types";

import { AuthContext } from "../../context/AuthContext";
import InternshipItemSkeleton from "../../skeleton/InternshipItemSkeleton";
import ENDPOINT from "../../context/EndPoint";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Dropdown, Toast } from "react-bootstrap";
import dateFormat from "dateformat";
import { MoreVert } from "@material-ui/icons";
import { Link, useParams } from "react-router-dom";
import InternAddMeeting from "./internships/InternAddMeeting";
import InternScheduleInterview from "./internships/InternScheduleInterview";

import InternSendNotification from "./internships/InternSendNotification";

import AddTeamTask from "./actions/AddTeamTask";
import SecurityHeaders from "../../context/SecurityHeaders";
import TeamItem from "../team/TeamItem";

const WorkHistory = ({ page, permission }) => {
  const params = useParams();

  const user_id = params.user_id;
  const internship_id = params.internship_id
  const { user } = useContext(AuthContext);

  const [category, setcategory] = useState("joined");
  useEffect(() => {
    GetWorkHistory()
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState("start");

  const [internshipid, setinternshipid] = useState(null);

  const getinternships = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    formData.append("status", status);

    formData.append("category", category);
    if (query !== null) {
      formData.append("query", query);
    }

    const res = await axios.post(ENDPOINT + "get-team-list.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      if (res.data.status === "success") {
        setInitialData(res.data.data);
        setResponse(res.data.data);
      } else {
      }
    }
  };

  useEffect(() => {
    getinternships();
  }, [category]);

  const [query, setquery] = useState(null);

  const updateInternship = async ({ iid, istatus }) => {
    fetch(
      `${ENDPOINT}update-intern-status.php?session=${user.SessionId}&application_id=${iid}&internship_status=${istatus}`
    )
      .then((r) => r.json())
      .then((result) => {
        //// console.log("updated internship status"+JSON.stringify(result)+" internship id"+iid+" status"+istatus)

        if (result.status === "success") {
          getinternships();
          settoasttitle("Application " + istatus + iid);
          settoastmessage("Successful");
          settoast(true);
        }
      });
  };

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);
  const [currentintern, setcurrentintern] = useState(null);
  const [schedule, setschedule] = useState(false);
  const [addtask, setaddtask] = useState(false);
  const [notification, setnotification] = useState(false);
  const [interview, setinterview] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [workhistory, setworkhistory] = useState(null);
  //delete task code
  const [history, sethistory] = useState(false);
  const GetWorkHistory = async () => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("intern_id", user_id);
    formData.append("internship_id", internship_id);

    const res = await axios.post(
      ENDPOINT + "admin-get-work-history.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      // console.log("work "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      setworkhistory(res.data.work_days);
      sethistory(true);
    } else {
    }
  };

  const [currentdp, setcurrentdp] = useState(null);
  const [currentname, setcurrentname] = useState(null);

  const [payload, setpayload] = useState(null);

  const InitiateTask = ({ payload }) => {
    setpayload(payload);
    setaddtask(true);
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

  function LinearWithValueLabel() {
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 10 : prevProgress + 10
        );
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);
  }

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term) ||
        item.phone_number.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  const UpdateInternProfile = async()=>{
  
  const data = new FormData()
  
  const res= await axios.post(ENDPOINT+"update-intern-profile.php",data,SecurityHeaders)
  
  
  
  }

  return (
    <>
 


<div className="container">

   <h4>Work History</h4> 
{workhistory?
<>
{workhistory.map((work, index) => (
                    <>
                      <div key={index} className="container-fluid mb-0 mt-1 ">
                        <div className="flex space-between">
                          <div className="col-5">
                            <p>
                              <b>LoggedIn : </b>
                              {dateFormat(
                                work.start_time,
                                "dS mmm yy hh:mm:ss tt"
                              )}
                            </p>
                            <p>
                              <b>Logged Out : </b>
                              {work.end_time !== null
                                ? dateFormat(work.end_time, "hh:mm:ss tt")
                                : "Working..."}
                            </p>
                          </div>

                          <div className="col-5">
                            <p>
                              <b>Active Hours : </b>
                              {work.end_time !== null
                                ? work.duration
                                : "Working..."}
                            </p>
                            <p>
                              <b>Tasks Done : </b>
                              {work.end_time !== null
                                ? work.duration
                                : "Working..."}
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}
</>:
<>

</>}
</div>
  
   
   

      <div className="position-fixed container-fluid left-0 bottom-60 justify-center">
        <Toast
          className="bg-warning"
          show={toast}
          onClose={() => {
            settoast(false);
          }}
          autohide={true}
          delay={5000}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
      </div>

      
    </>
  );
};

export default WorkHistory;

import MailIcon from '@mui/icons-material/Mail';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import InternalTemplate from '../../../themes/InternalTemplate'
import { Avatar, Chip } from '@material-ui/core'
import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'
import dateFormat from 'dateformat'
import { useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap'
import ENDPOINT from '../../../context/EndPoint'
import Unresolved from '@mui/icons-material/RadioButtonUnchecked';
import Resolved from '@mui/icons-material/CheckCircle';
import Rejected from '@mui/icons-material/Cancel';
import { MoreVert, VideoCall } from '@material-ui/icons'
import {Link} from 'react-router-dom'
import { Toast } from 'react-bootstrap'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';



import { DocumentScannerRounded } from '@mui/icons-material';
import axios from 'axios';
import { TextField } from '@material-ui/core'
import { Container } from 'react-bootstrap'
const InternalPlacementRequests = ({ page}) => {

const {user} = useContext(AuthContext)
const userdata = user;
const params = useParams();
const [status,setstatus] = useState("all")

const [resolutions,setresolutions]= useState(null)

useEffect(() => {
    window.scrollTo(0, 0)
    getinternships()
  }, [status])


    const [internships,setinternships] = useState("start");

    useEffect(()=>{
     
    
      
    getinternships();
           
    }
    
    ,[params.status])
    
    const  getinternships = async ()=>{


      setinternships("start")
const data = new FormData();
data.append('session',user.SessionId)
if(query){
  data.append('query',query)
}
if(status){
  data.append('status',status)
}
      const res = await axios.post(ENDPOINT+"admin-get-placement-requests.php",data,{headers:
        {'content-type':'multipart/form-data'}})
       
      
      if(res){
      
        if(res.data[0].status==="success"){
          setinternships(res.data[0].data)
          setresolutions(res.data[0].resolutions)
          setResponse(res.data[0].data)
          setInitialData(res.data[0].data)
 

        }else{
               }
      }
     
          
            
            }    ;
    
            const [toast,settoast] = useState(false);
            const [toasttitle,settoasttitle] = useState(null)
            
            const [toastmessage,settoastmessage] = useState(null)
            
    
    
            const  updateRequest = async ({ticketstatus,ticketid})=>{
      
                if (userdata!==null){
        
                    fetch(`${ENDPOINT}admin-update-placement-request.php?session=${user.SessionId}&status=${ticketstatus}&id=${ticketid}`)
                    .then(r=>r.json())
                    .then(result=>{
                   // console.log(JSON.stringify(result))
                            if(result.status==="success"){
        settoasttitle("Successful")
        settoastmessage("Status changed to "+ticketstatus)
        settoast(true)
        getinternships()
                            }
                            else{
                                settoasttitle("Something went wrong !")
                                settoastmessage("Please try again... ")
                                settoast(true)
                              
                            }
                       
                       
                        
                    })
                }
                else{
                
                }
                  
                    
                    }    ;
            
    
const [category,setcategory] = useState("all");
const [query,setquery] = useState(null)


const [initialData, setInitialData] = useState([]);
const [response, setResponse] = useState([]);
const [searchTerm, setSearchTerm] = useState('');

const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();

  const filteredData = initialData.filter((item) => {
    return (
      item.phone_number.toLowerCase().includes(term) ||
      item.email.toLowerCase().includes(term) ||
      item.status.toLowerCase().includes(term) ||
      item.first_name.toLowerCase().includes(term) ||
      item.last_name.toLowerCase().includes(term) 
  
    );
  });

  setResponse(filteredData);
  setSearchTerm(term);
};


    return (
        <>


<div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>
        




<div className="flex  align-items-center space-between mb-4">


{/* <div className="flex col-lg-8">
<FormControl >
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
      
<Select
  
  value={status}
  label="Status"
  onChange={(e)=>setstatus(e.target.value)}
>
  <MenuItem value={"all"}>All </MenuItem>
  <MenuItem value={"resolved"}>Resolved</MenuItem>
  <MenuItem value={"unresolved"}>Un resolved</MenuItem>
  <MenuItem value={"initiated"}>Initiated</MenuItem>
  <MenuItem value={"rejected"}>Rejected</MenuItem>
  
</Select>
      </FormControl>



      <Paper
    onSubmit={(e)=>{e.preventDefault();getinternships()}}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >

      <InputBase
      
         sx={{ ml: 1, flex: 1 }}
        onChange={(e)=>setquery(e.target.value)}
        placeholder="Search Course or request ID"
        inputProps={{ 'aria-label': 'Search Batch ID ' }}
      />
      <IconButton onClick={()=>getinternships()} sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      
    </Paper>

</div> */}



<Container fluid className="mb-3 col-8">
      
      <div>
   
      <TextField
        label="Search..."
        variant="outlined"
        value={searchTerm}
        onChange={(e) => handleSearch(e)}
        fullWidth
        margin="normal"
      />
    
    </div>
            </Container>


<div className="col-3">
<h4>{internships?internships.length:0} {status==="all"?"Total":status} requests</h4>
</div>

 
</div>





         
<div className="flex-wrap ">
<div className="container-fluid mt-3 ">



<hr/>

</div>
<div className="internships-list  sm-p-0  container-fluid">

{ internships==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>

:response?response.map(internship=>(

<>
{/* +(internship.status==="reolved"?"bg-success":"bg-danger") */}
<div className={"flex container-fluid  align-items-center shadow p-3 mt-2 "}>
<div className="flex col-lg-4">
<Avatar src={internship.profile_pic} className="me-2"/>
<div >
<p className="mb-0"> {internship.first_name+" "+internship.last_name} </p>

<p className="mb-0">{internship.email}</p>

<div className="flex">
  <PhoneInTalkIcon className="mr-2 text-primary" />
  <WhatsAppIcon className="text-success mr-2"/>
  <p className="mb-0 ">{internship.phone_number}</p>
  </div>
</div>
</div>


<div className="col-lg-2">

<p className="mb-0"> {internship.status}</p>


</div>

<div className="flex col-lg-4">
    {internship.category}


   
</div>




<div className="me-2">
    {dateFormat(internship.create_datetime,"dd mmm yy hh:mm:ss TT")}
</div>

<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>

  <Dropdown.Menu>
  <Dropdown.Item onClick={()=>updateRequest({ticketstatus:"initiated",ticketid:internship.id})}  >Initiate Resolution</Dropdown.Item>
  <Dropdown.Item onClick={()=>updateRequest({ticketstatus:"resolved",ticketid:internship.id})} >Mark Resolved</Dropdown.Item>
  <Dropdown.Item  onClick={()=>updateRequest({ticketstatus:"unresolved",ticketid:internship.id})} >Mark UnResolved</Dropdown.Item>
  <Dropdown.Item onClick={()=>updateRequest({ticketstatus:"rejected",ticketid:internship.id})}  >Mark Rejected</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
</div>
</>
)):
<div className="container-fluid">

No Results

    </div>

}





</div>




</div>



        </>
    )
}

export default InternalPlacementRequests
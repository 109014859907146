import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'

import { Dropdown, Modal } from 'react-bootstrap'
import { Delete, Edit, MoreVert, Share } from '@material-ui/icons'
import {Container,Row,Col,Table} from 'react-bootstrap';
import { IoFilter } from "react-icons/io5";
import { MdModeEditOutline,MdMessage } from "react-icons/md";
import { AiFillYoutube,AiFillFile,AiFillEyeInvisible,AiFillEye } from "react-icons/ai";
import { GoKebabVertical } from "react-icons/go";
import { BsFillFileBarGraphFill } from "react-icons/bs";


import ENDPOINT from '../../../context/EndPoint'
import Checkbox from '@mui/material/Checkbox';

import CountDown from '../components/CountDown'
import InternalTemplate from '../../../themes/InternalTemplate'
import { Download, UploadFile } from '@mui/icons-material'
import UploadVideo from './UploadVideo'
import { Link } from 'react-router-dom'
import Videos from './Videos'
import SecurityHeaders from '../../../context/SecurityHeaders'
const InternalVideoManagement = ({ page,role}) => {

const {user} = useContext(AuthContext)


const [edit,setedit] = useState(false);


const tasktype= useRef()


useEffect(() => {
    window.scrollTo(0, 0)
    getVideos()
  }, [])


    const [videos,setvideos] = useState(null);













const getVideos = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);



const res = await axios.post(ENDPOINT+"admin-get-videos.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
 // console.log("videos "+JSON.stringify(res))
 if(res.data.status==="success"){
  setvideos(res.data.videos)
 }
 else{

 }
}
}



//delete task code


const deleteVideo = async ({id})=>{

const formData = new FormData();
formData.append('session',user.SessionId);
formData.append('video_id',id);

const res = await axios.post(ENDPOINT+"admin-delete-video",formData,SecurityHeaders);


if(res.data[0].status==="success"){
 
}
else{
  
}
}

const [uploadvideo,setuploadvideo] = useState(false)



    return (
   <>
   
   
<Modal show={uploadvideo} 
size="lg"
animation={false}
onHide={()=>setuploadvideo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Video</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <UploadVideo/>

        </Modal.Body>
      
      </Modal>


<div className="container-fluid pt-2">

<div className="flex">




</div>

<div className="container-fluid">
  <div className="flex mt-1 mb-2 space-between">
  <h1 className="">Channel content</h1>
  <a href={`${ENDPOINT}excel-backup/export-excel-videos.php`}className="btn btn-primary h-fit  ">download data</a>
  <Link to="/internal/video-management/upload"  className="btn h-fit btn-danger"><UploadFile/> Upload Video</Link>

  </div>
           
         
         

            <Table bordered>
    <thead>
    <tr>
      <th><Checkbox/></th>
      <th>Video</th>
    
      <th>Privacy</th>
      <th>Published</th>
      <th>Date</th>
      <th>Views</th>
      <th>Comments</th>
      <th>Like(vs.dislikes)</th>
    </tr>
  </thead>
  <tbody>
   

 
{videos?
<>
{videos.map((video)=>(
<>



<tr>

      <td className="col-lg-2">
         <div className="d-flex ">
           
             <video controls className="w-100" src={video.url}></video>
            
             
         </div>
      </td>
      <td>
      <Col className="v-data w-100">
             <div className="title w-100">
                 <h6 className="mt-2">{video.title}</h6>
                 <p className="truncate truncate-4 extra-small">{video.description}</p>
             </div>
             <div className="hide space-evenly w-100 mt-2">
                <Link to={"/internal/video-management/edit/"+video.video_id}> <MdModeEditOutline className="me-3 icon click"/></Link>
                <BsFillFileBarGraphFill onClick={()=>deleteVideo({id:video.video_id})} className="me-3 icon click"/>
                 <MdMessage className="me-3 icon click"/>
                 <AiFillYoutube className="me-3 icon click"/>
                 <GoKebabVertical className="me-3 icon click"/>
             </div>
             </Col>
      </td>
     
      <td>None</td>
      <td><p className="m-0 extra-small">{video.upload_date}</p> </td>
      <td>2</td>
      <td>0</td>
      <td>-</td>
    </tr>




</>


))}
</>:
<>
no videos
</>}

</tbody>
</Table>




        </div>


</div>



  

   



   


   </>
    )
}

export default InternalVideoManagement


import { Chip } from '@material-ui/core'
import { Chat, PhoneCallback, VideoCall, WhatsApp } from '@material-ui/icons'
import { PlayCircle } from '@mui/icons-material'
import React from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'

const InternReport = () => {
     return (
          <>
         <InternshipTemplate>
              <>
              <div className="container-fluid  mt-3 mb-3 p-3">


</div>       
    
              </>
         </InternshipTemplate>
          </>
     )
}

export default InternReport

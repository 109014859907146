import { InfoOutlined } from '@material-ui/icons';
import { Avatar } from '@mui/material';
import axios from 'axios';
import React from 'react'
import { Dropdown, Toast} from 'react-bootstrap';
import ENDPOINT from '../../../context/EndPoint';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close'
import InternAddMeeting from './InternAddMeeting';
import dateFormat from 'dateformat'
import InternSendNotification from './InternSendNotification';
import InternAddTask from './InternAddTask';
import InternScheduleInterview from './InternScheduleInterview';
import SecurityHeaders from '../../../context/SecurityHeaders';

const InternshipApplicant = ({internship,index}) => {

    const {user} = useContext(AuthContext)
    const  updateInternship = async ({iid,istatus})=>{
      const data = new FormData();
      data.append('session',user.SessionId);
      data.append('application_id',iid);
      data.append('internship_status',istatus)
      const res = await axios.post(`${ENDPOINT}update-intern-status.php`,data,SecurityHeaders);
      if(res.data.status==="success"){
      
settoasttitle("Application "+istatus+iid);
settoastmessage("Successful")
settoast(true)

     }

//         fetch(`${ENDPOINT}update-intern-status.php?session=${user.SessionId}&application_id=${iid}&internship_status=${istatus}`)
//         .then(r=>r.json())
//         .then(result=>{
//             //// console.log("updated internship status"+JSON.stringify(result)+" internship id"+iid+" status"+istatus)
       
//              if(result.status==="success"){
              
// settoasttitle("Application "+istatus+iid);
// settoastmessage("Successful")
// settoast(true)

//              }
            
            
//         })
        
        }  ;

        const [workhistory,setworkhistory] = useState(null)
        //delete task code
        const [history,sethistory] = useState(false)
        const WorkHistory = async ({internship_id,intern_id})=>{
        
          const formData = new FormData();
          formData.append('session',user.SessionId);
          formData.append('intern_id',intern_id);
          formData.append('internship_id',internship_id);
          
          const res = await axios.post(ENDPOINT+"admin-get-work-history.php",formData,{
            headers:{
              'content-type': 'multipart/form-data'
            }
          });
          
          if(res){
            // console.log("work "+JSON.stringify(res))
          }
          if(res.data.status==="success"){
           setworkhistory(res.data.work_days)
           sethistory(true)
          }
          else{
            
          }
          }

          const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)
const [currentintern,setcurrentintern] = useState(null)
const [schedule,setschedule] = useState(false)
const [addtask,setaddtask] = useState(false)
const [notification,setnotification] = useState(false)
const [interview,setinterview]= useState(false)
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
   minWidth:400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [internshipid,setinternshipid] = useState(null)
  const [currentdp,setcurrentdp] = useState(null);
const [currentname,setcurrentname] = useState(null)
  return (

<>

<div key={index} className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>

<Modal
centered
  open={notification}
  onClose={()=>{setnotification(false)}}
>
  <Box  sx={style}>
   <>
<InternSendNotification intern_id={currentintern}/>
   </>
  </Box>
</Modal>

<Modal
  open={history}
  onClose={()=>{sethistory(false)}}
  className="container-fluid "
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box  className="col-lg-8 bg-white vh-100 my-auto overflow-y mx-auto">
   <>

   <Modal
  open={addtask}
  onClose={()=>{setaddtask(false)}}
  aria-labelledby="modal-modal-title"
  size="xl"
  aria-describedby="modal-modal-description"
>
  <Box  sx={style}>
   <>
<InternAddTask intern_id={currentintern} internship_id={internshipid}/>
   </>
  </Box>
</Modal>
<Modal
  open={interview}
  onClose={()=>{setinterview(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box  sx={style}>
   <>
   <div className="flex space-between">
     <h4>Schedule Interview</h4>
     <CloseIcon type="button" onClick={()=>setinterview(false)}/>
     </div>
<InternScheduleInterview intern_id={currentintern}/>
   </>
  </Box>
</Modal>



{workhistory?

<>


<div className="container-fluid overflow-y pt-3">

<div className="container-fluid align-items-center bg-primary p-2 mb-3 text-white flex space-between">

<div className="flex align-items-center col-lg-5 col-sm-6">

<Avatar src={currentdp} className="mr-2"/>
<div >
<h4 className="mb-0">{currentname}</h4>
<h6>Work History ( {workhistory.length} )</h6>

</div>

</div>
<CloseIcon type="button" className="text-danger" onClick={()=>sethistory(false)}/>
</div>




{workhistory.map((work,index)=>(
<>

<div key={index} className="container-fluid mb-0 mt-1 ">


<div className="flex space-between">
<div className="col-5">
<p><b>LoggedIn : </b>{dateFormat(work.start_time, "dS mmm yy hh:mm:ss tt")}
</p>
<p><b>Logged Out : </b>{work.end_time!==null?dateFormat(work.end_time, "hh:mm:ss tt"):"Working..."}
</p>
</div>


<div className="col-5">
<p><b>Active Hours : </b>{work.end_time!==null?work.duration:"Working..."}
</p>
<p><b>Tasks Done : </b>{work.end_time!==null?work.duration:"Working..."}
</p>
</div>

</div>

</div>
<hr/>
</>

))}

     
</div>

</>

:<>

</>

}
   </>
  </Box>
</Modal>

<Modal
  open={schedule}
  onClose={()=>{setschedule(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box  sx={style}>
   <>
   <div className="flex space-between">
     <h4>Schedule Meeting</h4>
     <CloseIcon type="button" onClick={()=>setschedule(false)}/>
     </div>
<InternAddMeeting intern_id={currentintern} internship_id={internshipid}/>
   </>
  </Box>
</Modal>



{/* Edited by Deepthi */}

<div   class="main-c mt-2 mb-2 p-3 col-lg-12 shadow py-0 rounded pt-3 pb-3">
<div className="col-lg-12 d-flex justify-content-between">
<div className='flex col-lg-7 '>
            <div class=" mt-3 me-2">
                <input type="checkbox"/>
            </div>
            <div class="  bg-light-subtle d-flex  ">
            <Avatar src={internship.profile_pic} className="me-3 mt-2"/>
                <div class="">
                    
                        <h5 className="mb-0">{internship.first_name+" "+internship.last_name}</h5>
                        <p>Phone: {internship.phone_number}  |  Email: {internship.email}</p>

                    
                    
                    
                </div>
            </div>

  </div>

  <div className="col-4 d-flex justify-content-end ">
   <div>
   <h6 className="me-0">{internship.position_title}</h6>
    <p className="me-0"><b>applied on : </b>  {dateFormat(internship.applied_date,"dS mmm yyyy hh:mm tt") }</p>
   </div>
  </div>
</div>
  <div class="col-12">
                    
                    <div class="col-12 d-none">
                        <div  class="col-3">
                            <h6>EXPERIENCE</h6>
                        </div>
                        <div class="col9">
                            <p><b>Cybersecurity at IBM SkillsBuild</b> Jan'23-Mar'231 mo (Internship)</p>
                            <p><b>Red Team Intern at CyberSapiens.</b> Jan'23-Present 6 mos (Internship) +2 more</p>
                        </div>
                    </div>
                    <div class="col-12 d-none ">
                        <div  class="col-3">
                            <h6>EDUCATION</h6>
                        </div>
                        <div class="col9">
                            <p><b>Bachelor of Engineering (B.E), Computer Science (2020-2024)</b></p>
                            <p>AISSMS College Of Engineering</p>
                        </div>

                    </div>
                    
                    <div class="col-12 d-none ">
                            <div  class="col-3">
                                <h6>SKILLS <InfoOutlined/> </h6>
                            </div>
                            <div class="col9">
                                <p>Ethical Hacking,Linux,Algorithms,C++ Programming</p>
                              
                            </div>
    
                    </div>


                    <div class="col-12 d-flex ">
                        <div  class="col-2 d-flex justify-content-between">
                            <h6>COVER LETTER <InfoOutlined/>  </h6>
                        </div>
                        <div class="col-10">
                            <p>{internship.reason}</p>
                          
                        </div>

                    </div>
                   <div class="col-12 d-flex justify-content-between">
                        <div  class="col-2">
                            <h6>AVAILABILITY <InfoOutlined/> </h6>
                        </div>
                        <div class="col-10">
                          <p>{internship.availability}</p>
                      
                        </div>

                    </div>
                    <div>
                        <div class="col-12 d-flex mt-4  justify-content-between">
                            <div  class="col-lg-3 col-md-1">
                               
                                     <a target="_blank" href={internship.resume_link} class="text-decoration-none"> Resume</a>

                                
                               
                                
                                
                            </div>
                            <div  class="col-lg-5 col-md-8 d-flex justify-content-between pl-0 pr-0">
                                <div className="col-lg-2 col-md-2 my-auto">
                                <a href="" class="text-decoration-none"> Add Notes</a>


                                    
                                </div>
                                <div class="d-flex col-lg-9 col-md-9 col-md-10 justify-content-between">
                                    <button  onClick={(e)=>{e.preventDefault(); updateInternship({iid:internship.id,istatus:"rejected"})}} class="text-danger border-0 p-2 me-"> <b>Not Intrested</b> </button>
                                    <button onClick={(e)=>{e.preventDefault(); updateInternship({iid:internship.id,istatus:"shortlisted"})}}  class="text-sucess ml-5 mr-5 border-0 p-2"> <b>Shortlist</b></button>
                        
                                   
                                    <Dropdown class="ml-5 d-none">
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                              Next Steps
      
                                           </Dropdown.Toggle>

                                                   <Dropdown.Menu>
                                       

  <Dropdown.Item  onClick={()=>{setcurrentintern(internship.user_id);setinterview(true)}} >Schedule Interview</Dropdown.Item>

    <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"selected"})}} >Select</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"joined"})}}  >Mark Joined</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"completed"})}} >Mark Completed</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{updateInternship({iid:internship.id,istatus:"terminated"})}} >Terminate</Dropdown.Item>




<Dropdown.Item  onClick={()=>{setcurrentintern(internship.user_id);setinternshipid(internship.internship_id);setschedule(true)}} >Schedule Meeting</Dropdown.Item>
  
  <Dropdown.Item  onClick={()=>{setcurrentintern(internship.user_id);setnotification(true)}} >Send Notification</Dropdown.Item>

  <Dropdown.Item  onClick={()=>{WorkHistory({intern_id:internship.user_id,internship_id:internship.internship_id});setcurrentdp(internship.profile_pic);setcurrentname(internship.first_name+" "+internship.last_name)}} >Work History</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{setcurrentintern(internship.user_id);setnotification(true)}} >Update Profile</Dropdown.Item>



                                                    </Dropdown.Menu>
                                         </Dropdown>

                                </div>
                
                            </div>
                        </div>
   </div>
</div>

{/* Edited by Deepthi */}
           
               
                    
        
            


      


  
</div>




</>
  )
}

export default InternshipApplicant
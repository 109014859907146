import axios from 'axios';
import React from 'react'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';

const AdminDeleteSupportRequest = async ({payload}) => {
    const data = new FormData();

    data.append('id',payload.id);

    const res = await axios.post(ENDPOINT+'admin-delete-support-request.php',data,SecurityHeaders);
    
    if(res){
        return res
    }
}

export default AdminDeleteSupportRequest
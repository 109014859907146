import React from 'react'
import  styled  from 'styled-components'

import InternalNavbar from '../components/navbar/InternalNavbar'

import { useEffect } from 'react'
import { useContext,useState } from 'react'

import ENDPOINT from '../context/EndPoint'
import { AuthContext } from '../context/AuthContext'
import InternalSidebar from '../components/leftsidebar/InternalSidebar'
import TeamNavbar from '../components/navbar/TeamNavbar'
import SideBar from '../components/leftsidebar/SideBar'
const TeamTemplate= ({bottomMenu,children,footer,timeline, page,props}) => {
  const {user} = useContext(AuthContext)
  
  
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [time,settime]= useState(0);


  setTimeout(
    () => settime(time+1), 
    
    1000
  );
  
  








  //get user account


  
  const [account,setaccount]=  useState(null)
  useEffect(()=>{
      
    
    
    const  getaccount = async ()=>{
    
      
              fetch(`${ENDPOINT}get-account.php?session=${user.SessionId} `)
                .then(r=>r.json())
                .then(result=>{
                 // // console.log(result)
               if(result){
        
              if((result[0].status==="success")&&(result[0].data!=null)){
                setaccount(result[0].data);
            // // console.log("got account"+result[0].data.first_name)
        
              }else {
                localStorage.setItem("user",null);
                window.location.replace("/")
              }
            
               }
                else{
                  localStorage.setItem("user",null);
                window.location.replace("/")
                }
                
              
             


                
                    
                })
              
      
         
              }    ;

if(user!=null||user!=="null"){
  getaccount();
  getnotifications();
}

       
}

,[])



 
const [notifications,setnotifications] = useState(null);
const [unread,setunread] = useState(0);


const  getnotifications = async ()=>{
   //// console.log("getting notifications")
try{
  fetch(`${ENDPOINT}get-notifications.php?session=${user.SessionId}`)
  .then(r=>r.json())
  .then(result=>{
    
      
 if(result[0].status==="success"){
     

  if((result[0].data)!==null){
      setnotifications(result[0].data);
   setunread(result[0].unread)
      
  

  }

 }
  
              
  
      
  })
}
catch{

}
  
  }    ;








  React.useEffect(() => {
   
    const interval = setInterval(() => {
      getnotifications();
    }, 5000);
  
    return () => {
     
      clearInterval(interval);
    };
  }, []); // has no dependency - this will be called on-component-mount







    return (
        <HomeStyled>

<SideBar userdata={user} page="Home" account={account}/>
<div className="page-container">



<TeamNavbar  time={time} account={account} unread={unread} notifications={notifications}/>


{children}




</div>
        </HomeStyled>
    )
}

export default TeamTemplate


export const HomeStyled = styled.div`
padding: 0px;
margin: 0px;
width: 100%;
.flex{
    display: flex;
}
.page-container{
  margin-top: 55px;
  width: auto !important;
}

.live-courses, .offline-courses{
   margin-top: 70px;
}

.feature-item{
    margin: 15px;
}
.slider-content{
    height: auto;
   
    
}
.slider-content img{
  width: 100%;
  height: auto;
}
.slide1{
    background-color: blue;
}
.slide2{
    background-color: red;
}
.slide3{
    background-color: orange;
}
.navbar-nav{
    display: flex;
}
.navbar .container{
    display: flex;
}
.home-options{
  
  margin-bottom: 30px;
}
@media only screen and (max-width:576px){
  .text-center{
    font-size: 22px;
  }
 
  .home-options{
    display: flex !important;
  }
 
  .how-number{
    min-width: 30px !important;
    min-height:30px !important;
    
    height: 30px !important;
    width: 30px !important;
    border-radius: 15px;
  }
  .how-number h2{
    font-size:18px !important;
  }
}

@media only screen and (max-width:576px){

.course-item-main{
    width: 100%;
    margin:  0px 10px;
}
.course-item{
display: flex;
min-width: 85%;
width: 100%;
max-width:450px;
margin: 10px 0px;
height: fit-content;
}
.course-image{
width:100px !important;
height: 100px !important;
border-radius: 0px;
}
.course-buttons{
display: none;
}
.courses-list{
justify-content: center !important;
}
.course-prices{
position: relative;

bottom: 0px;
}
.rating-value{
margin-left:0px !important;
}
.cutoff-price{
display: none;
}
.course-prices{
margin-left: 0px !important;
}
.ratings-container{
margin-bottom:0px;
}

.current-price, .cutoff-price{
margin-bottom: 0px;
}
}




`

import React from 'react'
import styled from 'styled-components'
import Footer from '../components/footer/Footer'

import TopNavBar from '../components/navbar/TopNavBar'
const NoSidebar = ({children, bottommenu,footer}) => {
    return (
        <DashboardStyled >
            <TopNavBar/>

       <div className="main">
           
       <div className="container-fluid flex">

<div className="page-content">
{children}

{footer===false?"":
<Footer bottommenu={bottommenu}/>
}
</div>




           





           </div>    




</div>








          
        </DashboardStyled>
    )
}

export default NoSidebar


export const DashboardStyled = styled.div`

height: auto;
width: 100%;

.main{
    margin-top: 56px;
}
.dash-slider{
    height:300px;
    width: 100%;
    background-color: black;
}
.dash-option{
    height: 90px;
    width: 90px;
    margin: 10px;
    box-shadow:0px 0px 5px black;
}
.home-options{
    display: flex;
    flex-wrap:wrap;
    justify-content: space-evenly;

}

`
import { Avatar } from '@material-ui/core'
import { AttachFile, Check, Delete, MoreVert, Schedule, ThumbDown } from '@material-ui/icons'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import GetAssignmentSubmission from '../../../actions/assignments/GetAssignmentSubmission'
import { useParams } from 'react-router-dom'
import { useContext } from 'react';

import { AuthContext } from '../../../context/AuthContext';
import { useState } from 'react'
import { useEffect } from 'react'

const BatchwiseAssignmentSubmission = () => {
    const {user} = useContext(AuthContext);
    const params = useParams();
const course_url = params.courseurl;
const batch_id = params.batchid;
   

    const [internship,setInternship] = useState(null)
    const getSubmission = async ()=>{
        const res = await GetAssignmentSubmission({payload:{
          course_url:course_url,
          batch_id:batch_id,
          session:user.SessionId


        }

        })
        if(res){
          console.log("got data "+res)
          
          if(res.data){
            setInternship(res.data);
          }
          
        }


    }
    useEffect(() => {
        getSubmission();
    
    }, [])
    

  return (
   
    <>
    <div className="d-flex flex-wrap">
   {
    internship?internship.map((item,index)=>(
        <div key={index} class="p-2 col-lg-6 col-md-12  col-sm-12">
        <div class="hover-active  rounded shadow p-4">
          <div class="d-flex flex-row justify-content-between">
            <h5 className="mb-0  text-primary">{item.title}</h5>
            <Dropdown className="options-dropdown">
              <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                <MoreVert className="text-dark" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  id={"edit" + item.id}
                 
                >
                  <Check className="mr-2" />
                  Evaluate
                </Dropdown.Item>
                <Dropdown.Item href="#" id={"delete" + item.id}>
                  <ThumbDown className="mr-2" />
                  Reject
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  id={"delete" + item.id}
                 
                >
                  <Delete className="mr-3" />
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <p class="ts-6">
            <b>Course:</b>
            {item.course_url} | Assignment ID :{" "}
            {item.assignment_id}
          </p>
          <p class="text-secondary truncate truncate-2">
            {item.description}
          </p>
          <div class="d-flex flex-row align-items-end justify-content-between">
            <div class=" d-flex align-items-end">
              <Avatar src={item.profile_pic}></Avatar>
              <div class="ml-2">
                <h6 class="mb-0">Skillsuprise</h6>
                <h6 class="mb-0 text-secondary">Efficiency:%</h6>
              </div>
            </div>

            <div class="d-flex flex-row align-items-end">
              <Schedule className="mr-1" />
              Deadline : 30th july
              <a href={item.solution_link} target="_blank">
                {" "}
                <AttachFile className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </div>
    )):<>
   <div className="col-12">
   <h2 className='mx-auto mt-5'>No Assignment Submission</h2>
   </div>
    </>
   }
    </div>
    </>
  )
}

export default BatchwiseAssignmentSubmission
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Avatar, CircularProgress, Snackbar } from '@material-ui/core';
import ENDPOINT from '../../../context/EndPoint'
import axios from 'axios'
const AdminAddTask = ({id,params}) => {



const {user} =  useContext(AuthContext)




const [time,settime] = useState(null)

const changeTime = (e)=>{
  settime(e.target.value)
}
const [title,settitle] = useState(null)
const [description,setdescription] = useState(null)
const [assignedto,setassignedto] = useState(null)
const [reference,setreference] = useState(null)
const [deadline,setdeadline] = useState(null)
const [marks,setmarks] = useState(null)
const [earnedmarks,setearnedmarks] = useState(null)

const [corrections,setcorrections] = useState(null)
const [assignedby,setassignedby]=  useState(null)


const [account,setaccount]=  useState(null)
useEffect(()=>{
    
  GetInterns()
  
  const  getaccount = async ()=>{
  
    
            fetch(`${ENDPOINT}admin-single-task.php?session=${user.SessionId}&id=${id}`)
              .then(r=>r.json())
              .then(result=>{
             
             if(result){
      
            if((result[0].status==="success")&&(result[0].data!=null)){
                const data = result[0].data;
             settitle(data.title)
             setdescription(data.description)
             setreference(data.reference)
             setstatus(data.status)
             setassignedby(data.assignedby)
             setassignedto(data.assignedto)
             setdeadline(data.deadline)
             setmarks(data.marks)
             setearnedmarks(data.marks_received)
        
            }else {
              
            }
          
             }
              else{
               
              }
              
                          
              
                  
              })
            
    
       
            }    ;

if(user!==null||user!=="null"){
getaccount();
}

     
}

,[user])





const [interns,setinterns] = useState(null)

const  GetInterns = async ()=>{
  

  const formdata = new FormData();
  formdata.append('session',user.SessionId)
    formdata.append('category','joined')
 const res = await axios.post(ENDPOINT+'admin-internship-applications.php',formdata,{
   headers:{'content-type':'multipart/form-data'}
 })

if(res){
  setinterns(res.data[0].data)
  
}

  }    ;






    const   UPLOAD_ENDPOINT = `${ENDPOINT}edit-profile.php`;

    const changeTitle = (e)=>{
        settitle(e.target.value)
        
      }
      const changeDescription = (e)=>{
          setdescription(e.target.value)
      }
      const changeAssignto = (e)=>{
          setassignedto(e.target.value)
      }
    const changeMaxMarks = (e)=>{
        setmarks(e.target.value)
    }
    const changeMarksGiven = (e)=>{
        setearnedmarks(e.target.value)
    }
    const changeCorrections = (e)=>{
        setcorrections(e.target.value)
    }
    const changeReference = (e)=>{
        setreference(e.target.value);

    }
    const changeDeadline = (e)=>{
        setdeadline(e.target.value)
    }
    const changeStatus = (e)=>{
        setstatus(e.target.value)
    }
      const [status,setstatus]= useState(false)

      const [snackbar,setsnackbar] = useState(false);
      const [snackbarMessage,setsnackbarMessage] = useState(null)
      
      const [selected,setselected] = useState(null)

      const onSubmit = async (e)=> {
        e.preventDefault();
          setstatus(true)


const formdata = new FormData();
formdata.append('title',title);
formdata.append('description',description);
formdata.append('status',status);
formdata.append('corrections',corrections)
formdata.append('marks',marks)
formdata.append('id',id)
formdata.append('deadline',deadline+" "+time)
formdata.append('reference',reference)
formdata.append('session',user.SessionId)
formdata.append('internship_id',interns[selected].internship_id)
formdata.append('intern_id',interns[selected].user_id)
         
    const res = await axios.post(ENDPOINT+'intern-add-task.php',formdata,{
      headers:{'content-type':'multipart/form-data'}
    })      
       
    if(res){
if(res.data.status==="success"){
  setstatus(false);
setsnackbarMessage("Changes Saved Successfully")
setsnackbar(true)   
}else{
  setstatus(false);
setsnackbarMessage("Error")
setsnackbar(true)   
}
    }

        
               
          }
          
         


     
          const options = ['Option 1', 'Option 2'];
          
    



      



    return (
        <div>
            
            {/*account form*/}
          
            
        <>
<div className="flex-wrap ">
            
            <div className="">
            
            
            
            <form onSubmit={onSubmit}>
            
         
            <div class="form-row">
                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Title</label>
                  <input type="text" class="form-control" id="inputEmail4" value={title} onChange={changeTitle} maxLength={100}/>
                </div>

                <div class="form-group col-lg-12">
                  <label for="inputEmail4">Description</label>
                  <input type="text" class="form-control" id="inputEmail4" value={description} onChange={changeDescription} maxLength={1000}/>
                </div>

                <div class="form-group col-lg-6">
                  <label for="inputEmail4">Reference</label>
                  <input type="text" class="form-control" id="inputEmail4" value={reference} onChange={changeReference} maxLength={30}/>
                </div>
                <div class="form-group col-lg-6">
                  <label for="inputEmail4">Deadline {deadline?deadline+" "+time:null}</label>


                  
                  <input type="date" class="form-control" value={deadline} onChange={changeDeadline} id="inputEmail4" />
               
                 <input type="time" class="form-control" value={time} onChange={changeTime} id="inputEmail4" />
                </div>
                <div class="form-group col-lg-12">

            
                  <label for="inputEmail4">Assign To {interns&&selected?interns[selected].user_id:null}</label>
                  <select value={selected} onChange={(e)=>{setselected(e.target.value)}} maxLength={30}>
{interns?

<>
{interns.map((item,index)=>(

<>
<option value={index}>
{item.email}
</option>
</>

))}

</>
:null
}

                    </select>
                
                </div>
                

   

<Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={()=>setsnackbar(false)}
        message={snackbarMessage}
     
      />





<div className="form-row">
<div class="form-group col-lg-12">
                  <label for="inputPassword4">Max Marks</label>
                  <input type="text" class="form-control" id="inputPassword4" value={marks} onChange={changeMaxMarks} maxLength={30}/>
                </div>
             
               
             
             
              </div>
            
            
             
</div>

           
                
            

            
            
            
           
            
             
            
            
            
              <div class="form-group">
                
              </div>
              <button type="submit" class="btn btn-primary w-100" onClick={onSubmit}  >{status?<CircularProgress className="text-white" size={20}/> :""}Save Changes</button>
            </form>
            
            </div>
  
            
            
            
                        </div>

        </>

       
                           {/*account form*/}
        </div>
    )
}

export default AdminAddTask

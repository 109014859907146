import React from "react";
import styled from "styled-components";
import StudentsList from "./StudentsList";
import { useState } from "react";
import { useEffect } from "react";
import ENDPOINT from "../../../context/EndPoint";
import ShimmerCourseItem from "../../../skeleton/ShimmerCourseItem";
import { Container, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AddCourseAssignment from "./AddCourseAssignment";
import {
  LibraryBooks,
  MenuBook,
  MoreVert,
  ViewAgenda,
  ViewArray,
  ViewColumn,
} from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import { Chip, List, TextField } from "@material-ui/core";
import EditCourseOverview from "./EditCourseOverview";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import {
  Article,
  Chair,
  Edit,
  Group,
  ListAlt,
  Quiz,
} from "@mui/icons-material";
import { Calendar, Clock } from "react-bootstrap-icons";

const AdminCoursesList = ({ props }) => {
  const params = useParams();
  const [mode, setmode] = useState("all");

  const [show, setShow] = useState(false);
  const [currentbatch, setcurrentbatch] = useState(null);
  const [courseurl, setcourseurl] = useState(null);
  const handleClose = () => setShow(false);
  const addAssignment = (course_url, batch_id) => {
    setcourseurl(course_url);
    setcurrentbatch(batch_id);
    setShow(true);
  };
  const [studentslist, setstudentslist] = useState(false);

  const [courses, setcourses] = useState(null);

  useEffect(() => {
    getcourses({ cat: "all" });
  }, [mode]);

  const getcourses = async ({ cat, query }) => {
    var LINK = null;
    if (cat) {
      LINK = `${ENDPOINT}admin-get-courses.php?mode=${cat}`;
    } else {
      LINK = `${ENDPOINT}admin-get-courses.php?mode=all`;
    }
    fetch(LINK)
      .then((r) => r.json())
      .then((result) => {
        setInitialData(result);
        setResponse(result);
      });
  };

  const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"];
  const [fullscreen, setFullscreen] = useState(true);
  const [currentcourse, setcurrentcourse] = useState(null);
  const displayStudents = (course) => {
    setcurrentcourse(course);
    setstudentslist(true);
  };

  const [editoverview, seteditoverview] = useState(false);

  const [courseid, setcourseid] = useState(null);

  const editCourseOverview = ({ courseurl, course_batch, course_id }) => {
    setcurrentbatch(course_batch);
    setcourseurl(courseurl);
    setcourseid(course_id);
    seteditoverview(true);
  };

  const [query, setquery] = useState(null);
  const [category, setcategory] = useState("all");

  const [initialData, setInitialData] = useState("");

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.course_id.toLowerCase().includes(term) ||
        item.course_title.toLowerCase().includes(term) ||
        item.mode.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {courseurl} {currentbatch}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCourseAssignment courseurl={courseurl} batchid={currentbatch} />
        </Modal.Body>
      </Modal>

      <Modal show={editoverview} onHide={() => seteditoverview(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {courseurl} {currentbatch}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCourseOverview
            currentbatch={currentbatch}
            course_id={courseid}
            courseurl={courseurl}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="min-vh-90"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={studentslist}
        fullscreen
        onHide={() => setstudentslist(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b className="text-primary">
              {currentcourse ? currentcourse.toUpperCase() : null}{" "}
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudentsList course={currentcourse} />
        </Modal.Body>
      </Modal>

     

      <Container fluid className="mb-3">
        <div>
          <input
            placeholder="Search courseId, mode, course name "
            variant="outlined"
            className="fixed-search"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </Container>

      <div className="flex-wrap d-flex vh-100 overflow-y pt-3 p-2 space-between">
        {response ? (
          response.map((course, index) => (
            <>
              {/* Rohith improvised trainings management section */}

              <div class="col-lg-6 p-2">
                <div class="shadow rounded p-3 hover-active">
                  <div class="d-flex justify-content-between">
                    <h5>{course.course_title}</h5>
                    <Dropdown className="options-dropdown">
                      <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                        <MoreVert className="text-dark" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="p-3 min-200">
                        <Link
                          className="text-secondary"
                          to={
                            "/internal/trainings-management/courses/edit/" +
                            course.course_id
                          }
                        >
                          <p>
                            <Edit className="mr-2" />
                            Edit Course
                          </p>
                        </Link>

                        <Link
                          className="text-secondary"
                          to={
                            "/internal/trainings-management/course/" +
                            course.course_url +
                            "/" +
                            course.course_id +
                            "/batches"
                          }
                        >
                          <p>
                            <Group className="me-2" />
                            Batches
                          </p>
                        </Link>
                        <Link
                          className="text-secondary"
                          to={
                            "/internal/trainings-management/courses/" +
                            course.course_url +
                            "/chapters"
                          }
                        >
                          <p key={index}>
                            <ViewColumn className="me-2" />
                            View Chapters
                          </p>
                        </Link>
                        <Link
                          className="text-secondary"
                          to={
                            "/internal/trainings-management/courses/" +
                            course.course_url +
                            "/students/all"
                          }
                        >
                          <p key={index}>
                            <ListAlt className="me-2" />
                            Students List
                          </p>
                        </Link>
                        <Link
                          className="text-secondary"
                          to={
                            "/internal/resource-management/" + course.course_url
                          }
                        >
                          <p key={index}>
                            <MenuBook className="mr-2" />
                            Resources
                          </p>
                        </Link>
                        <Link
                          className="text-secondary"
                          to={"/course-certification/" + course.course_url}
                        >
                          <p key={index}>
                            <Article className="mr-2" />
                            Course Certifications
                          </p>
                        </Link>
                        <Link
                          className="text-secondary"
                          to={"/mock-tests/" + course.course_url}
                        >
                          <p key={index}>
                            <Article className="mr-2" />
                            Mock Tests
                          </p>
                        </Link>
                        <Link
                          className="text-secondary"
                          to={"/technical-quizzes/" + course.course_url}
                        >
                          <p key={index}>
                            <Quiz className="mr-2" />
                            Technical Quizzes
                          </p>
                        </Link>
                        <Link
                          className="text-secondary"
                          to={"/cheat-sheets/" + course.course_url}
                        >
                          <p key={index}>
                            <LibraryBooks className="mr-2" />
                            Cheat sheets
                          </p>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <chip class="rounded-pill bg-success px-2 pb-1 text-white me-2">
                    Active
                  </chip>
                  <chip class="rounded-pill bg-primary ml-2 px-2 pb-1 text-white">
                    {course.mode}
                  </chip>
                  <hr />
                  <div class="d-flex flex-row w-100">
                    <div class="d-flex flex-row justify-content-between w-100">
                      <div class="d-flex flex-row">
                        <Group className="me-2" />
                        <p class="text-secondary ml-1 mb-o">
                          <b>Current batch :</b> 354652
                        </p>
                      </div>
                      <div class="d-flex flex-row">
                        <Chair className="me-2" />
                        <p class="text-secondary">
                          <b>Seats Left</b> : {course.seats_left}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-between w-100">
                    <div class="d-flex flex-row">
                      <Calendar className="me-2 mt-1" />
                      <p class="text-secondary ml-1 mb-o">
                        <b>Batch Starts :</b>
                        {course.registration_ends}
                      </p>
                    </div>
                    <div class="d-flex flex-row">
                      <Clock className="me-2 mt-1" />
                      <p class="text-secondary">
                        {" "}
                        {course.class_starts} - {course.class_ends}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Rohith improvised trainings management section */}

              <div
                key={index}
                className="hover-active d-none br-5 p-2 container-fluid mb-2 mt-2"
              >
                <div className="flex">
                  <div className="col">
                    <h4>{course.course_title}</h4>
                    <div className="flex">
                      {course.class_starts ? (
                        <Chip
                          size="small"
                          className="bg-success text-light"
                          label="Active"
                        />
                      ) : (
                        <Chip
                          size="small"
                          className="bg-danger  text-light"
                          label="InActive"
                        />
                      )}
                      <Chip
                        size="small"
                        className="bg-info ml-2 text-light"
                        label={course.mode}
                      />
                    </div>
                  </div>

                  <Dropdown className="options-dropdown">
                    <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                      <MoreVert className="text-dark" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-3">
                      <Link
                        className="text-secondary"
                        to={
                          "/internal/trainings-management/courses/edit/" +
                          course.course_id
                        }
                      >
                        <p>Edit Course</p>
                      </Link>

                      <Link
                        className="text-secondary"
                        to={
                          "/internal/trainings-management/course/" +
                          course.course_url +
                          "/" +
                          course.course_id +
                          "/batches"
                        }
                      >
                        <p>Batches</p>
                      </Link>

                      <Link
                        className="text-secondary"
                        to={
                          "/internal/trainings-management/courses/" +
                          course.course_url +
                          "/chapters"
                        }
                      >
                        <p key={index}>View Chapters</p>
                      </Link>

                      <Link
                        className="text-secondary"
                        to={
                          "/internal/trainings-management/courses/" +
                          course.course_url +
                          "/students/all"
                        }
                      >
                        <p key={index}>Students List</p>
                      </Link>

                      <Link
                        className="text-secondary"
                        to={
                          "/internal/resource-management/" + course.course_url
                        }
                      >
                        <p key={index}>Resources</p>
                      </Link>

                      <Link to={"/mock-tests/" + course.course_url}>
                        <p key={index}>Mock Tests</p>
                      </Link>

                      <Link to={"/technical-quizzes/" + course.course_url}>
                        <p key={index}>Technical Quizzes</p>
                      </Link>

                      <Link to={"/cheat-sheets/" + course.course_url}>
                        <p key={index}>Cheat sheets</p>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <hr />

                <div className="container-fluid text-secondary">
                  <div className="flex space-between">
                    <div className="col">
                      <p>
                        <b>Current Batch : </b>
                        {course.batch_id}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>Batch Starts : </b>
                        {dateFormat(course.registration_ends, "dS ddd mmm yy")}
                      </p>
                    </div>

                    <div className="col">
                      <p>
                        <b>Seats Left : </b>
                        {course.seats_left}
                      </p>
                    </div>

                    <div className="col">
                      <p>
                        <b>Timings : </b>
                        {course.class_starts} - {course.class_starts}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <div className="shimmer-courses-row">
              <ShimmerCourseItem />

              <ShimmerCourseItem />

              <ShimmerCourseItem />

              <ShimmerCourseItem />

              <ShimmerCourseItem />

              <ShimmerCourseItem />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminCoursesList;

export const SliderStyled = styled.div`
  margin-bottom: 50px;
  #container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
  }
  #content {
    width: 100%;

    display: flex;
    padding: 10px 5px;
  }
  .slider-buttons {
    display: flex;
  }
  .courses-title {
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .courses-title h1 {
    font-size: 32px;
  }
  .slider-buttons img {
    height: 30px;
    width: 30px;
    margin-right: 7px;
    padding: 5px;
    box-shadow: 0px 0px 5px grey;
    border-radius: 15px;
  }
  .slider-buttons img:hover {
    background-color: #00cbfd;
    color: white;
    fill: white;
    transition: 0.5s;
  }
  #container::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    .courses-title h1 {
      font-size: 20px;
      padding-left: 5px;
    }
    .slider-buttons img {
      padding: 5px;
      margin-right: 7px;
    }
  }
`;

import React, { useContext } from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'
import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import ENDPOINT from '../../context/EndPoint'
import SubmitInternshipTask from './SubmitInternshipTask'
import { AuthContext } from '../../context/AuthContext'

import MoreVert from '@mui/icons-material/MoreVert';


import { Dropdown } from 'react-bootstrap'

const InternReports = ({account,timeline}) => {
     useEffect(() => {
          window.scrollTo(0, 0)
         
        }, [])
      
const {user} = useContext(AuthContext)
     const params = useParams();
     const internship_id = params.internshipid;
    // // console.log("internship id"+internship_id);

     useEffect(() => {
          window.scrollTo(0, 0)
          getTasks();
        }, [])

const [tasks,settasks] = useState(null)

const [status,setstatus] = useState("all");

     const getTasks = async (status)=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          

          formData.append('internship_id',internship_id)
          
          const res = await axios.post(ENDPOINT+"interns-posts.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
         //  // console.log('internship posts are '+JSON.stringify(res.data.posts))
           if(res.data.status==="success"){
            settasks(res.data.posts)
           }
           else{
          
           }
          }
          }
          
const openSubmission = ()=>{
     setsubmit(true)
}

const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)

const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
       
     return (
          <>
         <InternshipTemplate account={account} timeline={timeline}>
              <>
              




              <Modal
  open={submit}
  onClose={()=>{setsubmit(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
   <>
   <div className="flex space-between">
         <h5>Submit Task</h5> 
    </div>

    <SubmitInternshipTask id={taskid}/>
   </>
  </Box>
</Modal>







<div className="container-fluid pt-3">
<h4>Leave</h4>


<div className="flex">
     <button className="btn btn-primary">Request Leave</button>
     <button className="btn btn-primary">Contact HR</button>
</div>




<div className="mt-4">

<h4>My Leaves</h4>
     <div className="shadow w-100 p-3 mt-3">


<div className="flex space-between">

<h4>General Leave</h4>

<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
  <Dropdown.Item  >Cancel Leave</Dropdown.Item>
  <Dropdown.Item >Extend Leave</Dropdown.Item>

  </Dropdown.Menu>
</Dropdown>
</div>
<hr/>
<div className="flex space-between">
<h6>Duration : 2 days</h6>
<h6>Start Date : 2 days</h6>
<h6>End Date : 2 days</h6>
</div>
     </div>
</div>
</div>
















              </>
         </InternshipTemplate>
          </>
     )
}

export default InternReports

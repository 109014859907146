import signupplacements from '../../assets/signup-placements.svg'
import signupopportunities from '../../assets/signup-opportunities.svg'
import signupcommunity from '../../assets/signup-community.svg'
import signuptrainings from '../../assets/signup-trainings.svg'
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import  { useState } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField';
import textlogo from '../../assets/bg/logo.svg'

import axios from 'axios'
import ENDPOINT from '../../context/EndPoint'
import { Link } from 'react-router-dom'
import {Button, Form, Toast } from 'react-bootstrap'
import { useEffect } from 'react'
import LogoutAllNotification from './LogoutAllNotification'

const LoginTest = (props,account,permission) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

   const [ToastTitle,setToastTitle] = useState(null);
   const [ToastMessage,setToastMessage] = useState(null)

    const redirect = sessionStorage.getItem("next") || "/dashboard";
    const msg = sessionStorage.getItem("msg") || null;
   
const [loginerror, setloginerror] = useState(null);



 const [email,setemail] = useState(null);
 const [password,setpassword] = useState(null)
    const usercredentials = {email,password}
    const [isFetching,setisFetching] =  useState(false)
        const  loginaction= async (e)=>{
            setisFetching(true)
    e.preventDefault();
  ;
    try {
        const res = await 
        axios.post(`${ENDPOINT}login.php?email=${email}&password=${password}&login=Submit`)
    const status = res.data.status;
    const data = res.data.data;
    setisFetching(false)
        if((status==="error")&&(data==="limit exceeded")){ 
          setloginerror("limit exceeded");
        }
        else if((status==="error")&&(data==="wrong credentials")){
        setloginerror("wrong credentials");
        setShowA(true);
        setToastTitle("Login Error !")
        setToastMessage("Invalid email or password.")
    }
    else if((status==="error")&&(data==="session error")){
        setShowA(true)
        setToastTitle("Something went wrong !")
        setToastMessage("Please try again after sometime.")
    }
        else if ((status==="success")&&(data!==null)){
            localStorage.setItem("user", JSON.stringify(data));
           
           setToastTitle("Login Successful")
           setToastMessage("Redirecting...")
            setShowA(true)
            if((sessionStorage.getItem("next")==="null")||(sessionStorage.getItem("next")==="")||(sessionStorage.getItem("next")===null)){
                window.location.replace("/");
            }else{
                window.location.replace(redirect);
            }
               
                sessionStorage.setItem("next","");
        
               
        }
        else{
            setisFetching(false)
            setShowA(true)
        setToastTitle("Something went wrong !")
        setToastMessage("Please try again after sometime.")
        }
    
       
      } catch (err) {
        setShowA(true)
        setToastTitle("Something went wrong !")
        setToastMessage("Please try again after sometime.")
      
      }

        }






            const [showA, setShowA] = useState(false);
          
            const toggleShowA = () => setShowA(!showA);
        











    return (
<LoginStyled>




<div className="container-fluid min-vh-100 p-0">









<div className="position-absolute fixed-bottom justify-center justify-content-center right-0">

<div  className="mb-2 col-lg-3 col-md-3 col-sm-4 col-10">
         
         <Toast show={showA} onClose={toggleShowA} delay={4000} autohide className="space-between bg-warning">
           <Toast.Header>
             <img
               src="holder.js/20x20?text=%20"
               className="rounded me-2"
               alt=""
             />
             <strong className="me-auto">{ToastTitle}</strong>
            
           </Toast.Header>
           <Toast.Body>{ToastMessage}</Toast.Body>
         </Toast>
       </div>
       

</div>
 










<div className="flex-wrap min-vh-100">
{/*login form holder*/}
<div className="col-lg-6 mx-auto  col-md-6 col-sm-6 col-12 min-vh-100 flex justify-center align-items-center">

{loginerror?loginerror==="limit exceeded"?
<LogoutAllNotification usercredentials={usercredentials}/>
:loginerror==="wrong credentials"? ""
:loginerror==="session error"?
<div className="error">
<h5>Error !</h5>
<p>Something went wrong</p>
</div>
:""
:""}



<div className="col-xl-8 bg-white col-12 col-lg-10 col-md-11 col-sm-10 mb-5 p-3 br-4 h-fit vh-fit mx-auto shadow">



<div className="mb-5">

<img src={textlogo}/>
    <p>A New Standard of Education</p>
   
</div>
<p className="text-danger">{msg?msg:""}</p>
<Form className="mt-3 w-100" autoComplete="off" onSubmit={loginaction}>
  
  
  <TextField  className="sinput w-100 "  onChange={(e)=>{setemail(e.target.value)}}  label="Email" type="email" variant="outlined"   minLength={10} maxLength={60} required  />

     <Form.Text className="text-muted">
    
    </Form.Text>
  
 <TextField  className="sinput w-100 mt-3" label="Password" type="password" variant="outlined" onChange={(e)=>{setpassword(e.target.value)}}  minLength={6} maxLength={60} required  />

    
  
<div className="flex mb-3 mt-3 space-between align-items-center sm-14">
<div className="flex align-items-center">
<Checkbox className="w-fit" name="Remember password" defaultChecked />
<p className="mb-0">Remember password</p>
</div>
<Link className="text-center sm-16" to="/password-reset">Forgot Password ?</Link>

</div>

  <Button className="w-100 mb-3" {...isFetching?"disabled":null} variant="primary" type="submit">
  {isFetching?"Signing in...":"Login"}
  </Button>
</Form>









<div className="text-center  pb-2">
<p className="mb-3 sm-16 mt-3">Not yet registered ? <b><Link to="/signup">Create New Account</Link></b></p>



</div>
</div>










{/*copy rights*/}

<div className="position-absolute mt-5 bottom-0 left-0 container-fluid">

<div className="container-fluid  text-center mt-4  sm-14">
   Copy Rights © 2022 <a href="https://awetecks.com" target="_blank" className="text-secondary sm-14">Awetecks |  Aweteck Services Pvt.Ltd</a>
</div>
<div className="container-fluid  text-center mt-2 mb-3">
  <div className="flex-wrap space-evenly container-fluid">
  <a href="https://skillsuprise.com/terms-and-conditions" target="_blank" className="text-secondary sm-14">Terms & Conditions</a>
  <a href="https://skillsuprise.com/privacy-policy" target="_blank" className="text-secondary sm-14">Privacy Policy</a>
  <a href="https://skillsuprise.com/about" target="_blank" className="text-secondary sm-14">About Us</a>
  <a href="https://skillsuprise.com/advantages" target="_blank" className="text-secondary sm-14">Advantages</a>

   </div>
  
</div>

</div>










</div>
{/*login form holder*/}





{/*right holder*/}


{/*right holder*/}

</div>


</div>

{/*main container end*/}













  






        </LoginStyled>
           
        
    )
}

export default LoginTest


export const LoginStyled = styled.div`


.check{
    height: 15px;
    width: 15px;
}

`;




import React, { useContext, useEffect, useState } from 'react'

import TextareaAutosize from '@mui/material/TextareaAutosize';
import AdminAddStudentReport from '../../../actions/student/AdminAddStudentReport';
import { AuthContext } from '../../../context/AuthContext';
import AdminGetStudentReports from '../../../actions/student/AdminGetStudentReports';
import { useParams } from 'react-router-dom';
const StudentReports = ({profile}) => {
const params = useParams()
const purchase_id = params.purchase_id
const {user} = useContext(AuthContext)



const GetReports = async ()=>{
  
          const res = await AdminGetStudentReports({
               payload:{
                    user:user.SessionId,
                    user_id:profile.user_id,
                    course_url:profile.course_url
                
     
               }
          })
          if(res.status==="success"&&res.reports!==null){
setreports(res.reports)
          }
     }
     
useEffect(()=>{
GetReports()
},[purchase_id])
const [reports,setreports] = useState(null)
  return (
    <div>
     


     <div className=" mt-2">
          {reports?
     <>
     {reports.map((report)=>(
          <>

          <div className="dashed-border p-2 mb-2">

          <p className="mb-2">{report.message}</p>
          <div className="d-flex justify-content-between">
               <p className="mb-0 extra-small text-secondary">Reported by : {report.reported_by}</p>
     <p className="extra-small mb-0 text-secondary">{report.id} |  {report.create_datetime}</p>
</div>
          </div>

          </>
     ))}
     </>:null}


</div>


    
    
    </div>
  )
}

export default StudentReports
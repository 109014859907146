import {Container,Row,Col,Button} from 'react-bootstrap';
import dp1 from './dp1.jpg';
import { RiCheckboxBlankFill } from "react-icons/ri";
import {useState,useEffect, useContext} from 'react';
import axios from "axios";
import { AuthContext } from '../../../context/AuthContext';
import ENDPOINT from '../../../context/EndPoint';
const StudentProfile=({userdata})=>{

  const {user} = useContext(AuthContext)
    const [users,setuserdata]=useState();
    const [courses,setcourses] = useState(null);
    const [internships,setinternships] = useState(null);
    const [badges,setbadges] = useState(null);
    const [journeys,setjourneys] = useState(null);
    const [reports,setreports] = useState(null);
    useEffect(() => {
        Fetch_userdata();
      },[]);
      const Fetch_userdata = async () => {
        const formdata = new FormData();
        formdata.append('user_id',userdata.user_id);
formdata.append('session', user.SessionId)
formdata.append('email',userdata.email)
        const res = await axios.post(ENDPOINT+"admin-get-profile.php",
          formdata,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        if (res) {
          if (res.data.status === "success") {
            setuserdata(res.data.data.user_data);
            setcourses(res.data.data.courses);
            setinternships(res.data.data.internships);
            setbadges(res.data.data.badges);
            setjourneys(res.data.data.journey);
            setreports(res.data.data.student_report);
          }
        }
      };
    

    return(
        <div>
            <Container>
            <Row className="flex">
                    <Col lg={3} md={3} sm={12} xs={12} className="bg-dark text-light">
                            <div className="text-center mt-5 mb-5">
                            <img src={user?user.profile_pic:dp1} alt="" className="rounded-circle w-50" ></img> 
                            </div>
                    </Col>
                    <Col lg={7} md={7} sm={12} xs={12} className="ps-5">
                    {users?
                        <>
                        {users.map(user=>(
                        <>
                        <div >
                        <h1 key={user.username} className="mt-5"> {user.username}</h1>
                        <p key={user.role} className="m-0 mb-3">Role: {user.role}</p>
                        <div className="mb-3">
                        <p key={user.phone_number} className="m-0">Phone : {user.phone_number}</p>
                        <p key={user.email} className="m-0">Email :{user.email} </p>
                        <p key={user.address} className="m-0">Address:{user.address} </p>
                        </div>
                        </div>

                        </>
                        ))}
                        </>
                        :<>
                        no results
                        </>}
                      
                        
                    </Col>
            </Row>
            <Row>
            <Col lg={3} md={3} sm={12} xs={12} className="bg-dark text-light">      
                            <div className="ms-4">
                            <h3 className="mt-3">Journey</h3>
                            <hr></hr>
                            {journeys?
                        <>
                        {journeys.map(journey=>(
                        <>
                        <div key={journey.course_url}>
                        <p className="m-0"><RiCheckboxBlankFill className="me-4"/>{journey.course_url} </p>
                        </div>

                        </>
                        ))}
                        </>
                        :<>
                        no results
                        </>}
                           
                        
                            <h3 className="mt-5">Report</h3>
                            <hr></hr>
                            {reports?
                        <>
                        {reports.map(report=>(
                        <>
                        <div key={report.course_url}>
                        <p className="m-0"><RiCheckboxBlankFill className="me-4"/>{report.note}</p>
                        </div>

                        </>
                        ))}
                        </>
                        :<>
                        no results
                        </>}
                           
                            

                            </div>
                    </Col>
                    <Col lg={7} md={7} sm={12} xs={12} className="ps-5">
                        <h3 className="mt-4">Courses Enrolled</h3>
                        <hr></hr>
                        {courses?
                        <>
                        {courses.map(course=>(
                        <>
                        <div key={course.course_url}>
                        <p className="m-0"><RiCheckboxBlankFill className="me-4"/>{course.course_url} ({course.purchase_status})</p>
                        </div>

                        </>
                        ))}
                        </>
                        :<>
                        no results
                        </>}

                   
                      
                        <h3 className="mt-4">Internships Applied</h3>
                        <hr></hr>
                        {internships?
                        <>
                        {internships.map(internship=>(
                        <>
                        <div key={internship.title}>
                        <p className="m-0"><RiCheckboxBlankFill className="me-4"/>{internship.title}</p>
                        </div>

                        </>
                        ))}
                        </>
                        :<>
                        no results
                        </>}
                        

                        <h3 className="mt-4">Badges Earned</h3>
                        <hr></hr>
                        {badges?
                        <>
                        {badges.map(badge=>(
                        <>
                        <div key={badge.title}>
                        <Row className="d-flex">
                        <Col><p className="m-0"><RiCheckboxBlankFill className="me-4"/>{badge.title}</p></Col>
                        <Col><Button>Activate</Button></Col>
                        </Row>
                        

                        </div>

                        </>
                        ))}
                        </>
                        :<>
                        no results
                        </>}
                        

                        

                        
                        <h3 className="mt-4">Activity Map</h3>
                        <hr></hr>
                    </Col>

            </Row>
                        
                    
               
            </Container>
        </div>
    );
}
export default StudentProfile;


import React from 'react'
import { Link } from 'react-router-dom';
import { useEffect,useState } from 'react';
import Localbase from 'localbase'
const FooterCoursesList = ({mode}) => {



const [list,setlist]= useState(null)

let db = new Localbase('db')


    useEffect(()=>{

       
if(mode==="live"){
    db.collection('paths').doc('live_courses_list')
    .get().then(courses=>{
setlist(courses.live_courses_list)
    }).catch(error=>{

    })
}
else if(mode==="selfpaced"){
    db.collection('paths').doc('selfpaced_courses_list')
    .get().then(courses=>{
setlist(courses.selfpaced_courses_list)
    }).catch(error=>{

    })
}else if(mode==="specialization"){
    db.collection('paths').doc('specialization_courses_list')
    .get().then(courses=>{
setlist(courses.specialization_courses_list)
    }).catch(error=>{

    })
}
else if(mode==="live"){
    db.collection('paths').doc('offline_courses_list')
    .get().then(courses=>{
setlist(courses.offline_courses_list)
    }).catch(error=>{

    })
}


    getlist();
           
    }
    
    ,[])
    
    
    
    
    const  getlist = async ()=>{
    
        fetch(`https://skillsuprise.com/footer-courses-list.php?mode=${mode}`)
        .then(r=>r.json())
        .then(result=>{
              if(result){
               if(result.status==="success"){
                setlist(result.data)


if(mode==="offline"){
db.collection('paths').add({
    offline_courses_list:result.data
},'offline_courses_list')
}
else if(mode==="live"){
db.collection('paths').add({
    live_courses_list:result.data
},'live_courses_list') 
}
else if(mode==="selfpaced"){
db.collection('paths').add({
    selfpaced_courses_list:result.data
},'selfpaced_courses_list') 
}
else if(mode==="specialization"){
db.collection('paths').add({
    specialization_courses_list:result.data
},'specialization_courses_list') 
}
            }
              }
        
     
            
        })
        
        }    ;









    return (
     <>

{list?
<>
<div>
            <h5>{mode==="live"?"Live Online Courses":mode==="offline"?"Offline Courses":mode==="selfpaced"?"Self Paced Courses":mode==="specialization"?"Specialization Programs":""}</h5>
        
     <ul>
{ list?list.map((item,index)=>(
 
<Link key={index} to={"/course/"+mode+"/"+item.course_url}>
<li>{item.course_title}
</li>

    </Link>
)):
<></>
}

</ul>


        
        </div>


</>
:""
}

     </>
    )
}

export default FooterCoursesList

import React from 'react'
import { Edit } from "@material-ui/icons";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";

import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';

import 'react-calendar-heatmap/dist/styles.css';
const InternWorkActivityMap = ({startdate,enddate,mapdays}) => {
  return (
    <div>
      <div className="col-lg-12  p-2">
<CalendarHeatmap 

  startDate={new Date(enddate)}
  endDate={new Date(startdate)}
  values={mapdays}
  classForValue={value => {
    if (!value) {
      return 'color-empty';
    }
    return `color-github-101`;
  }}
  tooltipDataAttrs={value => {
    return {
      'data-tip': `${value.date?value.date+" working day":""}`,
    };
  }}
  showWeekdayLabels={true}
  
/>

<ReactTooltip />
</div>


   

    </div>
  )
}

export default InternWorkActivityMap
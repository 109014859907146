import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'

import { Dropdown, Modal } from 'react-bootstrap'
import { MoreVert } from '@material-ui/icons'

import ENDPOINT from '../../../context/EndPoint'

import CountDown from '../components/CountDown'
import InternalTemplate from '../../../themes/InternalTemplate'
const InternalEmployeeManagement = ({ page,role}) => {

const {user} = useContext(AuthContext)


const [edit,setedit] = useState(false);


const tasktype= useRef()

const changeTaskStatus = (e)=>{
 
  settaskstatus(tasktype.current.value);
  getTasks(tasktype.current.value);

}

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [tasks,settasks] = useState("start");
const [category,setcategory] = useState("all")

const [taskstatus,settaskstatus]= useState("all")
    
    const [taskid,settaskid] = useState(null)
    const [remove,setremove] = useState(false)
    const [removeid,setremoveid] = useState(null);
    const [submit,setsubmit] = useState(false);

const showSubmit =(e)=>{
  const id= e.target.id;
settaskid((id.replace("submit","")));
setsubmit(true)
}


    const closeSubmit = ()=>{
      setsubmit(false);
    }
    const editTask = (e)=>{

const id= e.target.id;
settaskid((id.replace("edit","")));

setedit(true)
    }

const closeEdit = ()=>{
  setedit(false)

}
const [add,setadd] = useState(false)

const closeAddition =()=>{
  setadd(false)
  getTasks(taskstatus);
}

const showRemove = (e)=>{
    
  const id= e.target.id;
  
settaskid(id.replace("delete",""));
  setremove(true)

}
const closeRemove = ()=>{
  setremove(false)
}




useEffect(() => {
 getTasks(taskstatus)
}, [])





const getTasks = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);

  formData.append('status',status);


const res = await axios.post(ENDPOINT+"admin-get-tasks.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
 
 if(res.data[0].status==="success"){
  settasks(res.data[0].data)
 }
 else{

 }
}
}



//delete task code

const deleteTask = async ()=>{

const formData = new FormData();
formData.append('session',user.SessionId);
formData.append('id',taskid);

const res = await axios.post(ENDPOINT+"admin-delete-task.php",formData,{
  headers:{
    'content-type': 'multipart/form-data'
  }
});


if(res.data[0].status==="success"){
  getTasks()
  closeRemove();
}
else{
  
}
}




    return (
     <>
     
           
<div className="tasks-page">
<div className="container-fluid mt-3 space-between w-100">
    
<h4>{tasks?tasks.length:0} {category==="all"?"Total":category} tasks</h4>

<select  ref={tasktype} onChange={changeTaskStatus}>
  <option value="all">All</option>
  <option value="pending">Pending</option>
  <option value="completed">Completed</option>
  <option value="rejected">Rejected</option>
  </select>

</div>
{role==="admin"||role==="manager"||role==="leader"?
<>
<div className="container-fluid">
<button className="btn btn-primary" onClick={()=>{setadd(true)}}>Add Task</button>
</div>

</>
:null

}
<hr/>
<div className="internships-list  sm-p-0  container-fluid">

{ tasks==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>

:tasks?tasks.map(internship=>(

<>
<div className=" shadow p-3 mb-2">

<div className=" w-100 flex">
<h6 className="mb-0 w-100">{internship.title}</h6>
{role==="admin"||role==="manager"||role==="leader"?

<>

<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
  <Dropdown.Item href="#" id={"edit"+internship.id} onClick={editTask}>Edit</Dropdown.Item>
  <Dropdown.Item href="#" id={"delete"+internship.id} onClick={showRemove}>Delete</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>

</>
:null
}

</div>
<div className="flex space-between">
<p className="mb-0 col-lg-3 p-0"><b>Marks : </b>{internship.marks}</p> 
<p className="mb-0 cil-lg-3 p-0">{internship.deadline}</p>
<p className="mb-0 col-lg-3 p-0"><CountDown time={internship.deadline}/></p>
</div>
<hr/>
<div className="container-fluid">
<p>{internship.description}</p>
{internship.corrections!=="null"?

<>

<b>Corrections : </b>
<p>{internship.corrections}</p>
</>
:""
}
  </div>
<div className="flex">
{internship.status==="completed"?
"Task Submitted"
:internship.status==="submitted"?
<button className="btn btn-warning" id={"submit"+internship.id} onClick={showSubmit}>Re Submit Task</button>
:internship.status==="pending"?
<button className="btn btn-primary" id={"submit"+internship.id} onClick={showSubmit}>Submit Task</button>
:<button className="btn btn-primary" id={"submit"+internship.id} onClick={showSubmit}>Submit Task</button>
}
</div>



</div>
</>
)):
<div className="container-fluid">

No tasks

    </div>

}





</div>




</div>



<Modal show={edit} 
animation={false}
onHide={closeEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        
        </Modal.Body>
      
      </Modal>

      <Modal show={submit} 
animation={false}
onHide={closeSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
      
      </Modal>


      <Modal show={add} 
animation={false}
onHide={closeAddition}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
        </Modal.Body>
      
      </Modal>



      <Modal show={remove} 
animation={false}
onHide={closeRemove}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
     
        <button className="btn btn-danger" onClick={deleteTask}>Delete</button>
        </Modal.Body>
      
      </Modal>






     
     </>
    )
}

export default InternalEmployeeManagement


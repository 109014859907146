import axios from 'axios'
import React from 'react'
import ENDPOINT from '../../context/EndPoint'
import SecurityHeaders from '../../context/SecurityHeaders'

const EvaluateTask = async ({payload}) => {
const data= new FormData()
data.append("session",payload.session)
data.append("id",payload.id)
data.append("corrections",payload.corrections)
data.append("earned_marks",payload.earned_marks)
data.append("status",payload.status)
const res = await axios.post(ENDPOINT+"admin-evaluate-student-assignment.php",data,SecurityHeaders)

if(res){
     return res.data
}



}

export default EvaluateTask
import React, { useContext, useEffect, useState } from 'react'

import TextareaAutosize from '@mui/material/TextareaAutosize';
import AdminAddStudentReport from '../../../actions/student/AdminAddStudentReport';
import { AuthContext } from '../../../context/AuthContext';
import AdminGetStudentReports from '../../../actions/student/AdminGetStudentReports';
import { useParams } from 'react-router-dom';
const StudentReport = ({profile}) => {
const params = useParams()
const purchase_id = params.purchase_id
const {user} = useContext(AuthContext)
const [report,setreport] = useState(null)
const AddReport = async (e)=>{
e.preventDefault()
     const res = await AdminAddStudentReport({
          payload:{
               user:user.SessionId,
               user_id:profile.user_id,
               course_url:profile.course_url,
               report:report,
               purchase_id:purchase_id

          }
     })
     if(res.status==="success"){
          setreport(null)
          GetReports()
     }
}



const GetReports = async ()=>{
  
          const res = await AdminGetStudentReports({
               payload:{
                    user:user.SessionId,
                    user_id:profile.user_id,
                    course_url:profile.course_url
                
     
               }
          })
          if(res.status==="success"&&res.reports!==null){
setreports(res.reports)
          }
     }
     
useEffect(()=>{
GetReports()
},[])
const [reports,setreports] = useState(null)
  return (
    <div>
     
<div className="">
<h4 className=' mb-3'>Add Student Report</h4>

     <form onSubmit={(e)=>AddReport(e)}>

     <TextareaAutosize
  maxRows={4}
  minRows={4}
  required
  value={report}
  onChange={(e)=>setreport(e.target.value)}
  aria-label="maximum height"
  placeholder="Enter student report"
  defaultValue=""
 className="w-100 border p-2 rounded"
/>
<div className="d-flex justify-content-end">
<button type="submit" className="btn btn-sm mt-3 btn-primary rounded-pill">Add Report</button>

</div>

     </form>

</div>


    
    
    </div>
  )
}

export default StudentReport
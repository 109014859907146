import React from 'react'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import HomeIcon from '@mui/icons-material/Home';
import InternshipIcon from '@mui/icons-material/BusinessCenter';
import {  Facebook, Instagram, LinkedIn,WhatsApp,YouTube } from '@material-ui/icons'
import TutorialsIcon from '@mui/icons-material/VideoLibrary';
import FooterCoursesList from './FooterCoursesList'
import AccountIcon from '@mui/icons-material/AccountCircle';
import WebinarIcon from '@mui/icons-material/CastForEducation';
const Footer = ({bottommenu,footer,page}) => {
  const {user} = useContext(AuthContext);
  const userdata = user;

    return (

      <>
    
    {footer==="false"?
  ""
  :
  <>
      <div className="footer">
        
  <div className="container footer-content flex">



  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
  
  <FooterCoursesList mode={"live"}/>
  </div>
  
  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
  <FooterCoursesList mode={"offline"}/>
  </div>
  
  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
  <FooterCoursesList mode={"selfpaced"}/>
  
  </div>
  
  
  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
  
  <FooterCoursesList mode={"specialization"}/>
  </div>
  
              </div>  







<hr className="bg-light-grey "/>
<div className="flex-wrap container footer-end p-5">
<div className="col-lg-4 col-md-4 col-sm-5 col-12">
<h5>Other Links</h5>
<ul>

<li>
<Link to="/about">About Us</Link>
</li>

<li>
<Link to="/advantages">Advantages</Link>
</li>

<li>
<Link to="/internships">Careers</Link>
</li>

<li>
<Link to="/terms-and-conditions">Terms & Conditions</Link>
</li>

<li>
<Link to="/privacy-policy">Privacy Policy</Link>
</li>
<li>
<Link to="/refund-policy">Refund Policy</Link>
</li>

</ul>
</div>









  <div className="col-lg-4 col-md-4 col-sm-5 col-12">
  <h4>Follow Us On</h4>
 <div className="flex footer-social">
<a  rel="noreferrer" className="h-40 justify-center align-items-center  text-light mr-2" target="_blank" referrerPolicy="no-referrer" href="https://www.facebook.com/skillsuprise/">
<Facebook/>

</a>


<a   rel="noreferrer"  className="h-40 justify-center align-items-center  text-light mr-2" target="_blank" referrerPolicy="no-referrer" href="https://instagram.com/skillsuprise">
<Instagram/>
</a>



<a   rel="noreferrer"  className="h-40 justify-center align-items-center  text-light mr-2" target="_blank" referrerPolicy="no-referrer" href="https://www.linkedin.com/company/skills-uprise/" >
<LinkedIn/>
</a>

<a    rel="noreferrer"  className="h-40 justify-center align-items-center  text-light mr-2" target="_blank" referrerPolicy="no-referrer" href="https://api.whatsapp.com/send/?phone=919490174876&text=" >
<WhatsApp/>
</a>



 </div>
  
  </div>  
  
  

  <div className="col-lg-4 col-md-4 col-sm-5 col-12">
  <p>Copy Rights © 2022</p>
 
  <p><a   rel="noreferrer"  href="https://awetecks.com" target="_blank">Awetecks</a> | Aweteck Services Pvt.Ltd</p>
  
  
  </div> 

  



  
  </div>  













  {bottommenu==="false"||bottommenu===null?

<>


</>

:


<>

<div className="bottom-menu col-12 p-0">



{page==="home"?

<>
<Link to="/" className="col p-0 text-dark">
<div className="bottom-menu-item hovered-footer-menu solid-top">
  <HomeIcon/>
   <p>Home</p>   
</div>

</Link>
</>
:

<>
<Link to="/" className="col p-0 text-dark">
<div className="bottom-menu-item ">
  <HomeIcon/>
   <p>Home</p>   
</div>

</Link>
</>}










{page==="internships"?

<>
<Link to="/internships" className="col p-0 ">

<div className="bottom-menu-item solid-top hovered-footer-menu">

<InternshipIcon/>
   <p className="hovered-footer-menu">internships</p>
</div>
</Link>
</>
:
<>

<Link to="/internships" className="col p-0 text-dark">

<div className="bottom-menu-item">
<InternshipIcon/>
   <p>internships</p>
</div>
</Link>
</>
}

{page==="videos"?


<>
<Link to="/videos" className="col  p-0  text-dark">

<div className="bottom-menu-item solid-top hovered-footer-menu">

<TutorialsIcon/>
  <p>Tutorials</p>
</div>

</Link>
</>


:

<>

<Link to="/videos" className="col  p-0  text-dark">

<div className="bottom-menu-item">

<TutorialsIcon/>
  <p>Tutorials</p>
</div>

</Link>
</>}


{page==="workshops"?

<>

<Link to="/workshops" className="col  p-0 text-dark">
<div className="bottom-menu-item hovered-footer-menu solid-top">
<WebinarIcon/>
 <p>Workshops</p>
</div>

</Link>

</>

:
<>

<Link to="/workshops" className="col  p-0 text-dark">
<div className="bottom-menu-item">
<WebinarIcon/>
 <p>Workshops</p>
</div>

</Link>

</>
}











{userdata?
 <>
 
 {page==="dashboard"?
 

<>
<Link to="/dashboard"  className="col p-0  text-dark">

<div className="bottom-menu-item hovered-footer-menu solid-top">

<AccountIcon/>
  <p>Dashboard</p>
</div>

</Link>
</>

:

<>
<Link to="/dashboard" className="col p-0  ">

<div className="bottom-menu-item">

<AccountIcon/>
  <p>Dashboard</p>
</div>

</Link>
</>
}
 
 </>
:
<Link to="/login" className="col  p-0 text-dark">

<div className="bottom-menu-item">

<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path></svg>

  <p>Account</p>
</div>

</Link>
}


</div>










</>}
















</div>


</>




  }





  
  
    
      
      </>
      
    )
}

export default Footer

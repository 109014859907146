import * as React from 'react';
import VideoIcon from '@mui/icons-material/VideoLibraryOutlined';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/WorkOutlined';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import AccountIcon from '@mui/icons-material/AccountCircleOutlined';
import { useEffect } from 'react';
import { Avatar } from '@mui/material';
import FeedIcon from '@mui/icons-material/Feed';
import { AuthContext } from '../../context/AuthContext';
import DetectDevice from '../../context/DetectDevice';
export default function BottomNavigationBar({account,access}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
     setValue(newValue);
   };
   const {user} = React.useContext(AuthContext)
   React.useEffect(() => {
     const timer = setInterval(() => {
       setpage(JSON.parse(sessionStorage.getItem("page")));
     }, 300);
     return () => {
       clearInterval(timer);
     };
   }, []);
   const [page,setpage] = React.useState(JSON.parse(sessionStorage.getItem("page")))
const device = DetectDevice()



const hiddenPages = ["Comments","Event","Login","Notifications",
"Messages","My Course","My Classes","Recordings","Assignments","Projects","Placement cell","Rewards"
,"Trainer Support","My Internships","My Workshops",
"Settings","Badges","Badge Details","Payments","My Account","Withdrawls",
"My Posts","Saved Posts","Followings","Followers","Recommendations","Followers","Following","Requests sent",
"Requests received","Wallet","Edit Profile","Change Password","Notes","Refer & Earn",
"About Company","Refund Policy","Terms","Privacy Policy","Watch","Live Courses",
"Offline Courses","Specialization Courses","Student Pass","VCP","Challenges","Quizzes","Community Challenges","Interview Preparation",
"Reviews","Features","Resources","Watch Shorts","Test Topics","Internship","Cheat Sheets"];
  return (

     <>
        <div className="bottom-nav  w-100 align-items-center">
<div className="d-flex bottom-nav-items align-items-center w-100 justify-content-between">
   
 
    <Link to="/" className={page===null||page==="Home"||page==="Community"?"col text-center text-dark":"col text-dark text-center"}>
     <HomeIcon/>
     <p>Home</p>
     </Link> 

   



   
    <Link to="/internal/classes-today" className={page==="Classes"?"col text-center active":"col text-dark text-center"}>
     <VideoIcon/>
     <p>Classes</p>
     </Link> 




    <Link to="/internal/tasks" className={page==="Tasks"?"col text-center active":"col text-dark text-center"}>
      <VideoIcon/>
     <p>Works</p>
     </Link> 



     <Link to="/internal/sales-summary/all" className={page==="Sales"?"col text-center active":"col text-dark text-center"}>
      <VideoIcon/>
     <p>Sales</p>
     </Link> 


     <Link to="/internal/student-management/all" className={page==="Students"?"col text-center active":"col text-dark text-center"}>
      <VideoIcon/>
     <p>Students</p>
     </Link> 

    
</div>
     </div> 

     </>
     
    
  );
}

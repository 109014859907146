
import React, { useContext } from 'react'

import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ENDPOINT from '../../../context/EndPoint'
import dateFormat from 'dateformat';
import { AuthContext } from '../../../context/AuthContext'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { Container } from "react-bootstrap";
import { TextField } from "@material-ui/core";




import Toast from 'react-bootstrap/Toast'
import { Dropdown } from 'react-bootstrap'
import { MoreVert } from '@material-ui/icons'

import InternalTemplate from '../../../themes/InternalTemplate'
import InternAddMeeting from './InternAddMeeting';
import { Link } from 'react-router-dom';
import { Chip } from '@material-ui/core';

const InternalInternshipManagement = ({account,timeline}) => {
     useEffect(() => {
          window.scrollTo(0, 0)
         
        }, [])
      
const {user} = useContext(AuthContext)
     const params = useParams();
     const internship_id = params.internshipid;
   //  // console.log("internship id"+internship_id);

     useEffect(() => {
          window.scrollTo(0, 0)
        
         getInternships();
        }, [])

const [tasks,settasks] = useState(null)

const [status,setstatus] = useState("all");
const [description,setdescription] = useState(null)
const [startdate,setstartdate] = useState(null)
const [enddate,setenddate] = useState(null)
const [begins,setbegins] = useState(null);
const [ends,setends]= useState(null)
const [meetlink,setmeetlink] = useState(null)
const [meetdescription,setmeetdescription]= useState(null)
const [internships,setinternships] = useState(null)
const [meetingtype,setmeetingtype]= useState("General Meeting")
const [currentinternship,setcurrentinternship] = useState(null)
     const scheduleMeeting = async (status)=>{
        

          const formData = new FormData();
          
         // // console.log(" submit" + currentinternship+" "+startdate+" "+begins+" "+meetingtype)
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          

          formData.append('internship_id',currentinternship)
          formData.append('date',startdate)
          formData.append('begins',begins)
           formData.append('ends',ends)
          formData.append('meeting_type',meetingtype)
         
          formData.append('meeting_link',meetlink)
          formData.append('description',description)
          
          const res = await axios.post(ENDPOINT+"admin-add-interns-meeting.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
      // // console.log(" add"+JSON.stringify(res))
           if(res.data.status==="success"){
            settasks(res.data.posts)
           }
           else{
          
           }
          }
          }




          
const openSubmission = ()=>{
     setsubmit(true)
}

const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)

const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
    minWidth:400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
       





   const [category,setcategory] = useState("all");
   const [query,setquery] = useState(null)













   const updateWork = async ({status})=>{
        

     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     
     formData.append('session',user.SessionId);
     

     formData.append('attendance',status)

     formData.append('internship_id',internship_id)
     
     
     const res = await axios.post(ENDPOINT+"start-working.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
          getInternships()
     // // console.log('attendance '+JSON.stringify(res.data))
      if(res.data.status==="success" && res.data.message==="start"){
     
      }
      else{
     
      }
     }
     }

useEffect(()=>{
getInternships()
},[category])

const getInternships = async ()=>{
        

          const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          formData.append('status',category)
     

          
          const res = await axios.post(ENDPOINT+"admin-get-internships.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){        if(res.data.status==="success"){
            setinternships(res.data.data)
            setInitialData(res.data.data)
            setResponse(res.data.data)
           }
        
          }
          }
     




          const UpdateInternship = async ({internship_id,status})=>{
        

               const formData = new FormData();
               
               
               //formData.append('whyus',document.getElementById('whyus').value)
               
               formData.append('session',user.SessionId);
               
               formData.append('internship_id',internship_id);
               
               formData.append('status',status);
               
     
               
               const res = await axios.post(ENDPOINT+"admin-internship-actions.php", formData,{
                   headers: {
                       'content-type': 'multipart/form-data'
                   }
               });
               if(res){
                 if(res.data.status==="success"){
                      settoasttitle("Status updated to "+status)
                      settoastmessage("application status changed")
                      settoast(true)
                 getInternships()
                }else{
                    settoasttitle("Something went wrong 1 ")
                    settoastmessage("Please try again...")
                    settoast(true)
                }
             
               }
               }
        
const [toast,settoast] = useState(false);
const [toasttitle,settoasttitle] = useState(null);
const [toastmessage,settoastmessage] = useState(null)



const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.stipend.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term) ||
      
        item.title.toLowerCase().includes(term) 
     
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

     return (
          <>
              <>
              




              <Modal
  open={submit}
  onClose={()=>{setsubmit(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
   <>
<InternAddMeeting/>
   </>
  </Box>
</Modal>




<div className="position-fixed justify-center bottom-60 left-0 w-100">

<Toast className="bg-warning" onClose={() => settoast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
          
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>

</div>


<div className="container-fluid sm-p-15 ">

<div className="flex  align-items-center space-between mb-4">


{/* <div className="flex col-lg-9">
<FormControl >
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
      
<Select
  
  value={category}
  label="Category"
  onChange={(e)=>setcategory(e.target.value)}
>
  <MenuItem value={"all"}>All </MenuItem>
  <MenuItem value={"active"}>Active</MenuItem>

  <MenuItem value={"inactive"}>In-active</MenuItem>
    <MenuItem value={"rejected"}>Rejected</MenuItem>
  
</Select>
      </FormControl>



      <Paper
    onSubmit={(e)=>{e.preventDefault();getInternships()}}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >

      <InputBase
      
         sx={{ ml: 1, flex: 1 }}
        onChange={(e)=>setquery(e.target.value)}
        placeholder="Internship ID or Title"
        inputProps={{ 'aria-label': 'Search Batch ID ' }}
      />
      <IconButton onClick={()=>getInternships()} sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      
    </Paper>

</div> */}

<Container fluid className="mb-3 col-8">
              <div>
                <TextField
                  label="stipend status title"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e)}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Container>


<h4>{internships?internships.length:0} Internships</h4>

 
</div>

<Link className="btn btn-primary mb-3" to="/internal/internships-management/add">Add Internship</Link>


<div className="container-fluid">


{response?
<>
{response.map((internship,index)=>(

<>

<div key={index} className="shadow p-3 mb-1 mt-1">
  <div className="flex space-between">
  <h6>{internship.title}</h6>
  
<Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white text-dark options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu >
  <Dropdown.Item onClick={()=>UpdateInternship({internship_id:internship.id,status:"active"})} >Publish</Dropdown.Item>
 
  <Dropdown.Item onClick={()=>UpdateInternship({internship_id:internship.id,status:"inactive"})} >Deactivate</Dropdown.Item>
  
  <Dropdown.Item onClick={()=>UpdateInternship({internship_id:internship.id,status:"rejected"})} >Reject </Dropdown.Item>
  
  
  <Dropdown.Item  ><Link to={"/intern-application/"+internship.title +"/"+internship.id}>View Interns</Link></Dropdown.Item>

  <Dropdown.Item  onClick={()=>{setcurrentinternship(internship.id);setsubmit(true)}}>Schedule Meeting</Dropdown.Item>

  <Dropdown.Item  >Edit</Dropdown.Item>
  <Dropdown.Item  >Delete</Dropdown.Item>

    </Dropdown.Menu>
</Dropdown>
  </div>

<div className="container-fluid">
<Chip size="small" label={internship.status}/>
<hr/>
<div className="flex space-between">
<h6>ID : {internship.id}</h6>
     <h6>Deadline : {dateFormat(internship.deadline,"dS mmm yy, dddd")}</h6>
     <h6>Stipend : ₹ {internship.stipend}</h6>
   
</div>
</div>

</div>

</>


))}

</>

:null


}


</div>






</div>













              </>
          </>
     )
}

export default InternalInternshipManagement

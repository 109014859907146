import React from 'react'
import AdminWebsiteSettings from '../../settings/AdminWebsiteSettings'

const Configurations = () => {
  return (
    <div >

<div className="container d-flex flex-wrap">


  <AdminWebsiteSettings/>

</div>



    </div>
  )
}

export default Configurations

import Box from "@mui/material/Box";

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import EmailIcon from "@mui/icons-material/Email";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CancelIcon from "@mui/icons-material/Cancel";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { useParams } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";
import { Chip } from "@material-ui/core";

const InternOptions = ({ account }) => {
  const params = useParams();

  const internship_id = params.internshipid;

  const { user } = useContext(AuthContext);
  const [internship, setinternship] = useState(null);
  const [authority, setauthority] = useState(null);
  const getstats = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    formData.append("session", user.SessionId);
    formData.append("internship_id", internship_id);
    const res = await axios.post(
      ENDPOINT + "internship-statistics.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      // ("internship stats "+ JSON.stringify(res.data.authority))
      if (res.data.status === "success") {
        setinternship(res.data.data);
        setauthority(res.data.authority);
      } else {
      }
    }
  };

  useEffect(() => {
    getstats();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [leave, setleave] = useState(false);
  const [report, setreport] = useState(false);

  const [generalreport, setgeneralreport] = useState(false);

  const startConversation = async ({ status, receiver_id, initial_id, e }) => {
    sessionStorage.setItem("cid", initial_id);

    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    formData.append("receiver_id", receiver_id);

    const res = await axios.post(
      ENDPOINT + "start-conversation.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      // // console.log("conversation"+JSON.stringify(res.data))
      if (res.data.status === "success") {
        window.location.replace("/chat/23");

        // // console.log("conversation started");
      } else {
      }
    }
  };

  return (
    <>
      <Modal
        open={report}
        onClose={() => setreport(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="flex space-between">
              <h5>Report this post</h5>
              <CancelIcon type="button" onClick={() => setreport(false)} />
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <button className="btn btn-transparent">
              I think it's something else
            </button>

            <button className="btn btn-transparent">
              I think it's something else
            </button>

            <button className="btn btn-transparent">
              I think it's something else
            </button>

            <button className="btn btn-transparent">
              I think it's something else
            </button>
            <button className="btn btn-primary w-100 mt-3">
              Submit Report
            </button>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={generalreport}
        onClose={() => setgeneralreport(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="flex space-between">
              <h5>Report an Issue</h5>
              <CancelIcon
                type="button"
                onClick={() => setgeneralreport(false)}
              />
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <button className="btn btn-transparent">
              I think it's something else
            </button>

            <button className="btn btn-transparent">
              I think it's something else
            </button>

            <button className="btn btn-transparent">
              I think it's something else
            </button>

            <button className="btn btn-transparent">
              I think it's something else
            </button>
            <button className="btn btn-primary w-100 mt-3">
              Submit Report
            </button>
          </Typography>
        </Box>
      </Modal>

      {internship ? (
        <>
          <div className=" p-3 pb-5">
            <div className="flex">
              <img
                src={account.profile_pic}
                className="authority-dp shadow mt-0"
              />
              <div className="container-fluid">
                <div className="flex space-between">
                  <h4 className="sm-18">
                    {account.first_name + " " + account.last_name}
                  </h4>
                </div>

                <p className="text-secondary mb-1">
                  ID : {internship.intern_id}
                </p>
                <p className="text-secondary mb-1">Role : {internship.role}</p>
              </div>
            </div>

            <div className="container-fluid mt-3 p-0">
              <div className="flex-wrap space-between">
                <div className="col-4 mb-3 p-1 text-center">
                  <div className="shadow bg-white io1 p-3 br-4">
                    <p className="mb-1">Attendance</p>
                    <h6>{internship.attendance + "%"}</h6>
                  </div>
                </div>

                <div className="col-4 mb-3 p-1  text-center">
                  <div className="shadow bg-white io2 br-4 p-3">
                    <p className="mb-1">Effeciency</p>
                    <h6>{internship.efficiency}%</h6>
                  </div>
                </div>

                <div className="col-4 mb-3 p-1 text-center">
                  <div className="shadow bg-white io3 p-3 br-4">
                    <p className="mb-1">Completed</p>
                    <h6>{internship.completed}</h6>
                  </div>
                </div>

                <div className="col-6 mb-3 p-1 text-center">
                  <div className="shadow bg-white io4 br-4 p-1">
                    <p className="mb-1">Pending</p>
                    <h6>{internship.pending}</h6>
                  </div>
                </div>

                <div className="col-6 mb-3 p-1 text-center">
                  <div className="shadow bg-white io5  p-1">
                    <p className="mb-1">Rejected</p>
                    <h6>{internship.rejected}</h6>
                  </div>
                </div>
              </div>

              <div className="flex-wrap d-none d-sm-flex">
                <div className="col-3 mb-2 p-2 text-center">
                  <Link to={"/my-internship/" + internship_id + "/feed"}>
                    <Chip
                      type="button"
                      label="Updates"
                      className="w-100  bg-maroon"
                    />
                  </Link>
                </div>

                <div className="col-3 mb-2 p-2 text-center">
                  <Link to={"/my-internship/" + internship_id + "/tasks"}>
                    <Chip
                      type="button"
                      label="Tasks"
                      className="w-100 bg-maroon"
                    />
                  </Link>
                </div>

                <div className="col-3 mb-2 p-2 text-center">
                  <Link to={"/my-internship/" + internship_id + "/meetings"}>
                    <Chip
                      type="button"
                      label="Meetings"
                      className="w-100 bg-maroon"
                    />
                  </Link>
                </div>

                <div className="col-3 mb-2 p-2 text-center">
                  <Link
                    to={"/my-internship/" + internship_id + "/achievements"}
                  >
                    <Chip
                      type="button"
                      label="Team"
                      className="w-100 bg-maroon"
                    />
                  </Link>
                </div>
              </div>

              <div className="flex-wrap ">
                <div className="col-3 mb-2 p-2 text-center">
                  <Link to={"/my-internship/" + internship_id + "/attendance"}>
                    <Chip
                      type="button"
                      label="Attendance"
                      className="w-100 bg-maroon"
                    />
                  </Link>
                </div>

                <div className="col-3 mb-2 p-2 text-center">
                  <Link to={"/my-internship/" + internship_id + "/leaves"}>
                    <Chip
                      type="button"
                      label="Leaves"
                      className="w-100 bg-maroon"
                    />
                  </Link>
                </div>

                <div className="col-3 mb-2 p-2 text-center">
                  <Link to={"/my-internship/" + internship_id + "/feed"}>
                    <Chip
                      type="button"
                      label="Saved"
                      className="w-100  bg-maroon"
                    />
                  </Link>
                </div>

                <div className="col-3 mb-2 p-2 text-center">
                  <a href="mailto:report@skillsuprise.com">
                    <Chip
                      type="button"
                      label="Report"
                      className="w-100 bg-maroon"
                    />
                  </a>
                </div>
              </div>

              <hr />
              {authority ? (
                <>
                  <div className="sm-mt-4 mt-4 w-100 sm-p-15">
                    <h4 className="sm-22 mb-3 text-primary">Report to</h4>

                    <div className="flex">
                      <img
                        src={authority.profile_pic}
                        className="authority-dp shadow mt-0"
                      />

                      <div>
                        <h5 className="sm-18">
                          {authority.first_name + " " + authority.last_name}
                        </h5>
                        <h6 className="text-secondary">{authority.position}</h6>
                        <p className="text-secondary">
                          <b>Status : </b>
                          {authority.status}
                        </p>
                      </div>
                    </div>

                    <div className="flex w-100 mt-3 text-center space-between h-fit align-items-center ">
                      <a
                        className="text-dark"
                        href={"mailto:" + authority.company_email}
                      >
                        <EmailIcon className="text-orangered " />
                        <p>Email</p>
                      </a>

                      <a
                        className="text-dark"
                        href={"tel:" + authority.phone_number}
                      >
                        <PhoneInTalkIcon className="text-primary " />
                        <p>Phone</p>
                      </a>
                      <a
                        className="text-dark"
                        target="_blank"
                        rel="noreferrer"
                        href={
                          "https://wa.me/?phone=91" + authority.whatsapp_number
                            ? authority.whatsapp_number
                            : authority.phone_number
                        }
                      >
                        <WhatsAppIcon className="text-success " />
                        <p>WhatsApp</p>
                      </a>

                      <div type="button">
                        <ReportProblemIcon
                          onClick={() => setgeneralreport(true)}
                          className="text-danger "
                        />
                        <p>Report Issue</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid pt-3">
            <div className="flex">
              <Skeleton
                variant="circular"
                className="mr-2"
                width={80}
                height={80}
              />
              <div className="container-fluid">
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </div>
            </div>

            <div className="flex mt-3 container-fluid space-around">
              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>

              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>

              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>
            </div>

            <div className="flex mt-3 container-fluid space-around">
              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>

              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>

              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>
            </div>

            <div className="flex mt-3 container-fluid space-around">
              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>

              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>

              <div className="col-3">
                <Skeleton variant="rectangular" height={50} />
              </div>
            </div>

            <div className="container-fluid">
              <hr />
              <Skeleton variant="text" />
              <div className="flex mt-3 space-around">
                <Skeleton
                  variant="circular"
                  className="mr-2"
                  width={70}
                  height={70}
                />

                <Skeleton
                  variant="circular"
                  className="mr-2"
                  width={70}
                  height={70}
                />

                <Skeleton
                  variant="circular"
                  className="mr-2"
                  width={70}
                  height={70}
                />

                <Skeleton
                  variant="circular"
                  className="mr-2"
                  width={70}
                  height={70}
                />
              </div>
            </div>

            <hr />

            <div className="container-fluid">
              <Skeleton className="col-6" variant="text" />
              <div className="flex mt-3">
                <Skeleton
                  variant="circular"
                  className="mr-2"
                  width={80}
                  height={80}
                />
                <div className="container-fluid">
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
              </div>

              <div className="flex mt-3 container-fluid space-around">
                <div className="col-3">
                  <Skeleton variant="text" />
                </div>

                <div className="col-3">
                  <Skeleton variant="text" />
                </div>

                <div className="col-3">
                  <Skeleton variant="text" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InternOptions;

import React from 'react'
import ENDPOINT from '../../context/EndPoint'
import InternshipTemplate from '../../themes/InternshipTemplate'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import { useContext } from 'react'
import InternsLeaderboard from './InternsLeaderboard'
import { AuthContext } from '../../context/AuthContext'

const InternAchievements = ({timeline}) => {

     useEffect(() => {
          window.scrollTo(0, 0)
         
        }, [])
      


const {user} = useContext(AuthContext)



     
    const params = useParams();
    const internship_id = params.internshipid;
   
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    
    
    
    
    
    
    
    
    const [badges,setbadges] = useState(null);
    
    useEffect(()=>{
       
    
        const  getbadges = async ()=>{
      const result = await axios.post(`${ENDPOINT}internship-achievements.php?session=${user.SessionId}&internship_id=${internship_id}`)
       if(result.data!==null){
          //  // console.log("achievements "+JSON.stringify(result.data))
          setbadges(result.data.badges);
         
      }
                        
            
          
            
            }    ;
    getbadges();
           
    }
    
    ,[])
    
    

    
    
     return (
          <>
        <>
              
              
                
{badges?

<>

<div className="pt-4">

<div className="sm-p-15">
<h4 className="sm-p-18">Achievement Badges</h4>

</div>

<div className="flex-wrap mt-3 mb-3">
{badges?
badges.map((badge)=>(
<>
<div className="col-lg-4 col-4 p-2">
     <div className="shadow text-center">
<h6>{badge.title}</h6>

     </div>
</div>

</>

))
:

<>
<div className="container-fluid sm-p-15">

     <div className="text-center">
          <h4>No Achievement Badges</h4>
          <p >No achievement badges were added to this internship. Request your reporting authority to add milestones and badges.</p>
     </div>
</div>


</>
}























</div>












</div>   
</>
:
<>


</>

} 

<InternsLeaderboard/>


              </>
``
          </>
     )
}

export default InternAchievements

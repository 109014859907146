import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DailyReport from './DailyReport';
import { Link } from 'react-router-dom';
import ENDPOINT from '../../../context/EndPoint';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';

const DailyReports = () => {
  // Assuming you have a state to store the reports
  const [reports, setReports] = useState(null);
const {user} = useContext(AuthContext)
  // Assuming you have a function to fetch reports from the PHP endpoint
  const fetchReports = async () => {
    try {

      const data = new FormData()
      data.append("session",user.SessionId)
      // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint URL
      const response = await axios.post(ENDPOINT+"admin-get-daily-reports.php");
   
 if(response){
  
  if(response.data&&response.data.reports!==null){
    setReports(response.data.reports); // Assuming the response has a 'reports' key
  
  }}
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  useEffect(() => {
    // Fetch reports when the component mounts
    fetchReports();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <Link to="/internal/daily-reports/add" className="btn btn-primary">
          Add Report
        </Link>
      </div>

      <div className="container-fluid sm-p-15">
        {/* Map through the reports array and render DailyReport components */}
    {reports?
    <>
    
    {reports.map((report) => (
          <DailyReport key={report.id} report={report} />
        ))}
    </>:
    <>
    
    </>}
      </div>
    </div>
  );
};

export default DailyReports;

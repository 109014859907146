import React, { useContext } from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'
import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'
import dateFormat from "dateformat";
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import ENDPOINT from '../../context/EndPoint'
import SubmitInternshipTask from './SubmitInternshipTask'
import { AuthContext } from '../../context/AuthContext'
import InternWorkActivityMap from './InternWorkActivityMap'
import workinggif from '../../assets/gif/working.gif'
const InternAttendance = ({account,timeline}) => {
     useEffect(() => {
          window.scrollTo(0, 0)
         
        }, [])
      
const {user} = useContext(AuthContext)
     const params = useParams();
     const internship_id = params.internshipid;
   //  // console.log("internship id"+internship_id);

     useEffect(() => {
          window.scrollTo(0, 0)
         
         workingStatus();
        }, [])

const [tasks,settasks] = useState(null)

const [status,setstatus] = useState("all");

   



          
const openSubmission = ()=>{
     setsubmit(true)
}

const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)

const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     p: 4,
   };
       















const [loading,setloading] = useState(false)


   const updateWork = async ({status})=>{
        
setloading(true)
     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     
     formData.append('session',user.SessionId);
     

     formData.append('attendance',status)

     formData.append('internship_id',internship_id)
     
     
     const res = await axios.post(ENDPOINT+"start-working.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
          setloading(false)
          workingStatus()
    //  // console.log('attendance '+JSON.stringify(res.data))
      if(res.data.status==="success" && res.data.message==="start"){
     
      }
      else{
     
      }
     }
     }

     const [startdate,setstartdate] = useState("2022-01-01");
const [mapdays,setmapdays] = useState([
     { date: '2022-01-01', count: 12 ,tasks:2},
     { date: '2016-01-22', count: 122,tasks:5 },
     { date: '2022-01-30', count: 38,tasks:8 },
     // ...and so on
   ])
    const [enddate,setenddate] = useState("2022-04-30")
const [workstatus,setworkstatus] = useState("not_working")
const [workdays,setworkdays] = useState(null)
const [statistics,setstatistics] = useState(null) 
const workingStatus = async ()=>{
        
      const formData = new FormData();
          
          
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          
     
     
          formData.append('internship_id',internship_id)
          
          
          const res = await axios.post(ENDPOINT+"working-status.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
           // console.log('status '+JSON.stringify(res.data))
           if(res.data.status==="success"){
                setstatistics(res.data)
          setworkstatus(res.data.working_status)
          setworkdays(res.data.work_days)
          setmapdays(res.data.map_days)
          setstartdate(res.data.today_date);
          setenddate(res.data.previous_date)
           }
        
          }
          }
     





     return (
          <>
          <>
              




              <Modal
  open={submit}
  onClose={()=>{setsubmit(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
   <>
   <div className="flex space-between">
         <h5>Submit Task</h5> 
    </div>

    <SubmitInternshipTask id={taskid}/>
   </>
  </Box>
</Modal>







<div className="container sm-p-15 pt-3">
<h4>Work History</h4>

<hr/>

{workdays?
<>
<InternWorkActivityMap startdate={startdate} enddate={enddate} mapdays={mapdays}/>


</>
:
<>
</>}
<div className="flex-wrap pt-3 space-between">

<div className="col-lg-4 col-4 p-2">


<div className="shadow br-4 io1 p-3">
<h6 className="text-truncate truncate-1">Total Work Days</h6>
<p  className="mb-0">{workdays?workdays.length:0}</p>
</div>
</div>

<div className="col-lg-4 col-4 p-2">


<div className="shadow br-4 io2 p-3">
<h6  className="text-truncate truncate-1">Total Active Hours</h6>
<p  className="mb-0">{statistics&&statistics.total_hours>0?Math.round(statistics.total_hours):0} </p>
</div>
</div>

<div className="col-lg-4 col-4 p-2">


<div className="shadow br-4 io3 p-3">
<h6  className="text-truncate truncate-1">Leaves Taken</h6>
<p  className="mb-0">{statistics&&statistics.total_leaves>0?statistics.total_leaves:0}</p>
</div>
</div>

</div>



<div className="flex p-2 space-between mt-3 mb-3">

{workstatus==="ended_today"?

<>
<button disabled  className="btn btn-primary col-lg-5 col-6 mr-1">Start Working</button>
<button  disabled  className="btn btn-danger col-lg-5 col-6 ">Logout From Work</button>


</>
:<>
{workstatus==="working"?

<>

<button disabled className="btn btn-primary col-6 col-lg-5  w-100  mr-2">Start Working</button>

<button  onClick={()=>updateWork({status:"end"})}  
className="btn btn-danger h-fit w-100 col-6 col-lg-5">{loading?"Updating...":"Logout from work"}</button>


</>
:<>



<button onClick={()=>updateWork({status:"start"})} className="btn btn-primary  w-100  mr-2">{loading?"Updating...":"Start Working"}</button>
  <button   className="btn btn-danger  w-100 ">Logout From Work</button>



</>
}

</>
}


    
</div>




</div>
<div className="container">

     
<div className="flex-wrap sm-p-15 mt-4">

{workdays?
<>
{workdays.map((work,index)=>(

<div className="container-fluid p-2">
     <div className={"shadow p-3 work work"+index+((index%2)===0?" workeven":" workodd")}>
     
     <b> <p>{workdays.length-index} : {dateFormat(work.start_time, "dS mmmm, yyyy")}</p></b>
    <div className="flex space-between">
    <p  className="mb-0">Start Time: {dateFormat(work.start_time, "h:MM TT")}</p>
    <p className="mb-0">End Time: {work.end_time?dateFormat(work.end_time, "h:MM TT"):"Working..."}</p>
    </div>
     </div>
</div>



))}
</>
:null}










</div>


</div>











              </>
          </>
     )
}

export default InternAttendance

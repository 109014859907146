import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Avatar, Chip, TextField } from '@material-ui/core'
import axios from 'axios'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';




import { AuthContext } from '../../../context/AuthContext'
import InternshipItemSkeleton from '../../../skeleton/InternshipItemSkeleton'
import ENDPOINT from '../../../context/EndPoint'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close'
import {  Container, Toast } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import dateFormat from 'dateformat'
import { useParams } from 'react-router-dom'
import InternAddMeeting from '../internships/InternAddMeeting'
import InternAddTask from '../internships/InternAddTask'
import InternSendNotification from '../internships/InternSendNotification'
import InternScheduleInterview from '../internships/InternScheduleInterview'
import { ChevronDown } from 'react-bootstrap-icons'
import { AccountCircleOutlined, CheckCircleOutlineOutlined, CloseOutlined, DoneAllOutlined, GroupOutlined, InfoOutlined, MoreVert, RemoveCircleOutlineOutlined } from '@material-ui/icons'
import { NotificationAddOutlined, PersonAddAlt1Outlined } from '@mui/icons-material'
import InternshipApplicant from '../internships/InternshipApplicant'
import SecurityHeaders from '../../../context/SecurityHeaders'
const StudentInternshipApplications = ({ page}) => {
const params = useParams()

const {user} = useContext(AuthContext)

const [category,setcategory] = useState("all")
useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [internships,setinternships] = useState("start");

const [internshipid,setinternshipid] = useState(null)









    const getinternships = async (status)=>{
        

      const formData = new FormData();
      
      
      //formData.append('whyus',document.getElementById('whyus').value)
      
      formData.append('session',user.SessionId);
      
        formData.append('status',status);
      
        formData.append('category',category);
      if(query!==null){
        formData.append('query',query);
      
      }
      
      const res = await axios.post(ENDPOINT+"admin-internship-applications.php", formData,{
          headers: {
              'content-type': 'multipart/form-data'
          }
      });
      if(res){
       
       if(res.data.status==="success"){
        setinternships(res.data.data)

        setInitialData(res.data.data);
        setResponse(res.data.data);
       }
       else{
      
       }
      }
      }










        





    useEffect(()=>{
     
  
    getinternships();

           
    }
    
    ,[category])
    
    
    const [query,setquery] = useState(null)
 
    const  updateInternship = async ({iid,istatus})=>{
console.log("hello")
      const data = new FormData();
      data.append('session',user.SessionId);
      data.append('application_id',iid);
      data.append('internship_status',istatus)
      const res = await axios.post(`${ENDPOINT}update-intern-status.php`,data,SecurityHeaders);
      if(res.data.status==="success"){
        getinternships()
settoasttitle("Application "+istatus+iid);
settoastmessage("Successful")
settoast(true)

     }
      

        
        }    ;
    
    
    
    


    


const [toast,settoast] = useState(false)
const [toasttitle,settoasttitle] = useState(null)
const [toastmessage,settoastmessage] = useState(null)
const [currentintern,setcurrentintern] = useState(null)
const [schedule,setschedule] = useState(false)
const [addtask,setaddtask] = useState(false)
const [notification,setnotification] = useState(false)
const [interview,setinterview]= useState(false)
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
 minWidth:400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};









const [workhistory,setworkhistory] = useState(null)
//delete task code
const [history,sethistory] = useState(false)
const WorkHistory = async ({internship_id,intern_id})=>{

  const formData = new FormData();
  formData.append('session',user.SessionId);
  formData.append('intern_id',intern_id);
  formData.append('internship_id',internship_id);
  
  const res = await axios.post(ENDPOINT+"admin-get-work-history.php",formData,{
    headers:{
      'content-type': 'multipart/form-data'
    }
  });
  
  if(res){
    // console.log("work "+JSON.stringify(res))
  }
  if(res.data.status==="success"){
   setworkhistory(res.data.work_days)
   sethistory(true)
  }
  else{
    
  }
  }



const [currentdp,setcurrentdp] = useState(null);
const [currentname,setcurrentname] = useState(null)


const [details,setdetails] = useState(false)


const ToggleMore = ({id})=>{
const application = document.getElementById(id).classList;

if(application.contains("d-block")){
  application.replace('d-block','d-none')
}
else{
  application.replace('d-none','d-block')
}

}


const [initialData,setInitialData] = useState('')


const [response, setResponse] = useState(initialData);
const [searchTerm, setSearchTerm] = useState('');

const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();

  const filteredData = initialData.filter((item) => {
    return (
     
      item.phone_number.toLowerCase().includes(term) ||
        item.title.toLowerCase().includes(term) ||
        item.position_title.toLowerCase().includes(term) 
       
    );
  });

  setResponse(filteredData);
  setSearchTerm(term);
};

    
    return (
        <>


<Modal
  open={schedule}
  onClose={()=>{setschedule(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box  sx={style}>
   <>
   <div className="flex space-between">
     <h4>Schedule Meeting</h4>
     <CloseIcon type="button" onClick={()=>setschedule(false)}/>
     </div>
<InternAddMeeting intern_id={currentintern} internship_id={internshipid}/>
   </>
  </Box>
</Modal>



<Modal
  open={interview}
  onClose={()=>{setinterview(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box  sx={style}>
   <>
   <div className="flex space-between">
     <h4>Schedule Interview</h4>
     <CloseIcon type="button" onClick={()=>setinterview(false)}/>
     </div>
<InternScheduleInterview intern_id={currentintern}/>
   </>
  </Box>
</Modal>


<Modal
  open={addtask}
  onClose={()=>{setaddtask(false)}}
  aria-labelledby="modal-modal-title"
  size="xl"
  aria-describedby="modal-modal-description"
>
  <Box  sx={style}>
   <>
<InternAddTask intern_id={currentintern} internship_id={internshipid}/>
   </>
  </Box>
</Modal>


<Modal
  open={history}
  onClose={()=>{sethistory(false)}}
  className="container-fluid "
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box  className="col-lg-8 bg-white vh-100 my-auto overflow-y mx-auto">
   <>



{workhistory?

<>


<div className="container-fluid overflow-y pt-3">

<div className="container-fluid align-items-center bg-primary p-2 mb-3 text-white flex space-between">

<div className="flex align-items-center col-lg-5 col-sm-6">

<Avatar src={currentdp} className="mr-2"/>
<div >
<h4 className="mb-0">{currentname}</h4>
<h6>Work History ( {workhistory.length} )</h6>

</div>

</div>
<CloseIcon type="button" className="text-danger" onClick={()=>sethistory(false)}/>
</div>





{workhistory.map((work,index)=>(
<>

<div key={index} className="container-fluid mb-0 mt-1 ">


<div className="flex space-between">
<div className="col-5">
<p><b>LoggedIn : </b>{dateFormat(work.start_time, "dS mmm yy hh:mm:ss tt")}
</p>
<p><b>Logged Out : </b>{work.end_time!==null?dateFormat(work.end_time, "hh:mm:ss tt"):"Working..."}
</p>
</div>


<div className="col-5">
<p><b>Active Hours : </b>{work.end_time!==null?work.duration:"Working..."}
</p>
<p><b>Tasks Done : </b>{work.end_time!==null?work.duration:"Working..."}
</p>
</div>

</div>

</div>
<hr/>
</>

))}

     
</div>

</>

:<>

</>

}
   </>
  </Box>
</Modal>


<Modal
centered
  open={notification}
  onClose={()=>{setnotification(false)}}
>
  <Box  sx={style}>
   <>
<InternSendNotification intern_id={currentintern}/>
   </>
  </Box>
</Modal>


<div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>
           
<div className=" container-fluid min-vh-100">


<Container fluid className="mb-3">
      
      <div>
   
      <input
        placeholder="Search title, position title "
        variant="outlined"
      className='fixed-search'
        value={searchTerm}
        onChange={handleSearch}
      />
    
    </div>
            </Container>
<div className="  sm-p-15  container-fluid">

{ response==="start"?
<div className="container-fluid">

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

<InternshipItemSkeleton/>

    </div>

:response?response.map((response,index)=>(

<>
<InternshipApplicant internship={response} index={index}/>
</>
)):
<div className="container-fluid">

No Internships

    </div>

}





</div>




</div>

   



        </>
    )
}

export default StudentInternshipApplications


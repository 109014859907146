import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext } from 'react';
import {useState} from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import ENDPOINT from '../../../context/EndPoint'
import InternalTemplate from '../../../themes/InternalTemplate'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { CopyAll } from '@mui/icons-material';
import { MoreVert } from '@material-ui/icons';
import { Calendar2, Upload } from 'react-bootstrap-icons';
import { Chip } from '@mui/material';
const InternalAssignmentsList = () => {
const params = useParams();
const course_url = params.courseurl;
const batch_id = params.batch_id
const [chapters,setchapters] = useState(null)
const {user} = useContext(AuthContext)




const getChapters = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);

  formData.append('course_url',course_url);
formData.append("batch_id",batch_id)

const res = await axios.post(ENDPOINT+"admin-get-course-assignments.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){
 if(res.data.status==="success"){
  setchapters(res.data.data)
 }
 else{

 }
}
}






const addChapter = async (status)=>{
        

     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     formData.append('session',user.SessionId);
     
       formData.append('course_url',course_url);
       formData.append('chapter_title',ctitle);
       formData.append('chapter_id',currentchapter);
     
     const res = await axios.post(ENDPOINT+"admin-add-chapter.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
      if(res.data.status==="success"){
      // setchapters(res.data.data)
      setctitle(null)
      setcurrentchapter(null)
      getChapters()
      }
      else{
     
      }
     }
     }
     
const [edit,setedit] = useState(false)
const [ctitle,setctitle] = useState(null);
const [currentchapter,setcurrentchapter] = useState(null)

useEffect(()=>{

getChapters();
},[])









const deleteChapter = async ({assignment_id})=>{
        

     const formData = new FormData();
     
     
     //formData.append('whyus',document.getElementById('whyus').value)
     formData.append('session',user.SessionId);
     
       formData.append('assignment_id',assignment_id);
     
     const res = await axios.post(ENDPOINT+"admin-delete-assignment.php", formData,{
         headers: {
             'content-type': 'multipart/form-data'
         }
     });
     if(res){
      if(res.data.status==="success"){
      // setchapters(res.data.data)
      getChapters();
      }
      else{
     
      }
     }
     }
     

useEffect(()=>{

getChapters();
},[])








const style = {
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: 'background.paper',
     border: '2px solid #000',
     boxShadow: 24,
     pt: 2,
     px: 4,
     pb: 3,
   };
   







  return (
<>




<div className="container-fluid mt-2">





<Modal
  open={edit}
  onClose={()=>setedit(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...style, width: 500 }}>
  <p>Title  :</p>
    <input className="form-control"  value={ctitle} onChange={(e)=>{setctitle(e.target.value)}}></input>
    <br/>
    <p>Chapter No:</p>
    <input className="form-control" value={currentchapter} onChange={(e)=>{setcurrentchapter(e.target.value)}}></input>
    
   
 
<button className="btn btn-primary w-100 mt-3" onClick={addChapter}>Update Topic</button>
  </Box>
</Modal>









<div className="flex-wrap d-flex justify-content-between">

<h1>Assignment management</h1>

  
     <Link className="btn h-fit btn-primary" to={"/internal/add-assignment/"+course_url+"/"+batch_id} >
<p>Add Assignment</p></Link>
    
</div>
<hr></hr>
<div className="d-flex flex-wrap">
{chapters?



<>

{chapters.map((chapter)=>(

<>

<div class="col-lg-6 p-2">
     <div class="rounded shadow p-3 hover-active">
       <div class="d-flex justify-content-between">
       <h4>{chapter.assignment_id}:{chapter.title}</h4>
        <Dropdown className="options-dropdown">   
        <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
        <MoreVert className="text-dark"/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Dropdown.Item  onClick={()=>{setctitle(chapter.chapter_title);setcurrentchapter(chapter.chapter_id);setedit(true)}}><EditIcon className='mr-1'/>Edit</Dropdown.Item>
        <Dropdown.Item  onClick={()=>deleteChapter({assignment_id:chapter.assignment_id})}  ><DeleteIcon className='mr-1'/>Delete</Dropdown.Item>
         
       </Dropdown.Menu>
                   </Dropdown>
       </div>
       <p>{chapter.description}<a class=" text-dark"></a></p>
      <div class="d-flex justify-content-between">
      <div class="d-flex align-items-end "><Calendar2 className='mr-1 mb-1'/><p class="mb-0"><b>Deadline : </b>5th april</p></div>
      <button class="btn bg-warning"><Upload className='mr-1'/>Problem statement</button>
        </div>    
         </div>

  </div>


</>


))}

</>
:"no chapters"}


</div>
</div>

</>

  )
};

export default InternalAssignmentsList;

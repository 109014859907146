import axios from 'axios'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import ENDPOINT from '../../context/EndPoint'
import { AuthContext } from '../../context/AuthContext'
import { Dropdown, Toast } from 'react-bootstrap'
import { MoreVert, Visibility } from '@material-ui/icons'
import { Avatar } from '@material-ui/core'
import SecurityHeaders from '../../context/SecurityHeaders'
import TextField from '@mui/material/TextField';
import { Container } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import WhatsAppMessageModal from '../../components/modals/WhatsAppMessageModal'
import HackingWorkshopRegistrationItem from './workshops/HackingWorkshopRegistrationItem'




const InternalHackingScholarshipRegistrations = () => {

    const {user} = useContext(AuthContext);

   
   const [toast,setToast]= useState(false)
   const [message,setMessage] = useState("")
  
   
   // Sample array of user IDs
   const users = [];
   
    const [registrations,setRegistrations] = useState(null)

    const getRegistrations = async ()=>{
        const URL = `${ENDPOINT}admin-get-hacking-workshop.php?session=${user.SessionId}`;
        fetch(URL)
        .then(r=>r.json())
        .then(result=>{
       
         
                setRegistrations(result.data);
                setInititalData(result.data)
                setResponse(result.data)
                
       
   // Sample array of user IDs
   result.data.map((item)=>(
    users.push(item.id)
    ))
        console.log(users)    
        })

    }

    useEffect(() => {
  

        getRegistrations()
     
    }, [])

    const [sendMessage, setSendMessage] = useState('');
  
  // Load the saved message from local storage when the component mounts

  // Update the message state when the input field value changes
  const handleChange = (e) => {
    setSendMessage(e.target.value);
  };
  
  // Save the message to local storage
  const saveMessageToLocalStorage = () => {
    localStorage.setItem('wa_message', sendMessage);
  };

  const [initialData,setInititalData] = useState(null)
  
  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.email.toLowerCase().includes(term) ||      
        item.name.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  const [selecteditems,setselecteditems] = useState([])
  useEffect(()=>{
    console.log(selecteditems)
  },[selecteditems])
   const [visibility,setvisibility] = useState(false)

   const [selectedData, setSelectedData] = useState([]);

   // Function to handle user click
   const handleUserClick = (id) => {
     // Check if the user ID is already in the selectedData array
     const isSelected = selectedData.includes(id);
 
     // If selected, remove from the array; otherwise, add to the array
     setSelectedData((prevSelectedData) => (
       isSelected
         ? prevSelectedData.filter(uid => uid !== id)
         : [...prevSelectedData, id]
     ));

     console.log(selectedData)
   };
 
  return (
    <>


<Container fluid className="mb-2">
      
      <div>
      
      <input
        placeholder="Search name, email "
       className="fixed-search"
        value={searchTerm}
        onChange={handleSearch}
      />
    
    </div>
            </Container>

<div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{setToast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Status change</strong>
            
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
</div>


    {
        response? 
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
  <TableRow>
    <TableCell>User</TableCell>

    <TableCell>Phone</TableCell>
    <TableCell align="left">Date</TableCell>
  
    <TableCell align="left">Status</TableCell>
    <TableCell align="left">Certificate</TableCell>
 
    <TableCell align="right">Actions</TableCell>
  </TableRow>
</TableHead>
<TableBody>{
     response.map((registration,index)=>(
      <>
   <HackingWorkshopRegistrationItem handleSelection={(id)=>handleUserClick(id)} index={index} registration={registration}/>

</>

  ))
  }

</TableBody>
</Table>
        
     :<></>
    }


    </>
  )
}

export default InternalHackingScholarshipRegistrations
import ChatIcon from '@mui/icons-material/Chat';
import React,{memo} from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import homeicon from '../../assets/iconsv2/home.svg'
import caicon from '../../assets/iconsv2/cai.svg'
import howicon from '../../assets/iconsv2/how.svg'
import specializationicon from '../../assets/iconsv2/specialization.svg'
import offlinetraining from '../../assets/iconsv2/offline-training.svg'
import companyicon from '../../assets/iconsv2/company-icon.svg'
import accounticon from '../../assets/iconsv2/account.svg'
import workshopicon from '../../assets/iconsv2/workshop.svg'
import certificateicon2 from '../../assets/iconsv2/certificate-icon.svg'
import versionicon from '../../assets/iconsv2/version.svg'
import reviewsicon from '../../assets/iconsv2/reviews-icon.svg'
import termsicon from '../../assets/iconsv2/terms.svg'
import customersupport from '../../assets/iconsv2/customer-support-icon.svg'
import refundicon from '../../assets/iconsv2/refund.svg'
import faqicon from '../../assets/iconsv2/faq-icon.svg'
import advantageicon from '../../assets/iconsv2/advantage.svg'
import shareicon from '../../assets/iconsv2/share-icon.svg'
import scholarshipicon from '../../assets/imgv2/scholarship-test.svg'

import privacyicon from '../../assets/iconsv2/privacy.svg'


import internshipsicon from '../../assets/iconsv2/internships.svg'


import videosicon from '../../assets/iconsv2/videos.svg'
import mycourseicon from '../../assets/iconsv2/my-course.svg'
import selfpacedicon from '../../assets/iconsv2/selfpaced-training.svg'
import dashboardicon from '../../assets/iconsv2/dashboard.svg'
import livetrainingsicon from '../../assets/iconsv2/live-class.svg'
import { Form, FormControl, Navbar} from 'react-bootstrap'
import userdp from '../../assets/bg/bhuvanasri.jpg'
import { Link } from 'react-router-dom'
import NavBarTitles from './NavBarTitles'
import { useEffect } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { useContext } from 'react'
import { useState } from 'react'
import GridViewIcon from '@mui/icons-material/GridView';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { AuthContext } from '../../context/AuthContext'
import AccountOptions from '../../pages/account/AccountOptions'
import ENDPOINT from '../../context/EndPoint'
import ReactGA from 'react-ga'
import { NotificationsActiveRounded, Search } from '@material-ui/icons'
import { Badge, Box,  Modal} from '@material-ui/core'
import axios from 'axios'
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import Localbase from 'localbase';
import BottomNavigationBar from './BottomNavigationBar';
const TopNavBar = ({props,account,unreadnotifications,notifications}) => {


 const {user} = useContext(AuthContext);

const [profile,setprofile] = useState(account)

let db = new Localbase('db')
  const [showSearch, setshowSearch] = useState(false);
      
      const pathname = window.location.pathname;
useEffect(()=>{
  if(showSearch){
    setshowSearch(false)
  }else{

  }
},[pathname])
useEffect(()=>{
  if(explore){
    setexplore(false)
  }
},[pathname])

        useEffect(()=>{
       
  

db.collection('paths').doc('user').get().then(user=>{
setprofile(user.user)
}).catch(error=>{

})







db.collection('paths').doc('notifications').get().then(notifications=>{
setmessages(notifications.unread_messages)
}).catch(error=>{

})
       
      getGA();
             
      }
      
      ,[])
      
      
      const  getGA = async ()=>{
        
            
              
        ReactGA.initialize('UA-166108797-3');
        ReactGA.pageview(window.location.pathname);
        
                          
                 
                      
                      }    ;


  const width = window.innerWidth;
const [menustatus,setmenustatus] = useState(false);

const togglemenu=()=>{

  
 if(window.location.href.includes("/watch/")){

 }else{
  var sidebar = document.getElementById("sidebar");
  if(sidebar.classList.contains("sidebardisplay")){
setmenustatus(false)
    sidebar.classList.remove("sidebardisplay");
  }
  else{
    setmenustatus(true)
    sidebar.classList.add("sidebardisplay");
  
  }
 }



}


useEffect(()=>{
if(width<576){



  if(window.location.href.includes("/watch/")){

  }else{
    var sidebar = document.getElementById("sidebar");
 
    setmenustatus(false)
      sidebar.classList.remove("sidebardisplay");
  }

}
},[window.location.pathname])




const dashboard = ()=>{
localStorage.setItem("state","dashboard");

}




const [modal,setmodal] = useState(false)

const [websiteversion,setwebsiteversion] = useState(null)


const [versiondata,setversiondata] =useState(null)

// useEffect(()=>{



// try{

//   fetch(`${ENDPOINT}version.php?limit=1`)
//       .then(r=>r.json())
//       .then(result=>{
         
      
//       if(result){


     
   

  
//   setversiondata(result.data[0])


//   if(document.onloadeddata){
//     const thiswebsiteversion = document.getElementById("version").innerHTML;

//     setwebsiteversion(thiswebsiteversion)
           
//             if(result.data[0].version){
              
//               if(thiswebsiteversion!==result.data[0].version){
//                 serviceWorkerRegistration.unregister();
              
//                 setmodal(true)
                
              
                
//               }
//             else{
            
//               serviceWorkerRegistration.register()
//             }
            
            
//             }
            
//   }
      



//          }   
//       })
// }
// catch(err){
 
// }

  
// },[])


const searchstyle = {
  position: 'absolute',


  bgcolor: 'background.paper',
 
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',

  p: 4,
};
const search = [];const [query,setquery] = useState(null)
const startSearch = (e)=>{
  e.preventDefault()


  window.location.replace("/search/results/courses/"+query)
}










        
const [initialized, setInitialized] = useState(false);






const [messages,setmessages] = useState(null)













  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


const [trainings,settrainings] = useState(false)
const [explore,setexplore] = useState(false)


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));




    return (
        
        <div class="d-block nav-container  bg-theme"  expand="lg" >
        <div className="container-xl sm-p-15 justify-content-between primary-nav align-items-center flex nowrap">

<NavBarTitles />

{/*
<div className="search flex border-1 align-items-center sm-d-none">
<Search className="text-dark"/>
<input type="text" placeholder="Search..." className="bg-transparent text-dark" onClick={openSearch}></input>

</div>



*/}


<Modal
 keepMounted 
className="justify-center mt-4 pt-5"
  open={showSearch}
  staticbackdrop
  onMouseOver={()=>setshowSearch(true)}
  
  onClose={()=>setshowSearch(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box onMouseLeave={()=>setshowSearch(false)} className="col-lg-12 h-fit mx-auto p-3 bg-white"   >
    
  </Box>
</Modal>





      <Modal
  open={modal}
  
  onClose={()=>setmodal(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box  sx={{ ...style, width: 400 }} className="col-lg-4 bg-white" >
    <div className="flex space-between">
    <h4 className="sm-22 text-danger">New update available !</h4>
  

     <CloseIcon className="text-danger" type="button" onClick={()=>setmodal(false)}/>
   </div>
    <p>{versiondata?versiondata.update_note:null}</p>
  </Box>
</Modal>




           <div class="navbar-options align-center">
          
          
      
          
           <div class="nav-items">


          
           <p id="version" >2.02</p>
  

           <Link to="/">Home</Link>
{user!==null?   

<Link to="/dashboard" onclick={dashboard}>Dashboard</Link>:""}
        
     
<div className="d-block">

     
     
     
      <Menu
  
    
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
  
          <MenuItem
            key={1}
           
         
           
          >
        <div className="explore p-3">
         < h3>hello</h3>
          
          
          </div> 
          </MenuItem>
        

       
      </Menu>
    </div>
 
    
   



<Link className="mr-4" to="/work">Work</Link>


           <Link to="/about">Contact Us</Link>

       
  
      <>
      
      

  
         
      </>  
  
       




           </div> 


  
<div className="flex  d-sm-flex d-md-flex">

     {messages>0?
        
      
      <>


<Link className="mr-3 text-dark d-md-none" to="/search">
<Search/>
</Link>


<Link to="/chat">
<Badge type="button" badgeContent={messages} color="secondary">
      <ChatIcon />
</Badge>
</Link>
     

      </>:
      <>

<Link className="mr-3 text-dark d-md-none" to="/search">
<Search/>
</Link>

</>
      }
       
       {user?
<>

<Link className="ml-3" to="/notifications">
     {/*get notifications realtime*/}
     <Badge  badgeContent={unreadnotifications}   type="button" color="secondary">
     <NotificationsActiveRounded />
 </Badge>
 </Link>
</>
:null}      
     
    
</div>
   
  



         {/*  <img className="search-icon" type="button" onClick={openSearch} src={searchicon} alt="search"></img>*/}
 {user?
"" 
    :
    
    <div className="nav-signup d-none d-sm-flex btn ml-3 ">
      
      
      
      <Link to="/login" class="animated-button1 ab2">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <svg className="mr-2" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path></svg>
     
  Login 
</Link>

<Link to="/signup" class="animated-button1 ab1">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <svg className="mr-2" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path></svg>
     
 SignUp
</Link>



    </div>
    
    }
        
          {profile?
      

<AccountOptions userdp={userdp} account={profile} type="button" />
           :
         
      <>
  <Link to="/login" className="btn d-sm-none btn-danger">Login/SignUp</Link>     
      

      </>
      
      }
           
      
           <div class="toggle-menu mb-2" type="button" onClick={togglemenu}>
         
          
         {menustatus?
        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="CloseIcon" tabindex="-1" title="Close"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
         :
         
         <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="MenuIcon" tabindex="-1" title="Menu"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
         }
           </div>
           </div>
         
        </div>
   

   <BottomNavigationBar/>
      </div>
      
    )
}

export default memo(TopNavBar)


const RoutesList  = [
    { path: 'users', name: 'Users List', command: 'Users List , user list' },
    { path: 'internal/classes', name: 'All classes', command: 'All classes classes list upcoming classes' },
    { path: 'internal/trainings-management/courses/all', name: 'Courses Management', command: 'all courses courses management courses list' },
    { path: 'internal/sales-summary/all', name: 'Users Management', command: 'users management users list all users uses all uses' },
    { path: 'internal/students-tasks', name: 'Assignment submissions', command: 'Assignment submissions' },
    { path: 'internal/finance-summary', name: 'Finance Summary', command: 'Finance Summary' },
    { path: 'internal/finance-summary', name: 'Sales Overview', command: 'Sales Overview' },
    { path: 'internal/support/all', name: 'customer support', command: 'customer support' },
    { path: 'internal/doubts/all', name: 'open student support', command: 'open student support' },
    { path: 'internal/finance-summary', name: 'Courses Enrollments', command: 'Courses Enrollments' },
    { path: 'internal/finance-summary', name: 'Users Management', command: 'Users Management' },
    { path: 'internal/student-management/all', name: 'Students Management', command: 'Students Management student management ' },
    { path: 'internal/trainings-management/courses/all', name: 'Courses Management', command: 'Courses Management' },
    { path: 'internal/internship-applications/all', name: 'Internship applications', command: 'Internship applications' },
    // Add more routes as needed
    { path: 'students', name: 'students list', command: 'students list' },
    { path: 'enrollments', name: 'Course registrations', command: 'Course registrations' },
    { path: 'courses', name: 'Courses', command: 'Courses' },
    { path: 'notifications', name: 'Notifications', command: 'Notifications' },
    { path: 'courses', name: 'courses list', command: 'courses list' },
    // Add more routes as needed
  ];
  

export default RoutesList
import React from 'react'
import CircularProgressBar from '@mui/material/CircularProgress';
const CircularProgress = () => {
  return (
    <>

<div className="text-center">
<CircularProgressBar/>
</div>

    </>
  )
}

export default CircularProgress
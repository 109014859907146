
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react'

import { useParams } from 'react-router'
import {useState,useEffect} from 'react'
import axios from 'axios'
import ENDPOINT from '../../../context/EndPoint'

import { AuthContext } from '../../../context/AuthContext'
import {  Toast } from 'react-bootstrap'

const InternAddMeeting = ({account,timeline,intern_id,internship_id}) => {
     useEffect(() => {
          window.scrollTo(0, 0)
         
        }, [])
      
const {user} = useContext(AuthContext)
     const params = useParams();
   //  // console.log("internship id"+internship_id);



const [tasks,settasks] = useState(null)

const [status,setstatus] = useState("all");
const [description,setdescription] = useState(null)
const [startdate,setstartdate] = useState(null)
const [enddate,setenddate] = useState(null)
const [begins,setbegins] = useState(null);
const [ends,setends]= useState(null)
const [meetlink,setmeetlink] = useState(null)
const [meetdescription,setmeetdescription]= useState(null)
const [internships,setinternships] = useState(null)
const [meetingtype,setmeetingtype]= useState("General Meeting")
const [currentinternship,setcurrentinternship] = useState(internship_id)
     const scheduleMeeting = async ()=>{
        

          const formData = new FormData();
          
          // console.log(" submit" + currentinternship+" "+startdate+" "+begins+" "+meetingtype)
          //formData.append('whyus',document.getElementById('whyus').value)
          
          formData.append('session',user.SessionId);
          

          formData.append('internship_id',currentinternship)
          if(intern_id){
               formData.append('intern_id',intern_id)
          }
          formData.append('date',startdate)
          formData.append('begins',begins)
           formData.append('ends',ends)
          formData.append('meeting_type',meetingtype)
         
          formData.append('meeting_link',meetlink)
          formData.append('description',description)
          
          const res = await axios.post(ENDPOINT+"admin-add-interns-meeting.php", formData,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          });
          if(res){
     
           if(res.data.status==="success"){
                settoasttitle("Meeting scheduled")
                
        settoast(true)
           }
           else{
               settoasttitle("Something went wrong")
               settoast(true)
           }
          }
          }




          
const openSubmission = ()=>{
     setsubmit(true)
}

const [submit,setsubmit] = useState(false)
const [taskid,settaskid] = useState(null)

const [toast,settoast] = useState(false)


const [toastmessage,settoastmessage] = useState(null)
const [toasttitle,settoasttitle] = useState(null)












     





     return (
          <>
              <>
              

              <div className="position-fixed container-fluid left-0 bottom-60 justify-center">
<Toast className="bg-warning" show={toast} onClose={()=>{settoast(false)}} autohide={true} delay={5000}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toasttitle}</strong>
            
          </Toast.Header>
          <Toast.Body>{toastmessage}</Toast.Body>
        </Toast>
</div>



           
<div>
<h6>Intern  ID: {intern_id}</h6>
<h6>Internship  ID: {internship_id}</h6>
<div className="mt-4">
     <h6>Start Date</h6>
     <input  onChange={(e)=>setstartdate(e.target.value)}  className="form-control" type="date"></input>
</div>
<div className="flex mt-2 space-between w-100">


<div >
     <h6>Begins</h6>
     <input  onChange={(e)=>setbegins(e.target.value)}  className="form-control" type="time"></input>
</div>

<div >
     <h6>Ends</h6>
     <input  onChange={(e)=>setends(e.target.value)}  className="form-control" type="time"></input>
</div>
</div>

<div className="w-100 mt-3">
     <h6>Meeting Type</h6>

     <select  onChange={(e)=>{setmeetingtype(e.target.value)}} className="form-control" >
     <option>Select Options</option>
<option value="General Meeting">General Meeting</option>
<option value="Project Discussion">Project Discussion</option>
<option  value="Review Meeting">Review Meeting</option>
<option  value="Interactive Session">Interactive Session</option>
     </select>

</div>

<input onChange={(e)=>setmeetdescription(e.target.value)} className="form-control mt-3" placeholder="description"></input>
<input  onChange={(e)=>setmeetlink(e.target.value)}  className="form-control mt-3" placeholder="Meeting Link"></input>
<button onClick={()=>scheduleMeeting()} className="btn btn-primary mt-5 w-100">Confirm Schedule</button>
<div>

</div>



</div>
    






              </>
       
          </>
     )
}

export default InternAddMeeting

import React,{memo,useCallback} from 'react'
import  styled  from 'styled-components'
import Footer from '../components/footer/Footer'
import TopNavBar from '../components/navbar/TopNavBar'
import SideBar from '../components/leftsidebar/SideBar'
import { useEffect } from 'react'
import axios from 'axios'
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useContext,useState } from 'react'
import ENDPOINT from '../context/EndPoint'
import queryString from 'query-string';
import { AuthContext } from '../context/AuthContext'
import Localbase from 'localbase'
const HomeTemplate= ({bottomMenu,children,permission,footer, page,props,account}) => {
  const {user} = useContext(AuthContext)


const pathname = window.location.pathname;


useEffect(()=>{

updateActivity();

},[pathname])



const updateActivity = async (status)=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
    if(user!==null){

      formData.append('session',user.SessionId);

  }
  
    formData.append('page',pathname);
  
    formData.append('device',navigator.userAgent);
  
  
  const res = await axios.post(ENDPOINT+"save-activity.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

 
  }
  }


let db = new Localbase('db')

  useEffect(()=>{

db.collection('paths').doc('notifications').get().then(notifications=>{
setunread(notifications.unread_messages)
setnotifications(notifications.notifications)

}).catch(error=>{

})

  //// console.log("sidebar page is "+page)
if(page!==null){
  const option = document.getElementById(page);
  
   //// console.log("changed color")
   if(option!==null){
     
    //option.style.borderLeft = "3px solid blue";
    //option.style.backgroundColor = "#c3dfec";
    //option.style.fill = "blue !important";
   }
   
}
   
   

  
  },[page])
  
  useEffect(() => {
    window.scrollTo(0, 0)

 
  }, [])


  const [time,settime]= useState(0);


  setTimeout(
    () => settime(time+1), 
    
    1000
  );
  
  








  //get user account


  
  useEffect(()=>{
      
    
    
 

if(user!=null||user!=="null"){

  getnotifications();
}

       
}

,[user])



const [notifications,setnotifications] = useState(null);
const [unread,setunread] = useState(0);


const  getnotifications = useCallback(async ()=>{
   
  try{
    fetch(`${ENDPOINT}get-notifications.php?session=${user.SessionId}`)
    .then(r=>r.json())
    .then(result=>{
      
        
   if(result[0].status==="success"){
       
  
    if((result[0].data)!==null){

      db.collection('paths').add({
        notifications:result[0].data,
        unread_messages:result[0].messages
      },'notifications')
 
      //// console.log("notification are "+JSON.stringify(result))
        setnotifications(result[0].data);
     setunread(result[0].unread)
        
    
  
    }
  
   }
                
    
        
    })
  }
  catch{
  
  }
    
    } ,[notifications])








  React.useEffect(() => {
 
    const interval = setInterval(() => {
      getnotifications();

    }, 5000);
  
    return () => {
    
      clearInterval(interval);
    };
  }, []); // has no dependency - this will be called on-component-mount



const toggleDrawer = ()=>{
  setdrawer(!drawer)
}

const [drawer,setdrawer] = useState(false)




function draw() {
  var reveals = document.querySelectorAll(".arc-container");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 250;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active-arc");
    
    } else {
      reveals[i].classList.remove("active-arc");
   

    }
  }
}

useEffect(()=>{
 
  window.addEventListener("scroll", draw);

})














    return (
        <HomeStyled bottommenu={bottomMenu}>


<div className="page-container  ">


<TopNavBar time={time} account={account} unreadnotifications={unread} />




{children}


<Footer bottommenu={bottomMenu} page={page} footer={footer}/>


</div>
        </HomeStyled>
    )
}

export default HomeTemplate


export const HomeStyled = styled.div`
padding: 0px;
margin: 0px;
width: 100% ;
.flex{
    display: flex;
}
.page-container{
  margin-top: 60px;
  width: auto !important;
}

.live-courses, .offline-courses{
   margin-top: 70px;
}

.feature-item{
    margin: 15px;
}
.slider-content{
    height: auto;
   
    
}
.slider-content img{
  width: 100%;
  height: auto;
}
.slide1{
    background-color: blue;
}
.slide2{
    background-color: red;
}
.slide3{
    background-color: orange;
}
.navbar-nav{
    display: flex;
}
.navbar .container{
    display: flex;
}
.home-options{
  
  margin-bottom: 30px;
}
@media only screen and (max-width:576px){
  .text-center{
    font-size: 22px;
  }
 
  .home-options{
    display: flex !important;
  }
 
  .how-number{
    min-width: 30px !important;
    min-height:30px !important;
    
    height: 30px !important;
    width: 30px !important;
    border-radius: 15px;
  }
  .how-number h2{
    font-size:18px !important;
  }
}

@media only screen and (max-width:576px){

.course-item-main{
    width: 100%;
    margin:  0px 10px;
}
.course-item{
display: flex;
min-width: 85%;
width: 100%;
max-width:450px;
margin: 10px 0px;
height: fit-content;
}
.course-image{
width:100px !important;
height: 100px !important;
border-radius: 0px;
}
.course-buttons{
display: none;
}
.courses-list{
justify-content: center !important;
}
.course-prices{
position: relative;

bottom: 0px;
}
.rating-value{
margin-left:0px !important;
}
.cutoff-price{
display: none;
}
.course-prices{
margin-left: 0px !important;
}
.ratings-container{
margin-bottom:0px;
}

.current-price, .cutoff-price{
margin-bottom: 0px;
}
}




`
import React from 'react'
import InternshipTemplate from '../../themes/InternshipTemplate'

const InternVerification = () => {
     return (
          <>
         <InternshipTemplate>
              <>
              
              
                
<div className="">

<div className=" mb-3 flex container-fluid p-3  align-items-center">
<div className="col-4">
<h3>5 Total Tasks</h3>
</div>

<div className="col-5">
     
<input className="form-control" placeholder="Search task by title,description, status etc"></input>

</div>
<div className="col-3">
<select className="form-control">
     <option>All</option>
     <option>Submitted</option>
     <option>Pending</option>
</select>
</div>
</div>









<div className="container-fluid">
     <div className="shadow p-3">
          <h4>This is task 1</h4>
          <hr/>
          <div className="flex">
               <div className="col-4">
<h6>Marks</h6>
<p>100</p>
               </div>

               <div className="col-4">
<h6>Deadline</h6>
<p>100</p>
               </div>

               <div className="col-4">
<h6>Status</h6>
<p>Pending</p>
               </div>


              
          </div>
         <div className="flex space-between">
              <button className="btn btn-primary">Request Support</button>
              <button className="btn btn-warning">Submit Task</button>
         </div>
     </div>
</div>










</div>    
              </>
         </InternshipTemplate>
          </>
     )
}

export default InternVerification

import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import styled from 'styled-components';
import ENDPOINT from '../../../context/EndPoint';
import { CircularProgress } from '@material-ui/core';
const  SubmitTask = ({id})=>{

const [file,setfile] = useState(null)
const [status,setstatus]= useState(false)
 const   UPLOAD_ENDPOINT = `${ENDPOINT}upload-task.php`;
   
   const onSubmit = async (e)=> {
       setstatus(true)
        e.preventDefault() 
        let res = await uploadFile(file);
    

        if(res.data.status==="success"){

        
          window.location.reload()
        }else{
          setstatus(false)
         
   
        }
    }
  const  onChange = (e)=> {
        setfile(e.target.files[0])
     }
   const uploadFile = async (file)=>{
        

        const formData = new FormData();
        const user = JSON.parse(localStorage.getItem("user"));
   
        formData.append('resume',file);
        formData.append('id',id)
     
        formData.append('session',user.SessionId);
    
        return  await axios.post(UPLOAD_ENDPOINT, formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
      }
    


    return(

<>

<ApplyInternshipStyled>

<form onSubmit={onSubmit} className="position-relative">
          




   <input type="file" className=""  accept="application/pdf" onChange={onChange}/>
       <br/>
       
            <button className="btn btn-primary w-100 mt-5" type="submit">{status?<CircularProgress className="text-white " size={20}/>:""}Upload Task</button>
          
          
         
          </form>



          </ApplyInternshipStyled>
</>

    )
} 

   

export default SubmitTask;

const ApplyInternshipStyled = styled.div`

input, textarea{
  border: 1px solid lightgrey;
  border-radius:5px;
  margin-bottom: 10px;
}

`
import React from 'react'
import InternalTemplate from '../../themes/InternalTemplate'

import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import { useParams } from 'react-router'

import UsersList from './users/UsersList'


const InternalUsersManagement = ({permission}) => {
const params = useParams();

const {user} =  useContext(AuthContext)


    
    
    
    
    
    
    
    
    
    
    
    




    return (
<>


<UsersList permission={permission}/>
</>

    )
}

export default InternalUsersManagement







